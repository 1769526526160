import styled from '@emotion/styled';
import { Button } from '../../shared/components/ui';
import { Alert } from '@chakra-ui/react';

export const StyledLoginContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 2rem;

  > form {
    display: grid;
    grid-gap: 1rem;
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.radii.sm};
    box-shadow: ${({ theme }) => theme.shadow.card};
    padding: 2rem;

    > p {
      color: ${({ theme }) => theme.colors.text.main};
      font-size: ${({ theme }) => theme.fontSize.xl};
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    }
  }
`;

export const StyledShowPassButton = styled(Button)<{ active: boolean }>`
  background: transparent;
  border-radius: 100%;
  box-shadow: none;
  padding: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;

  svg {
    width: 1.25rem;
    height: auto;
  }

  svg,
  path {
    fill: ${({ theme, active }) => (active && theme.palette.primary) || theme.palette.neutral50};
  }
`;

export const StyledAlert = styled(Alert)`
  border-radius: ${({ theme }) => theme.radii.sm};

  > div {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-weight: unset;
  }
`;
