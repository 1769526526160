import { useMemo } from 'react';
import { range } from '../utils/utils';
import { getYear } from 'date-fns';

export const useGetYears = (start: number = 1900, end: number | null = null) => {
  return useMemo(
    () =>
      range(start, end ?? getYear(new Date()) + 1).map((year) => ({
        label: year.toString(),
        value: year,
      })),
    [start, end],
  );
};
