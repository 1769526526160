import styled from '@emotion/styled';
import { Divider, Text } from '@chakra-ui/react';
import { Button } from '../../../../shared/components/ui';

export const StyledUserPageWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  max-width: 40rem;
  margin-inline: auto;
`;

export const StyledCard = styled.div`
  padding: 1.5rem 2rem 2rem;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadow.card};
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
`;

export const StyledUserPreview = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
`;

export const StyledDivider = styled(Divider)`
  background: ${({ theme }) => theme.palette.neutral10};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.palette.neutral40};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const StyledHeaderWithButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.palette.neutral40};
    font-family: ${({ theme }) => theme.fontFamily.bodyBold};
    text-transform: uppercase;
  }

  a {
    height: 2rem;
  }
`;

export const StyledDangerHeader = styled(StyledHeader)`
  color: ${({ theme }) => theme.palette.error};
`;

export const StyleUserInfoForm = styled.form`
  display: grid;
  grid-gap: 1rem;
`;

export const StyledSectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
`;

export const StyledSectionSubtitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledMainInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

export const StyledRolesActions = styled.div`
  margin-top: 1rem;
  display: flex;
  grid-gap: 0.75rem;
  align-items: center;

  svg {
    width: 1.25rem !important;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > button:first-of-type {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > div:nth-of-type(1) {
      display: none;
    }
  }
`;

export const StyledDangerZone = styled.div`
  border-radius: ${({ theme }) => theme.radii.sm};
  border: 1px solid ${({ theme }) => theme.palette.error};
  padding: 1.5rem 2rem 2rem;

  > div:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledDangerZoneSkeleton = styled(StyledDangerZone)`
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
`;

export const StyledRandomButton = styled(Button)`
  background: transparent;
  padding: 0;
  width: 2rem;
  height: 2rem;

  svg {
    width: 1.25rem;
    height: auto;
    fill: ${({ theme }) => theme.palette.neutral60};
  }
`;

export const StyledInlineWrapper = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr auto;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

  > label {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.placeholder};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;

    button {
      width: 100%;
    }
  }
`;
