import React, { useEffect } from 'react';
import useHandleTemplateUpload from '../requests/useHandleTemplateUpload';
import { UploaderDropzone, UploaderFilesStat } from '../../../../shared/components/custom';
import { PreparedFile } from '../../../../shared/components/custom/UploaderDropzone/helpers';
import { Template } from '../../../../shared/types/production/template';

const TemplateUploader = ({
  template,
  onTemplateUpdate,
  onTemplateDeleted,
}: {
  template: Template;
  onTemplateUpdate: Function;
  onTemplateDeleted: Function;
}) => {
  const {
    getScheme,
    uploadFiles,
    scheme,
    isLoading,
    isUploading,
    uploadProgress,
    totalUploadProgress,
    uploaded,
    isFinished,
    setIsFinished,
  } = useHandleTemplateUpload();

  const loadScheme = () => {
    getScheme(template.id);
  };

  useEffect(() => {
    loadScheme();
  }, []);

  const handleUpload = async (files: PreparedFile[]) => {
    await uploadFiles(template.id, files);
  };

  return (
    <div>
      <UploaderFilesStat
        isUploading={isUploading}
        currentFileProgress={uploadProgress}
        totalProgress={totalUploadProgress}
        scheme={scheme}
        isLoading={isLoading}
        onRefresh={loadScheme}
        template={template}
        onTemplateUpdate={onTemplateUpdate}
        onTemplateDeleted={onTemplateDeleted}
      />
      {scheme && (
        <UploaderDropzone
          uploadedNumber={uploaded}
          isUploading={isUploading}
          scheme={scheme}
          isFinished={isFinished}
          setIsFinished={setIsFinished}
          onUploadStart={handleUpload}
        />
      )}
    </div>
  );
};

export default TemplateUploader;
