import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { LinkButton } from '../../ui';

export const StyledNotFoundWrapper = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: calc(100vh - 20rem);
`;

export const StyledHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  margin-inline: auto;
  margin-top: 39vh;
`;

export const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  line-height: 1.25rem;
  text-align: center;
  margin-inline: auto;
  max-width: 18rem;
  margin-bottom: 2.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 20vh;
  }
`;

export const StyledButton = styled(LinkButton)`
  margin-inline: auto;
  width: 9.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
  }
`;
