import React from 'react';
import { StyledPaymentLinkWrapper } from '../styled';
import { Button, showToast } from '../../../../../shared/components/ui';
import { Icon } from '../../../../../shared/components/icons';
import { copyToClipBoard } from '../../../../../shared/utils/utils';
import { Text } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../../../shared/hooks';

const OrderPaymentLink = ({ link }: { link: string }) => {
  const { t } = useScopedTranslation('order.invoices');

  const copyLink = () => {
    copyToClipBoard(link);
    showToast('success', t('link_copied'));
  };

  return (
    (link && (
      <StyledPaymentLinkWrapper>
        <div>
          <p>{t('link_to_page')}</p>
          <Text noOfLines={1} onClick={copyLink} role={'button'}>
            {link}
          </Text>
        </div>
        <Button onClick={copyLink} fab noShadow>
          <Icon type={'Copy'} />
        </Button>
      </StyledPaymentLinkWrapper>
    )) || <></>
  );
};

export default OrderPaymentLink;
