import React, { useEffect, useState } from 'react';
import { StyledCard, StyledDivider, StyledHeader, StyledRolesActions } from '../styled';
import { Button, LinkButton } from '../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../shared/theme';
import useUserContext from '../hooks/useUserContext';
import useRolesApi from '../../../../../shared/requests/user/useRolesApi';
import { ButtonGroup } from '../../../../../shared/components/custom';
import { ControlPaths } from '../../../../../router/routes';
import { Spacer } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { BiRefresh } from 'react-icons/bi';
import { useScopedTranslation } from '../../../../../shared/hooks';

const RolesForm = () => {
  const { t } = useScopedTranslation('users.page');

  const { user, updateUser, isEdit } = useUserContext();
  const [isSaving, setIsSaving] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const { rolesOptions, isLoading, getRoles } = useRolesApi();

  useEffect(() => {
    if (user) setSelectedRoles(user.roles);
  }, [user]);

  const onSubmit = async () => {
    setIsSaving(true);
    await updateUser({ roles: selectedRoles, name: user?.name, email: user?.email });
    setIsSaving(false);
  };

  return (
    <StyledCard>
      <StyledHeader>{t('roles')}</StyledHeader>

      <ButtonGroup
        isDisabled={!isEdit}
        options={rolesOptions}
        defaultValue={selectedRoles}
        onChange={setSelectedRoles}
        isLoading={isLoading}
        size={ButtonSizes.sm}
        isMultiple
        skeletonNumber={6}
      />

      <StyledDivider />

      {isEdit && (
        <StyledRolesActions>
          <Button
            isLoading={isSaving}
            onClick={onSubmit}
            color={'primary'}
            noShadow
            size={ButtonSizes.sm}
          >
            {t('save')}
          </Button>
          <Spacer />
          <Button
            title={t('refresh_roles')}
            isLoading={isLoading}
            onClick={getRoles}
            noShadow
            size={ButtonSizes.sm}
          >
            <Icon as={BiRefresh} />
          </Button>
          <LinkButton target={'_blank'} to={ControlPaths.roles} noShadow size={ButtonSizes.sm}>
            {t('manage')}
          </LinkButton>
        </StyledRolesActions>
      )}
    </StyledCard>
  );
};

export default RolesForm;
