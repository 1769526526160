import React from 'react';
import useCountdown, { UnitType } from './hooks/useCountdown';
import { StyledCountdownTimer } from './styled';

type ICountdown = {
  date: string | null;
  initialTime?: number;
  successTitle?: string;
  onTargetReach?: Function;
  units?: UnitType[];
  labels?: boolean;
  milliseconds?: boolean;
};

const Countdown = ({
  date = null,
  initialTime = 0,
  successTitle = '',
  onTargetReach,
  units,
  labels,
  milliseconds,
  ...props
}: ICountdown) => {
  const timeRemaining = useCountdown({
    targetDate: date,
    initialTime,
    onTargetReach,
    units,
    labels,
    milliseconds,
  });

  return <StyledCountdownTimer {...props}>{timeRemaining ?? successTitle}</StyledCountdownTimer>;
};

export default Countdown;
