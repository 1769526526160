/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgMenu = (props) => (
  <svg {...props} width="20" height="15" viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4997 14.7996H1.23332C0.552153 14.7996 0 14.2475 0 13.5663C0 12.8852 0.552153 12.333 1.23332 12.333H18.4997C19.1808 12.333 19.733 12.8852 19.733 13.5663C19.733 14.2475 19.1808 14.7996 18.4997 14.7996Z" />
    <path d="M18.4997 8.6331H1.23332C0.552153 8.6331 0 8.08095 0 7.39982C0 6.7187 0.552153 6.1665 1.23332 6.1665H18.4997C19.1808 6.1665 19.733 6.71866 19.733 7.39982C19.733 8.08099 19.1808 8.6331 18.4997 8.6331Z" />
    <path d="M18.4997 2.46664H1.23332C0.552153 2.46664 0 1.91449 0 1.23332C0 0.552153 0.552153 0 1.23332 0H18.4997C19.1808 0 19.733 0.552153 19.733 1.23332C19.733 1.91449 19.1808 2.46664 18.4997 2.46664Z" />
  </svg>
);

export default SvgMenu;
