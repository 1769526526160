import { Gender } from '../../../shared/constants/Gender';
import { TrackType } from '../../../shared/constants/production/common';

export const defaultData = {
  type: TrackType.full,
  gender: Gender.female,
  segment_id: null,
  occasion_id: null,
  personalizations: {
    start_year: null,
    eyes: null,
    kids: null,
    months: null,
    spouse: null,
  },
  name_to: null,
  name_from: null,
  artist_id: null,
  client_voice_url: null,
  template_id: null,
  intro_file_id: null,
};
