import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { designSystem } from '../../../theme';
import { StyledReactQuill } from './styled';

const RichTextEditor = ({
  value,
  onChange,
  setEditor,
}: {
  value: string;
  onChange: (value: string) => void;
  setEditor: Function;
}) => {
  const inputRef = useRef<ReactQuill>(null);

  useEffect(() => setEditor(inputRef?.current), [inputRef]);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [
        {
          color: [
            designSystem.palette.neutral90,
            designSystem.palette.primary,
            designSystem.palette.success,
            designSystem.palette.error,
          ],
        },
      ],
      [{ align: [] }],
    ],
  };

  const formats = ['bold', 'italic', 'underline', 'strike', 'color', 'align'];

  return (
    <StyledReactQuill
      ref={inputRef}
      value={value}
      modules={modules}
      formats={formats}
      onChange={onChange}
    />
  );
};

export default RichTextEditor;
