import styled from '@emotion/styled';
import { Icon } from '../../icons';
import { Text } from '@chakra-ui/react';

export const StyledTemplatesWrapper = styled.div`
  display: grid;
  grid-gap: 0.75rem;
`;

export const StyledArtistTemplatesTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 1rem;
`;

export const StyledTemplateCard = styled.div<{ selected?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadow.card};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1rem;
  background: ${({ theme, selected }) =>
    (selected && theme.palette.primary) || theme.palette.white};
  transition: 0.2s ease-in-out;

  > span:last-of-type {
    transition: 0.2s ease-in-out;
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme, selected }) => (selected && theme.palette.white) || theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  &:hover {
    background: ${({ theme, selected }) =>
      (selected && theme.palette.primaryHover) || theme.palette.primary16};
  }
`;

export const StyledCreateTemplateCard = styled(StyledTemplateCard)`
  padding: 0.75rem 1rem;

  > button {
    width: 2rem;
    height: 2rem;
    padding: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.neutral10};
  }
`;

export const StyledStatusIcon = styled(Icon)<{ enabled: boolean }>`
  display: inline-block;
  margin-right: 0.5rem;

  &,
  path {
    fill: ${({ theme, enabled }) => (enabled && theme.palette.success) || theme.palette.error};
  }
`;

export const StyledSelectedTemplateCard = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  grid-gap: 0.75rem;
  align-items: center;
  background: ${({ theme }) => theme.palette.primary};
  border-radius: ${({ theme }) => theme.radii.sm};

  > span:last-of-type {
    color: ${({ theme }) => theme.palette.white};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  > button {
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
`;
