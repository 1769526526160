import { useState } from 'react';
import { ArtistsApi } from '../../../api';
import {
  Artist,
  ArtistSegmentScheme,
  ArtistSegmentSchemeSlot,
  CreateArtistRequest,
  SlotTypes,
  UpdateArtistRequest,
} from '../../types/production/artist';

const useHandleArtistApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [artist, setArtist] = useState<Artist | null>(null);
  const [schemes, setSchemes] = useState<ArtistSegmentScheme[]>([]);

  const getArtist = async (artistId: number) => {
    setIsLoading(true);
    await ArtistsApi.getById(artistId)
      .then((response) => {
        setArtist(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createArtist = async (data: CreateArtistRequest) => {
    setIsLoading(true);
    const createdArtist = await ArtistsApi.create(data)
      .then((response) => {
        setArtist(response.data);
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return createdArtist;
  };

  const updateArtist = async ({ id }: Artist, data: UpdateArtistRequest) => {
    setIsLoading(true);
    await ArtistsApi.update(id, data)
      .then((response) => {
        setArtist(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateAvatar = async ({ id }: Artist, file: File) => {
    setIsLoading(true);
    await ArtistsApi.updatePhoto(id, file)
      .then((response) => {
        setArtist(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateExample = async ({ id }: Artist, file: File) => {
    setIsLoading(true);
    await ArtistsApi.updateExample(id, file)
      .then((response) => {
        setArtist(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteArtist = async ({ id }: Artist) => {
    setIsLoading(true);
    await ArtistsApi.delete(id).finally(() => {
      setIsLoading(false);
    });
  };

  const getSchemes = async ({ id }: Artist) => {
    setIsLoading(true);
    await ArtistsApi.getSchemes(id)
      .then(setSchemes)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateSlot = async (
    { id: artistId }: Artist,
    { id: schemeId }: ArtistSegmentScheme,
    { id: slotId }: ArtistSegmentSchemeSlot,
    data: { type: SlotTypes; value: string | number | null },
  ) => {
    setIsLoading(true);
    await ArtistsApi.updateSlot(artistId, schemeId, slotId, data)
      .then((response) => {
        const newScheme = response.data;
        setSchemes(schemes.map((scheme) => (scheme.id === newScheme.id && newScheme) || scheme));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    getArtist,
    createArtist,
    updateArtist,
    updateAvatar,
    updateExample,
    deleteArtist,
    setArtist,
    isLoading,
    artist,
    getSchemes,
    schemes,
    updateSlot,
  };
};

export default useHandleArtistApi;
