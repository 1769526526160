import { SelectOption } from '../../shared/types/common';

export const findOptionValueInOptionsArray = (
  options: SelectOption[],
  value: number | string | null,
) => {
  return (options.find(
    (option) => parseInt(option.value.toString(), 10) === parseInt((value ?? '').toString(), 10),
  )?.value ?? null) as number | null;
};
