import React from 'react';
import { StyledRoleSectionHeader } from '../styled';
import { Button } from '../../../shared/components/ui';
import { ButtonSizes } from '../../../shared/theme';
import { AddIcon } from '@chakra-ui/icons';
import { ButtonGroup } from '../../../shared/components/custom';
import { Role } from '../../../shared/types/role';
import { useScopedTranslation } from '../../../shared/hooks';
import { SelectOption } from '../../../shared/types/common';
import useHasPermission from '../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../shared/constants/Permissions';

type RolesSelectorType = {
  roles: Role[];
  rolesOptions: SelectOption[];
  isLoading: boolean;
  selectedRole: Role | null;
  setSelectedRole: Function;
};

const RolesSelector = ({
  roles,
  rolesOptions,
  isLoading,
  selectedRole,
  setSelectedRole,
}: RolesSelectorType) => {
  const { t } = useScopedTranslation('roles');
  const isEdit = useHasPermission(Permissions.roleEdit);

  const onRoleCreate = () => {
    setSelectedRole({ id: null, name: '', permissions: [] });
  };

  const onRoleSelect = (roleName: string) => {
    const foundRole = roles.find((role) => role.name === roleName);
    if (foundRole?.id === selectedRole?.id) setSelectedRole(null);
    setSelectedRole(foundRole);
  };

  return (
    <>
      <StyledRoleSectionHeader>
        <p>{t('select_role')}</p>
        <div />
        {!isEdit && (
          <Button color={'primary'} noShadow size={ButtonSizes.sm} onClick={onRoleCreate}>
            <AddIcon />
            {t('add_role')}
          </Button>
        )}
      </StyledRoleSectionHeader>
      <div>
        <ButtonGroup
          defaultValue={selectedRole?.name}
          options={rolesOptions}
          isLoading={isLoading}
          onChange={onRoleSelect}
        />
      </div>
    </>
  );
};

export default RolesSelector;
