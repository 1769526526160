/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgFacebook = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5748 34V20H13V15.3333H17.5748V12.8273C17.5748 8.0825 19.8405 6 23.7062 6C25.5578 6 26.5368 6.14 27 6.20417V10.6667H24.3638C22.7226 10.6667 22.1496 11.5498 22.1496 13.3395V15.3333H26.9588L26.3058 20H22.1496V34H17.5748Z"
      {...props}
    />
  </svg>
);

export default SvgFacebook;
