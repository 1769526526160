import { useEffect, useState } from 'react';
import { Option, OptionNames, Order } from '../../../shared/types/orders';
import { OrdersApi } from '../../../api';
import { paymentCancelUrl, paymentSuccessUrl } from '../../../shared/utils/navigation';
import useRedirectOnError from '../../../shared/hooks/useRedirectOnError';
import { ControlPaths } from '../../../router/routes';
import { formatISO } from 'date-fns';

export const useOrderApi = (orderId?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);
  const redirect = useRedirectOnError(ControlPaths.orders);

  const getOrder = (id: string) => {
    setIsLoading(true);
    OrdersApi.find(id)
      .then(setOrder)
      .catch((error) => {
        redirect(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const makeCall = async (base: Promise<unknown>) => {
    const r = await base.then((response) => response).catch(() => null);
    return r;
  };

  const speedUpOrder = async (value: number) =>
    (order && (await makeCall(OrdersApi.speedUp(order.uuid, value)))) || null;

  const addOptions = async (options: OptionNames[], silent: boolean = false) => {
    if (!order) return '';

    if (silent) {
      const response = await OrdersApi.addOptionsSilent(order.uuid, options).catch(() => []);
      setOrder({ ...order, options: response });
      return response;
    } else {
      const response = await OrdersApi.addOptions(order.uuid, {
        success_url: paymentSuccessUrl(order.uuid, order.id),
        cancel_url: paymentCancelUrl(order.uuid),
        options,
      }).catch(() => null);
      return response?.payment_url ?? '';
    }
  };

  const updateOrder = async (data: Object) => {
    if (!order) return null;

    OrdersApi.update(order.uuid, data)
      .then(setOrder)
      .catch(() => {});
  };

  const updateInfo = async (data: Record<string, string>) => {
    if (!order) return;

    await OrdersApi.updateInfo(order.uuid, data)
      .then((response) => {
        setOrder({ ...order, info: response });
      })
      .catch(() => {});
  };

  const remake = async () => {
    if (!order) return null;
    await OrdersApi.remake(order.uuid)
      .then(setOrder)
      .catch(() => {});
  };

  const updateOption = (optionName: OptionNames, option: Option) => {
    return (
      order?.options.map(
        (orderOption) => (orderOption.name === optionName && option) || orderOption,
      ) ?? []
    );
  };

  const remakeClientVoice = async () =>
    (order &&
      (await makeCall(
        OrdersApi.remakeClientVoice(order.uuid)
          .then((responseOptions) => {
            responseOptions.forEach((option) => {
              setOrder({ ...order, options: updateOption(OptionNames.clientVoice, option) });
            });
          })
          .catch(() => {}),
      ))) ||
    null;

  const remakeSlideshow = async () =>
    (order &&
      (await makeCall(
        OrdersApi.remakeSlideshow(order.uuid)
          .then((option) => {
            setOrder({ ...order, options: updateOption(OptionNames.video, option) });
          })
          .catch(() => {}),
      ))) ||
    null;

  const uploadVoice = async (file: File) =>
    (order && (await makeCall(OrdersApi.uploadVoice(order.uuid, file)))) || null;

  const removeOption = async (option: OptionNames) => {
    if (!order) return null;

    await OrdersApi.removeOption(order.uuid, option)
      .then(() => {
        setOrder({ ...order, options: order.options.filter((opt) => opt.name !== option) });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (orderId) getOrder(orderId);
  }, [orderId]);

  return {
    getOrder,
    isLoading,
    order,
    setOrder,
    speedUpOrder,
    addOptions,
    updateInfo,
    updateOrder,
    remake,
    remakeClientVoice,
    remakeSlideshow,
    uploadVoice,
    removeOption,
  };
};
