import React from 'react';
import { Input } from '../../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { useMailingContext } from '../hooks/useMailingContext';

const ActionLabel = () => {
  const { t } = useScopedTranslation('mailing.page');
  const { register } = useMailingContext();

  return (
    <div>
      <p>{t('action_label')}</p>
      <Input
        register={register}
        name={'action_label'}
        placeholder={t('action_label_placeholder')}
      />
    </div>
  );
};

export default ActionLabel;
