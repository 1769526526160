import React from 'react';
import { FileStructureUnit } from '../../../../types/production/fileStructure';
import { StyledFileName } from '../styled';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { designSystem } from '../../../../theme';
import { Icon } from '../../../icons';

const SchemeFile = ({ file }: { file: FileStructureUnit }) => {
  return (
    <StyledFileName title={file.file_url}>
      {(file.is_uploaded && <CheckCircleIcon color={designSystem.palette.success} />) || (
        <WarningIcon color={designSystem.palette.error} />
      )}
      <Icon type={'File'} />
      <span>{file.name}</span>
    </StyledFileName>
  );
};

export default SchemeFile;
