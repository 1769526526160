import { SubmitHandler, useForm } from 'react-hook-form';
import { useOrderContext } from '../useOrderContext';

type FormType = {
  eye_color: string;
  dating_month: string;
  kid_gender: string;
  start_year: string;
};

const usePersonalizationsForm = () => {
  const { order, updateInfo } = useOrderContext();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = useForm<FormType>({
    mode: 'onBlur',
    defaultValues: {
      eye_color: order?.info?.eye_color,
      dating_month: order?.info?.dating_month,
      kid_gender: order?.info?.kid_gender,
      start_year: order?.info?.start_year,
    },
  });

  const onSubmit: SubmitHandler<FormType> = async (args) => {
    await updateInfo(args);
  };

  return { register, reset, getValues, onSave: handleSubmit(onSubmit), isDirty };
};

export default usePersonalizationsForm;
