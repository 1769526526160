import { useAppContext } from './useAppContext';
import { useMemo } from 'react';
import { Permissions } from '../constants/Permissions';
import { User } from '../types/user';

export const checkPermission = (user: User | null, permission: Permissions) =>
  (!!user && user.permissions.includes(permission)) || false;

const useHasPermission = (permission: Permissions) => {
  const { user } = useAppContext();

  return useMemo(() => checkPermission(user, permission), [user, permission]);
};

export default useHasPermission;
