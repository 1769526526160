import { useEffect, useState } from 'react';
import { PersonalizationApi } from '../../../../api';

const useHandleImport = ({
  type,
  isOpen,
  onUpload,
}: {
  type: string;
  isOpen: boolean;
  onUpload: Function;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [parsedAliases, setParsedAliases] = useState<string[]>([]);

  useEffect(() => {
    setSelectedFile(null);
    setParsedAliases([]);
  }, [isOpen]);

  const createFormData = (file: File, mode: 'upload' | 'parse') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('mode', mode);
    return formData;
  };

  const parseFile = (file: File) => {
    setSelectedFile(null);
    setParsedAliases([]);
    setIsLoading(true);

    const formData = createFormData(file, 'parse');

    PersonalizationApi.import(formData)
      .then((response) => {
        setSelectedFile(file);
        setParsedAliases(response.aliases);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadFile = () => {
    if (!selectedFile) return;
    setIsUploading(true);

    const formData = createFormData(selectedFile, 'upload');

    PersonalizationApi.import(formData)
      .then((response) => {
        onUpload();
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return { isLoading, isUploading, selectedFile, parsedAliases, parseFile, uploadFile };
};

export default useHandleImport;
