import React, { useMemo } from 'react';
import {
  StyledButton,
  StyledDetailsButton,
  StyledStatusBarWrapper,
  StyledStatusText,
  StyledStatWrapper,
  StyledTemplateEditWrapper,
} from './styled';
import { Button, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { RepeatIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { ButtonSizes, designSystem } from '../../../theme';
import {
  FileStructure,
  FileStructureUnit,
  FileStructureTypes,
} from '../../../types/production/fileStructure';
import SchemeTreeModal from './components/SchemeTreeModal';
import { Spinner, Switch } from '../../ui';
import CombinedProgressBar from './components/CombinedProgressBar';
import { useScopedTranslation } from '../../../hooks';
import { Template } from '../../../types/production/template';
import { TemplateEditorModal } from '../TemplateEditorModal';
import useHandleTemplateApi from '../../../requests/production/useHandleTemplateApi';
import { TemplateLyricsModal } from '../TemplateLyricsModal';

type StatProps = {
  isUploading?: boolean;
  currentFileProgress?: number;
  totalProgress?: number;
  scheme: FileStructure | null;
  isLoading: boolean;
  onRefresh: () => void;
  template?: Template;
  onTemplateUpdate?: Function;
  onTemplateDeleted?: Function;
};

const UploaderFilesStat = ({
  scheme,
  isLoading,
  onRefresh,
  isUploading = false,
  currentFileProgress = 0,
  totalProgress = 0,
  template = undefined,
  onTemplateUpdate = () => {},
  onTemplateDeleted = () => {},
}: StatProps) => {
  const { t, rootT } = useScopedTranslation('production.intro');

  const { updateTemplate } = useHandleTemplateApi();

  const toggleTemplateStatus = async () => {
    if (template) {
      const updatedTemplate = await updateTemplate(template, { enabled: !template.enabled });
      onTemplateUpdate(updatedTemplate);
    }
  };

  const checkUnitMissingFiles = (unit: FileStructureUnit): number => {
    if (unit.type === FileStructureTypes.folder) {
      return (
        unit.children?.reduce(
          (accumulator, child) => accumulator + checkUnitMissingFiles(child),
          0,
        ) ?? 0
      );
    }
    if (unit.is_uploaded) return 0;
    return 1;
  };

  const filesMissing = useMemo(() => {
    return (
      scheme?.scheme?.reduce((accumulator, unit) => accumulator + checkUnitMissingFiles(unit), 0) ??
      0
    );
  }, [scheme]);

  return (
    <StyledStatusBarWrapper>
      {(scheme && !isLoading && (
        <StyledStatWrapper isUploading={isUploading} completed={!filesMissing}>
          {(isUploading && (
            <>
              <Spinner size={'sm'} />
              <StyledStatusText>{t('uploading_files')}</StyledStatusText>
              <CombinedProgressBar progress={currentFileProgress} totalProgress={totalProgress} />
            </>
          )) || (
            <>
              {(!filesMissing && <CheckCircleIcon color={designSystem.palette.success} />) || (
                <WarningIcon color={designSystem.palette.error} />
              )}
              <StyledStatusText>
                {(!filesMissing && t('all_uploaded')) ||
                  rootT('production.intro_missing', { count: filesMissing })}
              </StyledStatusText>
              <SchemeTreeModal
                scheme={scheme}
                activator={<StyledDetailsButton>{t('details')}</StyledDetailsButton>}
              />
              <Button onClick={onRefresh}>
                <RepeatIcon color={designSystem.palette.neutral90} />
              </Button>
            </>
          )}
        </StyledStatWrapper>
      )) || (
        <StyledStatWrapper>
          <SkeletonCircle w={'1rem'} h={'1rem'} my={'0.5rem'} />
          <Skeleton w={'9rem'} h={'1rem'} />
          <Skeleton w={'5rem'} h={'1rem'} />
          <SkeletonCircle w={'1rem'} h={'1rem'} />
        </StyledStatWrapper>
      )}

      {template && (
        <>
          <StyledTemplateEditWrapper>
            <Switch defaultChecked={template.enabled} onChange={toggleTemplateStatus} />
            <TemplateEditorModal
              template={template}
              activator={<StyledDetailsButton>{t('template_settings')}</StyledDetailsButton>}
              onSave={onTemplateUpdate}
              onTemplateDeleted={onTemplateDeleted}
              onSchemeRefresh={onRefresh}
            />
          </StyledTemplateEditWrapper>
          <TemplateLyricsModal
            template={template}
            activator={
              <StyledButton noShadow size={ButtonSizes.sm}>
                {t('lyrics')}
              </StyledButton>
            }
          />
        </>
      )}
    </StyledStatusBarWrapper>
  );
};

export default UploaderFilesStat;
