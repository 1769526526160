import styled from '@emotion/styled';
import { Select } from '@chakra-ui/react';

export const StyledSelect = styled(Select)`
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 3.25rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-weight: 500;

  + .chakra-select__icon-wrapper {
    svg,
    path {
      fill: ${({ theme }) => theme.palette.neutral80};
    }
  }

  &:focus,
  &[data-focus] {
    border-color: ${({ theme }) => theme.palette.primary};
    box-shadow: none;
  }

  + .chakra-select__icon-wrapper {
    margin-right: 1.2rem;

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
`;
