import React, { useMemo } from 'react';
import { StyledSelect } from './styled';
import { SelectProps } from '@chakra-ui/react';
import { FieldErrorsImpl } from 'react-hook-form';
import { FormErrorMessage } from '../FormErrorMessage';
import { useScopedTranslation } from '../../../hooks';
import { Icon } from '../../icons';
import { Spinner } from '../Spinner';

type ISelect = {
  name: string;
  register?: Function;
  errors?: FieldErrorsImpl;
  placeholder?: string;
  options: Array<{ label: string; value: string | number }>;
  translationScope?: string;
  isLoading?: boolean;
  isReadOnly?: boolean;
};

const Select = ({
  name,
  isReadOnly = false,
  placeholder,
  options,
  register = () => {},
  errors = {},
  translationScope = '',
  isLoading = false,
  ...props
}: ISelect & SelectProps) => {
  const { t } = useScopedTranslation(translationScope);
  const errorMessage = useMemo(() => errors[name]?.message as string, [errors]);

  const RightEl = () => {
    return (isLoading && <Spinner size={'sm'} />) || <Icon type={'SelectToggler'} />;
  };

  return (
    <div>
      <StyledSelect {...register(name)} {...{ ...props, icon: <RightEl /> }}>
        {placeholder && <option value="">{placeholder}</option>}
        {options.map(({ label, value }, index) => (
          <option disabled={isReadOnly} value={value} key={`option-${value}-${index}`}>
            {t(label)}
          </option>
        ))}
      </StyledSelect>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </div>
  );
};

export default Select;
