import React, { useMemo } from 'react';
import { Select } from '../../../../../../shared/components/ui';
import { StyledSectionRow } from '../../styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { useOrderContext } from '../../hooks/useOrderContext';
import { SelectOption } from '../../../../../../shared/types/common';

export type PersonalizationSelectType = {
  register: Function;
  options: SelectOption[];
  label: string;
  name: string;
  isLoading?: boolean;
  defaultValue?: string;
};

const PersonalizationSelect = ({
  label,
  name,
  defaultValue,
  options,
  isLoading = false,
  register,
}: PersonalizationSelectType) => {
  const { isEdit } = useOrderContext();
  const { t } = useScopedTranslation('order.info');

  const optionsWithNull = useMemo(() => {
    const opts = [{ value: '', label: t('dont_mention') }, ...options];
    const def = (!!defaultValue && { value: defaultValue, label: defaultValue }) || null;
    return (!!def && [def, ...opts]) || opts;
  }, [options, defaultValue]);

  return (
    <StyledSectionRow>
      <label>{label}</label>
      <Select
        isReadOnly={!isEdit}
        name={name}
        options={optionsWithNull}
        register={register}
        isLoading={isLoading}
      />
    </StyledSectionRow>
  );
};

export default PersonalizationSelect;
