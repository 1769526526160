import { useEffect, useState } from 'react';
import { useTabFocus } from '../../../../hooks';

const useHandleTabInfo = ({
  isFinished,
  isUploading,
  uploadedNumber,
  totalFiles,
}: {
  isUploading: boolean;
  isFinished: boolean;
  uploadedNumber: number;
  totalFiles: number;
}) => {
  const [initialTitle] = useState(document.title);
  const isTabFocused = useTabFocus();

  useEffect(() => {
    if (isTabFocused) {
      document.title = initialTitle;
    } else {
      if (isFinished) document.title = `Uploading is completed`;
      else if (isUploading) document.title = `${uploadedNumber}/${totalFiles} is uploading...`;
    }
  }, [isTabFocused, uploadedNumber, isFinished, isUploading]);
};

export default useHandleTabInfo;
