import { Button } from '../../ui/Button';
import styled from '@emotion/styled';
import { Box, Grid, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';

export const StyledDropzoneButton = styled(Button)`
  width: 100%;
`;

export const StyledDropzoneContainer = styled.div<{
  isDragActive: boolean;
  isRejected: boolean;
}>`
  padding: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%237F85967A' stroke-width='2' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: ${({ theme }) => theme.radii.md};
  margin-top: 1rem;
  min-height: 58vh;
  transition: background-color 0.3s ease-in-out;
  background-color: ${({ theme, isDragActive }) =>
    (isDragActive && theme.palette.primary8) || 'transparent'};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    border-radius: ${({ theme }) => theme.radii.sm};
    padding: 2rem;
  }
`;

export const StyledActions = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  grid-gap: 0.5rem;

  button {
    height: 2rem;
    min-height: 2rem;

    &:last-of-type {
      min-width: 8rem;
    }
  }
`;

export const StyledDropzoneHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  text-align: center;

  > span {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;
    text-align: start;
    margin-bottom: 0.5rem;
  }
`;

export const StyledFilesGrid = styled(Grid)`
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StyledDroppedFolder = styled(Box)`
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.card};
`;

export const StyledFolderHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  grid-gap: 0.25rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};

  > span {
    margin-top: 0.25rem;
  }
`;

export const StyledIgnoredFiles = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.error};

  &:hover {
    color: ${({ theme }) => theme.palette.primaryBorder};
  }
`;

export const StyledIgnoredFileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
`;
