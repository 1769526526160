import { createSearchParams, useNavigate } from 'react-router-dom';
import { Paths } from '../../router/routes';

export const useNavigateWithParams = () => {
  const routerNavigate = useNavigate();

  const urlWithParams = (path: Paths, params: Record<string, string>) => {
    let replaceString = path as string;

    Object.keys(params).forEach((name: string) => {
      replaceString = replaceString.replace(`:${name}`, params[name]);
    });

    return replaceString;
  };

  const navigate = (path: Paths, params: Record<string, string> = {}) => {
    routerNavigate(urlWithParams(path, params));
  };

  const getFullPath = (path: string) => {
    return `${window.location.protocol}//${window.location.hostname}${path}`;
  };

  const fullPathWithParams = (path: Paths, params: Record<string, string>) => {
    return getFullPath(urlWithParams(path, params));
  };

  const urlWithSearchParams = (path: Paths, params: Record<string, string>) => {
    return `${path}?${createSearchParams(params)}`;
  };

  const fullPathWithSearchParams = (path: Paths, params: Record<string, string>) => {
    return getFullPath(urlWithSearchParams(path, params));
  };

  return {
    navigate,
    urlWithParams,
    fullPathWithParams,
    urlWithSearchParams,
    fullPathWithSearchParams,
  };
};
