/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgMusic = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6004 9.69045L18.6005 9.69044L28.2961 8.0238C28.2962 8.02379 28.2963 8.02378 28.2963 8.02377C29.2123 7.86721 30.1175 8.11543 30.784 8.69601C31.4507 9.27797 31.8385 10.1619 31.8385 11.1252V27.2583C31.8385 29.8913 29.7628 32.0223 27.2236 32.0223C24.6844 32.0223 22.6088 29.8913 22.6088 27.2583C22.6088 24.6253 24.6844 22.4943 27.2236 22.4943C28.0627 22.4943 28.8436 22.745 29.5281 23.1557L29.8311 23.3375V22.9843V16.3126V16.0753L29.5972 16.1155L17.5601 18.1843L17.394 18.2128V18.3814V27.2583C17.394 29.8913 15.3184 32.0223 12.7792 32.0223C10.24 32.0223 8.16439 29.8913 8.16439 27.2583C8.16439 24.6253 10.24 22.4943 12.7792 22.4943C13.6194 22.4943 14.3991 22.7449 15.0835 23.1569L15.3866 23.3393V22.9855V13.6072C15.3866 11.7183 16.8049 9.99838 18.6004 9.69045ZM17.394 15.8646V16.1019L17.6279 16.0617L29.6649 13.9942L29.8311 13.9657V13.7971V11.1252C29.8311 10.7941 29.7204 10.4881 29.4905 10.2882C29.2596 10.0862 28.9466 10.0232 28.6238 10.078L28.6234 10.078L18.9288 11.7447C18.5071 11.8172 18.1249 12.0746 17.8503 12.4088C17.5754 12.7433 17.394 13.1719 17.394 13.6072V15.8646ZM10.1718 27.2583C10.1718 28.7318 11.3353 29.9403 12.7792 29.9403C14.2231 29.9403 15.3866 28.7318 15.3866 27.2583C15.3866 25.7848 14.2231 24.5763 12.7792 24.5763C11.3353 24.5763 10.1718 25.7848 10.1718 27.2583ZM24.6162 27.2583C24.6162 28.7318 25.7798 29.9403 27.2236 29.9403C28.6675 29.9403 29.8311 28.7318 29.8311 27.2583C29.8311 25.7848 28.6675 24.5763 27.2236 24.5763C25.7798 24.5763 24.6162 25.7848 24.6162 27.2583Z"
      {...props}
      strokeWidth="0.4"
    />
  </svg>
);

export default SvgMusic;
