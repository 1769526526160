import React, { useEffect } from 'react';
import useGeneratorContext from '../hooks/useGeneratorContext';
import { useForm } from 'react-hook-form';
import useGeneratorPersonalizations from '../../../../shared/requests/production/useGeneratorPersonalizations';
import { Button, Input, Select } from '../../../../shared/components/ui';
import {
  PersonalizationRow,
  StyledDivider,
  StyledHeaderWithActionWrapper,
  StyledNamesWrapper,
} from '../styled';
import { SelectOption } from '../../../../shared/types/common';
import { getRandomElement } from '../../../../shared/utils/utils';
import { Personalization } from '../../../../shared/types/production/personalization';
import { Icon } from '../../../../shared/components/icons';
import { useScopedTranslation } from '../../../../shared/hooks';

type RowType = {
  label: string;
  name: string;
  register: Function;
  options: SelectOption[];
  hasPlaceholder?: boolean;
};

type FormType = {
  start_year?: string | null;
  eyes?: string | null;
  kids?: string | null;
  months?: string | null;
  spouse?: string | null;
  name_from: string | null;
  name_to: string | null;
};

const RowComponent = ({ label, name, register, options, hasPlaceholder = true }: RowType) => {
  const { t } = useScopedTranslation('production.generator');
  return (
    <PersonalizationRow>
      <p>{label}</p>
      <Select
        name={name}
        {...{ register }}
        options={options}
        placeholder={(hasPlaceholder && t('do_not_mention')) || ''}
      />
    </PersonalizationRow>
  );
};

const PersonalizationSelector = () => {
  const { t } = useScopedTranslation('production.generator');

  const { data, setData } = useGeneratorContext();
  const {
    segment_id: segmentId,
    personalizations: defaultValues,
    gender,
    name_to: dataNameTo,
    name_from: dataNameFrom,
  } = data;

  const { register, watch, reset, getValues } = useForm<FormType>({
    defaultValues: { ...defaultValues, name_from: dataNameFrom, name_to: dataNameTo },
    mode: 'onBlur',
  });

  const {
    eyes: eyesOptions,
    spouses: spousesOptions,
    years: yearsOptions,
    months: monthsOptions,
    kids: kidsOptions,
    namesTo,
    namesFrom,
    isLoading,
  } = useGeneratorPersonalizations(segmentId);

  const {
    eyes,
    months,
    spouse,
    start_year: startYear,
    kids,
    name_to: nameTo,
    name_from: nameFrom,
  } = watch();

  useEffect(() => {
    setData({
      ...data,
      personalizations: {
        eyes,
        months,
        spouse,
        start_year: startYear,
        kids,
      },
      name_from: nameFrom,
      name_to: nameTo,
    });
  }, [eyes, months, spouse, kids, startYear, nameFrom, nameTo]);

  const randomValue = (obj: SelectOption[] | Personalization[]) => {
    const el = getRandomElement(obj);
    return (el?.value || el?.name)?.toString() ?? null;
  };

  const randomize = () => {
    reset({
      ...getValues(),
      name_to: randomValue(namesTo),
      name_from: randomValue(namesFrom),
      eyes: randomValue(eyesOptions),
      months: randomValue(monthsOptions),
      spouse: randomValue(spousesOptions),
      start_year: randomValue(yearsOptions),
      kids: randomValue(kidsOptions),
    });
  };

  const swapNames = () => {
    if (dataNameTo && dataNameFrom) {
      reset({
        ...getValues(),
        name_to: dataNameFrom,
        name_from: dataNameTo,
      });
    }
  };

  useEffect(() => {
    swapNames();
  }, [gender]);

  return (
    <>
      <StyledHeaderWithActionWrapper>
        <p>{t('choose_personalization')}</p>
        <StyledDivider />
        <Button fab noShadow title={t('randomize')} onClick={randomize} isDisabled={isLoading}>
          <Icon type={'Dice'} />
        </Button>
      </StyledHeaderWithActionWrapper>
      <div>
        <StyledNamesWrapper>
          <p>{t('from')}</p>
          <Input
            name={'name_from'}
            {...{ register }}
            placeholder={t('name_from')}
            isLoading={!namesFrom.length || isLoading}
          />
          <p>{t('to')}</p>
          <Input
            name={'name_to'}
            {...{ register }}
            placeholder={t('name_to')}
            isLoading={!namesTo.length || isLoading}
          />
        </StyledNamesWrapper>
        <RowComponent {...{ label: t('eye'), register, name: 'eyes', options: eyesOptions }} />
        <RowComponent
          {...{ label: t('month'), register, name: 'months', options: monthsOptions }}
        />
        <RowComponent
          {...{ label: t('start_year'), register, name: 'start_year', options: yearsOptions }}
        />
        <RowComponent {...{ label: t('kids'), register, name: 'kids', options: kidsOptions }} />
        <RowComponent
          {...{
            label: t('spouse'),
            register,
            name: 'spouse',
            options: spousesOptions,
            hasPlaceholder: !spousesOptions.length,
          }}
        />
      </div>
    </>
  );
};

export default PersonalizationSelector;
