import styled from '@emotion/styled';
import { Divider, LinkBox } from '@chakra-ui/react';

export const StyledDashboardWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

export const StyledSWelcomeMessage = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledQuickStatsWrapper = styled.div`
  display: flex;
  grid-gap: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }

  > div {
    flex: 1 1;
  }
`;

export const StyledQuickStatCard = styled.div`
  border-radius: ${({ theme }) => theme.radii.sm};
  display: grid;
  grid-template-columns: auto 1px 1fr 7rem;
  grid-gap: 1.5rem;
  align-items: center;
  justify-content: start;
  background: ${({ theme }) => theme.palette.neutral10};
  padding: 0.5rem 1.5rem;

  > div {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.palette.neutral60};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};

    > span {
      font-family: ${({ theme }) => theme.fontFamily.bodyBold};
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const StyledDivider = styled(Divider)``;

export const StyledVerticalDivider = styled.div`
  height: 70%;
  width: 1px;
  background: ${({ theme }) => theme.palette.neutral20};
`;

export const StyledGraph = styled.div`
  canvas {
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 1rem;
  align-items: center;

  > p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.palette.neutral90};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  > div {
    height: 1px;
    background: ${({ theme }) => theme.palette.neutral10};
  }

  > a {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const StyledMainWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledOrdersGrid = styled.div`
  display: grid;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 1;
  }
`;

export const StyledQuickWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    order: 0;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledQuickCard = styled(LinkBox)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.primary8};

  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.palette.neutral80};

  > div:first-of-type {
    border-radius: 100%;
    background: ${({ theme }) => theme.palette.primary16};
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.sm};

    > div:first-of-type {
      width: 2rem;
      height: 2rem;
    }
  }
`;
