import fixWebmDuration from 'fix-webm-duration';

export type RecordingResultType = {
  audioBlob: Blob;
  audioUrl: string;
};

export type RecorderType = {
  start: Function;
  stop: Function;
  isSettingUp: boolean;
};

export const audioRecorder = () => {
  return new Promise<RecorderType>((resolve) => {
    let startTime = 0;
    let isSettingUp: boolean = false;
    let mediaRecorder: MediaRecorder | null = null;
    let stream: MediaStream | null = null;
    const audioChunks: Blob[] = [];

    const mimeType = ['audio/wav', 'audio/mp4', 'audio/mpeg', 'audio/webm', 'audio/ogg'].filter(
      MediaRecorder.isTypeSupported,
    )[0];

    const start = () => {
      isSettingUp = true;
      return new Promise((resolveStart, rejectStart) => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mediaStream) => {
            stream = mediaStream;

            mediaRecorder = new MediaRecorder(mediaStream, {
              mimeType,
            });

            mediaRecorder.addEventListener('dataavailable', (event) => {
              audioChunks.push(event.data);
            });

            mediaRecorder.start();

            startTime = Date.now();

            resolveStart(true);
          })
          .catch(() => rejectStart(false))
          .finally(() => {
            isSettingUp = false;
          });
      });
    };

    const stop = () => {
      return new Promise<RecordingResultType>((resolveStop) => {
        mediaRecorder?.addEventListener('stop', () => {
          const duration = Date.now() - startTime;
          const audioBlob = new Blob(audioChunks, { type: mimeType });

          fixWebmDuration(audioBlob, duration, { logger: false }).then(function (fixedBlob) {
            const audioUrl = URL.createObjectURL(fixedBlob);

            stream?.getTracks().forEach((track) => track.stop());

            resolveStop({ audioBlob: fixedBlob, audioUrl });
          });
        });

        mediaRecorder?.stop();
      });
    };

    resolve({ start, stop, isSettingUp });
  });
};
