export const MOTION_VARIANT = {
  initial: () => ({
    opacity: 0,
  }),
  animate: () => ({
    opacity: 1,
  }),
  exit: () => ({
    opacity: 0,
  }),
};
