import React, { useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from '../../ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledFormActionsWrapper } from './styled';
import { ButtonSizes } from '../../../theme';
import useHandleTemplateApi from '../../../requests/production/useHandleTemplateApi';
import { CreateTemplateScheme } from './validationScheme';
import { useScopedTranslation } from '../../../hooks';
import useGetSegments from '../../../requests/production/useGetSegments';
import SegmentsSelector from './components/SegmentsSelector';

type TemplateCreateModalType = {
  artistId: number;
  activator: React.ReactNode;
  onCreated: Function;
};

const TemplateCreateModal = ({ artistId, activator, onCreated }: TemplateCreateModalType) => {
  const { t } = useScopedTranslation('production.templates');
  const { segments, isLoading, getSegments } = useGetSegments();
  const [selectedSegments, setSelectedSegments] = useState<number[]>([]);

  const segmentOptions = useMemo(() => {
    return segments.map((segment) => ({ value: segment.id, label: segment.name }));
  }, [segments]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createTemplate, isLoading: isCreating } = useHandleTemplateApi();

  useEffect(() => {
    setSelectedSegments([]);
    if (isOpen && artistId) getSegments(artistId);
  }, [isOpen, artistId]);

  const { register, reset, formState, handleSubmit } = useForm({
    resolver: yupResolver(CreateTemplateScheme),
  });
  const { isValid } = formState;

  useEffect(() => {
    reset({ name: '' });
  }, [isOpen]);

  const onSubmit = async (args: Record<string, string>) => {
    const createdTemplate = await createTemplate({
      name: args.name,
      segments_ids: selectedSegments,
      artist_id: artistId,
      enabled: false,
    });
    onCreated(createdTemplate);
    onClose();
  };

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal {...{ isOpen, onOpen, onClose }}>
        <ModalHeader>{t('create_template')}</ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Input name={'name'} {...{ register }} placeholder={t('enter_name')} />
            <SegmentsSelector
              segments={segmentOptions}
              defaultValue={selectedSegments}
              isLoading={isLoading}
              onChange={setSelectedSegments}
            />
          </ModalBody>
          <ModalFooter>
            <StyledFormActionsWrapper>
              <Button noShadow size={ButtonSizes.sm} type={'button'} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                noShadow
                size={ButtonSizes.sm}
                type={'submit'}
                color={'primary'}
                isDisabled={!isValid || !selectedSegments.length}
                isLoading={isCreating}
              >
                {t('create')}
              </Button>
            </StyledFormActionsWrapper>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default TemplateCreateModal;
