import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRouteGuard } from './hooks/useRouteGuard';
import { MainLayout, NotFound, PageLoader } from '../shared/components/custom';
import { CommonPaths, ControlPaths, ProductionPaths, PublicPaths } from './routes';
import {
  ArtistPage,
  Artists,
  Dashboard,
  Generator,
  IntroPage,
  Invoices,
  LetterPage,
  LettersList,
  LoginPage,
  Mailing,
  OrderPage,
  Orders,
  OrdersList,
  Personalization,
  Prices,
  Roles,
  SegmentPage,
  Segments,
  TemplatePage,
  UserPage,
  Users,
  UsersList,
  SettingsPage,
} from '../modules';
import useGetUser from './requests/useGetUser';

const RootRouter = () => {
  useRouteGuard();
  useGetUser();

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={PublicPaths.login} element={<LoginPage />} />

        <Route path={CommonPaths.dashboard} element={<MainLayout />}>
          <Route index element={<Dashboard />} />

          {/*invoices*/}
          <Route path={ControlPaths.invoices} element={<Invoices />} />

          {/*orders*/}
          <Route path={ControlPaths.orders} element={<Orders />}>
            <Route index element={<OrdersList />} />
            <Route path={ControlPaths.orderPage} element={<OrderPage />} />
          </Route>

          {/*users*/}
          <Route path={ControlPaths.users} element={<Users />}>
            <Route index element={<UsersList />} />
            <Route path={ControlPaths.userPage} element={<UserPage />} />
          </Route>

          {/*mailing*/}
          <Route path={ControlPaths.mailing} element={<Mailing />}>
            <Route index element={<LettersList />} />
            <Route path={ControlPaths.letterPage} element={<LetterPage />} />
          </Route>

          {/*settings*/}
          <Route path={ControlPaths.roles} element={<Roles />} />
          <Route path={ControlPaths.prices} element={<Prices />} />

          {/*production*/}
          <Route path={ProductionPaths.generator} element={<Generator />} />
          <Route path={ProductionPaths.personalization} element={<Personalization />} />
          <Route path={ProductionPaths.segments} element={<Segments />} />
          <Route path={ProductionPaths.segmentPage} element={<SegmentPage />} />
          <Route path={ProductionPaths.artists} element={<Artists />} />
          <Route path={ProductionPaths.artistPage} element={<ArtistPage />} />
          <Route path={ProductionPaths.intro} element={<IntroPage />} />
          <Route path={ProductionPaths.templates} element={<TemplatePage />} />
          <Route path={ProductionPaths.settings} element={<SettingsPage />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RootRouter;
