import styled from '@emotion/styled';
import { Card } from '../../ui';
import { Avatar, Grid, Text } from '@chakra-ui/react';

export const StyledArtistCard = styled(Card)`
  grid-gap: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  padding: 1rem;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1rem;
  }
`;

export const StyledHeaderWrapper = styled(Grid)`
  align-items: center;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
`;

export const StyledArtistAvatar = styled(Avatar)<{ enabled: boolean }>`
  width: 3.5rem;
  height: 3.5rem;
  border: 2px solid
    ${({ theme, enabled }) => (enabled && theme.palette.success) || theme.palette.error};

  border-radius: ${({ theme }) => theme.radii.md};
  overflow: hidden;

  img {
    border-radius: unset;
  }

  .chakra-avatar__initials {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;

export const StyledArtistInfo = styled(Grid)``;

export const StyledArtistName = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledArtistDescription = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 1rem;
`;

export const StyledArtistExampleWrapper = styled.div``;
