import React from 'react';
import { StyledMainLayout, StyledContentContainer, StyledPageWrapper } from './styled';
import { Header, Sidebar } from '../index';
import { Footer } from '../Footer';
import { Outlet } from 'react-router-dom';
import { useAppContext } from '../../../hooks';

const MainLayout = () => {
  const { fluid } = useAppContext();

  return (
    <StyledMainLayout>
      <Sidebar />
      <StyledPageWrapper>
        <Header />
        <StyledContentContainer fluid={fluid}>
          <Outlet />
        </StyledContentContainer>
        <Footer />
      </StyledPageWrapper>
    </StyledMainLayout>
  );
};

export default MainLayout;
