import React from 'react';
import { StyledActionsWrapper, StyledLoadingWrapper } from '../styled';
import { Button, Spinner } from '../../../../shared/components/ui';
import useGeneratorContext from '../hooks/useGeneratorContext';
import { TrackType } from '../../../../shared/constants/production/common';
import ClientVoice from './clientVoice/ClientVoice';
import { useScopedTranslation } from '../../../../shared/hooks';

const GeneratorActions = () => {
  const { t } = useScopedTranslation('production.generator');

  const { produceTrack, data, setData, isProducing } = useGeneratorContext();

  const produce = (type: TrackType) => async () => {
    await produceTrack({ ...data, type });
  };

  const addVoice = (fileUrl: string) => {
    setData({ ...data, client_voice_url: fileUrl });
  };

  return (
    <>
      <ClientVoice onAdd={addVoice} />

      {(isProducing && (
        <StyledLoadingWrapper>
          <Spinner size={'md'} />
          <p>{t('track_is_producing')}</p>
        </StyledLoadingWrapper>
      )) || (
        <StyledActionsWrapper>
          <Button color={'primary'} onClick={produce(TrackType.fullTag)}>
            {t('demo')}
          </Button>
          <Button color={'primary'} onClick={produce(TrackType.full)}>
            {t('full_track')}
          </Button>
          <Button color={'primary'} onClick={produce(TrackType.fullBack)}>
            {t('back_vocal')}
          </Button>
        </StyledActionsWrapper>
      )}
    </>
  );
};

export default GeneratorActions;
