import React from 'react';
import { Input } from '../../../../../shared/components/ui';
import VariablesButtons from './VariablesButtons';
import { useInsertValue, useScopedTranslation } from '../../../../../shared/hooks';
import { useMailingContext } from '../hooks/useMailingContext';

const SubjectInput = () => {
  const { t } = useScopedTranslation('mailing.page');

  const { reset, register, variables, watch, getValues } = useMailingContext();
  const { ref, rest, addValue } = useInsertValue({ register, name: 'subject' });
  const subject = watch('subject');

  const onAdded = (value: string) => {
    reset({ ...getValues(), subject: addValue(subject, value) });
  };

  return (
    <div>
      <p>{t('subject')}</p>
      <Input ref={ref} {...rest} placeholder={t('subject_placeholder')} />
      <VariablesButtons variables={variables} onAdded={onAdded} />
    </div>
  );
};

export default SubjectInput;
