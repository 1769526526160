import React from 'react';
import {
  StyledArtistCard,
  StyledArtistDescription,
  StyledArtistExampleWrapper,
  StyledArtistInfo,
  StyledHeaderWrapper,
} from './styled';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';

const CardArtistSkeleton = ({ ...props }: { children?: React.ReactNode }) => {
  return (
    <StyledArtistCard {...{ ...props }}>
      <StyledHeaderWrapper>
        <SkeletonCircle w={'3.5rem'} height={'3.5rem'} />
        <StyledArtistInfo>
          <Skeleton w={'100%'} height={'1rem'} mb={'0.5rem'} />
          <StyledArtistDescription>
            <Skeleton w={'90%'} height={'0.5rem'} mb={'0.25rem'} />
            <Skeleton w={'45%'} height={'0.5rem'} />
          </StyledArtistDescription>
        </StyledArtistInfo>
      </StyledHeaderWrapper>
      <StyledArtistExampleWrapper>
        <SkeletonCircle w={'2rem'} height={'2rem'} />
      </StyledArtistExampleWrapper>
    </StyledArtistCard>
  );
};

export default CardArtistSkeleton;
