/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgDot = (props) => (
  <svg {...props} width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.614 4.78569C9.57926 4.81852 9.52887 4.84385 9.5178 4.90666L8.66536 5.58341L8.2487 6.00008L8.66536 6.41675L9.45633 7.09753C9.51512 7.15533 9.54987 7.19137 9.58652 7.22562C10.5891 8.16013 11.5928 9.09357 12.5945 10.0288C12.9836 10.392 12.9813 10.8334 12.5934 11.1988C12.4663 11.3186 12.3381 11.4374 12.2099 11.5561L12.2097 11.5563C11.8092 11.9264 11.3331 11.9264 10.9334 11.5528C9.16542 9.90141 7.39817 8.24898 5.63054 6.59691C5.56488 6.53554 5.50112 6.47238 5.45378 6.39709C5.24686 6.06882 5.31138 5.7045 5.63322 5.4037C7.40046 3.75127 9.16847 2.0992 10.9361 0.447482C10.9708 0.415369 11.0063 0.383969 11.0438 0.354709C11.3144 0.142759 11.6913 0.0981562 11.9833 0.282275C12.2918 0.476385 12.5594 0.724731 12.7652 1.0134C12.9866 1.32454 12.9061 1.70063 12.593 1.99429C11.6004 2.92524 10.6066 3.85475 9.614 4.78569Z" />
    <path d="M3.60865 4.78569C3.64339 4.81852 3.69379 4.84385 3.70486 4.90666L4.55729 5.58341L4.97396 6.00008L4.55729 6.41675L3.76633 7.09753C3.70753 7.15533 3.67279 7.19137 3.63614 7.22562C2.63359 8.16013 1.6299 9.09357 0.628119 10.0288C0.239088 10.392 0.241379 10.8334 0.629264 11.1988C0.756317 11.3186 0.884515 11.4374 1.01271 11.5561L1.01295 11.5563C1.41343 11.9264 1.88951 11.9264 2.28923 11.5528C4.05724 9.90141 5.82449 8.24898 7.59211 6.59691C7.65778 6.53554 7.72153 6.47238 7.76888 6.39709C7.9758 6.06882 7.91128 5.7045 7.58944 5.4037C5.82219 3.75127 4.05419 2.0992 2.28656 0.447482C2.25182 0.415369 2.21631 0.383969 2.1789 0.354709C1.90822 0.142759 1.5314 0.0981562 1.23934 0.282275C0.930869 0.476385 0.663243 0.724731 0.457465 1.0134C0.236034 1.32454 0.316589 1.70063 0.629646 1.99429C1.62227 2.92524 2.61603 3.85475 3.60865 4.78569Z" />
  </svg>
);

export default SvgDot;
