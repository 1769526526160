import React, { useState } from 'react';
import {
  StyledHeader,
  StyledOptionCard,
  StyledPlayerWrapper,
  StyledSettingsWrapper,
} from './styled';
import useSettingsApi from '../../../shared/requests/production/useSettingsApi';
import { useScopedTranslation } from '../../../shared/hooks';
import { AudioPlayer, Button, FileInput } from '../../../shared/components/ui';

const SettingsPage = () => {
  const { settings, isLoading, updateTag, getSettings } = useSettingsApi();
  const { t } = useScopedTranslation('production.settings');

  const [file, setFile] = useState<File | null>(null);

  const onUpload = async () => {
    if (file) await updateTag(file);
    setFile(null);
    await getSettings();
  };

  return (
    <StyledSettingsWrapper>
      <StyledHeader>{t('header')}</StyledHeader>
      {settings.map((setting) => (
        <StyledOptionCard key={setting.name}>
          <p>{t(`names.${setting.name}`)}</p>
          {(!!file && (
            <StyledPlayerWrapper>
              <p>{file.name}</p>
              <p>{(file.size / 1024).toFixed(2)} KB</p>
            </StyledPlayerWrapper>
          )) || (
            <StyledPlayerWrapper>
              <AudioPlayer src={setting.value} withDuration withProgress />
            </StyledPlayerWrapper>
          )}
          <FileInput noShadow onFileChange={setFile} isDisabled={isLoading} accept={'audio/*'} />
          <Button color={'primary'} onClick={onUpload} isDisabled={!file} isLoading={isLoading}>
            {t('upload')}
          </Button>
        </StyledOptionCard>
      ))}
    </StyledSettingsWrapper>
  );
};

export default SettingsPage;
