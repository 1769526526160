import React from 'react';
import {
  StyledInitialAmount,
  StyledInvoiceActions,
  StyledInvoiceAmount,
  StyledInvoiceCard,
  StyledInvoiceDate,
  StyledInvoiceInfo,
  StyledInvoicePositionRow,
  StyledInvoicePromocode,
  StyledInvoiceReason,
  StyledInvoiceStatus,
  StyledOrderId,
  StyledPositionsInfo,
} from './styled';
import { Invoice, InvoiceStatuses } from '../../../types/invoice';
import { toPrettyDate } from '../../../utils/utils';
import { useNavigateWithParams, useScopedTranslation } from '../../../hooks';
import { Button, LinkButton } from '../../ui';
import { ButtonSizes } from '../../../theme';
import { ControlPaths } from '../../../../router/routes';
import useInvoiceApi from '../../../requests/order/useInvoiceApi';
import { Flex } from '@chakra-ui/react';

const InvoiceCard = ({
  invoice,
  noOrder = false,
  onUpdate,
  ...props
}: {
  invoice: Invoice;
  noOrder?: boolean;
  onUpdate: Function;
}) => {
  const { t } = useScopedTranslation('order.invoices');
  const { urlWithParams } = useNavigateWithParams();
  const { checkStatus, isChecking } = useInvoiceApi(invoice);

  const syncInvoice = async () => {
    const updatedInvoice = await checkStatus();
    onUpdate(updatedInvoice);
  };

  return (
    <StyledInvoiceCard {...props}>
      <StyledInvoiceInfo>
        <div>
          <StyledInvoiceStatus status={invoice.status}>
            {t(`statuses.${invoice.status}`)}
          </StyledInvoiceStatus>
          <StyledInvoiceDate>
            {toPrettyDate(invoice.created_at, 'dd.MM.yyyy HH:mm')}
          </StyledInvoiceDate>
        </div>
        <div>
          <StyledOrderId>{t('order_id', { orderId: invoice.order_id })}</StyledOrderId>
          <StyledInvoiceReason>{t(`reasons.${invoice.reason}`)}</StyledInvoiceReason>
        </div>
        <div>
          <div>
            <StyledInvoiceAmount>${invoice.final_amount.toFixed(2)}</StyledInvoiceAmount>
            {invoice.final_amount !== invoice.amount && (
              <StyledInitialAmount>${invoice.amount.toFixed(2)}</StyledInitialAmount>
            )}
          </div>
          {!!invoice.promocode && (
            <Flex gridGap={'0.25rem'}>
              <StyledInvoiceReason>{t('using_code')} </StyledInvoiceReason>
              <StyledInvoicePromocode>{invoice.promocode}</StyledInvoicePromocode>
            </Flex>
          )}
        </div>
        <StyledInvoiceActions>
          {invoice.receipt && (
            <LinkButton size={ButtonSizes.sm} noShadow to={invoice.receipt} target={'_blank'}>
              {t('receipt')}
            </LinkButton>
          )}
          {invoice.status !== InvoiceStatuses.paid && (
            <Button size={ButtonSizes.sm} noShadow onClick={syncInvoice} isLoading={isChecking}>
              {t('check_status')}
            </Button>
          )}
          {!noOrder && (
            <LinkButton
              size={ButtonSizes.sm}
              noShadow
              color={'primary'}
              to={urlWithParams(ControlPaths.orderPage, { orderId: invoice.order_uuid })}
            >
              {t('go_to_order')}
            </LinkButton>
          )}
        </StyledInvoiceActions>
      </StyledInvoiceInfo>

      <StyledPositionsInfo>
        {invoice.positions?.map((position) => (
          <StyledInvoicePositionRow key={position.id}>
            <div>{t(`positions.${position.name}`)}</div>
            <div></div>
            <div>
              <span>${position.final_amount.toFixed(2)}</span>
              {position.final_amount !== position.amount && (
                <StyledInitialAmount>${position.amount.toFixed(2)}</StyledInitialAmount>
              )}
            </div>
            <div></div>
          </StyledInvoicePositionRow>
        ))}
      </StyledPositionsInfo>
    </StyledInvoiceCard>
  );
};

export default InvoiceCard;
