import React, { useMemo } from 'react';
import { useCompletionPercentage } from '../../../../../shared/hooks';
import { StyledProgress } from '../styled';
import { ProgressProps } from '@chakra-ui/react';

type StatusProgressBarType = {
  isFinished: boolean;
  startedAt: string;
  finishAt: string;
  isIndeterminate?: boolean;
} & ProgressProps;

const StatusProgressBar = ({
  startedAt,
  finishAt,
  isFinished,
  isIndeterminate = false,
  ...props
}: StatusProgressBarType) => {
  const completion = useCompletionPercentage(startedAt, finishAt);

  const progress = useMemo(() => {
    if (isIndeterminate) return 0;
    if (isFinished) return 100;
    return completion;
  }, [completion, isFinished, isIndeterminate]);

  return <StyledProgress {...{ ...props, isIndeterminate, min: 0, max: 100 }} value={progress} />;
};

export default StatusProgressBar;
