import React, { useEffect, useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import { FileInput } from '../../../../shared/components/ui';
import { StyledArtistAvatarInputWrapper } from '../styled';
import { readAsDataUrl } from '../../../../shared/utils/imageUtils';
import { useScopedTranslation } from '../../../../shared/hooks';

const ArtistAvatarInput = ({
  name,
  avatar,
  setAvatar,
}: {
  name: string;
  avatar: File | null;
  setAvatar: Function;
}) => {
  const { t } = useScopedTranslation('production.artists');

  const [tempAvatarSrc, setTempAvatarSrc] = useState<string>('');

  useEffect(() => {
    if (avatar) readAsDataUrl(avatar).then(setTempAvatarSrc);
    else setTempAvatarSrc('');
  }, [avatar]);

  return (
    <StyledArtistAvatarInputWrapper>
      <Avatar size={'lg'} name={name} src={tempAvatarSrc} />
      <FileInput accept={'image/*'} color={'primary'} onFileChange={setAvatar}>
        <div role={'button'}>{(avatar && t('change_photo')) || t('select_photo')}</div>
      </FileInput>
    </StyledArtistAvatarInputWrapper>
  );
};

export default ArtistAvatarInput;
