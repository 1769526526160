import React, { useMemo, useState } from 'react';
import {
  StyledAddOptionsWrapper,
  StyledHeader,
  StyledOptionsUnavailableOverlay,
  StyledPanel,
  StyledSpeedupActionsWrapper,
  StyledSpeedupHeader,
} from '../../styled';
import { OptionNames, Order } from '../../../../../../shared/types/orders';
import { ButtonGroup } from '../../../../../../shared/components/custom';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Button } from '../../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../../shared/theme';
import { useOrderContext } from '../../hooks/useOrderContext';
import OrderPaymentLink from '../OrderPaymentLink';
import { OrderStatuses } from '../../../../../../shared/constants/OrderStatuses';

const OrderSpeedUp = ({ order }: { order: Order }) => {
  const { t } = useScopedTranslation('order.options');
  const [selectedOptions, setSelectedOptions] = useState<OptionNames[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<string>('');
  const { addOptions } = useOrderContext();

  const availableOptions = useMemo(() => {
    const {
      options,
      options_prices: { prices },
    } = order;
    const existingOptions = options.map((option) => option.name);
    return Object.values(OptionNames)
      .filter((option) => !existingOptions.includes(option))
      .map((option) => {
        const price = prices[option];
        return {
          label: `${t(`names.${option}`)} - $${price}`,
          value: option,
        };
      });
  }, [order]);

  const createLink = async () => {
    setIsLoading(true);
    const link = await addOptions(selectedOptions);
    setPaymentLink(link);
    setIsLoading(false);
  };

  const addOption = async () => {
    setIsLoading(true);
    await addOptions(selectedOptions, true);
    setIsLoading(false);
  };

  return (
    (!!availableOptions.length && (
      <StyledPanel>
        {order.status !== OrderStatuses.done && (
          <StyledOptionsUnavailableOverlay>
            <h3>{t('unavailable')}</h3>
          </StyledOptionsUnavailableOverlay>
        )}

        <StyledHeader>{t('add_options')}</StyledHeader>
        <StyledAddOptionsWrapper>
          <div>
            <StyledSpeedupHeader>{t('available_options')}</StyledSpeedupHeader>
            <ButtonGroup options={availableOptions} isMultiple onChange={setSelectedOptions} />
          </div>
        </StyledAddOptionsWrapper>

        <StyledSpeedupActionsWrapper>
          <Button
            size={ButtonSizes.sm}
            noShadow
            isLoading={isLoading}
            onClick={addOption}
            isDisabled={!selectedOptions.length}
          >
            {t('add_no_payment')}
          </Button>
          <Button
            onClick={createLink}
            isDisabled={!selectedOptions.length}
            color={'primary'}
            size={ButtonSizes.sm}
            noShadow
            isLoading={isLoading}
          >
            {t('create_payment')}
          </Button>
        </StyledSpeedupActionsWrapper>

        <OrderPaymentLink link={paymentLink} />
      </StyledPanel>
    )) || <></>
  );
};

export default OrderSpeedUp;
