import React from 'react';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';
import {
  StyledLogoutButton,
  StyledProfileButton,
  StyledUserAvatar,
  StyledUserEmail,
  StyledUserInfoActions,
  StyledUserInfoDivider,
  StyledUserInfoWrapper,
  StyledUserName,
  StyledUserRole,
  StyledUserWrapper,
} from '../styled';
import { useAppContext, useScopedTranslation } from '../../../../hooks';
import { Popover } from '../../../ui';
import { useLogout } from '../../../../requests/useLogout';
import { toPrettyDate } from '../../../../utils/utils';
import { ArrowUpDownIcon } from '@chakra-ui/icons';

const UserSkeleton = () => {
  return (
    <>
      <SkeletonCircle w={'2rem'} h={'2rem'} />
      <div>
        <Skeleton w={'50%'} h={'1rem'} mb={'0.5rem'} />
        <Skeleton w={'80%'} h={'0.75rem'} />
      </div>
    </>
  );
};

const UserInfo = () => {
  const { t } = useScopedTranslation('sidebar.user');

  const { user } = useAppContext();
  const logout = useLogout();

  return (
    <Popover
      portal
      placement={'top-start'}
      trigger={
        (user && (
          <StyledUserWrapper role={'button'} tabIndex={0}>
            <StyledUserAvatar size={'sm'} name={user?.name} />
            <div>
              <StyledUserName noOfLines={1}>{user?.name}</StyledUserName>
              <StyledUserRole noOfLines={1}>{user?.roles?.join(', ')}</StyledUserRole>
            </div>
            <ArrowUpDownIcon />
          </StyledUserWrapper>
        )) || <UserSkeleton />
      }
    >
      <StyledUserInfoWrapper>
        <div>
          <StyledUserName noOfLines={1}>{user?.name}</StyledUserName>
          <StyledUserEmail noOfLines={1}>{user?.email}</StyledUserEmail>
          <StyledUserEmail noOfLines={1}>
            {t('since')} {toPrettyDate(user?.created_at, 'MMM dd, yyyy')}
          </StyledUserEmail>
        </div>
      </StyledUserInfoWrapper>
      <StyledUserInfoDivider />
      <StyledUserInfoActions>
        <StyledProfileButton isDisabled color={'primaryLight'} noShadow>
          {t('settings')}
        </StyledProfileButton>
        <StyledLogoutButton noShadow onClick={logout}>
          {t('logout')}
        </StyledLogoutButton>
      </StyledUserInfoActions>
    </Popover>
  );
};

export default UserInfo;
