import { httpGet, httpPost, httpPostFormData } from '../../../shared/utils/http';
import { Gender } from '../../../shared/constants/Gender';
import { Segment } from '../../../shared/types/production/segment';
import { Personalization } from '../../../shared/types/production/personalization';
import { Artist } from '../../../shared/types/production/artist';
import { Template } from '../../../shared/types/production/template';
import { GeneratorDataType, GeneratorResponse } from '../../../modules/production/generator/types';
import { objectToFormData } from '../../../shared/utils/utils';
import { GENERATOR_ROUTE } from '../../constants';
import { SegmentPersonalizationKeys } from '../../../shared/constants/production/personalization';

const GENERATOR_URL = `${GENERATOR_ROUTE}/generator`;
export type PersonalizationsRoutes = 'months' | 'eye-colors' | 'years' | 'kids';

export type GeneratorApiType = {
  getGenders: () => Promise<Gender[]>;
  getSegments: (gender: Gender) => Promise<Segment[]>;
  getNames: (
    segmentId: number,
  ) => Promise<{ names_to: Personalization[]; names_from: Personalization[] }>;
  getOccasions: (segmentId: number) => Promise<Personalization[]>;
  getAllPersonalizations: () => Promise<Personalization[]>;
  getSegmentPersonalizations: (
    segmentId: number,
  ) => Promise<Record<SegmentPersonalizationKeys, Personalization[]>>;
  getPersonalization: (personalizationType: PersonalizationsRoutes) => Promise<Personalization[]>;
  getSpouses: (segmendId: number) => Promise<Personalization[]>;
  getArtists: (segmendId: number, occasionId: number) => Promise<Artist[]>;
  getArtistTemplates: (
    segmentId: number,
    occasionId: number,
    artistId: number,
  ) => Promise<Template[]>;
  getArtistIntro: (
    segmentId: number,
    occasionId: number,
    artistId: number,
  ) => Promise<{ id: number; name: string }[]>;
  produceTrack: (data: GeneratorDataType) => Promise<GeneratorResponse>;
  uploadVoice: (file: File | Blob) => Promise<string>;
};

export const GeneratorApi: GeneratorApiType = {
  getGenders: () => {
    return httpGet(`${GENERATOR_URL}/genders`);
  },
  getSegments: (gender) => {
    return httpGet(`${GENERATOR_URL}/genders/${gender}/segments`);
  },
  getNames: (segmentId) => {
    return httpGet(`${GENERATOR_URL}/segments/${segmentId}/names`);
  },
  getOccasions: (segmentId) => {
    return httpGet(`${GENERATOR_URL}/segments/${segmentId}/occasions`);
  },
  getAllPersonalizations: () => {
    return httpGet(`${GENERATOR_URL}/personalizations`);
  },
  getSegmentPersonalizations: (segmentId) => {
    return httpGet(`${GENERATOR_URL}/segments/${segmentId}/personalizations`);
  },
  getPersonalization: (personalizationType) => {
    return httpGet(`${GENERATOR_URL}/personalizations/${personalizationType}`);
  },
  getSpouses: (segmentId) => {
    return httpGet(`${GENERATOR_URL}/segments/${segmentId}/spouses`);
  },
  getArtists: (segmentId, occasionId) => {
    return httpGet(`${GENERATOR_URL}/segments/${segmentId}/occasions/${occasionId}/artists`);
  },
  getArtistTemplates: (segmentId, occasionId, artistId) => {
    return httpGet(
      `${GENERATOR_URL}/segments/${segmentId}/occasions/${occasionId}/artists/${artistId}/templates`,
    );
  },
  getArtistIntro: (segmentId, occasionId, artistId) => {
    return httpGet(
      `${GENERATOR_URL}/segments/${segmentId}/occasions/${occasionId}/artists/${artistId}/intros`,
    );
  },
  produceTrack: (data) => {
    return httpPost(`${GENERATOR_URL}/produce`, { ...data, debug: true });
  },
  uploadVoice: (file) => {
    return httpPostFormData(`${GENERATOR_URL}/voices`, objectToFormData({ file }));
  },
};
