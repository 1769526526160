import React from 'react';
import {
  StyledInvoiceActions,
  StyledInvoiceCard,
  StyledInvoiceInfo,
  StyledInvoicePositionRow,
  StyledPositionsInfo,
} from './styled';
import { Skeleton } from '@chakra-ui/react';

const InvoiceCardSkeleton = () => {
  return (
    <StyledInvoiceCard>
      <StyledInvoiceInfo>
        <div>
          <Skeleton w={'5rem'} h={'1rem'} mb={'0.5rem'} />
          <Skeleton w={'7rem'} h={'0.5rem'} />
        </div>
        <div>
          <Skeleton w={'5rem'} h={'1rem'} mb={'0.5rem'} />
          <Skeleton w={'5rem'} h={'0.5rem'} />
        </div>
        <div>
          <Skeleton w={'5rem'} h={'1rem'} />
        </div>
        <StyledInvoiceActions>
          <Skeleton w={'5rem'} h={'2.5rem'} />
          <Skeleton w={'8rem'} h={'2.5rem'} />
        </StyledInvoiceActions>
      </StyledInvoiceInfo>

      <StyledPositionsInfo>
        <StyledInvoicePositionRow>
          <Skeleton w={'6rem'} h={'0.75rem'} />
          <div></div>
          <Skeleton w={'2rem'} h={'0.75rem'} />
          <div></div>
        </StyledInvoicePositionRow>
        <StyledInvoicePositionRow>
          <Skeleton w={'5rem'} h={'0.75rem'} />
          <div></div>
          <Skeleton w={'2rem'} h={'0.75rem'} />
          <div></div>
        </StyledInvoicePositionRow>
      </StyledPositionsInfo>
    </StyledInvoiceCard>
  );
};

export default InvoiceCardSkeleton;
