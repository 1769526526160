import React from 'react';
import { Avatar } from '@chakra-ui/react';
import { Button } from '../../ui';
import { Icon } from '../../icons';
import { StyledSelectedArtistCard } from './styled';
import { Artist } from '../../../types/production/artist';

const SelectedArtistCard = ({
  artist,
  onRemove,
}: {
  artist: Artist;
  onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const { name, photo_url_thumb: photo } = artist;

  return (
    <StyledSelectedArtistCard role={'button'}>
      <Avatar size={'xs'} name={name} src={photo} />
      <span>{name}</span>
      <Button onClick={onRemove} fab noShadow color={'primary'}>
        <Icon type={'Close'} size={'xs'} color={'white'} />
      </Button>
    </StyledSelectedArtistCard>
  );
};

export default SelectedArtistCard;
