import React, { useMemo } from 'react';
import {
  StyledArtistInfo,
  StyledArtistWrapper,
  StyledTrackDivider,
  StyledResultCardHeader,
  StyledTrackWrapper,
  StyledNoIntroHeader,
} from '../../styled';
import { Avatar } from '@chakra-ui/react';
import { AudioPlayer, Button, showToast } from '../../../../../shared/components/ui';
import useGeneratorContext from '../../hooks/useGeneratorContext';
import { copyToClipBoard } from '../../../../../shared/utils/utils';
import { useScopedTranslation } from '../../../../../shared/hooks';

const TrackWrapper = () => {
  const { t } = useScopedTranslation('production.generator');
  const { result } = useGeneratorContext();

  const artist = useMemo(() => result?.data?.artist, [result]);
  const template = useMemo(() => result?.data?.template, [result]);
  const introFile = useMemo(() => result?.data?.intro_file, [result]);

  const copyTrackLink = () => {
    if (result?.track) {
      copyToClipBoard(result?.track.url);
      showToast('success', t('link_copied'), { autoClose: 1500, position: 'bottom-center' });
    }
  };

  return (
    <StyledTrackWrapper>
      <StyledResultCardHeader>
        <StyledArtistWrapper>
          <Avatar name={artist?.name} src={artist?.photo_url_thumb} />
          <StyledArtistInfo>
            <h3>{artist?.name}</h3>
            <p>
              <span>{template?.name}</span>{' '}
              {!!introFile && <span>| {t('intro', { introFile })}</span>}
            </p>
          </StyledArtistInfo>
        </StyledArtistWrapper>
        <Button noShadow onClick={copyTrackLink}>
          {t('copy_link')}
        </Button>
      </StyledResultCardHeader>
      <AudioPlayer src={result?.track?.url ?? ''} withProgress startOnInit download withDuration />
      <StyledTrackDivider />
      <StyledNoIntroHeader>{t('no_intro')}</StyledNoIntroHeader>
      <AudioPlayer src={result?.no_intro?.url ?? ''} withProgress download withDuration />
    </StyledTrackWrapper>
  );
};

export default TrackWrapper;
