/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgPicture = (props) => (
  <svg
    {...props}
    width="44"
    height="39"
    viewBox="0 0 44 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.91422 6.16553C12.0954 6.17067 13.8646 8.0025 13.8684 10.2592C13.8721 12.5237 12.0645 14.3824 9.87339 14.3683C7.70951 14.3542 5.92421 12.48 5.94153 10.2413C5.96009 7.98067 7.73425 6.16168 9.91422 6.16553Z"
      fill="#A8B2BB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6 21.4634V4.45299C39.5726 4.35326 39.5464 4.25303 39.5202 4.15281C39.4624 3.93181 39.4047 3.71085 39.334 3.49536C38.6647 1.45044 36.9041 0.0884392 34.8355 0.0114177C34.5439 0.000414631 34.2522 0.000729005 33.9606 0.00104338C33.912 0.00109577 33.8634 0.00114817 33.8148 0.00114817C24.2734 -0.000135523 14.732 -0.00141922 5.19191 0.00371555C2.8511 0.00371555 1.01385 1.30153 0.285129 3.44915C0.0772774 4.06147 0.00304437 4.69689 0.00304437 5.34131C0.00275326 7.08168 0.00218818 8.82205 0.00162309 10.5624C-0.00021345 16.2187 -0.00204999 21.875 0.00551885 27.5312C0.00923048 30.6467 2.16074 32.8534 5.16593 32.8547C12.3149 32.8566 19.4645 32.8564 26.6138 32.8556C26.4737 32.1925 26.4001 31.5049 26.4001 30.8001C26.4001 25.3325 30.8325 20.9001 36.3001 20.9001C37.4572 20.9001 38.5679 21.0986 39.6 21.4634ZM35.6149 19.4848C35.6083 19.5247 35.5999 19.5755 35.5902 19.6442C34.2367 18.0062 32.95 16.4491 31.6633 14.8933C31.4087 14.5854 31.1547 14.277 30.9008 13.9687C30.4315 13.399 29.9623 12.8294 29.4895 12.263C27.9294 10.3914 25.5119 10.4093 23.9827 12.3092C21.6728 15.177 19.3642 18.0447 17.0729 20.9279C16.7932 21.2796 16.6485 21.2681 16.3639 20.951C15.8801 20.4134 15.3729 19.8975 14.8659 19.3817C14.7324 19.2459 14.599 19.1102 14.466 18.9741C12.9158 17.3888 10.8509 17.3875 9.31306 18.9767C7.99943 20.3349 6.68997 21.6974 5.37999 23.0604C4.94295 23.5151 4.50585 23.97 4.06852 24.4247C3.9372 24.3302 3.95144 24.2141 3.96451 24.1075C3.96832 24.0766 3.97202 24.0464 3.97202 24.0177C3.96831 17.7931 3.96831 11.5698 3.96954 5.34516C3.96954 4.49279 4.33576 4.09484 5.14737 4.09484C14.9189 4.09227 24.6891 4.09227 34.4606 4.09484C35.2289 4.09484 35.6273 4.46455 35.6286 5.20267C35.6346 9.06193 35.6332 12.9212 35.6319 16.7804C35.6316 17.6375 35.6313 18.4945 35.631 19.3515C35.631 19.3873 35.6251 19.4232 35.6149 19.4848Z"
      fill="#A8B2BB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.3016 38.4999C40.5542 38.4999 44.0016 35.0524 44.0016 30.7999C44.0016 26.5473 40.5542 23.0999 36.3016 23.0999C32.049 23.0999 28.6016 26.5473 28.6016 30.7999C28.6016 35.0524 32.049 38.4999 36.3016 38.4999ZM35.4859 34.073C35.4859 34.5849 35.8959 34.9999 36.4016 34.9999C36.9073 34.9999 37.3172 34.5849 37.3172 34.073V31.8942H39.518C40.006 31.8942 40.4016 31.4938 40.4016 30.9999C40.4016 30.5059 40.006 30.1055 39.518 30.1055H37.3172V27.9267C37.3172 27.4148 36.9073 26.9999 36.4016 26.9999C35.8959 26.9999 35.4859 27.4148 35.4859 27.9267V30.1055H33.2851C32.7971 30.1055 32.4016 30.5059 32.4016 30.9999C32.4016 31.4938 32.7971 31.8942 33.2851 31.8942H35.4859V34.073Z"
      fill="#A8B2BB"
    />
  </svg>
);

export default SvgPicture;
