import React, { useState } from 'react';
import useLoadPermissions from '../../shared/requests/user/useLoadPermissions';
import useRolesApi from '../../shared/requests/user/useRolesApi';
import { Role } from '../../shared/types/role';
import {
  StyledCloseButton,
  StyledHeader,
  StyledRoleSectionHeader,
  StyledRolesWrapper,
  StyledSubheader,
} from './styled';
import RolesSelector from './components/RolesSelector';
import RoleForm from './components/RoleForm';
import { ButtonSizes } from '../../shared/theme';
import { CloseIcon } from '@chakra-ui/icons';
import { useScopedTranslation } from '../../shared/hooks';

const Roles = () => {
  const { t } = useScopedTranslation('roles');

  const { roles, rolesOptions, isLoading, createRole, updateRole, deleteRole } = useRolesApi();
  const { permissions } = useLoadPermissions();
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  const deselectRole = () => setSelectedRole(null);

  const onRoleSave = async (role: { id: number | null; name: string; permissions: number[] }) => {
    const data = { name: role.name, permissions: role.permissions };

    if (!!role.id) await updateRole(role.id, data);
    else {
      const createdRole = await createRole(data);
      if (createdRole) setSelectedRole({ ...createdRole });
    }
  };

  const onRoleDelete = async (roleId: number) => {
    await deleteRole(roleId);
    setSelectedRole(null);
  };

  return (
    <StyledRolesWrapper>
      <div>
        <StyledHeader>{t('manage_roles')}</StyledHeader>
        <StyledSubheader>{t('create_role')}</StyledSubheader>
      </div>

      <RolesSelector {...{ selectedRole, setSelectedRole, roles, rolesOptions, isLoading }} />

      {!!selectedRole && (
        <>
          <StyledRoleSectionHeader>
            <p>{t('choose_role')}</p>
            <div />
            <StyledCloseButton fab noShadow size={ButtonSizes.sm} onClick={deselectRole}>
              <CloseIcon />
            </StyledCloseButton>
          </StyledRoleSectionHeader>
          <RoleForm
            role={selectedRole}
            permissions={permissions}
            onSave={onRoleSave}
            onDelete={onRoleDelete}
          />
        </>
      )}
    </StyledRolesWrapper>
  );
};

export default Roles;
