import React from 'react';
import { StyledItem } from '../styled';

const SidebarItem = ({
  children,
  groupItem = false,
  to,
  icon = '',
  ...props
}: {
  children: React.ReactNode;
  to: string;
  groupItem?: boolean;
  icon?: React.ReactNode;
}) => {
  return (
    <StyledItem to={to} groupItem={groupItem} {...props}>
      {icon}
      {children}
    </StyledItem>
  );
};

export default SidebarItem;
