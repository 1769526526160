import { FileWithPath } from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import { createFolder, findInScheme, PreparedFile, UploaderFolder } from '../helpers';
import { FileStructure, FileStructureUnit } from '../../../../types/production/fileStructure';

type ProcessFilesToUploadType = {
  acceptedFiles: FileWithPath[];
  scheme: FileStructure;
};

const useProcessFilesToUpload = ({ acceptedFiles, scheme }: ProcessFilesToUploadType) => {
  const [processedFiles, setProcessedFiles] = useState<UploaderFolder[]>([]);

  const prepareFileForUpload = (sourceFile: FileWithPath, schemeFile: FileStructureUnit) => {
    return {
      name: schemeFile.name,
      file_url: schemeFile.file_url ?? '',
      file: sourceFile,
      ...schemeFile.data,
    };
  };

  const getFolderNameFromFile = (file: FileWithPath) => {
    if (!file.path) return '';
    const pathArray = file.path.split(`/${file.name}`);
    return (pathArray.length > 1 && pathArray.shift()?.substring(1)) || '';
  };

  const findFolderInProcessed = (processed: UploaderFolder[], folderName: string) => {
    return processed.find(({ name }) => name === folderName);
  };

  const processAcceptedFiles = (files: FileWithPath[]) => {
    return files.reduce((processed: UploaderFolder[], file: FileWithPath) => {
      if (!file.path) return processed;

      const folderName = getFolderNameFromFile(file);
      const existingFolder = findFolderInProcessed(processed, folderName);
      const folder = existingFolder || createFolder(folderName);

      const schemeFile = findInScheme(scheme, file);
      if (schemeFile) folder.success.push(prepareFileForUpload(file, schemeFile));
      else folder.error.push(file);

      if (!existingFolder) processed.push(folder);
      return processed;
    }, []);
  };

  useEffect(() => {
    if (!acceptedFiles.length) return;
    setProcessedFiles(processAcceptedFiles(acceptedFiles));
  }, [acceptedFiles]);

  const preparedFiles = useMemo(() => {
    return processedFiles.reduce(
      (files: PreparedFile[], folder) => [...files, ...folder.success],
      [],
    );
  }, [processedFiles]);

  const totalFiles = useMemo(() => preparedFiles.length, [preparedFiles]);

  const reset = () => {
    setProcessedFiles([]);
  };

  return { processedFiles, preparedFiles, totalFiles, reset };
};

export default useProcessFilesToUpload;
