import { useEffect, useState } from 'react';
import { Artist } from '../../../../shared/types/production/artist';
import { Segment } from '../../../../shared/types/production/segment';
import { Template } from '../../../../shared/types/production/template';
import { useHandleQueryUrl } from '../../../../shared/hooks';
import { FilterQueryParams } from '../../../../shared/constants/production/common';
import { useLocation } from 'react-router-dom';

const useProcessFilters = ({
  artists,
  segments,
  templates,
}: {
  artists: Artist[];
  segments?: Segment[];
  templates: Template[];
}) => {
  const [selectedArtist, setSelectedArtist] = useState<Artist | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<Segment | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const { setInstanceByParameter, setQueryParam, removeQueryParam } = useHandleQueryUrl();
  const { search } = useLocation();

  useEffect(() => {
    setInstanceByParameter(FilterQueryParams.artistId, artists, setSelectedArtist);
  }, [artists, search]);

  useEffect(() => {
    if (selectedArtist?.id) setQueryParam(FilterQueryParams.artistId, selectedArtist.id.toString());
  }, [selectedArtist]);

  useEffect(() => {
    if (segments) setInstanceByParameter(FilterQueryParams.segmentId, segments, setSelectedSegment);
  }, [segments, search]);

  useEffect(() => {
    if (selectedSegment?.id)
      setQueryParam(FilterQueryParams.segmentId, selectedSegment.id.toString());
  }, [selectedSegment]);

  useEffect(() => {
    setInstanceByParameter(FilterQueryParams.templateId, templates, setSelectedTemplate);
  }, [templates, search]);

  useEffect(() => {
    if (selectedTemplate?.id)
      setQueryParam(FilterQueryParams.templateId, selectedTemplate.id.toString());
  }, [selectedTemplate]);

  const clearArtist = () => {
    setSelectedArtist(null);
    removeQueryParam(FilterQueryParams.artistId);
    clearTemplate();
  };

  const clearSegment = () => {
    setSelectedSegment(null);
    removeQueryParam(FilterQueryParams.segmentId);
    clearTemplate();
  };

  const clearTemplate = () => {
    setSelectedTemplate(null);
    removeQueryParam(FilterQueryParams.templateId);
  };

  return {
    selectedArtist,
    setSelectedArtist,
    selectedSegment,
    setSelectedSegment,
    selectedTemplate,
    setSelectedTemplate,
    clearArtist,
    clearSegment,
    clearTemplate,
  };
};

export default useProcessFilters;
