/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgWave = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      {...props}
      d="M2.00099 18.6913C2.23549 18.2607 2.56438 17.9693 3.07809 18.1195C3.58881 18.2697 3.715 18.6863 3.69314 19.1809C3.67625 19.5784 3.67625 19.978 3.69314 20.3755C3.71401 20.8691 3.59378 21.2816 3.08504 21.4439C2.56239 21.6111 2.25238 21.2917 2 20.8881C2.00099 20.1572 2.00099 19.4242 2.00099 18.6913Z"
    />
    <path
      d="M19.2194 19.7917C19.2194 23.6367 19.2194 27.4817 19.2194 31.3257C19.2194 32.1728 18.9292 32.5893 18.3559 32.5743C17.8005 32.5593 17.5242 32.1508 17.5242 31.3357C17.5242 23.6297 17.5242 15.9237 17.5252 8.21671C17.5252 8.0505 17.5232 7.88228 17.5521 7.72007C17.6335 7.26047 17.9843 6.96609 18.4205 7.00314C18.8736 7.04219 19.1448 7.29952 19.2015 7.75912C19.2233 7.94035 19.2194 8.12459 19.2194 8.30683C19.2204 12.1348 19.2194 15.9638 19.2194 19.7917Z"
      {...props}
    />
    <path
      d="M20.914 17.9352C20.914 14.1072 20.914 12.5733 20.914 8.7453C20.914 7.87718 21.1823 7.48467 21.7655 7.49068C22.3418 7.49668 22.6101 7.8982 22.6101 8.76233C22.6101 16.4353 22.6111 21.8142 22.6081 29.4872C22.6081 29.7025 22.6071 29.9238 22.5574 30.131C22.4551 30.5596 22.1481 30.7819 21.7238 30.7639C21.3015 30.7458 21.0123 30.5065 20.9418 30.068C20.91 29.8727 20.915 29.6704 20.915 29.4712C20.913 25.6252 20.914 21.7802 20.914 17.9352Z"
      {...props}
    />
    <path
      d="M15.8233 19.7995C15.8243 21.9302 15.8243 24.061 15.8233 26.1928C15.8233 26.376 15.8302 26.5612 15.8044 26.7415C15.7438 27.159 15.4745 27.3963 15.078 27.4454C14.6766 27.4944 14.3775 27.3042 14.2106 26.9247C14.1132 26.7034 14.1311 26.4681 14.1311 26.2358C14.1311 21.9402 14.1311 17.6457 14.1311 13.3501C14.1311 13.25 14.1281 13.1498 14.1331 13.0507C14.1629 12.4759 14.5236 12.0924 15.0135 12.1105C15.4944 12.1285 15.8183 12.489 15.8203 13.0577C15.8272 15.3036 15.8223 17.5515 15.8233 19.7995Z"
      {...props}
    />
    <path
      d="M24.3081 19.755C24.3081 17.6243 24.3081 15.4935 24.3091 13.3627C24.3091 13.1965 24.3042 13.0283 24.323 12.8641C24.3737 12.4215 24.647 12.1672 25.0663 12.1231C25.4896 12.0781 25.7916 12.2913 25.9426 12.7039C26.0192 12.9131 26.0013 13.1304 26.0013 13.3467C26.0023 17.6423 26.0023 21.9369 26.0013 26.2324C26.0013 26.3486 26.0072 26.4657 25.9973 26.5819C25.9516 27.1226 25.58 27.4871 25.107 27.46C24.646 27.434 24.3161 27.0805 24.3141 26.5488C24.3061 24.2849 24.3101 22.021 24.3101 19.757C24.3091 19.755 24.3081 19.755 24.3081 19.755Z"
      {...props}
    />
    <path
      d="M12.4364 19.8063C12.4364 21.4524 12.4394 23.0986 12.4344 24.7447C12.4324 25.3675 12.0926 25.761 11.5859 25.754C11.0811 25.747 10.7482 25.3515 10.7482 24.7247C10.7453 21.4324 10.7433 18.1401 10.7482 14.8468C10.7492 14.0378 11.3931 13.5742 12.0072 13.9297C12.345 14.1249 12.4414 14.4393 12.4394 14.8178C12.4324 16.48 12.4364 18.1431 12.4364 19.8063Z"
      {...props}
    />
    <path
      d="M27.6885 19.7926C27.6885 18.1465 27.6865 16.5004 27.6895 14.8542C27.6905 14.2154 28.0005 13.8289 28.5052 13.8139C29.0259 13.7989 29.3767 14.2074 29.3767 14.8592C29.3796 18.1515 29.3796 21.4438 29.3767 24.7371C29.3757 25.3108 29.1302 25.6512 28.6712 25.7293C28.2648 25.7984 27.9697 25.6242 27.7719 25.2667C27.6696 25.0815 27.6895 24.8792 27.6895 24.682C27.6875 23.0519 27.6885 21.4228 27.6885 19.7926Z"
      {...props}
    />
    <path
      d="M9.05875 19.772C9.05875 20.3197 9.0667 20.8674 9.05577 21.4141C9.04484 21.9698 8.71297 22.3263 8.22708 22.3333C7.74319 22.3393 7.38051 21.9798 7.37455 21.4371C7.36163 20.3427 7.36362 19.2473 7.37356 18.1519C7.37852 17.6062 7.72927 17.2327 8.20423 17.2257C8.69508 17.2187 9.04782 17.6042 9.05676 18.1789C9.06571 18.7096 9.05875 19.2413 9.05875 19.772Z"
      {...props}
    />
    <path
      d="M31.0666 19.7879C31.0666 19.2402 31.0576 18.6925 31.0686 18.1458C31.0795 17.6021 31.4282 17.2286 31.9032 17.2256C32.3772 17.2226 32.7438 17.5971 32.7498 18.1358C32.7617 19.2302 32.7617 20.3256 32.7498 21.421C32.7438 21.9738 32.399 22.3312 31.9131 22.3342C31.4292 22.3372 31.0805 21.9758 31.0686 21.4311C31.0566 20.8833 31.0666 20.3356 31.0666 19.7879Z"
      {...props}
    />
    <path
      d="M6.24037 19.8104C6.24037 20.3271 6.2503 20.8427 6.23739 21.3584C6.22248 21.9602 5.89657 22.3247 5.39677 22.3307C4.89499 22.3367 4.55616 21.9722 4.55119 21.3754C4.54026 20.31 4.54126 19.2437 4.55119 18.1783C4.55716 17.6085 4.87711 17.2461 5.35405 17.225C5.84788 17.203 6.20261 17.5785 6.23937 18.1603C6.24136 18.1933 6.24136 18.2263 6.24136 18.2604C6.24037 18.7781 6.24037 19.2947 6.24037 19.8104Z"
      {...props}
    />
    <path
      d="M35.581 19.805C35.581 20.3547 35.5949 20.9044 35.577 21.4531C35.5601 21.9868 35.2302 22.3233 34.7582 22.3313C34.2892 22.3393 33.9136 22.0058 33.9027 21.4822C33.8789 20.3507 33.8789 19.2172 33.9027 18.0858C33.9136 17.5661 34.2942 17.2206 34.7533 17.2256C35.2153 17.2316 35.5601 17.5821 35.577 18.1058C35.5939 18.6725 35.581 19.2393 35.581 19.805Z"
      {...props}
    />
    <path
      d="M38.1306 19.8077C38.1306 20.0901 38.1455 20.3745 38.1276 20.6558C38.0958 21.1345 37.7421 21.4789 37.3009 21.4859C36.8379 21.4939 36.4673 21.1395 36.4514 20.6328C36.4335 20.0681 36.4335 19.5013 36.4514 18.9356C36.4673 18.4209 36.824 18.0805 37.2999 18.0875C37.752 18.0935 38.0968 18.4239 38.1286 18.9096C38.1465 19.208 38.1306 19.5084 38.1306 19.8077Z"
      {...props}
    />
  </svg>
);

export default SvgWave;
