import React from 'react';
import { MOTION_VARIANT } from '../../../constants/MotionVariant';
import { motion } from 'framer-motion';

const MotionContainer = ({
  children,
  key,
}: {
  children: React.ReactNode | React.ReactNode[];
  key?: string | number | null;
}) => {
  return (
    <motion.div initial="initial" key={key} animate="animate" exit="exit" variants={MOTION_VARIANT}>
      {children}
    </motion.div>
  );
};

export default MotionContainer;
