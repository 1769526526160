import React from 'react';
import { StyledHeaderButton, StyledHeaderWrapper, StyledMenuButtonWrapper } from './styled';
import { Icon } from '../../icons';
import { useAppContext } from '../../../hooks';
import { Spacer } from '@chakra-ui/react';
import { LanguageChanger } from '../LanguageChanger';

const Header = () => {
  const { sidebarCollapsed, setSidebarCollapsed } = useAppContext();
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <StyledHeaderWrapper>
      <StyledMenuButtonWrapper>
        <StyledHeaderButton noShadow onClick={toggleSidebar} type={'button'}>
          <Icon type={'Menu'} color={'secondary'} />
        </StyledHeaderButton>
      </StyledMenuButtonWrapper>
      <Spacer />
      <LanguageChanger />
    </StyledHeaderWrapper>
  );
};

export default Header;
