import { useEffect, useMemo, useState } from 'react';
import { SettingsApi } from '../../../api';
import { Settings } from '../../types/production/settings';
import { SettingNames } from '../../constants/production/settings';

const useSettingsApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<Settings>({});

  const getSettings = async () => {
    setIsLoading(true);
    await SettingsApi.getSettings()
      .then(setSettings)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  const updateTag = async (file: File) => {
    setIsLoading(true);
    const r = await SettingsApi.updateTag(file)
      .then((response) => response)
      .catch(() => null)
      .finally(() => {
        setIsLoading(false);
      });
    return r;
  };

  const settingsArray = useMemo(() => {
    const names = Object.keys(settings) as SettingNames[];
    return names.map((key) => ({ name: key, value: settings[key] ?? '' }));
  }, [settings]);

  return { settings: settingsArray, setSettings, getSettings, isLoading, updateTag };
};

export default useSettingsApi;
