import React from 'react';
import { StyledDivider, StyledResultActionsWrapper } from '../../styled';
import { LinkButton } from '../../../../../shared/components/ui';
import { ProductionPaths } from '../../../../../router/routes';
import { ButtonSizes } from '../../../../../shared/theme';
import { useNavigateWithParams, useScopedTranslation } from '../../../../../shared/hooks';
import useGeneratorContext from '../../hooks/useGeneratorContext';

const QuickAccess = () => {
  const { t } = useScopedTranslation('production.generator');
  const { urlWithParams, urlWithSearchParams } = useNavigateWithParams();

  const { data, result } = useGeneratorContext();

  return (
    (!!result?.data && (
      <StyledResultActionsWrapper>
        <p>{t('quick_access')}</p>
        <StyledDivider />
        <LinkButton
          noShadow
          to={urlWithParams(ProductionPaths.artistPage, {
            artistId: result.data?.artist?.id?.toString(),
          })}
          target={'_blank'}
          size={ButtonSizes.sm}
        >
          {t('artist_page')}
        </LinkButton>
        <LinkButton
          noShadow
          to={urlWithSearchParams(ProductionPaths.templates, {
            artistId: result.data?.artist?.id?.toString(),
            segmentId: data.segment_id?.toString() ?? '',
            templateId: result.data?.template?.id?.toString(),
          })}
          target={'_blank'}
          size={ButtonSizes.sm}
        >
          {t('template_page')}
        </LinkButton>
        <LinkButton
          noShadow
          to={urlWithSearchParams(ProductionPaths.intro, {
            artistId: result.data?.artist?.id?.toString(),
            segmentId: data.segment_id?.toString() ?? '',
          })}
          target={'_blank'}
          size={ButtonSizes.sm}
        >
          {t('intro_page')}
        </LinkButton>
      </StyledResultActionsWrapper>
    )) || <></>
  );
};

export default QuickAccess;
