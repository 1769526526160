import React, { useEffect, useMemo } from 'react';
import { StyledSegmentRowSlot } from '../styled';
import useArtistContext from '../hooks/useArtistContext';
import {
  ArtistSegmentScheme,
  ArtistSegmentSchemeSlot,
  AvailableSlotTypes,
  NameSlotValues,
  SlotTypes,
} from '../../../../shared/types/production/artist';
import { Input, Select } from '../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../shared/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateSlotSchema } from '../validationScheme';

const ArtistSegmentSchemeRowSlot = ({
  scheme,
  slot,
  index,
}: {
  scheme: ArtistSegmentScheme;
  slot: ArtistSegmentSchemeSlot;
  index: number;
}) => {
  const { t } = useScopedTranslation('production.artists.schemes');
  const { artist, updateSlot, isEdit } = useArtistContext();

  const options = Object.values(AvailableSlotTypes).map((value) => ({
    label: t(`slots.${value}`),
    value,
  }));
  const nameValues = Object.values(NameSlotValues).map((value) => ({
    label: t(`names.${value}`),
    value,
  }));
  const isTiming = useMemo(() => slot.type === SlotTypes.timing, [slot]);
  const isOccasion = useMemo(() => slot.type === SlotTypes.occasion, [slot]);

  const { register, reset, handleSubmit } = useForm({
    resolver: yupResolver(UpdateSlotSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    const { type, value } = slot;
    reset({ type, value });
  }, [slot]);

  const onSlotUpdated = (args: Object) => {
    if (!isEdit) return;
    updateSlot(artist, scheme, slot, { ...args });
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!isEdit) return;

    const newType = e.target.value;
    const data = {
      type: newType,
      value: slot.value,
    };

    const namesTypes = [SlotTypes.nameTo, SlotTypes.nameFrom];

    if (newType === SlotTypes.occasion) data.value = null;
    else if (newType === SlotTypes.timing) data.value = 0.2;
    else if (!namesTypes.includes(slot.type)) data.value = NameSlotValues.maleNames;

    updateSlot(artist, scheme, slot, data);
  };

  const slotsLabels = [1, 0, 2, 0, 3];

  return (
    <StyledSegmentRowSlot>
      <p>{t((isTiming && 'timing') || 'slot', { name: slotsLabels[index] })}</p>
      {!isTiming && (
        <Select
          isReadOnly={!isEdit}
          name={'type'}
          onChange={onTypeChange}
          register={register}
          options={options}
        />
      )}
      {(isTiming && (
        <Input
          isReadOnly={!isEdit}
          type={'number'}
          name={'value'}
          register={register}
          placeholder={t('enter_timing')}
          onBlur={handleSubmit(onSlotUpdated)}
        />
      )) ||
        (!isOccasion && (
          <Select
            isReadOnly={!isEdit}
            name={'value'}
            register={register}
            onBlur={handleSubmit(onSlotUpdated)}
            options={nameValues}
          />
        ))}
    </StyledSegmentRowSlot>
  );
};

export default ArtistSegmentSchemeRowSlot;
