import { useEffect, useMemo, useState } from 'react';
import { OrdersApi } from '../../../api';
import { Segment } from '../../../shared/types/production/segment';

const useGetSegments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [segments, setSegments] = useState<Segment[]>([]);

  const getSegments = () => {
    setIsLoading(true);

    OrdersApi.segments({})
      .then((response) => {
        setSegments(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const segmentOptions = useMemo(() => {
    return segments.map((segment) => ({
      value: segment.id,
      label: segment.name,
    }));
  }, [segments]);

  useEffect(() => {
    getSegments();
  }, []);

  return { getSegments, segmentOptions, setSegments, isLoading, segments };
};

export default useGetSegments;
