import React from 'react';
import { Button } from '../../ui';
import { Icon } from '../../icons';
import { StyledSelectedTemplateCard } from './styled';
import { Template } from '../../../types/production/template';

const SelectedTemplateCard = ({
  template,
  onRemove,
}: {
  template: Template;
  onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const { name } = template;

  return (
    <StyledSelectedTemplateCard role={'button'}>
      <span>{name}</span>
      <Button onClick={onRemove} fab noShadow color={'primary'}>
        <Icon type={'Close'} size={'xs'} color={'white'} />
      </Button>
    </StyledSelectedTemplateCard>
  );
};

export default SelectedTemplateCard;
