/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgInstagram2 = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4703 35.7175C22.8229 35.7175 17.1765 35.7175 11.5292 35.7175C11.3091 35.6781 11.0881 35.6416 10.869 35.6003C7.98431 35.0554 5.98846 33.4113 4.83728 30.7226C4.52978 30.0038 4.41831 29.236 4.28571 28.474C4.28571 22.8267 4.28571 17.1803 4.28571 11.5329C4.39621 10.9429 4.46828 10.3443 4.65758 9.77059C5.74439 6.47558 8.70596 4.30388 12.1922 4.29428C17.3985 4.27986 22.6048 4.28659 27.8111 4.29235C29.4946 4.29428 31.0436 4.76321 32.4033 5.76353C34.6154 7.39134 35.7089 9.6005 35.7118 12.343C35.7166 17.4167 35.7128 22.4894 35.7137 27.5631C35.7137 28.0849 35.6955 28.6057 35.5984 29.1207C35.0536 32.0218 33.4027 34.0253 30.6948 35.1746C29.9818 35.4773 29.2227 35.5849 28.4703 35.7175ZM20.0055 6.92817C20.0055 6.92337 20.0055 6.91856 20.0055 6.91472C17.4485 6.91472 14.8914 6.91664 12.3335 6.9128C11.6839 6.91184 11.0477 6.9791 10.4347 7.20011C8.24376 7.98903 6.9148 9.90031 6.91287 12.2882C6.90807 17.433 6.91095 22.5788 6.91191 27.7236C6.91191 28.1435 6.93209 28.5615 7.02915 28.9718C7.62012 31.4673 9.65151 33.0855 12.246 33.0913C17.4119 33.1009 22.5769 33.0932 27.7428 33.0951C28.3607 33.0951 28.9671 33.0221 29.5504 32.8136C31.7557 32.0256 33.0846 30.1115 33.0866 27.7043C33.0904 22.5692 33.0885 17.4349 33.0866 12.2997C33.0866 11.9625 33.0654 11.6271 33.0193 11.2917C32.6983 8.97686 30.5276 6.97141 28.1983 6.93778C25.4674 6.89742 22.7364 6.92817 20.0055 6.92817Z"
      {...props}
      {...props}
      strokeWidth="0.5"
    />
    <path
      d="M27.8552 20.0041C27.8552 24.333 24.3046 27.8769 19.9843 27.8606C15.6572 27.8442 12.1441 24.3234 12.1441 20.0041C12.1441 15.6732 15.6918 12.1331 20.015 12.1485C24.3411 12.1648 27.8552 15.6847 27.8552 20.0041ZM19.9785 25.2353C22.8632 25.2536 25.2021 22.9368 25.23 20.0319C25.2569 17.1511 22.9401 14.8054 20.0352 14.7737C17.1448 14.742 14.7809 17.0828 14.7684 19.9916C14.7559 22.8791 17.0794 25.2171 19.9785 25.2353Z"
      {...props}
      {...props}
      strokeWidth="0.5"
    />
    <path
      d="M28.5117 13.446C27.4306 13.446 26.5648 12.587 26.5571 11.5069C26.5495 10.421 27.4306 9.52834 28.5117 9.5293C29.5869 9.5293 30.495 10.4431 30.4758 11.5069C30.4566 12.587 29.5869 13.446 28.5117 13.446Z"
      {...props}
    />
  </svg>
);

export default SvgInstagram2;
