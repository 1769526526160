import React, { useEffect, useState } from 'react';
import { StyledCard, StyledHeader, StyleUserInfoForm } from '../styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import useUserContext from '../hooks/useUserContext';
import { CreateUserRequestData } from '../../../../../shared/types/user';
import { Button, Input } from '../../../../../shared/components/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateUserScheme } from '../../../validation';
import { ButtonSizes } from '../../../../../shared/theme';
import { useScopedTranslation } from '../../../../../shared/hooks';

const InfoForm = () => {
  const { t } = useScopedTranslation('users.page');

  const { user, updateUser, isEdit } = useUserContext();
  const [isSaving, setIsSaving] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateUserRequestData>({
    mode: 'onBlur',
    resolver: yupResolver(UpdateUserScheme),
  });

  useEffect(() => {
    reset({ ...user });
  }, [user]);

  const onSubmit: SubmitHandler<CreateUserRequestData> = async (args) => {
    setIsSaving(true);
    await updateUser(args);
    setIsSaving(false);
  };

  return (
    <StyledCard>
      <StyledHeader>{t('user_data')}</StyledHeader>
      <StyleUserInfoForm onSubmit={handleSubmit(onSubmit)}>
        <Input
          isReadOnly={!isEdit}
          name={'name'}
          register={register}
          placeholder={t('enter_name')}
        />
        <Input
          isReadOnly={!isEdit}
          name={'email'}
          type={'email'}
          register={register}
          placeholder={t('enter_email')}
        />
        {isEdit && (
          <div>
            <Button
              isLoading={isSaving}
              isDisabled={!isValid}
              type={'submit'}
              color={'primary'}
              noShadow
              size={ButtonSizes.sm}
            >
              {t('save')}
            </Button>
          </div>
        )}
      </StyleUserInfoForm>
    </StyledCard>
  );
};

export default InfoForm;
