import React, { useEffect, useRef } from 'react';
import {
  StyledDownloadButton,
  StyledDuration,
  StyledLabel,
  StyledPlayerWrapper,
  StyledPlayIcon,
  StyledPlayPauseButton,
  StyledSlider,
  StyledSliderFilledTrack,
  StyledSliderTrack,
} from './styled';
import useAudioPlayer from './hooks/useAudioPlayer';
import { Icon as CustomIcon } from '../../icons';
import { ThemeColorButton } from '../../../theme';
import { Icon } from '@chakra-ui/react';
import { MdFileDownload } from 'react-icons/md';
import { fancyTimeFormat } from '../../../utils/utils';

type IAudioPlayer = {
  src: string;
  color?: ThemeColorButton;
  withProgress?: boolean;
  label?: string;
  startOnInit?: boolean;
  download?: boolean;
  withDuration?: boolean;
};

const AudioPlayer = ({
  src,
  withProgress,
  label,
  color = 'primary',
  startOnInit = false,
  download = false,
  withDuration = false,
  ...props
}: IAudioPlayer) => {
  const { playing, toggle, touchToggle, duration, currentTime, currentPercent, setTime } =
    useAudioPlayer(src, startOnInit);
  const track = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    track.current?.style.setProperty('width', `${currentPercent}%`);
  }, [currentPercent]);

  const downloadTrack = () => {
    window?.open(src, '_blank')?.focus();
  };

  return (
    <>
      <StyledPlayerWrapper {...props}>
        <StyledPlayPauseButton
          fab
          noShadow
          color={color}
          onTouchStart={touchToggle}
          onClick={toggle}
          isDisabled={!src}
        >
          {(playing && <CustomIcon type="Pause" />) || <StyledPlayIcon type="Play" />}
        </StyledPlayPauseButton>
        {download && (
          <StyledDownloadButton onClick={downloadTrack} fab noShadow isDisabled={!src}>
            <Icon as={MdFileDownload} />
          </StyledDownloadButton>
        )}
        {withProgress && (
          <StyledSlider
            color={color}
            min={0}
            max={duration}
            step={0.1}
            defaultValue={0}
            onChangeEnd={setTime}
            focusThumbOnChange={false}
            isDisabled={!src}
          >
            <StyledSliderTrack>
              <StyledSliderFilledTrack ref={track} color={color} />
            </StyledSliderTrack>
          </StyledSlider>
        )}
        {label && <StyledLabel>{label}</StyledLabel>}
        {withDuration && (
          <StyledDuration>
            {fancyTimeFormat(currentTime)} / {fancyTimeFormat(duration)}
          </StyledDuration>
        )}
      </StyledPlayerWrapper>
    </>
  );
};

export default AudioPlayer;
