import React, { useEffect, useState } from 'react';
import {
  Template,
  UpdateTemplateFormValues,
  UpdateTemplatePersonalizationRequest,
} from '../../../types/production/template';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { Modal, ModalBody, ModalCloseButton, ModalHeader, Spinner, Textarea } from '../../ui';
import { SubmitHandler } from 'react-hook-form';
import {
  StyledFormHeader,
  StyledFormSubheader,
  StyledFormWrapper,
  StyledPersonalizationsWrapper,
} from './styled';
import useHandleTemplateApi from '../../../requests/production/useHandleTemplateApi';
import useProcessUpdateForm from './hooks/useProcessUpdateForm';
import PersonalizationRow from './components/PersonalizationRow';
import GeneralInfo from './components/GeneralInfo';
import SegmentsSelector from './components/SegmentsSelector';
import IntroSettings from './components/IntroSettings';
import { useScopedTranslation } from '../../../hooks';

type TemplateEditorModalType = {
  template: Template;
  activator: React.ReactNode;
  onSave: Function;
  onTemplateDeleted: Function;
  onSchemeRefresh: Function;
};

const TemplateEditorModal = ({
  template,
  activator,
  onSave,
  onTemplateDeleted,
  onSchemeRefresh,
}: TemplateEditorModalType) => {
  const { t } = useScopedTranslation('production.templates');
  const [shouldResetScheme, setShouldResetScheme] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateTemplate, updatePersonalization, isLoading: isUpdating } = useHandleTemplateApi();

  const onModalClose = () => {
    if (shouldResetScheme) {
      onSchemeRefresh();
      setShouldResetScheme(false);
    }
    onClose();
  };

  const {
    segmentOptions,
    segmentsIds,
    register,
    handleSubmit,
    getValues,
    resetForm,
    isLoadingSegments,
  } = useProcessUpdateForm({ template });

  useEffect(() => {
    resetForm();
  }, [isOpen, segmentsIds]);

  const onSubmit: SubmitHandler<UpdateTemplateFormValues> = async (args) => {
    const data = {
      ...args,
      genres: (args?.genres && args?.genres?.split(',')?.map((genre) => genre.trim())) || [],
    };
    const updatedTemplate = await updateTemplate(template, { ...data });
    onSave(updatedTemplate);
  };

  const onSegmentsChange = (selectedSegmentsIds: number[]) => {
    resetForm({ ...getValues(), segments_ids: selectedSegmentsIds });
    handleSubmit(onSubmit)();
    setShouldResetScheme(true);
  };

  const onPersonalizationUpdate =
    (templatePersonalizationId: number) => async (data: UpdateTemplatePersonalizationRequest) => {
      const updatedTemplate = await updatePersonalization(
        template,
        templatePersonalizationId,
        data,
      );
      onSave(updatedTemplate);
      setShouldResetScheme(true);
    };

  const onDelete = async () => {
    onTemplateDeleted(template);
  };

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal {...{ isOpen, onOpen, onClose: onModalClose, size: 'full' }}>
        <ModalHeader>
          <Flex gap={'1rem'} alignItems={'center'}>
            <span>{template.name}</span>
            {isUpdating && <Spinner size={'sm'} />}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <StyledFormWrapper>
            <div>
              <GeneralInfo
                template={template}
                register={register}
                onSubmit={handleSubmit(onSubmit)}
                onDelete={onDelete}
              />
              <SegmentsSelector
                segments={segmentOptions}
                defaultValue={segmentsIds}
                isLoading={isLoadingSegments}
                onChange={onSegmentsChange}
              />
              <StyledFormHeader>{t('genres')}</StyledFormHeader>
              <StyledFormSubheader>{t('enter_genre_hint')}</StyledFormSubheader>
              <Textarea
                name={'genres'}
                placeholder={t('enter_genre')}
                {...{ register }}
                onBlur={handleSubmit(onSubmit)}
              />
              <IntroSettings register={register} onSubmit={handleSubmit(onSubmit)} />
            </div>
            <div>
              <StyledFormHeader>{t('settings')}</StyledFormHeader>
              <StyledFormSubheader>{t('settings_hint')}</StyledFormSubheader>
              <StyledPersonalizationsWrapper>
                {template?.personalizations?.map((personalization) => (
                  <PersonalizationRow
                    onUpdate={onPersonalizationUpdate(personalization.template_personalization_id)}
                    personalization={personalization}
                    key={personalization.name}
                  />
                ))}
              </StyledPersonalizationsWrapper>
            </div>
          </StyledFormWrapper>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TemplateEditorModal;
