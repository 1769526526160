import { useState } from 'react';
import { FileStructure } from '../../../../shared/types/production/fileStructure';
import { PreparedFile } from '../../../../shared/components/custom/UploaderDropzone/helpers';
import { TemplatesApi } from '../../../../api/modules/production/TemplatesApi';

const useHandleTemplateUpload = () => {
  const [scheme, setScheme] = useState<FileStructure | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [totalUploadProgress, setTotalUploadProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploaded, setUploaded] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  const getScheme = async (templateId: number) => {
    setIsLoading(true);
    await TemplatesApi.getScheme(templateId)
      .then(setScheme)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadFiles = async (templateId: number, files: PreparedFile[]) => {
    setIsFinished(false);
    setIsUploading(true);
    setUploaded(0);
    setTotalUploadProgress(0);
    const progressSegment = 100 / files.length;

    for (const index in files) {
      const file = files[index];
      await TemplatesApi.uploadTemplateFile(templateId, file, setUploadProgress).catch(() => {});
      setUploadProgress(0);
      const progress = progressSegment * (parseInt(index) + 1);
      setTotalUploadProgress((progress >= 100 && 100) || progress);
      setUploaded(parseInt(index) + 1);
    }

    setIsUploading(false);
    setIsFinished(true);

    await getScheme(templateId);
  };

  return {
    isLoading,
    isUploading,
    getScheme,
    scheme,
    uploadFiles,
    uploadProgress,
    totalUploadProgress,
    uploaded,
    isFinished,
    setIsFinished,
  };
};

export default useHandleTemplateUpload;
