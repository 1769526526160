import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';
import { Button } from '../../ui';

export const StyledMainTextWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: start;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;

    > div:first-of-type {
      order: 1;
    }

    textarea {
      overflow: auto !important;
    }
  }
`;

export const StyledStickySection = styled.div`
  position: sticky;
  top: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    position: unset;
  }
`;

export const StyledPersonalizationButtons = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  flex-wrap: wrap;

  button {
    padding: 0.5rem 1rem;
    height: 2.25rem;
  }
`;

export const StyledFormHeader = styled(Text)`
  margin-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledFormSubheader = styled(Text)`
  margin-bottom: 1rem;
  margin-top: -0.35rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledPersonalizationRowsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.75rem;
`;

export const StyledPersonalizationRow = styled.div``;

export const StyledCommonRow = styled.form`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.75rem;
  margin-bottom: 1rem;

  > p {
    padding-left: 1.5rem;
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.colors.text.main};
  }

  input {
    padding: 0.5rem 1.5rem;
  }

  textarea {
    //min-height: 3.25rem;
    //padding: 0.9rem 1.5rem;
  }

  button {
    height: 3.25rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledCustomRow = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr auto;
  grid-gap: 0.75rem;

  input {
    height: 3.25rem;
    padding: 0.5rem 1.5rem;
  }

  textarea {
    //min-height: 3.25rem;
    //padding: 0.9rem 1.5rem;
  }

  button {
    padding: 0;
    height: 3.25rem;
    width: 3.25rem;
    background: transparent;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr auto;

    > div:first-of-type {
      order: 0;
    }

    div:last-of-type {
      order: 1;
    }

    > div:nth-of-type(2) {
      grid-column-start: 1;
      grid-column-end: 3;
      order: 2;
    }

    button {
      background: ${({ theme }) => theme.palette.neutral10};
    }
  }
`;

export const StyledAddCustomRowButton = styled(Button)`
  margin-top: 1rem;
`;
