import React from 'react';
import {
  StyledDroppedFolder,
  StyledFolderHeader,
  StyledIgnoredFileRow,
  StyledIgnoredFiles,
} from '../styled';
import { UploaderFolder } from '../helpers';
import { Icon } from '../../../icons';
import { Popover } from '../../../ui';
import { useScopedTranslation } from '../../../../hooks';

const DroppedFolderCard = ({ folder }: { folder: UploaderFolder }) => {
  const { rootT } = useScopedTranslation('');
  const ignored = folder.error;

  return (
    <StyledDroppedFolder>
      <StyledFolderHeader>
        <p>{folder.name || 'Root'}</p>
        <Icon type={'File'} />
        <span>{folder.success.length}</span>
      </StyledFolderHeader>
      {!!ignored.length && (
        <Popover
          trigger={
            <StyledIgnoredFiles>
              <div role={'button'}>
                {rootT('production.intro_ignored', { count: ignored.length })}
              </div>
            </StyledIgnoredFiles>
          }
        >
          {ignored.map((file, index) => (
            <StyledIgnoredFileRow key={`${file.name}-${index}`}>
              <span>{file.name}</span>
              <span>{(file.size / 1000 / 1000).toFixed(3)} Mb</span>
            </StyledIgnoredFileRow>
          ))}
        </Popover>
      )}
    </StyledDroppedFolder>
  );
};

export default DroppedFolderCard;
