import React, { useEffect } from 'react';
import { StyledHeader, StyledPanel, StyledSectionWrapper } from '../../styled';
import { useOrderContext } from '../../hooks/useOrderContext';
import PersonalizationSelect from './PersonalizationSelect';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import useGeneratorPersonalizations from '../../../../../../shared/requests/production/useGeneratorPersonalizations';

export type OrderPersonalizationType = {
  registerRecipient: Function;
  registerPersonalizations: Function;
  resetPersonalizations: Function;
  watchInfo: Function;
};

const OrderPersonalization = ({
  registerRecipient,
  registerPersonalizations,
  resetPersonalizations,
  watchInfo,
}: OrderPersonalizationType) => {
  const { t } = useScopedTranslation('order.info');
  const { order } = useOrderContext();

  const segmentId = watchInfo('segment_id');

  const { eyes, months, kids, namesTo, namesFrom, isLoading, years } =
    useGeneratorPersonalizations(segmentId);

  useEffect(() => {
    const isLoadedOptions = !!eyes.length && !!months.length && !!kids.length && !!years.length;

    if (order && isLoadedOptions) {
      resetPersonalizations({
        eye_color: order?.info?.eye_color,
        dating_month: order?.info?.dating_month,
        kid_gender: order?.info?.kid_gender,
        start_year: order?.info?.start_year,
      });
    }
  }, [order, eyes, months, kids, years]);

  return (
    order && (
      <StyledPanel>
        <StyledHeader>{t('personalization')}</StyledHeader>
        <StyledSectionWrapper>
          <PersonalizationSelect
            isLoading={isLoading}
            register={registerPersonalizations}
            options={eyes}
            label={t('eye')}
            name={'eye_color'}
          />
          <PersonalizationSelect
            isLoading={isLoading}
            register={registerPersonalizations}
            options={months}
            label={t('month')}
            name={'dating_month'}
          />
          <PersonalizationSelect
            isLoading={isLoading}
            register={registerPersonalizations}
            options={kids}
            label={t('kids')}
            name={'kid_gender'}
          />
          <PersonalizationSelect
            isLoading={isLoading}
            register={registerPersonalizations}
            options={years}
            label={t('together')}
            name={'start_year'}
          />
          <PersonalizationSelect
            isLoading={isLoading}
            register={registerRecipient}
            options={namesFrom}
            label={t('sender')}
            name={'sender_name'}
            defaultValue={order?.sender_name}
          />
          <PersonalizationSelect
            isLoading={isLoading}
            register={registerRecipient}
            options={namesTo}
            label={t('recipient')}
            name={'recipient_name'}
            defaultValue={order?.recipient_name}
          />
        </StyledSectionWrapper>
      </StyledPanel>
    )
  );
};

export default OrderPersonalization;
