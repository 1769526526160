import React from 'react';
import StatusProgressBar from '../../../OrdersList/components/StatusProgressBar';
import { Option, OptionStatus } from '../../../../../../shared/types/orders';
import { StyledOptionDates, SyledOptionProgress } from '../../styled';
import { toPrettyDate } from '../../../../../../shared/utils/utils';

const OptionUpsaleProgress = ({ option, isRemaking }: { option: Option; isRemaking: boolean }) => {
  return (
    <SyledOptionProgress>
      <StatusProgressBar
        isIndeterminate={isRemaking}
        isFinished={option.status === OptionStatus.finished}
        startedAt={option.start_at}
        finishAt={option.deadline_at}
      />
      {!isRemaking && (
        <StyledOptionDates>
          <p>{toPrettyDate(option.start_at, 'dd.MM.yyyy hh:mm')}</p>
          <p>{toPrettyDate(option.deadline_at, 'dd.MM.yyyy hh:mm')}</p>
        </StyledOptionDates>
      )}
    </SyledOptionProgress>
  );
};

export default OptionUpsaleProgress;
