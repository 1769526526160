import React from 'react';
import { useReactQuill, useScopedTranslation } from '../../../../../shared/hooks';
import { useMailingContext } from '../hooks/useMailingContext';
import VariablesButtons from './VariablesButtons';
import { RichTextEditor } from '../../../../../shared/components/custom';

const ContentForm = () => {
  const { t } = useScopedTranslation('mailing.page');

  const { setEditor, addValue } = useReactQuill();
  const { reset, variables, watch, getValues } = useMailingContext();
  const content = watch('content');

  const onContentChange = (value: string) => {
    reset({ ...getValues(), content: value });
  };

  return (
    <div>
      <p>{t('content')}</p>
      <RichTextEditor value={content} onChange={onContentChange} setEditor={setEditor} />
      <VariablesButtons variables={variables} onAdded={addValue} />
    </div>
  );
};

export default ContentForm;
