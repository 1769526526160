import styled from '@emotion/styled';
import { SocialNetworks } from '../../../constants/SocialNetworks';
import { Link } from '@chakra-ui/react';
import { mapButtonSizes } from '../../ui/Button/helpers';
import { ButtonSizes } from '../../../theme';

export const StyledSocialButton = styled(Link)<{
  socialnetwork: SocialNetworks;
  size: ButtonSizes;
}>`
  background: ${({ socialnetwork, theme }) => theme.gradients[socialnetwork]};
  color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.sm};
  box-shadow: ${({ theme, noshadow }) => (!noshadow && theme.shadow.button) || 'none'};
  border-radius: ${({ theme, fab }) => (fab && '100%') || theme.radii.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  ${({ size }) => mapButtonSizes(size)}

  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  transition: none;

  svg {
    width: auto;
    height: 1.7rem;
    margin-right: 0.7rem;
    fill: ${({ theme }) => theme.palette.white};

    path {
      fill: ${({ theme }) => theme.palette.white};
    }
  }

  &:focus {
    box-shadow: 0 0.5rem 1rem 0 #7a265f14;
    background: ${({ socialnetwork, theme }) =>
      `${theme.gradients[socialnetwork]} padding-box, ${theme.colors.border[socialnetwork]} border-box`};
    border: 2px solid transparent;
  }

  &:hover,
  &:hover[disabled] {
    background: ${({ socialnetwork, theme }) => theme.gradients[socialnetwork]};
    opacity: 0.88;
    text-decoration: none;
    border: none;
  }
`;
