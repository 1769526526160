import styled from '@emotion/styled';
import TextareaAutosize from 'react-autosize-textarea';

export const StyledTextarea = styled(TextareaAutosize)`
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
  border-radius: ${({ theme }) => theme.radii.sm};
  min-height: 5.25rem;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-weight: 500;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.placeholder};
  }

  &:focus,
  &:focus-visible,
  &[data-focus],
  &[data-focus-visible] {
    border-color: ${({ theme }) => theme.palette.primary};
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
