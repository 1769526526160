import React, { useEffect, useRef, useState } from 'react';
import { Button, Textarea } from '../../../ui';
import { ButtonSizes } from '../../../../theme';
import { StyledCommonRow, StyledFormHeader, StyledFormSubheader } from '../styled';
import { PersonalizationLyrics } from '../../../../types/production/template';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormType } from '../types';
import { insertValueIntoText } from '../../../../utils/utils';
import { makeInsertValue, stringKey } from '../helpers';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CommonLyricScheme } from '../validationScheme';
import { useScopedTranslation } from '../../../../hooks';

type CommonStringType = {
  lyric?: PersonalizationLyrics;
  onChange: Function;
};

const CommonString = ({ lyric, onChange }: CommonStringType) => {
  const { t } = useScopedTranslation('production.templates');

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [lyricState, setLyricState] = useState<PersonalizationLyrics>();

  const { register, watch, reset, handleSubmit } = useForm<FormType>({
    mode: 'onChange',
    resolver: yupResolver(CommonLyricScheme),
  });
  const { ref, ...rest } = register('text');
  const text = watch('text');

  useEffect(() => {
    const newState = lyric ?? { key: null, text: '' };
    setLyricState(newState);
    reset({ ...newState });
  }, [lyric]);

  const onSubmit: SubmitHandler<FormType> = (args) => {
    if (args.text && args.text !== lyricState?.text) onChange({ ...lyricState, ...args });
  };

  const addKey = () => {
    const { current: input } = inputRef;
    if (!input) return;

    const result = insertValueIntoText(input, text, makeInsertValue(stringKey));
    reset({ key: null, text: result });
    onChange({ ...lyricState, text: result });
  };

  return (
    <div>
      <StyledFormHeader>{t('common_text_title')}</StyledFormHeader>
      <StyledFormSubheader>{t('common_text_subtitle')}</StyledFormSubheader>

      <StyledCommonRow onSubmit={handleSubmit(onSubmit)}>
        <Button onClick={addKey} noShadow size={ButtonSizes.sm}>
          {t('insert_value')}
        </Button>
        <Textarea
          placeholder={t('enter_lyrics_string')}
          {...{ ...rest, onBlur: handleSubmit(onSubmit) }}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />
      </StyledCommonRow>
    </div>
  );
};

export default CommonString;
