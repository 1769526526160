import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SegmentContextProvider } from './context/SegmentContext';
import { StyledPageWrapper } from './styled';
import useHandleSegmentApi from '../../../shared/requests/production/useHandleSegmentApi';
import GeneralInfo from './components/GeneralInfo';
import { Divider } from '@chakra-ui/react';
import { ProductionPaths } from '../../../router/routes';
import Senders from './components/Senders';
import Occasions from './components/Occasions';
import Personalizations from './components/Personalizations';
import SegmentPageSkeleton from './SegmentPageSkeleton';
import { PageBreadcrumbs } from '../../../shared/components/custom';
import { useScopedTranslation } from '../../../shared/hooks';
import useHasPermission from '../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../shared/constants/Permissions';

const SegmentPage = () => {
  const { t } = useScopedTranslation('production.segments');
  const { segmentId } = useParams();
  const {
    getSegment,
    updateSegment,
    updateSegmentSender,
    deleteSegment,
    syncOccasions,
    setSegment,
    segment,
    isLoading,
    addPersonalizationType,
    deletePersonalizationType,
    syncSegmentPersonalizationValues,
  } = useHandleSegmentApi();

  useEffect(() => {
    if (segmentId) getSegment(parseInt(segmentId));
  }, [segmentId]);

  const isEdit = useHasPermission(Permissions.generationEdit);

  return (
    <SegmentContextProvider
      value={{
        segment,
        setSegment,
        isLoading,
        updateSegment,
        deleteSegment,
        updateSegmentSender,
        syncOccasions,
        addPersonalizationType,
        deletePersonalizationType,
        syncSegmentPersonalizationValues,
        isEdit,
      }}
    >
      <PageBreadcrumbs
        rootPageName={t('segments')}
        rootPagePath={ProductionPaths.segments}
        currentPageName={segment?.name}
        isLoading={isLoading}
        isUpdating={!!segment}
      />

      {(segment && (
        <StyledPageWrapper>
          <GeneralInfo />
          <Divider />
          <Senders />
          <Divider />
          <Occasions />
          <Divider />
          <Personalizations />
        </StyledPageWrapper>
      )) || <SegmentPageSkeleton />}
    </SegmentContextProvider>
  );
};

export default SegmentPage;
