/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgPlay = (props) => (
  <svg
    {...props}
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.97775 14.9554C1.86842 14.9554 1.7591 14.9554 1.6487 14.9554C1.63155 14.949 1.61547 14.9383 1.59832 14.9361C1.20925 14.8718 0.933795 14.6542 0.751586 14.3113C0.574736 13.979 0.534007 13.6178 0.534007 13.2502C0.532935 9.71315 0.532935 6.17508 0.534007 2.63808C0.534007 2.37763 0.557587 2.11932 0.632614 1.86744C0.818039 1.24579 1.29821 0.891017 1.91237 0.937105C2.26071 0.962829 2.57368 1.09788 2.87379 1.26937C5.97027 3.04537 9.06782 4.82245 12.1643 6.59845C12.3872 6.726 12.5952 6.87069 12.7752 7.05612C13.2683 7.56416 13.2983 8.19653 12.8513 8.74423C12.6573 8.98217 12.4087 9.15045 12.1461 9.3005C9.21145 10.9833 6.27681 12.6671 3.34217 14.3498C2.90808 14.5985 2.48579 14.875 1.97775 14.9554Z"
      {...props}
    />
  </svg>
);

export default SvgPlay;
