import { useMemo } from 'react';
import { format } from 'date-fns';

export const useGetMonths = (displayFormat: string = 'LLLL', numeric: boolean = false) => {
  return useMemo(() => {
    const months: string[] = [];
    Array.from({ length: 12 }, (_, i: number) =>
      months.push(format(new Date(2000, i, 1), displayFormat)),
    );
    return months.map((month: string, key: number) => ({
      label: month,
      value: (numeric && key) || format(new Date(2000, key, 1), 'MMM').toLowerCase(),
    }));
  }, [displayFormat]);
};
