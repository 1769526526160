import styled from '@emotion/styled';
import { Button } from '../../ui';

export const StyledStatusBarWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledStatWrapper = styled.div<{ isUploading?: boolean; completed?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isUploading }) =>
    (isUploading && 'auto auto 1fr') || 'auto 1fr auto auto'};
  align-items: center;
  grid-gap: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.neutral10};
  border: 1px solid
    ${({ theme, completed, isUploading }) =>
      (completed === undefined && 'transparent') ||
      (isUploading && theme.palette.primary) ||
      (completed && theme.palette.success) ||
      theme.palette.error};
  height: 3.125rem;
  flex: 1 1;

  > button:last-of-type {
    border-radius: 100%;
    padding: 0;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    background: transparent;

    &:hover {
      background: ${({ theme }) => theme.palette.neutral20};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const StyledTemplateEditWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.neutral10};
  height: 3.125rem;
`;

export const StyledDetailsButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  background: transparent;
  height: 2rem;
  color: ${({ theme }) => theme.colors.text.main};
  box-shadow: none;

  &:hover {
    background: ${({ theme }) => theme.palette.neutral20};
  }
`;

export const StyledButton = styled(StyledDetailsButton)`
  height: 3.125rem;
  background: ${({ theme }) => theme.palette.neutral10};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
  }
`;

export const StyledStatusText = styled.p`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const SchemeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  align-items: flex-start;
`;

export const StyledFolderName = styled.div`
  padding: 0.25rem;
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;

  path {
    stroke: ${({ theme }) => theme.colors.text.main};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text.darker};
  }
`;

export const StyledChildrenWrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.neutral5};
  padding-left: 1rem;
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const StyledFileName = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0.25rem 0.5rem;
  background: ${({ theme }) => theme.palette.neutral5};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;

  span {
    margin-top: 2px;
  }

  svg:first-of-type {
    margin-right: 0.25rem;
  }

  svg:last-of-type {
    width: 0.85rem;
    height: auto;

    path {
      stroke: ${({ theme }) => theme.colors.text.main};
    }
  }
`;

export const StyledProgressContainer = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 0.5rem;
  width: 100%;
  overflow: hidden;
`;

export const StyledProgressBar = styled.div<{ value: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({ value }) => `${value}%`};
  background: ${({ theme }) => theme.palette.primary};
  z-index: 2;
  transition: 0.2s ease-in-out;
  border-radius: ${({ theme }) => theme.radii.sm};
`;

export const StyledTotalProgressBar = styled.div<{ value: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({ value }) => `${value}%`};
  background: ${({ theme }) => theme.palette.primary60};
  z-index: 1;
  transition: 0.2s ease-in-out;
  border-radius: ${({ theme }) => theme.radii.sm};
`;
