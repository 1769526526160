import styled from '@emotion/styled';
import { Avatar } from '@chakra-ui/react';
import { FileInput, Input, Textarea } from '../../../shared/components/ui';

export const StyledArtistPageWrapper = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: ${({ theme }) => theme.shadow.card};
`;

export const StyledArtistInfoCard = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: auto 1fr 1fr repeat(4, auto);
  align-items: center;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: auto 1fr auto;

    > div:nth-of-type(1) {
      grid-column-start: 2;
      grid-column-end: 5;
    }

    > div:nth-of-type(2) {
      display: none;
    }

    > div:nth-of-type(3) {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    > div:nth-of-type(4) {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    > div:nth-of-type(5) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
`;

export const StyledInputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 0 1rem 1rem;
`;

export const StyledArtistNameWrapper = styled.div`
  p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.placeholder};
    cursor: pointer;
    margin-top: -0.2rem;

    &:hover {
      color: ${({ theme }) => theme.colors.text.main};
      text-decoration: underline;
    }
  }
`;

export const StyledArtistPhoto = styled(Avatar)`
  width: 4rem;
  height: 4rem;

  img {
    border-radius: ${({ theme }) => theme.radii.md};
  }
`;

export const StyledArtistNameInput = styled(Input)`
  font-size: ${({ theme }) => theme.fontSize.xl2};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  height: unset;
  border: none;
  padding: 0;
`;

export const StyledArtistDescriptionInput = styled(Textarea)`
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-family: ${({ theme }) => theme.fontFamily.body};
  border: none;
  padding: 0;
`;

export const StyledArtistExampleInputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 0.5rem 1rem;
`;

export const StyledFileInput = styled(FileInput)`
  height: 3rem;
  box-shadow: none;
`;

export const StyledSchemesCard = styled.div`
  padding: 1rem;
`;

export const StyledSegmentRow = styled.div`
  > p {
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: 0.5rem;
  }
`;

export const StyledSchemeSlotsWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  background: ${({ theme }) => theme.palette.neutral5};
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }
`;

export const StyledSegmentRowSlot = styled.div`
  padding-right: 1rem;
  border-right: 1px solid ${({ theme }) => theme.palette.neutral10};
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;

  p {
    font-family: ${({ theme }) => theme.fontFamily.body};
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  input,
  select {
    padding: 0.25rem 1rem;
    height: 2.25rem;
    background: ${({ theme }) => theme.palette.white};
  }

  &:last-of-type {
    padding: 0;
    border-right: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral10};
    padding-bottom: 1rem;
  }
`;
