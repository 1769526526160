import React from 'react';
import { StyledPlaceholder } from './styled';
import { useScopedTranslation } from '../../../hooks';

const ListNoItems = ({ title, query }: { title: string; query?: string }) => {
  const { t } = useScopedTranslation('general');

  return (
    <StyledPlaceholder>
      {(!query && <span>{title}</span>) || (
        <span>
          {t('no_results_for')} <b>"{query}"</b>
        </span>
      )}
    </StyledPlaceholder>
  );
};

export default ListNoItems;
