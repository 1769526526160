import { useState } from 'react';
import { Template } from '../../types/production/template';
import { TemplatesApi } from '../../../api/modules/production/TemplatesApi';

const useGetTemplates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);

  const getTemplates = async ({
    artistId,
    segmentId,
  }: {
    artistId?: number;
    segmentId?: number;
  }) => {
    setIsLoading(true);
    const data: Record<string, string> = {};
    if (artistId) data.artist_id = artistId.toString();
    if (segmentId) data.segment_id = segmentId.toString();

    await TemplatesApi.get(data)
      .then((response) => {
        setTemplates(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { getTemplates, setTemplates, isLoading, templates };
};

export default useGetTemplates;
