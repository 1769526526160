import { useEffect, useState } from 'react';
import { SegmentsApi } from '../../../api';
import { Segment } from '../../types/production/segment';

const useGetSegments = (instant: boolean = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [segments, setSegments] = useState<Segment[]>([]);

  const getSegments = (artistId: number | null = null) => {
    setIsLoading(true);

    const data: Record<string, string> = {};
    if (artistId) data.artist_id = artistId.toString();

    SegmentsApi.get(data)
      .then((response) => {
        setSegments(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (instant) getSegments();
  }, [instant]);

  return { getSegments, setSegments, isLoading, segments };
};

export default useGetSegments;
