import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';
import { Button } from '../../shared/components/ui';

export const StyledRolesWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

export const StyledHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
`;

export const StyledSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const StyledSectionHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;

  > p {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  > div {
    height: 1px;
    background: ${({ theme }) => theme.palette.neutral10};
  }
`;

export const StyledRoleSectionHeader = styled(StyledSectionHeader)`
  grid-template-columns: auto 1fr auto;

  button {
    height: 2rem;
  }
`;

export const StyledCloseButton = styled(Button)`
  background: transparent;
  padding: 0;

  width: 2rem;
  height: 2rem;

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

export const StyledRoleCard = styled.form`
  box-shadow: ${({ theme }) => theme.shadow.card};
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1rem 1.5rem;

  display: grid;
  grid-gap: 1rem;

  p {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    margin-bottom: 0.5rem;
  }
`;

export const StyledPermissionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1rem;

  .chakra-checkbox__label {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  grid-gap: 0.75rem;
`;
