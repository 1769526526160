import React from 'react';
import { Spinner } from '../../ui';
import { StyledPageLoaderContainer } from './styled';

const PageLoader = () => {
  return (
    <StyledPageLoaderContainer>
      <Spinner />
    </StyledPageLoaderContainer>
  );
};

export default PageLoader;
