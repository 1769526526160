import React from 'react';
import { StyledFormHeader, StyledFormSubheader } from '../styled';
import { ButtonGroup } from '../../ButtonGroup';
import { ButtonGroupTypes } from '../../ButtonGroup/types';
import { useScopedTranslation } from '../../../../hooks';

const SegmentsSelector = ({
  segments,
  defaultValue,
  isLoading,
  onChange,
}: {
  segments: { value: number; label: string }[];
  defaultValue: number[];
  isLoading: boolean;
  onChange: Function;
}) => {
  const { t } = useScopedTranslation('production.templates');

  return (
    <>
      <StyledFormHeader>{t('segments')}</StyledFormHeader>
      <StyledFormSubheader>{t('segments_subheader')}</StyledFormSubheader>
      <ButtonGroup
        options={segments}
        onChange={onChange}
        type={ButtonGroupTypes.stack}
        isMultiple
        isLoading={isLoading}
        defaultValue={defaultValue}
        isRequired
      />
    </>
  );
};

export default SegmentsSelector;
