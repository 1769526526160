import { useEffect, useState } from 'react';
import { PersonalizationApi } from '../../../api';
import { Personalization } from '../../types/production/personalization';

const useGetOccasions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [occasions, setOccasions] = useState<Personalization[]>([]);

  const getOccasions = async () => {
    setIsLoading(true);
    await PersonalizationApi.getOccasions()
      .then((response) => {
        setOccasions(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOccasions();
  }, []);

  return { occasions, isLoading };
};

export default useGetOccasions;
