import React from 'react';
import {
  StyledBox,
  StyledCardHeading,
  StyledCardSubTitle,
  StyledCardTitle,
  StyledIconContainer,
  StyledTitleWrapper,
} from './styled';

type ICard = {
  icon?: React.ReactElement;
  children?: React.ReactNode;
  title?: String;
  subtitle?: String;
  bordered?: Boolean;
  row?: Boolean;
};

const Card = ({ children, icon, title, subtitle, bordered = false, row, ...props }: ICard) => {
  return (
    <StyledBox row={(row && 1) || 0} bordered={(bordered && 1) || 0} {...props}>
      {(icon || title || subtitle) && (
        <StyledCardHeading>
          {icon && <StyledIconContainer>{icon}</StyledIconContainer>}
          {(title || subtitle) && (
            <StyledTitleWrapper>
              {title && <StyledCardTitle>{title}</StyledCardTitle>}
              {subtitle && <StyledCardSubTitle>{subtitle}</StyledCardSubTitle>}
            </StyledTitleWrapper>
          )}
        </StyledCardHeading>
      )}
      {children}
    </StyledBox>
  );
};

export default Card;
