import { useEffect, useState } from 'react';
import { UserApi } from '../../../api';
import { User, UsersFilters } from '../../../shared/types/user';
import useInfiniteScrollItems from '../../../shared/hooks/useInfiniteScrollItems';

type UseLoadFiltersType = {
  limit: number;
} & UsersFilters;

const useLoadUsers = ({ limit, search, role }: UseLoadFiltersType) => {
  const [isLoading, setIsLoading] = useState(false);
  const { items, hasMore, setHasMore, appendItems, resetItems, setItems } =
    useInfiniteScrollItems(limit);

  const getUsers = (reset: boolean = false) => {
    if (isLoading) return;

    setIsLoading(true);

    if (reset) resetItems();

    const offset = (!reset && items.length) || 0;

    UserApi.get({ limit, offset, search, role })
      .then((response) => {
        appendItems(response?.data ?? [], reset);
        setIsLoading(false);
      })
      .catch(() => {
        setHasMore(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUsers(true);
  }, [limit, role, search]);

  return { getUsers, isLoading, users: items as User[], hasMore, setUsers: setItems };
};

export default useLoadUsers;
