import { OrderStatuses } from '../constants/OrderStatuses';
import { Artist } from './production/artist';
import { Invoice } from './invoice';
import { Customer } from './customer';

export type OrdersListRequest = {
  limit: number;
  offset: number;
  status?: string;
  search?: string;
  date_from?: string;
  date_to?: string;
};

export enum OptionNames {
  clientVoice = 'clientVoice',
  duet = 'duet',
  video = 'video',
}

export type OrderTrackResult = {
  url: string;
  lyrics: string;
};

export type Option = {
  name: OptionNames;
  status: OptionStatus;
  track_url: string;
  start_at: string;
  deadline_at: string;
  remade_at: string;
  has_paid_invoice: boolean;
};

export type OrderInfo = {
  dating_month: string;
  eye_color: string;
  kid_gender: string;
  start_year: string;
  date: string;
};

export type Order = {
  id: number;
  uuid: string;
  customer: Customer;
  artist: { data: Artist };
  segment: {
    id: number;
    name: string;
  };
  occasion: {
    id: number;
    name: string;
  };
  info: OrderInfo;
  recipient_name: string;
  sender_name: string;
  speed_up: {
    current: number;
    prices: {
      value: number;
      price: number;
    }[];
  };
  client_voice_url: string;
  slideshow: {
    remakes_available: number;
    url: string;
  };
  postcard: {
    id: number;
    image_url: string;
    share_url: string;
    track: {
      url: string;
      lyrics: string;
      length: number;
    };
    track_variant: string;
    title: string;
    text: string;
  } | null;
  options: Option[];
  options_prices: {
    expires_at: string;
    prices: Record<OptionNames, number>;
  };
  order_price: {
    expires_at: string;
    price: number;
  };
  result: {
    demo?: OrderTrackResult;
    client_voice?: OrderTrackResult;
    track?: OrderTrackResult;
    no_intro?: OrderTrackResult;
  };
  payments: Invoice[];
  status: OrderStatuses;
  created_at: string;
  deadline_at: string;
  failure_message: string;
  remade_at: string;
  intro_file_id: number;
  template_id: number;
};

export enum OptionStatus {
  waitingForPayment = 'waitingForPayment',
  pending = 'pending',
  processing = 'inWork',
  generate = 'generate',
  finished = 'finished',
}

export type OrderListItem = {
  id: number;
  uuid: string;
  customer: {
    name: string;
    email: string;
  };
  options: Option[];
  status: string;
  created_at: string;
  deadline_at: string;
};
