import { extendTheme } from '@chakra-ui/react';
import { ITheme } from './types';
import palette from './palette';
import gradients from './gradients';

export const designSystem: ITheme = {
  palette,
  spacing: {
    none: '0rem',
    xs3: '0.143rem',
    xs2: '0.286rem',
    xs: '0.571rem',
    s: '0.857rem',
    m: '1.143rem',
    ml: '1.429rem',
    l: '1.714rem',
    xl: '2.286rem',
    xl2: '2.857rem',
    xl3: '3.429rem',
  },
  radii: {
    none: '0px',
    xs: '4px',
    sm: '8px',
    md: '12px',
    xl: '16px',
    xl2: '24px',
  },
  borderWidth: {
    none: '0px',
    xs3: '1px',
    xs2: '2px',
  },
  colors: {
    text: {
      darker: palette.neutral100,
      main: palette.neutral90,
      secondary: palette.neutral80,
      placeholder: palette.neutral70,
    },
    border: {
      input: palette.neutral10,
      card: palette.neutral10,
      instagram: 'linear-gradient(to right, #b77e60, #85388e)',
      facebook: '#324774',
    },
    icons: {
      primary: palette.primary,
      secondary: palette.neutral80,
      textMain: palette.neutral90,
      white: palette.white,
    },
    buttons: {
      text: {
        primary: palette.white,
        primaryLight: palette.primary,
        success: palette.white,
        secondary: palette.neutral90,
        error: palette.white,
      },
      bg: {
        primary: palette.primary,
        primaryLight: palette.primary16,
        success: palette.success,
        secondary: palette.neutral10,
        error: palette.error,
      },
      hover: {
        primary: palette.primaryHover,
        primaryLight: palette.primary16,
        success: palette.successHover,
        secondary: '#71778814',
        error: palette.errorHover,
      },
      border: {
        primary: palette.primaryBorder,
        primaryLight: palette.primary16,
        success: palette.successHover,
        secondary: '#7F859629',
        error: '#7F859629',
      },
    },
    links: {},
    tabs: {},
    feedback: {},
    status: {},
    chips: {},
    inputs: {},
    cells: {},
  },
  gradients: {
    instagram: gradients.instagram,
    facebook: gradients.facebook,
    fadeToBottom: gradients.fadeToBottom,
    successLeft: gradients.successLeft,
    errorLeft: gradients.errorLeft,
  },
  fontSize: {
    xxs: '0.688rem', // 11px
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    xl2: '1.5rem', // 24px
    xl3: '1.75rem', // 28px
    xl4: '2.5rem', // 40px
    xl5: '3rem', // 48px
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  fontFamily: {
    heading: "'IbarraRealNova-Regular', sans-serif",
    headingMedium: "'IbarraRealNova-SemiBold', sans-serif",
    headingBold: "'IbarraRealNova-Bold', sans-serif",
    body: "'GothamPro-Regular', sans-serif",
    bodyMedium: "'GothamPro-Medium', sans-serif",
    bodyBold: "'GothamPro-Bold', sans-serif",
    stepper: "'Alice-Regular', sans-serif",
  },
  shadow: {
    button: '0 8px 16px rgba(165, 68, 86, 0.08)',
    card: '0px 12px 24px rgba(219, 217, 224, 0.24)',
    datepicker: '0px 0px 1px rgba(46, 56, 75, 0.24), 0px 4px 12px rgba(46, 56, 75, 0.08)',
  },
  breakpoints: {
    xs: `320px`,
    sm: `640px`,
    md: `768px`,
    lg: `1024px`,
    xl: `1150px`,
  },
  zIndex: {
    LN: -1,
    L0: 1,
    L1: 2,
    L3: 3,
    L4: 5,
    L5: 13,
  },
};

export const chakraTheme = extendTheme({
  ...designSystem,
  fonts: {
    body: designSystem.fontFamily,
  },
});

export type ThemePalette = keyof typeof designSystem.palette;
export type ThemeSpacing = keyof typeof designSystem.spacing;
export type ThemeColorText = keyof typeof designSystem.colors.text;
export type ThemeColorBorder = keyof typeof designSystem.colors.border;
export type ThemeColorIcon = keyof typeof designSystem.colors.icons;
export type ThemeColorButton =
  | keyof typeof designSystem.colors.buttons.bg
  | keyof typeof designSystem.colors.buttons.text
  | keyof typeof designSystem.colors.buttons.hover;
export type ThemeColorLink = keyof typeof designSystem.colors.links;
export type ThemeColorTab = keyof typeof designSystem.colors.tabs;
export type ThemeColorFeedback = keyof typeof designSystem.colors.feedback;
export type ThemeColorStatus = keyof typeof designSystem.colors.status;
export type ThemeFontSize = keyof typeof designSystem.fontSize;
export type ThemeFontWeight = keyof typeof designSystem.fontWeight;
export type ThemeBreakpoints = keyof typeof designSystem.breakpoints;
export type ThemeShadow = keyof typeof designSystem.shadow;
