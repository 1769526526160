import { useEffect, useState } from 'react';
import { Artist } from '../../../../shared/types/production/artist';
import { Segment } from '../../../../shared/types/production/segment';
import { useHandleQueryUrl } from '../../../../shared/hooks';
import { FilterQueryParams } from '../../../../shared/constants/production/common';
import { useLocation } from 'react-router-dom';

const useProcessQueryParams = ({
  artists,
  segments,
}: {
  artists: Artist[];
  segments: Segment[];
}) => {
  const [selectedArtist, setSelectedArtist] = useState<Artist | null>(null);
  const [selectedSegment, setSelectedSegment] = useState<Segment | null>(null);
  const { setInstanceByParameter, setQueryParam, removeQueryParam } = useHandleQueryUrl();
  const { search } = useLocation();

  useEffect(() => {
    setInstanceByParameter(FilterQueryParams.artistId, artists, setSelectedArtist);
  }, [artists, search]);

  useEffect(() => {
    if (selectedArtist?.id) setQueryParam(FilterQueryParams.artistId, selectedArtist.id.toString());
  }, [selectedArtist]);

  useEffect(() => {
    setInstanceByParameter(FilterQueryParams.segmentId, segments, setSelectedSegment);
  }, [segments, search]);

  useEffect(() => {
    if (selectedSegment?.id)
      setQueryParam(FilterQueryParams.segmentId, selectedSegment.id.toString());
  }, [selectedSegment]);

  const clearArtist = () => {
    setSelectedArtist(null);
    removeQueryParam(FilterQueryParams.artistId);
  };

  const clearSegment = () => {
    setSelectedSegment(null);
    removeQueryParam(FilterQueryParams.segmentId);
  };

  return {
    selectedArtist,
    setSelectedArtist,
    selectedSegment,
    setSelectedSegment,
    clearArtist,
    clearSegment,
  };
};

export default useProcessQueryParams;
