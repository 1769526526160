import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RootRouter from './router/RootRouter';
import './shared/i18n/i18n';
import * as Sentry from '@sentry/react';

// Theme
import { GlobalThemeProvider } from './shared/theme';
import { setDefaultDateLocale } from './shared/i18n/i18n';
import { useGlobalAudioHandler } from './shared/hooks';
import ToastContainer from './shared/components/ui/Toast/Toast';
import { UncaughtException } from './shared/components/custom';
import { AppContextProvider, defaultState } from './shared/context';
import { User } from './shared/types/user';
import { Language } from './shared/types/language';

function App() {
  setDefaultDateLocale();
  useGlobalAudioHandler();

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { fluid: defaultFluid } = defaultState;
  const [fluid, setFluid] = useState<boolean>(defaultFluid);
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string>('');
  const [language, setLanguage] = useState<Language | null>(null);

  return (
    <GlobalThemeProvider>
      <BrowserRouter>
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => <UncaughtException resetError={resetError} />}
        >
          <AppContextProvider
            value={{
              fluid,
              setFluid,
              sidebarCollapsed,
              setSidebarCollapsed,
              user,
              setUser,
              accessToken: token,
              setAccessToken: setToken,
              language,
              setLanguage,
            }}
          >
            <RootRouter />
          </AppContextProvider>
        </Sentry.ErrorBoundary>
      </BrowserRouter>
      <ToastContainer />
    </GlobalThemeProvider>
  );
}

export default App;
