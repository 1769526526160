/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgMusic2 = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2818 24.8944C33.4483 23.0222 34.2222 20.6543 34.3723 18.3113C34.4676 16.8075 34.1182 15.2289 33.3617 13.7444C32.1749 11.4125 30.1364 10.1026 28.1671 8.83693C26.6974 7.89254 25.3085 7.00077 24.3267 5.72681L24.1506 5.49971C23.5702 4.75196 22.9147 3.9045 22.8108 3.19274C22.7068 2.47268 22.0254 1.96032 21.2804 2.00187C20.5239 2.05172 19.9377 2.65269 19.9377 3.38107V28.0571C18.7278 27.3536 17.2379 26.9216 15.6065 26.9216C11.6275 26.9216 8.3877 29.4058 8.3877 32.4606C8.3877 35.5153 11.6275 37.9995 15.6065 37.9995C19.5854 37.9995 22.8252 35.5153 22.8252 32.4606V16.3201C24.9937 17.1149 28.5425 19.1394 29.5184 23.8669C29.3365 24.1244 29.1633 24.4014 28.9612 24.6229C28.4327 25.1962 28.4905 26.0714 29.0911 26.5754C29.6859 27.085 30.5984 27.0241 31.1268 26.4508C31.5137 26.0298 31.8573 25.5369 32.1865 25.0217C32.2211 24.983 32.253 24.9414 32.2818 24.8944Z"
      {...props}
    />
  </svg>
);

export default SvgMusic2;
