import styled from '@emotion/styled';

export const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  border-top: 1px solid ${({ theme }) => theme.palette.neutral10};
`;
