import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from '../../../ui';
import { FileStructure, FileStructureTypes } from '../../../../types/production/fileStructure';
import SchemeFolder from './SchemeFolder';
import SchemeFile from './SchemeFile';
import { SchemeWrapper } from '../styled';
import { useScopedTranslation } from '../../../../hooks';

const SchemeTreeModal = ({
  scheme,
  activator,
}: {
  scheme: FileStructure;
  activator: React.ReactNode;
}) => {
  const { t } = useScopedTranslation('production.intro');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal {...{ isOpen, onOpen, onClose, size: 'full' }}>
        <ModalCloseButton />
        <ModalHeader>{t('required_files')}</ModalHeader>
        <ModalBody>
          {scheme && (
            <SchemeWrapper>
              {scheme.scheme.map(
                (unit) =>
                  (unit.type === FileStructureTypes.folder && <SchemeFolder folder={unit} />) || (
                    <SchemeFile file={unit} />
                  ),
              )}
            </SchemeWrapper>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SchemeTreeModal;
