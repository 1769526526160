import * as Yup from 'yup';

export const MainLyricScheme = Yup.object().shape({
  lyrics: Yup.string().nullable().label('Text'),
});

export const CommonLyricScheme = Yup.object().shape({
  key: Yup.string().nullable().label('Key'),
  text: Yup.string().label('Text'),
});

export const CustomLyricScheme = Yup.object().shape({
  key: Yup.string().required().label('Key'),
  text: Yup.string().label('Text'),
});
