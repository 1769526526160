import React from 'react';
import { StyledFormHeader, StyledIntroSettings } from '../styled';
import { Input } from '../../../ui';
import { useScopedTranslation } from '../../../../hooks';

const IntroSettings = ({
  register,
  onSubmit,
}: {
  register: Function;
  onSubmit: React.ReactEventHandler;
}) => {
  const { t } = useScopedTranslation('production.templates');

  return (
    <StyledIntroSettings>
      <div>
        <StyledFormHeader>{t('intro_gain')}</StyledFormHeader>
        <Input
          name={'intro_gain'}
          type={'number'}
          placeholder={t('gain')}
          {...{ register }}
          onBlur={onSubmit}
        />
      </div>
      <div>
        <StyledFormHeader>{t('intro_length')}</StyledFormHeader>
        <Input
          name={'intro_length'}
          type={'number'}
          placeholder={t('length')}
          {...{ register }}
          onBlur={onSubmit}
        />
      </div>
      <div>
        <StyledFormHeader>{t('tag_padding')}</StyledFormHeader>
        <Input
          name={'tag_padding'}
          type={'number'}
          placeholder={t('padding')}
          {...{ register }}
          onBlur={onSubmit}
        />
      </div>
      <div>
        <StyledFormHeader>{t('tag_gain')}</StyledFormHeader>
        <Input
          name={'tag_gain'}
          type={'number'}
          placeholder={t('gain')}
          {...{ register }}
          onBlur={onSubmit}
        />
      </div>
    </StyledIntroSettings>
  );
};

export default IntroSettings;
