import React, { useState } from 'react';
import { Segment } from '../../../../shared/types/production/segment';
import {
  StyledDragHandle,
  StyledFormCard,
  StyledSegmentFolderName,
  StyledSegmentName,
  StyledStatusIcon,
} from '../styled';
import { DotsMenuButton, Switch } from '../../../../shared/components/ui';
import {
  Flex,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import { ProductionPaths } from '../../../../router/routes';
import { useNavigateWithParams, useScopedTranslation } from '../../../../shared/hooks';
import { Link } from 'react-router-dom';
import useHandleSegmentApi from '../../../../shared/requests/production/useHandleSegmentApi';
import { ConfirmDialog } from '../../../../shared/components/custom';
import { MdDragIndicator } from 'react-icons/md';
import useSortableList from '../../../../shared/hooks/useSortableList';

const SegmentCard = ({
  segment,
  onDelete,
  isEdit = false,
}: {
  segment: Segment;
  onDelete: Function;
  isEdit?: boolean;
}) => {
  const { t, rootT } = useScopedTranslation('production.segments');
  const { urlWithParams } = useNavigateWithParams();
  const urlToPage = urlWithParams(ProductionPaths.segmentPage, {
    segmentId: segment.id.toString(),
  });
  const [enabled, setEnabled] = useState(segment.enabled);
  const { updateSegment, deleteSegment, isLoading } = useHandleSegmentApi();
  const { attributes, listeners, setNodeRef, style } = useSortableList(segment);

  const toggleStatus = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEnabled(!enabled);
    updateSegment(segment, { enabled: !segment.enabled });
  };

  const deleteConfirmed = () => {
    onDelete(segment);
    deleteSegment(segment);
  };

  return (
    <LinkBox ref={setNodeRef} {...{ style }}>
      <StyledFormCard>
        <StyledDragHandle as={MdDragIndicator} {...attributes} {...listeners} />
        <div>
          <LinkOverlay as={Link} to={urlToPage}>
            <StyledSegmentName>{segment.name}</StyledSegmentName>
          </LinkOverlay>
          <StyledSegmentFolderName>
            <StyledStatusIcon enabled={enabled} type={'Dot'}></StyledStatusIcon>
            <span>
              {t('direction', {
                sender: rootT(`production.genders.${segment.senders[0].sender_gender}`),
                receiver: rootT(`production.genders.${segment.receiver_gender}`),
              })}
            </span>
          </StyledSegmentFolderName>
        </div>
        <Spacer />
        <Menu placement={'bottom-end'}>
          <MenuButton style={{ zIndex: 3 }}>
            <DotsMenuButton isLoading={isLoading} />
          </MenuButton>
          <MenuList zIndex={4}>
            <MenuItem as={Link} to={urlToPage}>
              {t('open')}
            </MenuItem>
            {isEdit && (
              <>
                <MenuItem onClick={toggleStatus}>
                  <Flex justifyContent={'space-between'} w={'100%'}>
                    <span>{t((enabled && 'enabled') || 'disabled')}</span>
                    <Switch isChecked={enabled} />
                  </Flex>
                </MenuItem>
                <MenuDivider />
                <ConfirmDialog
                  title={'Are you sure you want to delete this segment'}
                  onConfirm={deleteConfirmed}
                  confirmLabel={'Delete'}
                  activator={<MenuItem>{t('delete')}</MenuItem>}
                />
              </>
            )}
          </MenuList>
        </Menu>
      </StyledFormCard>
    </LinkBox>
  );
};

export default SegmentCard;
