import React from 'react';
import useGetArtists from '../../../shared/requests/production/useGetArtists';
import { StyledArtistsWrapper } from './styled';
import { CardArtist, SkeletonRepeater } from '../../../shared/components/custom';
import { CardArtistSkeleton } from '../../../shared/components/custom/CardArtist';
import CreateArtistModal from './components/CreateArtistModal';
import { Artist } from '../../../shared/types/production/artist';
import useHasPermission from '../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../shared/constants/Permissions';

const Artists = () => {
  const { artists, setArtists, isLoading } = useGetArtists();
  const isEdit = useHasPermission(Permissions.generationEdit);

  const onCreated = (artist: Artist) => {
    setArtists([...artists, artist]);
  };

  const onDeleted = (artist: Artist) => {
    setArtists(artists.filter(({ id }) => id !== artist.id));
  };

  return (
    <StyledArtistsWrapper>
      {(!isLoading && (
        <>
          {artists.map((artist) => (
            <CardArtist key={artist.id} artist={artist} onDelete={onDeleted} isEdit={isEdit} />
          ))}
          {isEdit && <CreateArtistModal onCreate={onCreated}></CreateArtistModal>}
        </>
      )) ||
        (isLoading && (
          <SkeletonRepeater number={4}>
            <CardArtistSkeleton />
          </SkeletonRepeater>
        ))}
    </StyledArtistsWrapper>
  );
};

export default Artists;
