import React from 'react';
import { OptionStatus, Order } from '../../../../../shared/types/orders';
import {
  StyledCreatedDate,
  StyledCustomer,
  StyledErrorMessage,
  StyledErrorTitle,
  StyledOptionStatus,
  StyledOptionsWrapper,
  StyledOrderCard,
  StyledOrderNumber,
  StyledOrderStatus,
  StyledStatusWrapper,
} from '../styled';
import {
  useNavigateWithParams,
  useOrderFailureMessage,
  useScopedTranslation,
} from '../../../../../shared/hooks';
import { toPrettyDate } from '../../../../../shared/utils/utils';
import StatusProgressBar from './StatusProgressBar';
import { LinkOverlay } from '@chakra-ui/react';
import { ControlPaths } from '../../../../../router/routes';
import { OrderStatuses } from '../../../../../shared/constants/OrderStatuses';
import { Icon } from '@chakra-ui/icons';
import { RiErrorWarningLine } from 'react-icons/ri';

const OrderCard = ({ order, showOptions = true }: { order: Order; showOptions?: boolean }) => {
  const { t } = useScopedTranslation('order');
  const { urlWithParams } = useNavigateWithParams();
  const { errorMessage, hasError } = useOrderFailureMessage(order);

  return (
    <StyledOrderCard options={showOptions}>
      <div>
        <LinkOverlay
          as={StyledOrderNumber}
          to={urlWithParams(ControlPaths.orderPage, { orderId: order.uuid })}
        >
          #{order.id}
        </LinkOverlay>
        <StyledCreatedDate>{toPrettyDate(order.created_at, 'dd.MM.yyyy HH:mm')}</StyledCreatedDate>
      </div>
      <div>
        <StyledCustomer>
          <p>{order.customer.name}</p>
          <p>{order.customer.email}</p>
        </StyledCustomer>
      </div>
      <StyledStatusWrapper>
        {(hasError && (
          <StyledErrorTitle>
            <Icon as={RiErrorWarningLine} />
            {t(`error`)}
          </StyledErrorTitle>
        )) || (
          <StyledOrderStatus status={order.status}>
            {t(`statuses.${order.status}`)}
          </StyledOrderStatus>
        )}
        {(!hasError && order.created_at && order.deadline_at && (
          <StatusProgressBar
            isIndeterminate={!!order.remade_at}
            isFinished={order.status === OrderStatuses.done}
            startedAt={order.created_at}
            finishAt={order.deadline_at}
          />
        )) ||
          (hasError && (
            <StyledErrorMessage noOfLines={1} title={errorMessage}>
              {errorMessage}
            </StyledErrorMessage>
          ))}
      </StyledStatusWrapper>
      {showOptions && (
        <StyledOptionsWrapper>
          {order.options.map((option) => (
            <div key={option.name}>
              <p>{t(`options.names.${option.name}`)}</p>
              <StyledOptionStatus status={option.status}>
                {t(`options.statuses.${option.status}`)}
              </StyledOptionStatus>
              <StatusProgressBar
                isIndeterminate={!!option.remade_at}
                isFinished={option.status === OptionStatus.finished}
                startedAt={option.start_at}
                finishAt={option.deadline_at}
              />
            </div>
          ))}
        </StyledOptionsWrapper>
      )}
    </StyledOrderCard>
  );
};

export default OrderCard;
