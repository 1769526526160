import React, { useEffect } from 'react';
import useGeneratorContext from '../hooks/useGeneratorContext';
import { useForm } from 'react-hook-form';
import { Select } from '../../../../shared/components/ui';
import useGetArtist from '../requests/useGetArtist';
import { useScopedTranslation } from '../../../../shared/hooks';
import { findOptionValueInOptionsArray } from '../../helpers';

type FormType = {
  artist_id: number | null;
  template_id?: number | null;
  intro_file_id?: number | null;
};

const ArtistSelector = ({ onArtistsUpdate }: { onArtistsUpdate: Function }) => {
  const { t } = useScopedTranslation('production.generator');

  const { isLoading, artists, templates, intro, getArtists, updateTemplatesAndIntro } =
    useGetArtist();
  const { data, setData } = useGeneratorContext();
  const { segment_id: segmentId, occasion_id: occasionId } = data;

  const { register, watch, reset, getValues } = useForm<FormType>();
  const { artist_id: artistId, template_id: templateId, intro_file_id: introId } = watch();

  useEffect(() => {
    if (segmentId && occasionId) getArtists(segmentId, occasionId);
  }, [segmentId, occasionId]);

  useEffect(() => {
    setData({ ...data, artist_id: artistId, template_id: templateId, intro_file_id: introId });
  }, [artistId, templateId, introId]);

  useEffect(() => {
    reset({
      ...getValues(),
      template_id: findOptionValueInOptionsArray(templates, data.template_id ?? null),
    });
  }, [templates]);

  useEffect(() => {
    reset({
      ...getValues(),
      intro_file_id: findOptionValueInOptionsArray(intro, data.intro_file_id ?? null),
    });
  }, [intro]);

  useEffect(() => {
    if (segmentId && artistId && occasionId) {
      updateTemplatesAndIntro(segmentId, occasionId, artistId);
    }
  }, [segmentId, occasionId, artistId]);

  useEffect(() => {
    onArtistsUpdate(artists);
  }, [artists]);

  return (
    <>
      <Select
        placeholder={t((!artists.length && 'no_artists') || 'random_artist')}
        {...{ register }}
        name={'artist_id'}
        options={artists}
        isDisabled={isLoading}
      />
      {!!artistId && (
        <>
          <Select
            placeholder={t('random_template')}
            {...{ register }}
            name={'template_id'}
            options={templates}
            isDisabled={isLoading}
          />
          <Select
            placeholder={t('random_intro')}
            {...{ register }}
            name={'intro_file_id'}
            options={intro}
            isDisabled={isLoading}
          />
        </>
      )}
    </>
  );
};

export default ArtistSelector;
