import { useState } from 'react';
import { Personalization } from '../../types/production/personalization';
import { PersonalizationApi } from '../../../api';
import useInfiniteScrollItems from '../../hooks/useInfiniteScrollItems';

const useGetPersonalization = () => {
  const [limit] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const { items, hasMore, setHasMore, appendItems, resetItems, setItems } =
    useInfiniteScrollItems(limit);

  const getPersonalizations = (type: string, reset: boolean = false) => {
    if (isLoading) return;

    if (reset) resetItems();
    const offset = (!reset && items.length) || 0;

    setIsLoading(true);
    PersonalizationApi.get({ type, limit, offset })
      .then((response) => {
        appendItems(response ?? [], reset);
        setIsLoading(false);
      })
      .catch(() => {
        setHasMore(false);
        setIsLoading(false);
      });
  };

  const syncOrder = async (personalizations: Personalization[]) => {
    const ids = personalizations.map(({ id }) => id);
    PersonalizationApi.syncOrder(ids)
      .then(() => {})
      .catch(() => {});
  };

  return {
    getPersonalizations,
    setPersonalizations: setItems,
    isLoading,
    personalizations: items as Personalization[],
    hasMore,
    syncOrder,
  };
};

export default useGetPersonalization;
