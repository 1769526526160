import React from 'react';
import { User } from '../../../../../shared/types/user';

export type IUserContext = {
  user?: User;
  updateUser: Function;
  deleteUser: Function;
  isEdit: boolean;
};

export const defaultState = {
  user: undefined,
  updateUser: () => {},
  deleteUser: () => {},
  isEdit: false,
};

export const UserContext = React.createContext<IUserContext>(defaultState);

export const UserContextProvider = UserContext.Provider;
