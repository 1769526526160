import { useMemo } from 'react';
import { InvoiceStatuses, PaymentPosition } from '../../../../../shared/types/invoice';
import { Order } from '../../../../../shared/types/orders';

const useTotalPaymentsAmount = (order: Order | null = null) => {
  const allPositions = useMemo(() => {
    const paidPayments =
      order?.payments?.filter(({ status }) => status === InvoiceStatuses.paid) ?? [];

    return paidPayments.reduce(
      (positions: PaymentPosition[], invoice) => [...positions, ...invoice.positions],
      [],
    );
  }, [order]);

  const totalPrice = useMemo(() => {
    return allPositions.reduce((sum, position) => sum + position.final_amount, 0);
  }, [allPositions]);

  return { allPositions, totalPrice };
};

export default useTotalPaymentsAmount;
