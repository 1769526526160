import { useState } from 'react';
import { GeneratorApi } from '../../../../api';
import { SelectOption } from '../../../../shared/types/common';

const UseGetArtist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [artists, setArtists] = useState<SelectOption[]>([]);
  const [templates, setTemplates] = useState<SelectOption[]>([]);
  const [intro, setIntro] = useState<SelectOption[]>([]);

  const getArtists = async (segmentId: number, occasionId: number) => {
    setIsLoading(true);
    GeneratorApi.getArtists(segmentId, occasionId)
      .then((response) => {
        setArtists(response.map((occasion) => ({ value: occasion.id, label: occasion.name })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getArtistTemplates = async (segmentId: number, occasionId: number, artistId: number) => {
    setIsLoading(true);
    GeneratorApi.getArtistTemplates(segmentId, occasionId, artistId)
      .then((response) => {
        setTemplates(response.map((template) => ({ value: template.id, label: template.name })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getArtistIntro = async (segmentId: number, occasionId: number, artistId: number) => {
    setIsLoading(true);
    GeneratorApi.getArtistIntro(segmentId, occasionId, artistId)
      .then((response) => {
        setIntro(response.map((file) => ({ value: file.id, label: file.name })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateTemplatesAndIntro = (segmentId: number, occasionId: number, artistId: number) => {
    getArtistTemplates(segmentId, occasionId, artistId);
    getArtistIntro(segmentId, occasionId, artistId);
  };

  return {
    isLoading,
    getArtists,
    artists,
    templates,
    intro,
    updateTemplatesAndIntro,
  };
};

export default UseGetArtist;
