import React from 'react';
import { StyledPeronalizationTypeRow, StyledTypeCell, StyledVariantsCell } from '../styled';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';

const PersonalizationTypeRow = () => {
  return (
    <StyledPeronalizationTypeRow>
      <StyledTypeCell>
        <Skeleton w={'5rem'} h={'1rem'} />
        <SkeletonCircle w={'2rem'} h={'2rem'} />
      </StyledTypeCell>
      <StyledVariantsCell>
        <Skeleton w={'7rem'} h={'2rem'} />
      </StyledVariantsCell>
    </StyledPeronalizationTypeRow>
  );
};

export default PersonalizationTypeRow;
