import React from 'react';
import {
  StyledDots,
  StyledHeader,
  StyledPanel,
  StyledPanelsWrapper,
  StyledPosition,
  StyledPositionsWrapper,
  StyledTotalWrapper,
  StyledTransactionCard,
} from '../../styled';
import { useOrderContext } from '../../hooks/useOrderContext';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import useTotalPaymentsAmount from '../../hooks/useTotalPaymentsAmount';
import { Invoice } from '../../../../../../shared/types/invoice';

const OrderPayments = () => {
  const { t } = useScopedTranslation('order.invoices');
  const { order, setOrder } = useOrderContext();

  const { allPositions, totalPrice } = useTotalPaymentsAmount(order);

  const onInvoiceUpdate = (invoice: Invoice) => {
    setOrder({
      ...order,
      payments: (order?.payments ?? []).map(
        (payment) => (payment.id === invoice.id && invoice) || payment,
      ),
    });
  };

  return (
    order && (
      <StyledPanelsWrapper>
        <StyledPanel>
          <StyledHeader>{t('payment_status')}</StyledHeader>
          {(!!allPositions.length && (
            <>
              <StyledPositionsWrapper>
                {allPositions.map((position) => (
                  <StyledPosition key={position.id}>
                    <p>{t(`positions.${position.name}`)}</p>
                    <StyledDots />
                    <p>${position.final_amount.toFixed(2)}</p>
                  </StyledPosition>
                ))}
              </StyledPositionsWrapper>
              <StyledTotalWrapper>
                <p>{t('total_amount')}</p>
                <StyledDots />
                <p>${totalPrice.toFixed(2)}</p>
              </StyledTotalWrapper>
            </>
          )) || <p>{t('no_payments')}</p>}
        </StyledPanel>

        {!!order?.payments?.length &&
          order.payments.map((invoice) => (
            <StyledTransactionCard
              noOrder
              invoice={invoice}
              key={invoice.id}
              onUpdate={onInvoiceUpdate}
            />
          ))}
      </StyledPanelsWrapper>
    )
  );
};

export default OrderPayments;
