import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  StyledDropzoneButton,
  StyledDropzoneContainer,
  StyledDropzoneHeader,
  StyledDropzoneIcon,
  StyledDropzoneInsideWrapper,
  StyledDropzoneSubHeader,
  StyledFilesGrid,
  StyledFullHeightContainer,
} from './styled';
import { ButtonSizes } from '../../../theme';
import ImageFileCard from './ImageFileCard';
import { Icon } from '../../icons';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useScopedTranslation, useSortableDropzone } from '../../../hooks';
import { DropzoneFile } from '../../../types/dropzoneFile';
import { SlidesPhoto } from '../../../types/slidesPhoto';
import { MAX_SLIDES_PHOTOS } from '../../../constants/mixed';

type IDropzone = {
  files: DropzoneFile[];
  setFiles: Function;
  isLoading: boolean;
  onRemove: (file: DropzoneFile) => void;
  onSort?: (sortedItems: SlidesPhoto[]) => void;
  onDrop?: (droppedItems: SlidesPhoto[]) => void;
  onRetry?: (file: SlidesPhoto) => void;
  isEdit?: boolean;
};

const ImagesDropzone = ({
  files,
  setFiles,
  onRemove,
  isLoading = false,
  onSort = () => {},
  onDrop = () => {},
  onRetry = () => {},
  isEdit = true,
  ...props
}: IDropzone) => {
  const [maxFiles] = useState(MAX_SLIDES_PHOTOS);
  const { t, rootT } = useScopedTranslation('dropzone');

  const {
    sensors,
    dropzoneFiles,
    filesLeft,
    activeFile,
    handleDragCancel,
    handleDragStart,
    handleDragEnd,
  } = useSortableDropzone({ files, setFiles, maxFiles, onSort, isSortable: !isLoading && isEdit });

  const { acceptedFiles, getRootProps, getInputProps, open, isDragReject, isDragAccept } =
    useDropzone({
      noClick: true,
      accept: {
        'image/*': ['.jpeg', '.png', '.jpg', '.heic', '.heif'],
      },
    });

  useEffect(() => {
    const addedFiles =
      acceptedFiles
        ?.filter((acceptedFile) => !dropzoneFiles.find((file) => file.name === acceptedFile.name))
        ?.map((acceptedFile) => {
          return {
            id: acceptedFile.name,
            name: acceptedFile.name,
            src: '',
            thumb_src: '',
            file: acceptedFile,
            error: false,
          };
        }) ?? [];

    const updatedFiles = [...dropzoneFiles, ...addedFiles];
    const numberOfFiles = updatedFiles.length;
    if (numberOfFiles > maxFiles) updatedFiles.splice(maxFiles - 1, numberOfFiles - maxFiles);
    onDrop(updatedFiles);
  }, [acceptedFiles]);

  return (
    <>
      <StyledDropzoneContainer
        {...{ ...getRootProps(), isRejected: isDragReject, isAccepted: isDragAccept, ...props }}
      >
        <StyledDropzoneInsideWrapper>
          <input {...getInputProps()} />
          <StyledDropzoneHeader>
            {(filesLeft > 0 && (
              <>
                {t('you_can_upload')} <span>{filesLeft}</span> {filesLeft < maxFiles && t('more')}{' '}
                {rootT('dropzone.photo', { count: filesLeft })}
              </>
            )) ||
              t('all_uploaded')}
          </StyledDropzoneHeader>
          <StyledDropzoneSubHeader>
            {(!!dropzoneFiles?.length && t('arrange_photos')) || t('once_downloaded')}
          </StyledDropzoneSubHeader>

          <StyledFullHeightContainer>
            {(!!dropzoneFiles?.length && (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext
                  items={dropzoneFiles.map(({ name }) => ({ id: name }))}
                  strategy={rectSortingStrategy}
                >
                  <StyledFilesGrid>
                    {dropzoneFiles.map((file, index) => (
                      <ImageFileCard
                        key={file.name}
                        file={file}
                        index={index}
                        isRemovable={isEdit}
                        onRemove={onRemove}
                        onRetry={onRetry}
                      />
                    ))}
                  </StyledFilesGrid>
                </SortableContext>

                <DragOverlay adjustScale={true}>
                  {activeFile && <ImageFileCard file={activeFile} />}
                </DragOverlay>
              </DndContext>
            )) || (
              <StyledDropzoneIcon {...{ isRejected: isDragReject, isAccepted: isDragAccept }}>
                <Icon type={'Slides'} />
              </StyledDropzoneIcon>
            )}
          </StyledFullHeightContainer>

          {isEdit && filesLeft > 0 && (
            <StyledDropzoneButton
              isLoading={isLoading}
              noShadow
              type="button"
              size={ButtonSizes.md}
              onClick={open}
            >
              {t('add_pics')}
            </StyledDropzoneButton>
          )}
        </StyledDropzoneInsideWrapper>
      </StyledDropzoneContainer>
    </>
  );
};

export default ImagesDropzone;
