import React, { useState } from 'react';
import { InvoiceCardSkeleton, ListNoItems, SkeletonRepeater } from '../../shared/components/custom';
import useLoadInvoices, { InvoiceFiltersType } from './requests/useLoadInvoices';
import InvoiceCard from '../../shared/components/custom/InvoiceCard/InvoiceCard';
import { StyledInvoicesHeader, StyledInvoicesWrapper } from './styled';
import InvoicesFilter from './components/InvoicesFilter';
import { useScopedTranslation } from '../../shared/hooks';
import { Invoice } from '../../shared/types/invoice';

const Invoices = () => {
  const { t } = useScopedTranslation('invoices');
  const [params, setParams] = useState<InvoiceFiltersType>({});
  const { invoices, getInvoices, hasMore, meta, isLoading, setItems } = useLoadInvoices({
    limit: 15,
    ...params,
  });

  const loadMore = () => {
    getInvoices();
  };

  const onUpdate = (invoice: Invoice) => {
    setItems(invoices.map((item) => (item.id === invoice.id && invoice) || item));
  };

  return (
    <>
      <InvoicesFilter setParams={setParams} meta={meta} />
      <StyledInvoicesHeader>
        <p>{t('table.info')}</p>
        <p>{t('table.description')}</p>
        <p>{t('table.amount')}</p>
        <p>{t('table.actions')}</p>
      </StyledInvoicesHeader>
      <StyledInvoicesWrapper loadMore={loadMore} initialLoad={false} hasMore={hasMore}>
        {(!!invoices.length &&
          invoices.map((invoice) => (
            <InvoiceCard invoice={invoice} key={invoice.id} onUpdate={onUpdate} />
          ))) ||
          (!isLoading && <ListNoItems title={t('no_payments')} query={params.search} />)}
        {isLoading && (
          <SkeletonRepeater key={0} number={4}>
            <InvoiceCardSkeleton />
          </SkeletonRepeater>
        )}
      </StyledInvoicesWrapper>
    </>
  );
};

export default Invoices;
