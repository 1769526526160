import React from 'react';
import { StyledGroupHeader } from '../styled';

const SidebarGroupHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledGroupHeader>
      <p>{children}</p>
    </StyledGroupHeader>
  );
};

export default SidebarGroupHeader;
