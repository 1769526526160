import React from 'react';
import { StyledProgressBar, StyledProgressContainer, StyledTotalProgressBar } from '../styled';

const CombinedProgressBar = ({
  progress,
  totalProgress,
}: {
  progress: number;
  totalProgress: number;
}) => {
  return (
    <StyledProgressContainer>
      <StyledProgressBar value={progress} />
      <StyledTotalProgressBar value={totalProgress} />
    </StyledProgressContainer>
  );
};

export default CombinedProgressBar;
