import { ControlPaths, ProductionPaths } from '../../../../router/routes';
import i18n from 'i18next';
import { Permissions } from '../../../constants/Permissions';

export const productionLinks = [
  {
    to: ProductionPaths.generator,
    caption: i18n.t('sidebar.generator'),
    permission: Permissions.generationView,
  },
  {
    to: ProductionPaths.segments,
    caption: i18n.t('sidebar.segments'),
    permission: Permissions.generationView,
  },
  {
    to: ProductionPaths.personalization,
    caption: i18n.t('sidebar.personalizations'),
    permission: Permissions.generationView,
  },
  {
    to: ProductionPaths.artists,
    caption: i18n.t('sidebar.artists'),
    permission: Permissions.generationView,
  },
  {
    to: ProductionPaths.intro,
    caption: i18n.t('sidebar.intro'),
    permission: Permissions.generationView,
  },
  {
    to: ProductionPaths.templates,
    caption: i18n.t('sidebar.templates'),
    permission: Permissions.generationView,
  },
  {
    to: ProductionPaths.settings,
    caption: i18n.t('sidebar.settings'),
    permission: Permissions.generationEdit,
  },
];

export const settingsLinks = [
  {
    to: ControlPaths.mailing,
    caption: i18n.t('sidebar.mailing'),
    permission: Permissions.mailingView,
  },
  {
    to: ControlPaths.roles,
    caption: i18n.t('sidebar.roles'),
    permission: Permissions.roleView,
  },
  {
    to: ControlPaths.prices,
    caption: i18n.t('sidebar.prices'),
    permission: Permissions.paymentView,
  },
];
