import React, { useRef, useEffect, useState, useMemo } from 'react';
import {
  ChartData,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { buildChartData, chartOptions } from '../helpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

export const DashboardChart = ({ dataSet }: { dataSet: { value: number; label: string }[] }) => {
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });

  const options = useMemo(() => chartOptions(dataSet), [dataSet]);

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    setChartData(buildChartData(dataSet));
  }, [dataSet]);

  return <Chart ref={chartRef} height={40} type="line" data={chartData} options={options} />;
};
