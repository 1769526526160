import React from 'react';
import { FileStructureTypes, FileStructureUnit } from '../../../../types/production/fileStructure';
import { StyledChildrenWrapper, StyledFolderName } from '../styled';
import SchemeFile from './SchemeFile';
import { Icon } from '../../../icons';
import { copyToClipBoard } from '../../../../utils/utils';
import { showToast } from '../../../ui';

const SchemeFolder = ({ folder }: { folder: FileStructureUnit }) => {
  const name = folder.name.toUpperCase();
  const copyFolderName = () => {
    copyToClipBoard(name);
    showToast('info', 'Folder name copied to clipboard', {
      position: 'bottom-center',
      autoClose: 1500,
    });
  };

  return (
    <div>
      <StyledFolderName onClick={copyFolderName} role={'button'}>
        <Icon type={'Folder'} />
        {name}
      </StyledFolderName>

      <StyledChildrenWrapper>
        {folder.children?.map(
          (unit) =>
            (unit.type === FileStructureTypes.folder && <SchemeFolder folder={unit} />) || (
              <SchemeFile file={unit} />
            ),
        )}
      </StyledChildrenWrapper>
    </div>
  );
};

export default SchemeFolder;
