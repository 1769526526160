import React from 'react';
import { Gender } from '../../../constants/Gender';
import { useScopedTranslation } from '../../../hooks';
import { ButtonGroup } from '../ButtonGroup';
import useArtistContext from '../../../../modules/production/artistPage/hooks/useArtistContext';

const GenderSelector = ({
  gender,
  setGender,
  ...props
}: {
  gender?: Gender;
  setGender: Function;
}) => {
  const { t } = useScopedTranslation('production.genders');
  const { isEdit } = useArtistContext();
  const gendersOptions = [Gender.female, Gender.male].map((g) => ({ value: g, label: t(g) }));

  return (
    <ButtonGroup
      isDisabled={!isEdit}
      defaultValue={gender}
      options={gendersOptions}
      onChange={setGender}
      {...props}
    />
  );
};

export default GenderSelector;
