import styled from '@emotion/styled';

/**
 App main div styles
 */
export const AppWrapper = styled.div`
  &&&& {
  }
`;

export default AppWrapper;
