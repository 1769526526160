import React from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from '../../ui';
import { useDisclosure } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../hooks';
import { StyledButtonsWrapper, StyledModal } from './styled';

type IAlert = {
  title: string;
  text?: string;
  actionLabel?: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const Alert = ({
  title,
  text = '',
  isOpen,
  onOpen,
  onClose,
  actionLabel = '',
  ...props
}: IAlert) => {
  const { t } = useScopedTranslation('general');

  const closeAlert = () => {
    onClose();
  };

  return (
    <StyledModal {...{ isOpen, onOpen, onClose, size: 'xs', ...props }}>
      <ModalHeader>{title}</ModalHeader>
      {text && <ModalBody>{text}</ModalBody>}
      <ModalFooter>
        <StyledButtonsWrapper>
          <Button noShadow color={'primary'} onClick={closeAlert}>
            {actionLabel || t('ok')}
          </Button>
        </StyledButtonsWrapper>
      </ModalFooter>
    </StyledModal>
  );
};

export default Alert;
