import { useEffect, useState } from 'react';
import { DashboardApi } from '../../api';
import { Order } from '../types/orders';
import { StatsResponse } from '../types/dashboard';

const useDashboardApi = () => {
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);

  const [stats, setStats] = useState<StatsResponse | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);

  const getStats = () => {
    setIsLoadingStats(true);
    DashboardApi.stats()
      .then(setStats)
      .finally(() => setIsLoadingStats(false));
  };

  const getOrders = () => {
    setIsLoadingOrders(true);
    DashboardApi.attention()
      .then(setOrders)
      .finally(() => setIsLoadingOrders(false));
  };

  useEffect(() => {
    getStats();
    getOrders();
  }, []);

  return { stats, isLoadingStats, orders, isLoadingOrders };
};

export default useDashboardApi;
