import {
  TemplatePersonalization,
  UpdateTemplatePersonalizationRequest,
} from '../../../../types/production/template';
import { Button, Input, Spinner, Switch } from '../../../ui';
import { StyledPersonalizationRow } from '../styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { PersonalizationTemplateScheme } from '../validationScheme';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../../hooks/useDebounce';
import { Flex } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../../hooks';

const PersonalizationRow = ({
  personalization,
  onUpdate,
}: {
  personalization: TemplatePersonalization;
  onUpdate: Function;
}) => {
  const { t } = useScopedTranslation('production.templates');

  const [isLoading, setIsLoading] = useState(false);
  const { name } = personalization;

  const { register, reset, handleSubmit, getValues, watch } =
    useForm<UpdateTemplatePersonalizationRequest>({
      resolver: yupResolver(PersonalizationTemplateScheme),
      defaultValues: {
        gain: personalization.gain,
        back: personalization.back,
      },
    });
  const gain = watch('gain');
  const debouncedGain = useDebounce(gain, 700);

  useEffect(() => {
    if (debouncedGain !== personalization.gain) handleSubmit(onSubmit)();
  }, [debouncedGain]);

  const minusGain = () => {
    reset({ ...getValues(), gain: gain - 1 });
  };

  const plusGain = () => {
    reset({ ...getValues(), gain: gain + 1 });
  };

  const onSubmit: SubmitHandler<UpdateTemplatePersonalizationRequest> = async (args) => {
    setIsLoading(true);
    await onUpdate(args);
    setIsLoading(false);
  };

  return (
    <StyledPersonalizationRow onSubmit={handleSubmit(onSubmit)}>
      <div>
        {name}
        {isLoading && <Spinner size={'sm'} />}
      </div>
      <Flex gap={'0.5rem'}>
        <Input
          type={'number'}
          name={'gain'}
          title={t('slot_gain')}
          {...{ register }}
          placeholder={'0'}
        />
        <Button onClick={minusGain} fab noShadow>
          -
        </Button>
        <Button onClick={plusGain} fab noShadow>
          +
        </Button>
      </Flex>
      <Switch name={'back'} onInput={handleSubmit(onSubmit)} {...{ register }}>
        {t('back')}
      </Switch>
    </StyledPersonalizationRow>
  );
};

export default PersonalizationRow;
