import styled from '@emotion/styled';
import { Tabs } from '@chakra-ui/react';

export const StyledTabs = styled(Tabs)`
  display: grid;
  
  .chakra-tabs__tablist {
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral10};
  }

  .chakra-tabs__tab {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.darker};

    &[aria-selected='true'] {
      color: ${({ theme }) => theme.palette.primary};
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }

  .chakra-tabs__tab-panel {
    padding-inline: 0;
    padding-top: 1.5rem;
    padding-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    .chakra-tabs__tablist {
      overflow: auto;
      overflow-y: hidden;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      min-height: 4rem;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      .chakra-tabs__tab {
        min-width: fit-content;
      }
      
      .chakra-tabs__tab:first-of-type {
        padding-left: 1.25rem;
      }
    }
  }
}
`;
