import React from 'react';
import {
  StyledEmailAction,
  StyledEmailContent,
  StyledEmailFooter,
  StyledEmailImage,
  StyledPreviewWrapper,
  StyledEmailHeader,
  StyledEmailContentWrapper,
  StyledEmailHelpWrapper,
} from '../styled';
import Logo from '../../../../../shared/theme/assets/img/logo.svg';

type EmailPreviewType = {
  content: string;
  imageUrl: string;
  actionLabel: string;
  actionUrl: string;
};

const EmailPreview = ({ content, imageUrl, actionLabel, actionUrl }: EmailPreviewType) => {
  return (
    <StyledPreviewWrapper>
      <StyledEmailHeader>
        <img src={Logo} alt={'logo'} />
      </StyledEmailHeader>
      <StyledEmailImage src={imageUrl} />
      <StyledEmailContentWrapper>
        <StyledEmailContent dangerouslySetInnerHTML={{ __html: content }} />
        <StyledEmailAction>
          <a href={'#'} rel="noreferrer">
            {actionLabel}
          </a>
        </StyledEmailAction>
      </StyledEmailContentWrapper>
      <StyledEmailHelpWrapper>
        <h2>Do you have any questions?</h2>
        <div>
          <a href={'#'} target={'_blank'} rel="noreferrer">
            CLICK HERE
          </a>
          - we will be happy to answer them
        </div>
      </StyledEmailHelpWrapper>
      <StyledEmailFooter>
        <p>Copyright @2022 Songfinch. All rights reserved.</p>
        <p>1525 N Elston Ave #6, Chicago, IL 60642</p>
        <p>
          You're on the list because you expressed interest in Songfinch or received a song from one
          of our incredible artists. Need to take a break from our emails?
        </p>
        <a href={'#'}>Unsubscribe</a>
      </StyledEmailFooter>
    </StyledPreviewWrapper>
  );
};

export default EmailPreview;
