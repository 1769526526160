import styled from '@emotion/styled';
import { Flex, Text } from '@chakra-ui/react';

export const StyledFormActionsWrapper = styled(Flex)`
  justify-content: flex-end;
  grid-gap: 0.75rem;
`;

export const StyledFormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledIntroSettings = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

export const StyledGeneralInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  grid-gap: 0.75rem;

  button {
    height: 3.25rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr auto;

    > div:first-of-type {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

export const StyledSwitchWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 0.75rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.neutral10};
  padding: 0.5rem 1rem;
  height: 3.25rem;

  > span:last-of-type {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const StyledFormHeader = styled(Text)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledFormSubheader = styled(Text)`
  margin-bottom: 0.5rem;
  margin-top: -0.35rem;
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledPersonalizationsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
`;

export const StyledPersonalizationRow = styled.form`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  grid-gap: 1.25rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral10};

  > div:first-of-type {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    display: flex;
    align-items: center;
    grid-gap: 0.75rem;
  }

  input {
    height: 2rem;
    width: 5rem;
    text-align: center;
  }

  button {
    width: 2rem;
    height: 2rem;
    min-height: 2rem;
  }

  &:last-of-type {
    border-bottom: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;

    > label {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;
