import { insertValueIntoText } from '../utils/utils';
import { useRef } from 'react';

type RefType = HTMLInputElement | HTMLTextAreaElement | null;

export const useInsertValue = ({ register, name }: { register: Function; name: string }) => {
  const inputRef = useRef<RefType>(null);
  const { ref: hookRef, ...rest } = register(name);

  const addValue = (text: string, value: string) => {
    const { current: input } = inputRef;
    if (!input) return;

    return insertValueIntoText(input, text, value);
  };

  const ref: (instance: RefType) => void = (e) => {
    hookRef(e);
    inputRef.current = e;
  };

  return { addValue, ref, rest };
};
