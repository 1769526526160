import React from 'react';
import {
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { StyledPopoverContent } from './styled';
import { Placement } from '@popperjs/core/lib/enums';

const Popover = ({
  trigger,
  header = '',
  children,
  placement = 'auto',
  portal = false,
  ...props
}: {
  trigger: React.ReactNode;
  header?: string;
  children: React.ReactNode;
  placement?: Placement;
  portal?: boolean;
}) => {
  const Content = () => {
    return (
      <StyledPopoverContent>
        <PopoverArrow />
        {header && <PopoverHeader>{header}</PopoverHeader>}
        <PopoverBody>{children}</PopoverBody>
      </StyledPopoverContent>
    );
  };

  return (
    <ChakraPopover placement={placement} {...props}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      {(portal && (
        <Portal>
          <Content />
        </Portal>
      )) || <Content />}
    </ChakraPopover>
  );
};

export default Popover;
