export enum PersonalizationTypeEnum {
  maleNames = 'male_names',
  femaleName = 'female_names',
  occasion = 'occasion',
  eyes = 'eyes',
  kids = 'kids',
  months = 'months',
  spouse = 'spouse',
  start_year = 'start_year',
}

export type SegmentPersonalizationKeys = PersonalizationTypeEnum | 'names_to' | 'names_from';
