import React, { useEffect, useMemo, useState } from 'react';
import { Button, Datepicker, Input, Select, Spinner } from '../../../../../shared/components/ui';
import {
  StyledCountPanel,
  StyledErrorButton,
  StyledFiltersWrapper,
  StyledPeriodWrapper,
} from '../styled';
import { OrderErrorStatus, OrderStatuses } from '../../../../../shared/constants/OrderStatuses';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { useDebounce } from '../../../../../shared/hooks/useDebounce';
import { useForm } from 'react-hook-form';
import { Spacer } from '@chakra-ui/react';
import { addDays, format } from 'date-fns';
import { numberWithCommas } from '../../../../../shared/utils/utils';
import { ButtonSizes } from '../../../../../shared/theme';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Icon } from '@chakra-ui/icons';

const OrdersFilter = ({ setParams, count }: { setParams: Function; count: number }) => {
  const { t, rootT } = useScopedTranslation('order.list.filters');
  const statuses = [
    ...Object.values(OrderStatuses).map((status) => ({
      value: status,
      label: rootT(`order.statuses.${status}`),
    })),
    { value: OrderErrorStatus, label: t('error') },
  ];

  const { register, watch, control, setValue } = useForm();
  const search = watch('search');
  const status = watch('status');
  const dateFrom = watch('date_from');
  const dateTo = watch('date_to');

  const debouncedSearch = useDebounce(search, 400);

  const hasFilter = useMemo(() => {
    return !!debouncedSearch || !!status || !!dateFrom || !!dateTo;
  }, [debouncedSearch, status, dateFrom, dateTo]);

  useEffect(() => {
    setParams({
      search: debouncedSearch,
      status,
      date_from: !!dateFrom && format(dateFrom, 'yyyy-MM-dd'),
      date_to: !!dateTo && format(addDays(dateTo, 1), 'yyyy-MM-dd'),
    });
  }, [debouncedSearch, status, dateFrom, dateTo]);

  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setShowErrors(status === OrderErrorStatus);
  }, [status]);

  const toggleErrors = () => {
    setShowErrors(!showErrors);
    setValue('status', (!showErrors && OrderErrorStatus) || '');
  };

  return (
    <>
      <StyledFiltersWrapper>
        <StyledErrorButton
          noShadow
          size={ButtonSizes.sm}
          active={showErrors}
          color={(showErrors && 'error') || 'secondary'}
          onClick={toggleErrors}
        >
          <Icon as={RiErrorWarningLine} />
          {t('error')}
        </StyledErrorButton>
        <Select
          placeholder={t('all_statuses')}
          name={'status'}
          options={statuses}
          register={register}
        />
        <div />
        <Input placeholder={t('search')} name={'search'} register={register} />
      </StyledFiltersWrapper>
      <StyledPeriodWrapper>
        <Datepicker isClearable placeholder={t('date_from')} name={'date_from'} control={control} />
        <Datepicker isClearable placeholder={t('date_to')} name={'date_to'} control={control} />
        <Spacer />
        <StyledCountPanel>
          <h3>{t((hasFilter && 'filtered_num') || 'total_num')}</h3>
          {(!!count && <p>{numberWithCommas(count)}</p>) || <Spinner size={'sm'} />}
        </StyledCountPanel>
      </StyledPeriodWrapper>
    </>
  );
};

export default OrdersFilter;
