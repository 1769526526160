import React, { useEffect } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSubheader,
  showToast,
} from '../../ui';
import { StyledFormActionsWrapper, StyledModalBody, StyledRandomButton } from './styled';
import { ButtonSizes } from '../../../theme';
import { useDisclosure } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useUserApi from '../../../requests/user/useUserApi';
import { CreateUserRequestData } from '../../../types/user';
import { CreateUserScheme } from './validation';
import { Icon } from '../../icons';
import { copyToClipBoard, generatePassword } from '../../../utils/utils';
import { useScopedTranslation } from '../../../hooks';

type CreateUserModalType = {
  activator: React.ReactNode;
  onCreated: Function;
};

const CreateUserModal = ({ activator, onCreated }: CreateUserModalType) => {
  const { t } = useScopedTranslation('users.page');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createUser, isLoading } = useUserApi();

  const {
    register,
    reset,
    getValues,
    formState: { isValid },
    handleSubmit,
  } = useForm<CreateUserRequestData>({
    resolver: yupResolver(CreateUserScheme),
  });

  useEffect(() => {
    reset({});
  }, [isOpen]);

  const onSubmit: SubmitHandler<CreateUserRequestData> = async (args) => {
    const user = await createUser({
      ...args,
      password_confirmation: args.password,
    });
    if (user) {
      onCreated(user);
      copyToClipBoard(args.password);
      showToast('success', 'Password copied to clipboard');
      onClose();
    }
  };

  const createPassword = () => {
    const password = generatePassword(16);
    reset({ ...getValues(), password });
  };

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal {...{ isOpen, onOpen, onClose }}>
        <ModalHeader>{t('add_new_user')}</ModalHeader>
        <ModalSubheader>{t('enter_info')}</ModalSubheader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledModalBody>
            <Input name={'name'} {...{ register }} placeholder={t('enter_name')} />
            <Input name={'email'} {...{ register }} placeholder={t('enter_email')} />
            <Input
              name={'password'}
              {...{ register }}
              placeholder={t('create_password')}
              inputRightElement={
                <StyledRandomButton fab noShadow title={t('random')} onClick={createPassword}>
                  <Icon type={'Dice'} />
                </StyledRandomButton>
              }
            />
          </StyledModalBody>
          <ModalFooter>
            <StyledFormActionsWrapper>
              <Button noShadow size={ButtonSizes.sm} type={'button'} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                noShadow
                size={ButtonSizes.sm}
                type={'submit'}
                color={'primary'}
                isDisabled={!isValid}
                isLoading={isLoading}
              >
                {t('add')}
              </Button>
            </StyledFormActionsWrapper>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CreateUserModal;
