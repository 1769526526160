import React from 'react';
import { StyledResultActionsWrapper, StyledResultWrapper, StyledTrackWrapper } from '../styled';
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { SkeletonRepeater } from '../../../../shared/components/custom';

const GenerationResult = () => {
  return (
    <StyledResultWrapper>
      <StyledTrackWrapper>
        <Skeleton w={'9rem'} h={'1rem'} mb={'1rem'} />
        <Flex alignItems={'center'}>
          <SkeletonCircle w={'2rem'} h={'2rem'} mr={'1rem'} />
          <Skeleton w={'100%'} h={'0.75rem'} />
        </Flex>
      </StyledTrackWrapper>
      <StyledTrackWrapper>
        <Skeleton w={'7rem'} h={'1rem'} mb={'1rem'} />
        <SkeletonRepeater number={8}>
          <Skeleton w={'100%'} h={'1rem'} mb={'0.5rem'} />
        </SkeletonRepeater>
      </StyledTrackWrapper>
      <StyledResultActionsWrapper>
        <Skeleton w={'6rem'} h={'1rem'} />
        <div />
        <Skeleton w={'6rem'} h={'2.25rem'} />
        <Skeleton w={'9rem'} h={'2.25rem'} />
        <Skeleton w={'8rem'} h={'2.25rem'} />
      </StyledResultActionsWrapper>
    </StyledResultWrapper>
  );
};

export default GenerationResult;
