import styled from '@emotion/styled';
import { Modal } from '../../ui/Modal';

export const StyledButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  width: 100%;
`;

export const StyledModal = styled(Modal)``;

export const StyledWarning = styled.p`
  background: ${({ theme }) => theme.palette.error};
  color: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  padding: 0.5rem;
  margin-bottom: 1rem;
`;

export const StyledFileWrapper = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.neutral5};
  border-radius: ${({ theme }) => theme.radii.sm};

  > button {
    box-shadow: none;
    width: 100%;
    height: 3rem;
  }
`;

export const StyledFileWrapperHeader = styled.p`
  margin-bottom: 0.5rem;
`;
