export enum FilterQueryParams {
  artistId = 'artistId',
  segmentId = 'segmentId',
  templateId = 'templateId',
}

export enum TrackType {
  full = 'FULL',
  fullBack = 'FULL BACK',
  fullTag = 'FULL TAG',
}
