import { API_URL, AUTH_TOKEN_KEY } from '../constants';
import { httpErrorHandler } from './httpErrorHandler';
import { appLocalStorage } from './appLocalStorage';

type IHttpParams = {
  url: string;
  body?: string | FormData | null;
  headers?: Record<string, string>;
  silent?: boolean;
};

export type ProgressHandler = (value: number) => void;

const BASE_URL = API_URL;
const DEFAULT_HEADERS = {
  Accept: 'application/json',
};

const apiUrl = (url: string) => `${BASE_URL}${url}`;

const getAuthHeader = () => {
  const authToken = appLocalStorage.getItem(AUTH_TOKEN_KEY);
  return (authToken && `Bearer ${authToken}`) || '';
};

const http = (
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  { url, body = null, headers = {}, silent = false }: IHttpParams,
) => {
  return new Promise<never>(async (resolve, reject) => {
    await fetch(apiUrl(url), {
      method,
      headers: { ...DEFAULT_HEADERS, Authorization: getAuthHeader(), ...headers },
      body,
      credentials: 'include',
    })
      .then(
        (response) =>
          (response.ok && ((response.status !== 204 && response.json()) || "''")) ||
          Promise.reject(response),
      )
      .then((response) => resolve(response))
      .catch(async (response) => {
        console.error(response);
        try {
          const error = await response.json();
          if (!silent) httpErrorHandler(error);
          reject({ ...error, status: response?.status });
        } catch (err) {
          if (!silent) httpErrorHandler({ message: '' });
          reject({ message: '', status: response?.status });
        }
      });
  });
};

export const httpGet = (
  url: string,
  params?: Record<string, string | number | undefined>,
  silent: boolean = false,
) => {
  const filteredParams: Record<string, string> = {};
  Object.keys(params ?? {}).forEach((key) => {
    if (!!params && (!!params[key] || params[key] === 0))
      filteredParams[key] = params[key]?.toString() ?? '';
  });

  const queryParams = new URLSearchParams(filteredParams);
  return http('GET', { url: (!!queryParams && `${url}?${queryParams}`) || url, silent });
};

export const httpPost = (url: string, data: Object, silent: boolean = false) =>
  http('POST', {
    url,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    silent,
  });

export const httpPostFormData = (url: string, body: FormData, silent: boolean = false) =>
  http('POST', { url, body, silent });

export const httpPut = (url: string, data: Object, silent: boolean = false) =>
  http('PUT', {
    url,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    silent,
  });

export const httpPatch = (url: string, data: Object, silent: boolean = false) =>
  http('PATCH', {
    url,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    silent,
  });

export const httpDelete = (
  url: string,
  params: Record<string, string | ''> = {},
  silent: boolean = false,
) => {
  const queryParams = new URLSearchParams(params);
  return http('DELETE', { url: (queryParams && `${url}?${queryParams}`) || url, silent });
};

export const uploadWithProgress = async (
  url: string,
  file: FormData,
  onProgressChange: ProgressHandler,
): Promise<boolean> => {
  const xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        onProgressChange((event.loaded / event.total) * 100);
      }
    });
    xhr.addEventListener('loadend', () => {
      resolve(xhr.readyState === 4 && xhr.status === 200);
    });
    xhr.addEventListener('error', () => {
      httpErrorHandler({ message: 'Error during upload' });
      reject(false);
    });
    xhr.open('POST', apiUrl(url), true);
    xhr.setRequestHeader('Authorization', getAuthHeader());
    xhr.send(file);
  });
};
