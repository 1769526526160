import { useMemo } from 'react';
import { Order } from '../types/orders';

export const useOrderFailureMessage = (order: Order | null) => {
  const errorMessage = useMemo(() => {
    const message = order?.failure_message ?? '';
    try {
      return JSON.parse(message)?.message;
    } catch ($e) {
      return message;
    }
  }, [order]);

  const hasError = useMemo(() => !!errorMessage, [errorMessage]);

  return { errorMessage, hasError };
};
