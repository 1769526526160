/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgBalloon = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_439_3297" fill="white">
      <path d="M29.4037 6.53911C25.6555 4.37507 20.8882 5.58799 18.7783 9.24251C18.5436 9.64903 18.6914 10.1744 19.1084 10.4151C19.5254 10.6559 20.0543 10.5213 20.289 10.1148C21.9304 7.2719 25.6375 6.3287 28.5533 8.01211C28.9702 8.25283 29.4992 8.11824 29.7339 7.71173C29.9686 7.30522 29.8206 6.77983 29.4037 6.53911Z" />
    </mask>
    <path
      d="M29.4037 6.53911C25.6555 4.37507 20.8882 5.58799 18.7783 9.24251C18.5436 9.64903 18.6914 10.1744 19.1084 10.4151C19.5254 10.6559 20.0543 10.5213 20.289 10.1148C21.9304 7.2719 25.6375 6.3287 28.5533 8.01211C28.9702 8.25283 29.4992 8.11824 29.7339 7.71173C29.9686 7.30522 29.8206 6.77983 29.4037 6.53911Z"
      {...props}
      strokeWidth="6"
      mask="url(#path-1-inside-1_439_3297)"
    />
    <mask id="path-2-inside-2_439_3297" fill="white">
      <path d="M35.3972 18.8373C38.4452 13.5581 36.874 6.1056 31.4594 2.9795C26.0448 -0.146611 18.1914 1.86461 15.1434 7.14382C12.0257 12.5439 14.2422 21.1159 17.0279 24.5075L16.1787 24.7223C15.8402 24.8096 15.5933 25.0874 15.5521 25.4309C15.5093 25.7735 15.6821 26.1128 15.9873 26.289L16.9949 26.8708C16.2236 33.4192 14.1257 33.3373 11.5316 34.0275C9.94148 34.4509 7.99029 34.9008 6.25656 36.7103C5.93192 37.0493 6.3072 38.223 6.65922 38.5519C6.70965 38.6007 6.76511 38.6406 6.82258 38.6738C7.16552 38.8718 7.60617 38.8237 7.88334 38.532C9.29065 37.0644 10.8826 36.5052 12.3246 36.122C15.5731 35.2577 17.721 34.3251 18.6296 27.8145L19.0088 28.0335C19.314 28.2097 19.6942 28.1896 19.9696 27.9813C20.2464 27.7739 20.3635 27.4212 20.2699 27.0843L20.0313 26.2415C24.3614 26.9582 32.2795 24.2374 35.3972 18.8373ZM19.4115 23.847C16.7994 22.3389 13.8236 13.5016 17 7.99994C19.5794 3.53232 25.9178 1.85442 30.5 4.49994C35.0822 7.14546 36.4658 13.4975 33.8864 17.9651C30.71 23.4668 22.0237 25.3551 19.4115 23.847Z" />
    </mask>
    <path
      d="M35.3972 18.8373C38.4452 13.5581 36.874 6.1056 31.4594 2.9795C26.0448 -0.146611 18.1914 1.86461 15.1434 7.14382C12.0257 12.5439 14.2422 21.1159 17.0279 24.5075L16.1787 24.7223C15.8402 24.8096 15.5933 25.0874 15.5521 25.4309C15.5093 25.7735 15.6821 26.1128 15.9873 26.289L16.9949 26.8708C16.2236 33.4192 14.1257 33.3373 11.5316 34.0275C9.94148 34.4509 7.99029 34.9008 6.25656 36.7103C5.93192 37.0493 6.3072 38.223 6.65922 38.5519C6.70965 38.6007 6.76511 38.6406 6.82258 38.6738C7.16552 38.8718 7.60617 38.8237 7.88334 38.532C9.29065 37.0644 10.8826 36.5052 12.3246 36.122C15.5731 35.2577 17.721 34.3251 18.6296 27.8145L19.0088 28.0335C19.314 28.2097 19.6942 28.1896 19.9696 27.9813C20.2464 27.7739 20.3635 27.4212 20.2699 27.0843L20.0313 26.2415C24.3614 26.9582 32.2795 24.2374 35.3972 18.8373ZM19.4115 23.847C16.7994 22.3389 13.8236 13.5016 17 7.99994C19.5794 3.53232 25.9178 1.85442 30.5 4.49994C35.0822 7.14546 36.4658 13.4975 33.8864 17.9651C30.71 23.4668 22.0237 25.3551 19.4115 23.847Z"
      {...props}
      strokeWidth="6"
      mask="url(#path-2-inside-2_439_3297)"
    />
  </svg>
);

export default SvgBalloon;
