import React from 'react';
import { Artist, ArtistSegmentScheme } from '../../../../shared/types/production/artist';

export type ISegmentContext = {
  isLoading: boolean;
  artist: Artist | null;
  setArtist: React.Dispatch<React.SetStateAction<Artist | null>>;
  updateArtist: Function;
  deleteArtist: Function;
  updateAvatar: Function;
  updateExample: Function;
  getSchemes: Function;
  schemes: ArtistSegmentScheme[];
  updateSlot: Function;
  isEdit: boolean;
};

export const defaultState = {
  isLoading: false,
  artist: null,
  setArtist: () => {},
  updateArtist: () => {},
  deleteArtist: () => {},
  updateAvatar: () => {},
  updateExample: () => {},
  getSchemes: () => {},
  schemes: [],
  updateSlot: () => {},
  isEdit: false,
};

export const ArtistContext = React.createContext<ISegmentContext>(defaultState);

export const ArtistContextProvider = ArtistContext.Provider;
