import styled from '@emotion/styled';
import { Button } from '@chakra-ui/react';
import { ButtonSizes, ThemeColorButton } from '../../../theme';
import { mapButtonSizes } from './helpers';

type StyledButtonProps = {
  colortheme: ThemeColorButton;
  size: ButtonSizes;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.6rem;
  padding: ${({ fab }) => (fab && '0') || '0 1rem'};
  background: ${({ colortheme, theme }) => theme.colors.buttons.bg[colortheme]};
  color: ${({ colortheme, theme }) => theme.colors.buttons.text[colortheme]};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.sm};
  box-shadow: ${({ theme, noshadow }) => (!noshadow && theme.shadow.button) || 'none'};
  border-radius: ${({ theme, fab }) => (fab && '100%') || theme.radii.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  ${({ block }) => block && 'width: 100%'};
  ${({ size }) => mapButtonSizes(size)}
  border: 1px solid transparent;
  line-height: 1;
  transition: 0.3s ease-in-out;

  &:hover,
  &:hover[disabled] {
    background: ${({ colortheme, theme }) => theme.colors.buttons.hover[colortheme]};
  }

  &:focus {
    border-color: ${({ colortheme, theme }) => theme.colors.buttons.border[colortheme]};
    box-shadow: ${({ theme, noshadow }) => (!noshadow && theme.shadow.button) || 'none'};
  }

  > svg {
    width: 0.75rem;
    height: auto;
  }
`;

export const StyledCardMenuButton = styled(Button)`
  background: transparent;
  width: 2rem !important;
  min-height: 2.5rem;
  height: 2.5rem;
  box-shadow: none;
  border-radius: 100%;
  margin-right: -0.5rem;

  > svg {
    width: 4px;
    height: auto;
  }
`;
