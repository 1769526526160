import React from 'react';
import { StyledPersonalizationRow } from '../styled';
import { Skeleton, Spacer } from '@chakra-ui/react';

const PersonalizationRowSkeleton = () => {
  return (
    <StyledPersonalizationRow>
      <Skeleton w={'1.5rem'} h={'1rem'} />
      <Skeleton w={'9rem'} h={'1rem'} ml={'1.5rem'} />
      <Skeleton w={'5rem'} h={'1rem'} />
      <Spacer />
    </StyledPersonalizationRow>
  );
};

export default PersonalizationRowSkeleton;
