import React from 'react';

const SkeletonRepeater = ({
  children,
  number = 1,
}: {
  children: React.ReactNode;
  number?: number;
}) => {
  return (
    <React.Fragment key={0}>
      {Array.from(Array(number)).map((e, i) => (
        <React.Fragment key={`skel-${i}`}>{children}</React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default SkeletonRepeater;
