import { useLayoutEffect, useMemo, useState } from 'react';
import { designSystem, ThemeBreakpoints } from '../theme';

export const useBreakpoints = () => {
  const [breakpoints] = useState(designSystem.breakpoints);
  const [windowWidth, setWindowWidth] = useState<number>(window.screen.width);

  const isBreakpoint = (breakpoint: ThemeBreakpoints) => {
    return window.screen.width >= parseInt(breakpoints[breakpoint]);
  };

  const updateSize = () => {
    setWindowWidth(window.screen.width);
  };

  const isLg = useMemo(() => isBreakpoint('lg'), [windowWidth]);
  const isMd = useMemo(() => isBreakpoint('md'), [windowWidth]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { windowWidth, isLg, isMd };
};
