import { designSystem } from '../theme';

export type VisualizerType = {
  start: Function;
  stop: Function;
};

const bgColor = 'white';
const lineWidth = 4;
const lineColor = designSystem.palette.primary;
const lineCap = 'round';
const barsGap = 2;

const createContext = (canvas: HTMLCanvasElement) => {
  const context = canvas.getContext('2d');
  if (context) {
    context.fillStyle = bgColor; // background
    context.lineWidth = lineWidth; //width of candle/bar
    context.strokeStyle = lineColor; //color of candle/bar
    context.lineCap = lineCap;
  }
  return context;
};

export const visualizer = (canvas: HTMLCanvasElement) => {
  canvas.width = canvas.parentElement?.clientWidth ?? window.innerWidth;
  canvas.height = canvas.parentElement?.clientHeight ?? window.innerWidth / 2;

  const context = createContext(canvas);

  const draw = (micData: Uint8Array, canvasContext: CanvasRenderingContext2D) => {
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    const centerY = canvas.height / 2;
    const isLg = window.innerWidth >= parseInt(designSystem.breakpoints.lg);

    micData.forEach((value, index) => {
      const xPos = (index && index * (lineWidth + barsGap)) || 0;
      const displayedValue = (value && value / ((isLg && 4) || 2)) || 0;
      canvasContext.beginPath();
      canvasContext.moveTo(xPos, centerY - displayedValue); //x,y
      canvasContext.lineTo(xPos, centerY + displayedValue); //x,y
      canvasContext.stroke();
    });
  };

  return new Promise<VisualizerType>((resolve) => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      try {
        const audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 2048;
        const audioSrc = audioCtx.createMediaStreamSource(stream);
        audioSrc.connect(analyser);
        const data = new Uint8Array(analyser.frequencyBinCount);

        const loopingFunction = () => {
          analyser.getByteFrequencyData(data);
          if (context) draw(data, context);
          requestAnimationFrame(loopingFunction);
        };

        const start = () => {
          requestAnimationFrame(loopingFunction);
        };

        const stop = () => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        };

        resolve({ start, stop });
      } catch (err) {}
    });
  });
};
