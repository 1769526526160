/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgShare = (props) => (
  <svg
    {...props}
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666016 13.2622V12.5836C0.695118 12.3245 0.720941 12.0649 0.754142 11.8062C0.995976 9.92377 1.69032 8.22596 2.87326 6.74737C4.79399 4.34585 7.2902 3.01861 10.359 2.79898C10.5008 2.7887 10.5468 2.7521 10.5459 2.60362C10.5459 2.16724 10.5176 1.73004 10.5636 1.29448C10.6513 0.460796 11.3796 0.132998 12.0568 0.622845C12.1589 0.699403 12.2549 0.783984 12.3437 0.875789C13.5037 2.03727 14.6628 3.19944 15.8212 4.3623C16.0552 4.58713 16.2283 4.86811 16.3241 5.17871C16.354 5.28071 16.3774 5.38435 16.4061 5.48841V5.95111C16.3983 5.96386 16.3852 5.97579 16.3831 5.98936C16.3221 6.4241 16.1122 6.77822 15.806 7.08463C14.6526 8.23871 13.5007 9.39403 12.3503 10.5506C12.2236 10.6855 12.0782 10.8014 11.9186 10.8948C11.7211 11.0034 11.5137 11.0688 11.2858 11.0256C10.8874 10.9499 10.6148 10.6143 10.5644 10.1376C10.5181 9.69674 10.5463 9.2546 10.5455 8.81287C10.5455 8.68702 10.5082 8.65782 10.3894 8.66686C9.94504 8.701 9.50236 8.74213 9.06214 8.81411C7.138 9.12269 5.32164 9.91033 3.77911 11.105C2.92802 11.7597 2.16961 12.5275 1.52473 13.3872C1.41529 13.5324 1.2788 13.6163 1.09394 13.6056C0.86973 13.5912 0.754961 13.4448 0.666016 13.2622Z"
      {...props}
    />
  </svg>
);

export default SvgShare;
