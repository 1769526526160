import React from 'react';
import { useOrderContext } from '../../hooks/useOrderContext';
import { StyledHeader, StyledPanel, StyledPanelsWrapper, StyledSectionWrapper } from '../../styled';
import OptionUpsale from './OptionUpsale';
import OrderSpeedUp from './OrderSpeedUp';
import OrderAddOptions from './OrderAddOptions';
import useOrderInWork from '../../hooks/useOrderInWork';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const OrderAddons = () => {
  const { t } = useScopedTranslation('order.options');
  const { order, isEdit } = useOrderContext();
  const isInWork = useOrderInWork(order);

  return (
    <>
      {order && (
        <StyledPanelsWrapper>
          {isEdit && isInWork && <OrderSpeedUp order={order} />}

          {isEdit && <OrderAddOptions order={order} />}

          {!!order?.options?.length && (
            <StyledPanel>
              <StyledHeader>{t('added_options')}</StyledHeader>
              <StyledSectionWrapper>
                {order.options.map((option) => (
                  <OptionUpsale option={option} key={option.name} />
                ))}
              </StyledSectionWrapper>
            </StyledPanel>
          )}
        </StyledPanelsWrapper>
      )}
    </>
  );
};

export default OrderAddons;
