/* eslint-disable max-len */
const gradients = {
  instagram:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.0384) 0%, rgba(0, 0, 0, 0.16) 100%), linear-gradient(88.16deg, #FFB27A 0%, #F456A2 23.96%, #E854B8 47.92%, #CF51C7 77.08%, #B442C4 100%)',
  facebook:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.0384) 0%, rgba(0, 0, 0, 0.16) 100%), linear-gradient(88.16deg, #FFB27A 0%, #B442C4 0.01%, #F456A2 0.02%, #E854B8 0.03%, #3B599A 0.04%)',
  fadeToBottom: 'linear-gradient(0deg, #fff 10%, #ffffff00 100%)',
  successLeft: 'linear-gradient(100deg, rgb(142 215 144) 8%, rgba(113,119,136,0.08) 35%)',
  errorLeft: 'linear-gradient(100deg, #eb948d 8%, rgba(113,119,136,0.08) 35%)',
};

export default gradients;
