import { Box, Divider, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { OptionStatus } from '../../../../shared/types/orders';
import { optionStatusToColor, statusToColor } from '../../../../shared/utils/orderUtils';
import { OrderStatuses } from '../../../../shared/constants/OrderStatuses';
import { InvoiceCard } from '../../../../shared/components/custom';
import StatusProgressBar from '../OrdersList/components/StatusProgressBar';

export const StyledOrderNumber = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl4};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  margin-bottom: 1rem;

  > span {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const StyledOrderPageWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 3rem;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

export const StyledPanelsWrapper = styled.form`
  display: grid;
  grid-gap: 1.5rem;
`;

export const StyledPanel = styled(Box)`
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1.5rem 2rem 2rem;
  position: relative;
`;

export const StyledOrderInfoFormFooter = styled(StyledPanel)`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem 2rem;
  position: sticky;
  bottom: 1rem;
  z-index: 3;

  h3 {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.text.main};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;

    button {
      width: 100%;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  background: ${({ theme }) => theme.palette.neutral10};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledRemakeWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1rem;

  > p {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  > div {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 2rem;

    & > p {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }

    button {
      min-width: 5.5rem;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    > div {
      grid-template-columns: 1fr;
      grid-gap: 1rem;

      button {
        width: 100%;
      }
    }
  }
`;

export const StyledQuickActions = styled.div`
  display: flex;
  grid-gap: 1rem;
`;

export const StyledOptionsUnavailableOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > h3 {
    font-size: ${({ theme }) => theme.fontSize.xl3};
    color: ${({ theme }) => theme.palette.neutral60};
    font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  }
`;

export const StyledHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.palette.neutral40};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const StyledOptionRemakeDate = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  padding-top: 0.25rem;
`;

export const StyledSlideshowProgressWrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;

  > span {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledDropzoneContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledSectionWrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
`;

export const StyledStatusProgressBar = styled(StatusProgressBar)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledPostcardWrapper = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-gap: 1rem;
  align-items: center;

  > div {
    h3 {
      font-size: ${({ theme }) => theme.fontSize.sm};
      color: ${({ theme }) => theme.colors.text.main};
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    }

    > p {
      font-size: ${({ theme }) => theme.fontSize.xs};
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSlideshowActions = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;
  align-items: center;
  margin-top: 0.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr;

    a,
    button {
      width: 100%;
    }
  }
`;

export const StyledPostcardImage = styled.img`
  width: 100%;
  height: 7rem;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.radii.sm};
`;

export const StyledPostcardText = styled(Text)`
  white-space: pre-wrap;
  word-break: break-word;
`;

export const StyledVoiceWrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
  align-items: center;

  > div:first-of-type {
    flex: 1 1;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const StyledVoiceFileInfo = styled.div`
  > h3 {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  > div {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;

    > p {
      font-size: ${({ theme }) => theme.fontSize.xs};
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`;

export const StyledResetVoiceButton = styled.p`
  cursor: pointer;
  background: ${({ theme }) => theme.palette.neutral10};
  transition: 0.2s;
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 0.1rem 0.5rem;
  width: fit-content;

  &:hover {
    background: ${({ theme }) => theme.palette.neutral20};
  }
`;

export const StyledVideoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export const StyledTracksPanel = styled(StyledPanel)`
  padding: 0;

  .chakra-tabs__tablist {
    margin-inline: 0;
  }

  .chakra-tabs__tab {
    &:first-of-type {
      border-top-left-radius: ${({ theme }) => theme.radii.sm};
    }
  }
`;

export const StyledAudioCard = styled.div`
  padding: 0 2rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: center;

  > p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    display: block;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 1.5rem 1.5rem;
  }
`;

export const StyledPanelActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const StyledSectionRow = styled.div`
  display: grid;
  grid-template-columns: 8rem 1fr;
  grid-gap: 1rem;
  align-items: center;

  label {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
  }

  input,
  select {
    height: 2.5rem;
    padding: 0.5rem 1.5rem;
  }

  button {
    height: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 1rem;
  }
`;

export const StyledOptionContainer = styled.div`
  padding-top: 0.5rem;
  display: flex;
  grid-gap: 1rem;
  align-items: center;
`;

export const StyledOptionStatus = styled.span<{ status?: OptionStatus }>`
  width: 8rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
  display: block;
  color: ${({ status }) => optionStatusToColor(status)};
`;

export const SyledOptionProgress = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
`;

export const StyledStatusString = styled(Text)<{ status: OrderStatuses }>`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ status }) => statusToColor(status)};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  text-transform: uppercase;
`;

export const StyledErrorMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.error};
  margin-bottom: 1rem;
  word-break: break-word;
`;

export const StyledErrorString = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.palette.error};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  text-transform: uppercase;
`;

export const StyledRemakingString = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xl3};
  color: ${({ theme }) => theme.palette.primary60};
  font-family: ${({ theme }) => theme.fontFamily.bodyBold};
  text-transform: uppercase;
`;

export const StyledStatusDate = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledStatusHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  margin-bottom: 1rem;
`;

export const StyledSpeedupHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 0.5rem;
`;

export const StyledSpeedupWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;

    button {
      width: 100%;
    }
  }
`;

export const StyledSpeedupActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    button {
      width: 100%;
    }
  }
`;

export const StyledPaymentLinkWrapper = styled.div`
  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  margin-top: 1rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;

  > div > p:first-of-type {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    margin-bottom: 0.25rem;
  }

  > div > p:last-of-type {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    cursor: pointer;
    max-width: 30rem;
  }

  button {
    width: 3rem;
    height: 3rem;
    padding: 0;

    svg,
    path {
      width: 1.5rem;
      height: 1.5rem;
      fill: ${({ theme }) => theme.palette.neutral50};
    }
  }
`;

export const StyledAddOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr;

    button {
      width: 100%;
    }
  }
`;

export const StyledTransactionsWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

export const StyledTransactionCard = styled(InvoiceCard)``;

export const StyledPositionsWrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
`;

export const StyledPosition = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: flex-end;
  grid-gap: 1rem;

  > p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }
`;

export const StyledTotalWrapper = styled(StyledPosition)`
  border-top: 4px solid ${({ theme }) => theme.palette.neutral10};
  padding-top: 1rem;
  margin-top: 1rem;
`;

export const StyledDots = styled.div`
  border-bottom: dotted 2px ${({ theme }) => theme.palette.neutral30};
  width: 100%;
  position: relative;
  display: block;
  height: 0.75rem;
  margin-bottom: 0.35rem;
`;

export const StyledOptionDates = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -0.5rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
