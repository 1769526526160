import styled from '@emotion/styled';
import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

export const StyledModalContent = styled(ModalContent)`
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: none;
  max-height: 100vh;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 2rem;
  padding: 1.25rem 1.25rem 0.5rem;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  margin-inline: auto;
  width: 100%;
`;

export const StyledModalSubheader = styled(Text)`
  padding: 0 1.25rem 0.5rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const StyledModalBody = styled(ModalBody)`
  padding-inline: 1.25rem;
  padding-bottom: 1.25rem;
  max-width: ${({ theme }) => theme.breakpoints.xl};
  margin-inline: auto;
  width: 100%;
`;

export const StyledModalFooter = styled(ModalFooter)`
  padding: 1rem 1.25rem 1.25rem;
  grid-gap: 0.75rem;
`;

export const StyledModalOverlay = styled(ModalOverlay)`
  background: ${({ theme }) => theme.palette.overlay};
`;
