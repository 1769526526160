import styled from '@emotion/styled';
import { Button, Card, Input } from '../../../shared/components/ui';
import InfiniteScroll from 'react-infinite-scroller';
import { Divider } from '@chakra-ui/react';

export const StyledPersonalizationsWrapper = styled(InfiniteScroll)`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral10};
  margin-top: 1rem;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

export const StyledPersonalizationRow = styled(Card)`
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  min-height: 3.75rem;

  form {
    display: flex;
    align-items: center;
    flex: 1 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0.25rem 1rem;
  }

  button {
    height: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: auto 1fr auto auto;

    form {
      > span:first-of-type {
        display: none;
      }

      > div:nth-of-type(2) {
        display: none;
      }
    }

    input {
      padding: 0.5rem;
    }

    .chakra-switch__label {
      display: none;
    }
  }
`;

export const StyledPersonalizationForm = styled.form`
  position: sticky;
  bottom: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  z-index: 33;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    > div {
      grid-template-columns: 1fr auto auto;
      grid-gap: 0.5rem;

      > span:first-of-type {
        display: none;
      }

      > div:nth-of-type(2) {
        display: none;
      }

      > label {
        display: none;
      }
    }
  }
`;

export const StyledPersonalizationIndex = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  min-width: 1.5rem;
  text-align: center;
  display: block;
`;

export const StyledPersonalizationInput = styled(Input)`
  margin-bottom: 0;
  border: none;
`;

export const StyledDeleteButton = styled(Button)`
  width: 2.5rem;
  background: transparent;

  svg {
    width: 1.5rem;
    height: auto;

    path {
      fill: ${({ theme }) => theme.palette.neutral50};
    }
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const VariantValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledDivider = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledSynonymForm = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.75rem;

  input {
    height: 3rem;
  }
`;

export const StyledSynonymsButton = styled(Button)`
  span {
    color: ${({ theme }) => theme.palette.primary};
  }

  svg {
    width: 1rem;
    height: auto;
  }
`;
