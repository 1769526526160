// Global modules
import React from 'react';

// Components
import 'react-toastify/dist/ReactToastify.css';

// Local helpers
import { defaultOptions } from './helpers';

// Styled
import { StyledToastContainer } from './styled';
import { toast, ToastOptions } from 'react-toastify';

export const showToast = (
  status: 'info' | 'warning' | 'success' | 'error' | undefined,
  message: string,
  customOptions: ToastOptions = {},
) => {
  const options = { ...defaultOptions, ...customOptions };
  switch (status) {
    case 'info': {
      return toast.info(message, options);
    }
    case 'warning': {
      return toast.warning(message, options);
    }
    case 'success': {
      return toast.success(message, options);
    }
    case 'error': {
      return toast.error(message, options);
    }
    default: {
      return toast(message, options);
    }
  }
};

const ToastContainer = () => <StyledToastContainer limit={2} newestOnTop />;

export default ToastContainer;
