import { useEffect, useMemo, useState } from 'react';
import {
  addMilliseconds,
  formatISO,
  fromUnixTime,
  getUnixTime,
  intervalToDuration,
  isAfter,
  parseISO,
} from 'date-fns';
import { useScopedTranslation } from '../../../../hooks';
import { withLeadingZero } from '../../../../utils/utils';

export type UnitType = 'years' | 'moths' | 'days' | 'hours' | 'minutes' | 'seconds';

type ICountdown = {
  targetDate: string | null;
  onTargetReach?: Function;
  units?: UnitType[];
  labels?: boolean;
  milliseconds?: boolean;
  initialTime: number;
};

export const useCountdown = ({
  targetDate = null,
  onTargetReach,
  units = ['days', 'hours', 'minutes', 'seconds'],
  milliseconds = false,
  labels = true,
  initialTime,
}: ICountdown) => {
  const { t } = useScopedTranslation('date.shorthand');
  const [unitsToShow] = useState<UnitType[]>(units);
  const [isReached, setIsReached] = useState(false);

  const countDownFromUnix = useMemo(
    () => (targetDate && new Date(targetDate).getTime()) || null,
    [targetDate],
  );
  const [countDown, setCountDown] = useState(
    (countDownFromUnix && countDownFromUnix - Date.now()) || null,
  );

  const checkCompletion = (interval: string | number | null | undefined = null) => {
    if (!countDownFromUnix) return;

    const nowTime = new Date().getTime();
    if (isAfter(nowTime, countDownFromUnix)) {
      if (interval) clearInterval(interval);
      if (onTargetReach) onTargetReach();
      setIsReached(true);
    }
  };

  useEffect(() => {
    if (!countDownFromUnix) return;

    checkCompletion();
    const interval = setInterval(() => {
      setCountDown(countDownFromUnix - Date.now());
      checkCompletion(this);
    }, (milliseconds && 10) || 1000);

    return () => clearInterval(interval);
  }, [countDownFromUnix]);

  const formatToShorthand = (start: Date, date: Date) => {
    const duration: Record<string, Object | undefined> = intervalToDuration({
      start,
      end: date || new Date(),
    });

    const shorthand: string[] = [];
    Object.keys(duration)
      .filter((key) => unitsToShow.includes(key as UnitType))
      .map((key) => {
        const displayedValue = withLeadingZero((duration[key] ?? 0) as number);
        shorthand.push((labels && `${displayedValue}${t(key)}`) || displayedValue);
        return key;
      });

    if (milliseconds) {
      const timeDiff = (date.getTime() - start.getTime()).toString();
      const msDiff = timeDiff.substring(timeDiff.length - 3, timeDiff.length - 1);
      shorthand.push((labels && `${msDiff}${t('milliseconds')}`) || msDiff);
    }

    return shorthand.join(' : ');
  };

  const timeRemaining = useMemo(
    () => (countDown && targetDate && formatToShorthand(new Date(), parseISO(targetDate))) || null,
    [countDown, targetDate],
  );

  const placeholder = useMemo(() => {
    const startDate = new Date();
    const initialDate = addMilliseconds(startDate, initialTime);
    return formatToShorthand(startDate, initialDate);
  }, [initialTime]);

  return (!isReached && timeRemaining) || (!isReached && placeholder) || null;
};

export default useCountdown;
