import { useState } from 'react';
import { GeneratorApi } from '../../../../api';

const useGetOccasions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [occasions, setOccasions] = useState<{ value: number; label: string }[]>([]);

  const getOccasions = async (segmentId: number) => {
    setIsLoading(true);
    GeneratorApi.getOccasions(segmentId)
      .then((response) => {
        setOccasions(response.map((occasion) => ({ value: occasion.id, label: occasion.name })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getOccasions,
    occasions,
  };
};

export default useGetOccasions;
