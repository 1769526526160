import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { StyledSegmentsWrapper, StyledSegmentCard, StyledStatusIcon } from './styled';
import { SkeletonRepeater } from '../index';
import { Segment } from '../../../types/production/segment';

const SegmentSelector = ({
  segments,
  isLoading,
  selectedSegment = null,
  setSelectedSegment,
}: {
  segments: Segment[];
  isLoading: boolean;
  selectedSegment: Segment | null;
  setSelectedSegment: Function;
}) => {
  const isSelected = (segment: Segment) => {
    return segment.id === selectedSegment?.id;
  };
  const selectSegment = (segment: Segment) => () => {
    setSelectedSegment((!isSelected(segment) && segment) || null);
  };

  return (
    <StyledSegmentsWrapper>
      {(isLoading && (
        <SkeletonRepeater number={4}>
          <StyledSegmentCard>
            <Skeleton w={'100%'} h={'1rem'} />
          </StyledSegmentCard>
        </SkeletonRepeater>
      )) ||
        segments.map((segment) => (
          <StyledSegmentCard
            role={'button'}
            selected={isSelected(segment)}
            onClick={selectSegment(segment)}
            key={segment.id}
          >
            <span>{segment.name}</span>
            <StyledStatusIcon enabled={segment.enabled} type={'Dot'}></StyledStatusIcon>
          </StyledSegmentCard>
        ))}
    </StyledSegmentsWrapper>
  );
};

export default SegmentSelector;
