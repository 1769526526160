import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const StyledErrorMessage = styled(Text)`
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-top: 0.5rem;

  &:first-letter {
    text-transform: capitalize;
  }
`;
