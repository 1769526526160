import { httpDelete, httpGet, httpPost, httpPut } from '../../shared/utils/http';
import { Permission, Role, UpdateRoleRequest } from '../../shared/types/role';

export type RoleApiType = {
  getRoles: () => Promise<Role[]>;
  createRole: (data: UpdateRoleRequest) => Promise<Role>;
  updateRole: (roleId: number, data: UpdateRoleRequest) => Promise<Role>;
  deleteRole: (roleId: number) => Promise<string>;
  getPermissions: () => Promise<Permission[]>;
};

export const RoleApi: RoleApiType = {
  getRoles: () => {
    return httpGet(`/roles`);
  },
  createRole: (data) => {
    return httpPost(`/roles`, data);
  },
  updateRole: (roleId, data) => {
    return httpPut(`/roles/${roleId}`, data);
  },
  deleteRole: (roleId) => {
    return httpDelete(`/roles/${roleId}`);
  },
  getPermissions: () => {
    return httpGet(`/permissions`);
  },
};
