import * as Yup from 'yup';

export const UpdateUserScheme = Yup.object().shape({
  name: Yup.string().trim().label('Name').required().min(1),
  email: Yup.string().trim().email().label('Email').required().min(1),
});

export const UpdatePasswordScheme = Yup.object().shape({
  password: Yup.string().trim().label('Password').required().min(8),
});
