/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgCart = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.3999 16.0681C35.4568 15.7114 35.3588 15.3464 35.1342 15.0702C34.9081 14.7957 34.5776 14.6362 34.2296 14.6362H5.76453C5.41662 14.6362 5.08611 14.7957 4.85997 15.0702C4.63541 15.3464 4.53737 15.7114 4.5943 16.0681C4.5943 16.0681 6.21048 26.333 7.00592 31.3948C7.35067 33.5812 9.16769 35.1857 11.2978 35.1857H28.6963C30.8265 35.1857 32.6435 33.5812 32.9883 31.3948L35.3999 16.0681ZM32.8333 17.1022L30.6462 30.9969C30.4896 31.9899 29.6642 32.7198 28.6963 32.7198C24.3143 32.7198 15.6799 32.7198 11.2978 32.7198C10.33 32.7198 9.50453 31.9899 9.34797 30.9969L7.1609 17.1022H32.8333Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.5074 15.2347L25.7632 7.01485C25.4264 6.43125 24.6974 6.24219 24.136 6.59236C23.5746 6.94252 23.3927 7.70039 23.7296 8.28399L28.4737 16.5038C28.8106 17.0874 29.5396 17.2765 30.101 16.9263C30.6624 16.5761 30.8442 15.8183 30.5074 15.2347Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5267 16.5038L16.2709 8.28399C16.6078 7.70039 16.4259 6.94252 15.8645 6.59236C15.3031 6.24219 14.5741 6.43125 14.2373 7.01485L9.49307 15.2347C9.15624 15.8183 9.3381 16.5761 9.89949 16.9263C10.4609 17.2765 11.1899 17.0874 11.5267 16.5038Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8149 21.6231V28.199C18.8149 28.8796 19.3463 29.4319 20.001 29.4319C20.6557 29.4319 21.187 28.8796 21.187 28.199V21.6231C21.187 20.9425 20.6557 20.3901 20.001 20.3901C19.3463 20.3901 18.8149 20.9425 18.8149 21.6231Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4859 21.6231V28.199C12.4859 28.8796 13.0172 29.4319 13.6719 29.4319C14.3266 29.4319 14.858 28.8796 14.858 28.199V21.6231C14.858 20.9425 14.3266 20.3901 13.6719 20.3901C13.0172 20.3901 12.4859 20.9425 12.4859 21.6231Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.1388 21.6231V28.199C25.1388 28.8796 25.6702 29.4319 26.3249 29.4319C26.9796 29.4319 27.5109 28.8796 27.5109 28.199V21.6231C27.5109 20.9425 26.9796 20.3901 26.3249 20.3901C25.6702 20.3901 25.1388 20.9425 25.1388 21.6231Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.814 14.6362H4.18605C3.53135 14.6362 3 15.1886 3 15.8692C3 16.5498 3.53135 17.1022 4.18605 17.1022H35.814C36.4687 17.1022 37 16.5498 37 15.8692C37 15.1886 36.4687 14.6362 35.814 14.6362Z"
      fill="black"
    />
  </svg>
);

export default SvgCart;
