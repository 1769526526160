import styled from '@emotion/styled';
import { LinkBox, Progress, Text } from '@chakra-ui/react';
import { OrderStatuses } from '../../../../shared/constants/OrderStatuses';
import { OptionStatus } from '../../../../shared/types/orders';
import { optionStatusToColor, statusToColor } from '../../../../shared/utils/orderUtils';
import { Link } from 'react-router-dom';
import { Button } from '../../../../shared/components/ui';

export const StyledOrderCard = styled(LinkBox)<{ options?: boolean }>`
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow.card};

  display: grid;
  grid-template-columns: ${({ options }) =>
    (options === false && 'repeat(3, 1fr)') || 'repeat(3, 1fr) 1.7fr'};
  grid-gap: 1rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledOrderNumber = styled(Link)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledCreatedDate = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export const StyledCustomer = styled.div`
  > p:first-of-type {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  > p:last-of-type {
    color: ${({ theme }) => theme.colors.text.placeholder};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

export const StyledHeader = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledOrderStatus = styled.div<{ status: OrderStatuses }>`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ status }) => statusToColor(status)};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  margin-bottom: 0.25rem;
`;

export const StyledErrorTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.palette.error};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
`;

export const StyledErrorMessage = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  z-index: 3;
  position: relative;
  word-break: break-all;
`;

export const StyledOptionsWrapper = styled.div`
  > div {
    display: grid;
    grid-template-columns: 6rem auto 8rem;
    grid-gap: 0.5rem;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

export const StyledOptionStatus = styled.div<{ status: OptionStatus }>`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ status }) => optionStatusToColor(status)};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  margin-left: auto;
`;

export const StyledProgress = styled(Progress)<{ value?: number }>`
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 0.25rem;
  background: ${({ theme }) => theme.palette.neutral10};

  > div {
    border-radius: ${({ theme }) => theme.radii.sm};
    background: ${({ theme, value }) =>
      (value === 100 && theme.palette.success) || theme.palette.primary};
  }
`;

export const StyledStatusWrapper = styled.div`
  padding-right: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-right: 0;
  }
`;

export const StyledFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 15rem 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;

  select,
  input {
    height: 2.5rem;
    padding: 0.5rem 1.5rem;
    background: ${({ theme }) => theme.palette.primary8};
  }

  button {
    height: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 0.25rem;
    margin-bottom: 0.5rem;
  }
`;

export const StyledErrorButton = styled(Button)<{ active?: boolean }>`
  background: ${({ theme, active }) => (active && theme.palette.error) || theme.palette.primary8};
  color: ${({ theme, active }) => (active && theme.palette.white) || theme.palette.error};
  border: 1px solid ${({ theme }) => theme.colors.border.input};

  svg {
    width: 1rem;
    height: auto;
  }
`;

export const StyledPeriodWrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
  margin-bottom: 2rem;
  align-items: center;

  button:first-of-type {
    height: 2.5rem;
    background: ${({ theme }) => theme.palette.primary8};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const StyledCountPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.primary8};
  height: 2.5rem;
  padding-inline: 1rem;

  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;

  > h3 {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
  }

  > p {
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }
`;
