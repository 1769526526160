import React, { useEffect } from 'react';
import { ArtistContextProvider } from './context/ArtistContext';
import { StyledArtistPageWrapper } from './styled';
import useHandleArtistApi from '../../../shared/requests/production/useHandleArtistApi';
import { ProductionPaths } from '../../../router/routes';
import { PageBreadcrumbs } from '../../../shared/components/custom';
import ArtistPageSkeleton from './ArtistPageSkeleton';
import { useParams } from 'react-router-dom';
import ArtistInfo from './components/ArtistInfo';
import { Divider } from '@chakra-ui/react';
import ArtistSegmentSchemes from './components/ArtistSegmentSchemes';
import { useScopedTranslation } from '../../../shared/hooks';
import useHasPermission from '../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../shared/constants/Permissions';

const ArtistPage = () => {
  const { t } = useScopedTranslation('production.artists');
  const {
    getArtist,
    artist,
    setArtist,
    updateArtist,
    deleteArtist,
    updateAvatar,
    updateExample,
    isLoading,
    getSchemes,
    schemes,
    updateSlot,
  } = useHandleArtistApi();

  const { artistId } = useParams();

  useEffect(() => {
    if (artistId) getArtist(parseInt(artistId));
  }, [artistId]);

  const isEdit = useHasPermission(Permissions.generationEdit);

  return (
    <ArtistContextProvider
      value={{
        artist,
        setArtist,
        updateArtist,
        deleteArtist,
        updateAvatar,
        updateExample,
        isLoading,
        getSchemes,
        schemes,
        updateSlot,
        isEdit,
      }}
    >
      <PageBreadcrumbs
        rootPageName={t('artists')}
        rootPagePath={ProductionPaths.artists}
        currentPageName={artist?.name}
        isLoading={isLoading}
        isUpdating={!!artist}
      />

      {(artist && (
        <StyledArtistPageWrapper>
          <ArtistInfo />
          <Divider />
          <ArtistSegmentSchemes />
        </StyledArtistPageWrapper>
      )) || <ArtistPageSkeleton />}
    </ArtistContextProvider>
  );
};

export default ArtistPage;
