import { Invoice } from '../../types/invoice';
import { OrdersApi } from '../../../api';
import { useState } from 'react';

const useInvoiceApi = (invoice: Invoice) => {
  const [isChecking, setIsChecking] = useState(false);

  const checkStatus = async () => {
    setIsChecking(true);
    const r = await OrdersApi.paymentSuccessful(invoice.order_uuid, invoice.transaction)
      .then((response) => response)
      .catch(() => null);
    setIsChecking(false);
    return r;
  };

  return { isChecking, checkStatus };
};

export default useInvoiceApi;
