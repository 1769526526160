import React, { useState } from 'react';
import {
  StyledHeader,
  StyledPanel,
  StyledResetVoiceButton,
  StyledSectionWrapper,
  StyledVoiceFileInfo,
  StyledVoiceWrapper,
} from '../../styled';
import { AudioPlayer, Button, FileInput } from '../../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../../shared/theme';
import { useOrderContext } from '../../hooks/useOrderContext';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const ClientVoice = ({ voiceUrl }: { voiceUrl: string }) => {
  const { t } = useScopedTranslation('order.materials');
  const { uploadVoice, isEdit } = useOrderContext();
  const [isUploadingVoice, setIsUploadingVoice] = useState(false);

  const [voiceFile, setVoiceFile] = useState<File | null>(null);
  const addClientVoice = async () => {
    setIsUploadingVoice(true);
    await uploadVoice(voiceFile);
    setIsUploadingVoice(false);
  };
  const resetVoiceFile = () => {
    setVoiceFile(null);
  };

  return (
    <StyledPanel>
      <StyledHeader>{t('client_voice')}</StyledHeader>
      <StyledSectionWrapper>
        <StyledVoiceWrapper>
          {(voiceFile && (
            <StyledVoiceFileInfo>
              <h3>{t('selected_file')}</h3>
              <div>
                <p>
                  {voiceFile.name} - {(voiceFile.size / 1024000).toFixed(2)} {t('mb')}
                </p>
                <StyledResetVoiceButton onClick={resetVoiceFile}>
                  {t('reset')}
                </StyledResetVoiceButton>
              </div>
            </StyledVoiceFileInfo>
          )) || (
            <div>
              {(voiceUrl && <AudioPlayer src={voiceUrl} withProgress download withDuration />) || (
                <p>{t('no_intro')}</p>
              )}
            </div>
          )}
          {isEdit && (
            <FileInput
              onFileChange={setVoiceFile}
              noShadow
              accept={'audio/*'}
              size={ButtonSizes.sm}
              color={(voiceFile && 'secondary') || 'primary'}
              label={t((voiceUrl && 'upload_new') || (voiceFile && 'change_file') || 'upload_file')}
            />
          )}
          {voiceFile && (
            <Button
              size={ButtonSizes.sm}
              color={'primary'}
              isLoading={isUploadingVoice}
              noShadow
              onClick={addClientVoice}
            >
              {t('remake')}
            </Button>
          )}
        </StyledVoiceWrapper>
      </StyledSectionWrapper>
    </StyledPanel>
  );
};

export default ClientVoice;
