import { useEffect, useMemo, useState } from 'react';
import { RoleApi } from '../../../api';
import { Role, UpdateRoleRequest } from '../../types/role';

const useRolesApi = (instant: boolean = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);

  const getRoles = async () => {
    setIsLoading(true);
    await RoleApi.getRoles()
      .then((response) => {
        setRoles(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const rolesOptions = useMemo(
    () =>
      roles
        .sort((a, b) => (a.name < b.name && -1) || (a.name > b.name && 1) || 0)
        .map((role) => ({
          value: role.name,
          label: role.name[0].toUpperCase() + role.name.substring(1),
        })),
    [roles],
  );

  useEffect(() => {
    if (instant) getRoles();
  }, [instant]);

  const createRole = async (data: UpdateRoleRequest) => {
    const role = await RoleApi.createRole(data)
      .then((response) => {
        setRoles([...roles, response]);
        return response;
      })
      .catch(() => null);

    return role;
  };

  const updateRole = async (roleId: number, data: UpdateRoleRequest) => {
    await RoleApi.updateRole(roleId, data)
      .then((response) => {
        setRoles(roles.map((role) => (role.id === response.id && response) || role));
      })
      .catch(() => {});
  };

  const deleteRole = async (roleId: number) => {
    await RoleApi.deleteRole(roleId)
      .then(() => {
        setRoles(roles.filter((role) => role.id !== roleId));
      })
      .catch(() => {});
  };

  return { getRoles, setRoles, isLoading, roles, rolesOptions, createRole, updateRole, deleteRole };
};

export default useRolesApi;
