import React from 'react';
import { Flex, Grid, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { StyledCard, StyledDangerZoneSkeleton, StyledUserPreview } from './styled';

const UserPageSkeleton = () => {
  return (
    <>
      <StyledUserPreview>
        <SkeletonCircle w={'3rem'} h={'3rem'} />
        <div>
          <Skeleton w={'10rem'} h={'1.5rem'} mb={'0.5rem'} />
          <Skeleton w={'15rem'} h={'1rem'} />
        </div>
      </StyledUserPreview>

      <StyledCard>
        <Skeleton w={'10rem'} h={'1rem'} mb={'1rem'} />
        <Skeleton w={'100%'} h={'2.5rem'} mb={'1rem'} />
        <Skeleton w={'100%'} h={'2.5rem'} mb={'1rem'} />
        <Skeleton w={'8rem'} h={'2.5rem'} />
      </StyledCard>

      <StyledCard>
        <Skeleton w={'10rem'} h={'1rem'} mb={'1rem'} />
        <Flex gridGap={'1rem'} mb={'1rem'}>
          <Skeleton w={'7rem'} h={'2.5rem'} />
          <Skeleton w={'8rem'} h={'2.5rem'} />
          <Skeleton w={'5rem'} h={'2.5rem'} />
        </Flex>
        <Skeleton w={'8rem'} h={'2.5rem'} />
      </StyledCard>

      <StyledDangerZoneSkeleton>
        <Skeleton w={'10rem'} h={'1rem'} mb={'1rem'} />
        <Grid
          gridGap={'1rem'}
          gridTemplateColumns={'auto 1fr auto'}
          mb={'1rem'}
          alignItems={'center'}
        >
          <Skeleton w={'7rem'} h={'1.5rem'} />
          <Skeleton w={'100%'} h={'2.5rem'} />
          <Skeleton w={'5rem'} h={'2.5rem'} />
        </Grid>
        <Flex gridGap={'1rem'} alignItems={'center'}>
          <Skeleton w={'7rem'} h={'1.5rem'} />
          <Skeleton w={'8rem'} h={'2.5rem'} />
        </Flex>
      </StyledDangerZoneSkeleton>
    </>
  );
};

export default UserPageSkeleton;
