import React, { useEffect } from 'react';
import { StyledSchemesCard } from '../styled';
import ArtistSegmentSchemeRow from './ArtistSegmentSchemeRow';
import useArtistContext from '../hooks/useArtistContext';

const ArtistSegmentSchemes = () => {
  const { artist, schemes, getSchemes } = useArtistContext();

  useEffect(() => {
    if (artist) getSchemes(artist);
  }, [artist]);

  return (
    <StyledSchemesCard>
      {schemes.map((scheme) => (
        <ArtistSegmentSchemeRow scheme={scheme} key={scheme.id} />
      ))}
    </StyledSchemesCard>
  );
};

export default ArtistSegmentSchemes;
