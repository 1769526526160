import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getParam } from '../../../shared/utils/queryParamsUtils';
import { CommonPaths } from '../../../router/routes';
import { appLocalStorage } from '../../../shared/utils/appLocalStorage';
import { AUTH_TOKEN_KEY } from '../../../shared/constants';
import useStoredAccessToken from '../../../shared/hooks/useStoredAccessToken';

export const useOnLoginRedirect = () => {
  const accessToken = useStoredAccessToken();
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (accessToken) {
      appLocalStorage.setItem(AUTH_TOKEN_KEY, accessToken);
      const nextUrl = getParam(search, 'next');
      if (nextUrl) navigate(nextUrl);
      else navigate(CommonPaths.dashboard);
    }
  }, [accessToken]);
};
