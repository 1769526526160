import React from 'react';
import { StyledSegmentRow, StyledSchemeSlotsWrapper } from '../styled';
import { ArtistSegmentScheme } from '../../../../shared/types/production/artist';
import ArtistSegmentSchemeRowSlot from './ArtistSegmentSchemeRowSlot';

const ArtistSegmentSchemeRow = ({ scheme }: { scheme: ArtistSegmentScheme }) => {
  return (
    <StyledSegmentRow>
      <p>{scheme.segment.name}</p>

      <StyledSchemeSlotsWrapper>
        {scheme.slots.map((slot, index) => (
          <ArtistSegmentSchemeRowSlot scheme={scheme} slot={slot} key={slot.id} index={index} />
        ))}
      </StyledSchemeSlotsWrapper>
    </StyledSegmentRow>
  );
};

export default ArtistSegmentSchemeRow;
