import React from 'react';
import { StyledFormHeader, StyledGeneralInfoWrapper, StyledSwitchWrapper } from '../styled';
import { Button, Input, Switch } from '../../../ui';
import { ConfirmDialog } from '../../ConfirmDialog';
import { ButtonSizes } from '../../../../theme';
import useHandleTemplateApi from '../../../../requests/production/useHandleTemplateApi';
import { Template } from '../../../../types/production/template';
import { useScopedTranslation } from '../../../../hooks';

const GeneralInfo = ({
  template,
  register,
  onSubmit,
  onDelete,
}: {
  template: Template;
  register: Function;
  onSubmit: React.ReactEventHandler;
  onDelete: Function;
}) => {
  const { t } = useScopedTranslation('production.templates');

  const { deleteTemplate, isLoading: isDeleting } = useHandleTemplateApi();

  const deleteTemplateConfirmed = async () => {
    await deleteTemplate(template);
    onDelete(template);
  };

  return (
    <>
      <StyledFormHeader>{t('general_info')}</StyledFormHeader>
      <StyledGeneralInfoWrapper>
        <Input name={'name'} placeholder={t('enter_name')} {...{ register }} onBlur={onSubmit} />
        <StyledSwitchWrapper>
          <Switch name={'enabled'} {...{ register }} onInput={onSubmit} />
          <span>{t('enabled')}</span>
        </StyledSwitchWrapper>
        <ConfirmDialog
          title={t('delete_confirm')}
          isLoading={isDeleting}
          onConfirm={deleteTemplateConfirmed}
          activator={
            <Button noShadow size={ButtonSizes.sm}>
              {t('delete')}
            </Button>
          }
        />
      </StyledGeneralInfoWrapper>
    </>
  );
};

export default GeneralInfo;
