import { useEffect, useState } from 'react';
import { useAppContext } from '../../shared/hooks';
import { UserApi } from '../../api';
import { appLocalStorage } from '../../shared/utils/appLocalStorage';
import { AUTH_TOKEN_KEY } from '../../shared/constants';

const useGetUser = () => {
  const token = appLocalStorage.getItem(AUTH_TOKEN_KEY);
  const { user, accessToken, setAccessToken, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const resetToken = () => {
    appLocalStorage.removeItem(AUTH_TOKEN_KEY);
    setAccessToken('');
  };

  const getMyself = () => {
    setIsLoading(true);
    UserApi.myself()
      .then((response) => {
        setUser(response);
      })
      .catch(() => {
        resetToken();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setAccessToken(token);
  }, [token]);

  useEffect(() => {
    if (accessToken && !user) getMyself();
  }, [accessToken, user]);

  return { getMyself, isLoading };
};

export default useGetUser;
