import React from 'react';
import { StyledCardMenuButton } from './styled';
import { CustomButtonProps } from './Button';
import { ButtonProps } from '@chakra-ui/react';
import { Icon } from '../../icons';

const DotsMenuButton = ({ ...props }: CustomButtonProps & ButtonProps) => {
  return (
    <StyledCardMenuButton {...props}>
      <Icon type={'DotsVertical'} />
    </StyledCardMenuButton>
  );
};

export default DotsMenuButton;
