import React, { useEffect, useRef, useState } from 'react';
import { visualizer, VisualizerType } from '../../../utils/visualizer';

const VoiceVisualization = ({ isWorking = false }: { isWorking: boolean }) => {
  const analyserCanvas: React.Ref<HTMLCanvasElement> = useRef(null);
  const [visual, setVisual] = useState<VisualizerType | null>(null);

  useEffect(() => {
    if (analyserCanvas?.current) visualizer(analyserCanvas?.current).then((vis) => setVisual(vis));
  }, [analyserCanvas?.current]);

  useEffect(() => {
    if (visual) {
      if (isWorking) visual.start();
      else visual.stop();
    }

    return () => {
      if (visual) visual.stop();
    };
  }, [visual, isWorking]);

  return <canvas ref={analyserCanvas}></canvas>;
};

export default VoiceVisualization;
