import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { StyledTemplateCard } from '../styled';

const TemplateCardSkeleton = () => {
  return (
    <StyledTemplateCard>
      <Skeleton w={'100%'} h={'1rem'} />
    </StyledTemplateCard>
  );
};

export default TemplateCardSkeleton;
