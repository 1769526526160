import styled from '@emotion/styled';
import { Progress } from '@chakra-ui/react';

export const StyledProgressWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 15rem;
  }
`;

export const StyledCounter = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.stepper};
  font-size: ${({ theme }) => theme.fontSize.xl2};
  color: ${({ theme }) => theme.colors.text.main};
  margin-right: 1rem;

  > span {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const StyledProgress = styled(Progress)`
  flex: 1 1;

  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 0.5rem;

  > div {
    transition: 0.3s ease-in;
    background: ${({ theme }) => theme.palette.success};
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;
