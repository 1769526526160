import React, { useState } from 'react';
import { Option, OptionStatus } from '../../../../../../shared/types/orders';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { StyledOptionContainer } from '../../styled';
import { Button } from '../../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../../shared/theme';
import { useOrderContext } from '../../hooks/useOrderContext';
import { ConfirmDialog } from '../../../../../../shared/components/custom';
import OptionUpsaleProgress from './OptionUpsaleProgress';
import OptionUpsaleStatus from './OptionUpsaleStatus';

const OptionUpsale = ({ option }: { option: Option }) => {
  const { t } = useScopedTranslation('order.options');
  const { isEdit } = useOrderContext();
  const [isRemoving, setIsRemoving] = useState(false);
  const isRemaking = !!option.remade_at;

  const { removeOption } = useOrderContext();

  const remove = async () => {
    setIsRemoving(true);
    await removeOption(option.name);
    setIsRemoving(false);
  };

  return (
    <StyledOptionContainer>
      <div>
        <p>{t(`names.${option.name}`)}</p>
        <OptionUpsaleStatus
          status={(isRemaking && OptionStatus.processing) || option?.status}
          isRemaking={isRemaking}
        />
      </div>
      <OptionUpsaleProgress option={option} isRemaking={isRemaking} />
      {!option.has_paid_invoice && isEdit && (
        <div>
          <ConfirmDialog
            title={t('remove_confirm')}
            isLoading={isRemoving}
            onConfirm={remove}
            activator={
              <Button noShadow size={ButtonSizes.sm}>
                {t('remove')}
              </Button>
            }
          />
        </div>
      )}
    </StyledOptionContainer>
  );
};

export default OptionUpsale;
