import styled from '@emotion/styled';
import { Box, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';

export const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.palette.white};
  padding: 1.25rem;
  box-shadow: ${({ theme }) => theme.shadow.card};
  overflow-x: hidden;
  display: grid;
  grid-gap: 1rem;

  border: ${({ bordered, theme }) =>
    (bordered && `4px solid ${theme.colors.border.card}`) || 'none'};
  border-radius: ${({ theme, bordered }) => (bordered && theme.radii.md) || theme.radii.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 2rem;

    ${({ row }) =>
      row &&
      css`
        display: flex;
        align-items: center;
      `};
  }
`;

export const StyledIconContainer = styled.div`
  margin-bottom: 0.25rem;

  svg {
    width: 2rem;
    height: auto;
  }
`;

export const StyledCardHeading = styled.div`
  display: grid;
  grid-gap: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    align-items: center;
    flex: 1 1;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;

export const StyledCardTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.fontSize.xl2};
  }
`;

export const StyledCardSubTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;
