/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgArrowLeft = (props) => (
  <svg
    {...props}
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6.41109C17.9455 6.36271 17.951 6.2912 17.9217 6.23339C17.7609 5.91611 17.4947 5.7337 17.1208 5.69431C17.0338 5.68532 16.9454 5.68832 16.8574 5.68832C12.9848 5.68789 9.11223 5.68789 5.23964 5.68789C5.17 5.68789 5.10037 5.68789 5.01974 5.68789C5.03302 5.61253 5.09349 5.58213 5.13518 5.54274C6.32633 4.4256 7.51885 3.31019 8.70999 2.19306C9.08566 1.84066 9.18233 1.38936 8.91661 1.01598C8.66967 0.66958 8.34853 0.371565 7.97835 0.138633C7.62788 -0.0823095 7.17571 -0.0287865 6.85089 0.225554C6.80599 0.260665 6.76339 0.298346 6.7217 0.336882C4.60054 2.31894 2.47893 4.30143 0.358239 6.28435C-0.0279669 6.64531 -0.105392 7.08248 0.142916 7.47641C0.199725 7.56676 0.276232 7.64255 0.355031 7.71619C2.47618 9.69868 4.59688 11.6816 6.71849 13.6632C7.19816 14.1115 7.76945 14.1115 8.25003 13.6675C8.40396 13.5249 8.55789 13.3823 8.71045 13.2385C9.17591 12.8 9.17866 12.2704 8.71182 11.8345C7.50968 10.7122 6.30525 9.59206 5.1022 8.47065C5.05822 8.42955 5.01653 8.3863 4.94598 8.31693C5.0486 8.31693 5.11594 8.31693 5.18329 8.31693C9.03252 8.31693 12.8817 8.31693 16.731 8.31693C17.5103 8.31693 17.7109 8.1919 17.9991 7.52779C18 7.15613 18 6.78361 18 6.41109Z"
      {...props}
    />
  </svg>
);

export default SvgArrowLeft;
