import React, { useEffect } from 'react';
import { Artist } from '../../../../shared/types/production/artist';
import { Segment } from '../../../../shared/types/production/segment';
import useHandleIntroUpload from '../requests/useHandleIntroUpload';
import { UploaderDropzone, UploaderFilesStat } from '../../../../shared/components/custom';
import { PreparedFile } from '../../../../shared/components/custom/UploaderDropzone/helpers';

const IntroUploader = ({ artist, segment }: { artist: Artist; segment: Segment }) => {
  const {
    getScheme,
    uploadFiles,
    scheme,
    isLoading,
    isUploading,
    uploadProgress,
    totalUploadProgress,
    uploaded,
    isFinished,
    setIsFinished,
  } = useHandleIntroUpload();

  const loadScheme = () => {
    getScheme(artist.id, segment.id);
  };

  useEffect(() => {
    loadScheme();
  }, [artist, segment]);

  const handleUpload = async (files: PreparedFile[]) => {
    await uploadFiles(artist.id, segment.id, files);
  };

  return (
    <div>
      <UploaderFilesStat
        isUploading={isUploading}
        currentFileProgress={uploadProgress}
        totalProgress={totalUploadProgress}
        scheme={scheme}
        isLoading={isLoading}
        onRefresh={loadScheme}
      />
      {scheme && (
        <UploaderDropzone
          uploadedNumber={uploaded}
          isUploading={isUploading}
          scheme={scheme}
          isFinished={isFinished}
          setIsFinished={setIsFinished}
          onUploadStart={handleUpload}
        />
      )}
    </div>
  );
};

export default IntroUploader;
