import { useEffect, useMemo } from 'react';

export enum Directions {
  top = 'top',
}

export const useScrollTo = ({
  direction = Directions.top,
  deps = [],
}: {
  direction?: Directions;
  deps: Array<unknown>;
}) => {
  const scrollToValue = useMemo(() => {
    return {
      [Directions.top]: 0,
    }[direction];
  }, [direction]);

  useEffect(() => {
    window.scrollTo(0, scrollToValue);
  }, [...deps, scrollToValue]);
};
