import { useEffect } from 'react';
import { appLocalStorage } from '../utils/appLocalStorage';
import { Language, LanguageFull } from '../types/language';
import { useAppContext } from './useAppContext';
import { LANGUAGE_KEY } from '../constants/mixed';
import i18n, { setDatepickerLocale } from '../i18n/i18n';

export const useChangeLanguage = () => {
  const { language, setLanguage } = useAppContext();

  const getLocale = (lng: LanguageFull) =>
    ({
      [LanguageFull.ENGLISH]: Language.ENGLISH,
      [LanguageFull.RUSSIAN]: Language.RUSSIAN,
    }[lng] ||
    appLocalStorage.getItem(LANGUAGE_KEY) ||
    Language.ENGLISH);

  const getFullLocale = (lng: Language) =>
    ({
      [Language.ENGLISH]: LanguageFull.ENGLISH,
      [Language.RUSSIAN]: LanguageFull.RUSSIAN,
    }[lng] || LanguageFull.ENGLISH);

  const changeLanguage = (lng: string) => {
    const locale = lng as Language;
    appLocalStorage.setItem(LANGUAGE_KEY, locale);
    i18n.changeLanguage(locale);
    setLanguage(locale);
    setDatepickerLocale(locale);
  };

  useEffect(() => {
    if (!language) changeLanguage(appLocalStorage.getItem(LANGUAGE_KEY) || i18n.language);
  }, []);

  return { currentLanguage: language, changeLanguage, getLocale, getFullLocale };
};
