/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTheme } from '@emotion/react';
import * as Components from './components';
import { ThemeColorIcon } from '../../theme';

// svgr key value map - [key: string]: svgrComponent
export const SVG = {
  ...Components,
};

// map all the keys for props 'type' completion
export type SVGKey = keyof typeof SVG;

export type SVGProps = {
  type: SVGKey;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  color?: ThemeColorIcon;
} & React.SVGProps<SVGSVGElement>;

const sizeMap = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 28,
  xl: 32,
  xxl: 40,
};

export const Icon = ({ type, size = 'md', color = 'secondary', ...otherProps }: SVGProps) => {
  const { colors } = useTheme();
  const SVGComponent = SVG[type];
  const dimensions = sizeMap[size];

  return (
    <SVGComponent
      preserveAspectRatio="none"
      width={dimensions}
      height={dimensions}
      viewBox="0 0 16 16"
      fill={colors.icons[color]}
      {...otherProps}
    />
  );
};
