import React from 'react';
import { Icon } from '../../icons';
import { VariantValueItem } from './styled';

type IVariantItem = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  name: string;
  isSelected?: boolean;
  isEdit?: boolean;
};

const Chip = ({ onClick, name, isSelected = false, isEdit = false }: IVariantItem) => {
  return (
    <VariantValueItem onClick={onClick} noShadow color={(isSelected && 'primary') || 'secondary'}>
      <span>{name}</span>
      {isEdit &&
        ((isSelected && <Icon type={'Close'} color={'white'} />) || <Icon type={'Plus'} />)}
    </VariantValueItem>
  );
};

export default Chip;
