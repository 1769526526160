import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroller';

export const StyledInvoicesHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;

  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledInvoicesWrapper = styled(InfiniteScroll)`
  display: grid;
  grid-gap: 1rem;
`;

export const StyledFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;

  select,
  input {
    height: 2.5rem;
    padding: 0.5rem 1.5rem;
    background: ${({ theme }) => theme.palette.primary8};
  }

  button {
    height: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 0.25rem;
    margin-bottom: 0.5rem;
  }
`;

export const StyledPeriodWrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
  margin-bottom: 2rem;
  align-items: center;

  button:first-of-type {
    height: 2.5rem;
    background: ${({ theme }) => theme.palette.primary8};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const StyledCountPanel = styled.div`
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.primary8};
  height: 2.5rem;
  padding-inline: 1rem;

  > h3 {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
  }

  > span {
    color: ${({ theme }) => theme.palette.neutral30};
  }

  > p {
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
`;
