import { httpGet, httpPostFormData } from '../../shared/utils/http';
import {
  GetMailingLettersParams,
  MailingLetter,
  MailingLetterRequest,
} from '../../shared/types/mailing';
import { objectToFormData } from '../../shared/utils/utils';

export type MailingApiType = {
  getLetters: (params: GetMailingLettersParams) => Promise<MailingLetter[]>;
  getLetter: (letterId: number) => Promise<MailingLetter>;
  updateLetter: (letterId: number, data: MailingLetterRequest) => Promise<MailingLetter>;
};

export const MailingApi: MailingApiType = {
  getLetters: (params) => {
    return httpGet(`/admin/mailing/templates`, params);
  },
  getLetter: (letterId) => {
    return httpGet(`/admin/mailing/templates/${letterId}`);
  },
  updateLetter: (letterId, data) => {
    return httpPostFormData(`/admin/mailing/templates/${letterId}`, objectToFormData(data));
  },
};
