import React from 'react';
import { ButtonGroup } from '../../../../shared/components/custom';
import useGetGenders from '../requests/useGetGenders';
import { Gender } from '../../../../shared/constants/Gender';
import useGeneratorContext from '../hooks/useGeneratorContext';

const GenderSelector = () => {
  const { isLoading, genders } = useGetGenders();
  const { data, setData } = useGeneratorContext();

  const onGenderChange = (gender: Gender) => {
    setData({ ...data, gender });
  };

  return (
    <ButtonGroup
      defaultValue={data.gender}
      options={genders}
      isLoading={isLoading}
      onChange={onGenderChange}
      skeletonNumber={2}
    />
  );
};

export default GenderSelector;
