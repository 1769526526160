/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgMail = (props) => (
  <svg
    {...props}
    width="32"
    height="23"
    viewBox="0 0 32 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_439_3660" fill="white">
      <path d="M29.1875 0H2.8125C1.25856 0 0 1.26637 0 2.8125V19.6875C0 21.2428 1.26781 22.5 2.8125 22.5H29.1875C30.7284 22.5 32 21.2481 32 19.6875V2.8125C32 1.26912 30.7464 0 29.1875 0ZM28.7936 1.875C28.219 2.44656 18.3302 12.2833 17.9888 12.6229C17.4575 13.1542 16.7513 13.4467 16 13.4467C15.2487 13.4467 14.5425 13.1541 14.0095 12.6212C13.7799 12.3927 4.00019 2.66462 3.20637 1.875H28.7936ZM1.875 19.3059V3.19531L9.97738 11.255L1.875 19.3059ZM3.20756 20.625L11.3068 12.5773L12.6854 13.9487C13.5708 14.8341 14.7479 15.3217 16 15.3217C17.2521 15.3217 18.4292 14.8341 19.3128 13.9505L20.6933 12.5773L28.7924 20.625H3.20756ZM30.125 19.3059L22.0226 11.255L30.125 3.19531V19.3059Z" />
    </mask>
    <path
      d="M29.1875 0H2.8125C1.25856 0 0 1.26637 0 2.8125V19.6875C0 21.2428 1.26781 22.5 2.8125 22.5H29.1875C30.7284 22.5 32 21.2481 32 19.6875V2.8125C32 1.26912 30.7464 0 29.1875 0ZM28.7936 1.875C28.219 2.44656 18.3302 12.2833 17.9888 12.6229C17.4575 13.1542 16.7513 13.4467 16 13.4467C15.2487 13.4467 14.5425 13.1541 14.0095 12.6212C13.7799 12.3927 4.00019 2.66462 3.20637 1.875H28.7936ZM1.875 19.3059V3.19531L9.97738 11.255L1.875 19.3059ZM3.20756 20.625L11.3068 12.5773L12.6854 13.9487C13.5708 14.8341 14.7479 15.3217 16 15.3217C17.2521 15.3217 18.4292 14.8341 19.3128 13.9505L20.6933 12.5773L28.7924 20.625H3.20756ZM30.125 19.3059L22.0226 11.255L30.125 3.19531V19.3059Z"
      fill="#9B70DD"
      stroke="white"
      strokeWidth="0.6"
      mask="url(#path-1-inside-1_439_3660)"
    />
  </svg>
);

export default SvgMail;
