import React from 'react';
import { User } from '../../../../../shared/types/user';
import { StyledUserAction, StyledUserName, StyledUserRole, StyledUserRow } from '../styled';
import { Avatar } from '@chakra-ui/react';
import { LinkButton } from '../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../shared/theme';
import { useNavigateWithParams, useScopedTranslation } from '../../../../../shared/hooks';
import { ControlPaths } from '../../../../../router/routes';

const UserRow = ({ user }: { user: User }) => {
  const { t, rootT } = useScopedTranslation('users.list');
  const { urlWithParams } = useNavigateWithParams();

  return (
    <StyledUserRow>
      <StyledUserName>
        <Avatar size={'sm'} name={user.name} />
        <div>
          <h3>{user.name}</h3>
          <p>{user.email}</p>
        </div>
      </StyledUserName>
      <StyledUserRole>
        <h3>{user?.roles?.join(', ') || t('no_role')}</h3>
        <p>{rootT('user.permissions', { count: user?.permissions?.length ?? 0 })}</p>
      </StyledUserRole>
      <StyledUserAction>
        <LinkButton
          size={ButtonSizes.sm}
          to={urlWithParams(ControlPaths.userPage, { userId: user?.id?.toString() })}
          noShadow
        >
          {t('show_more')}
        </LinkButton>
      </StyledUserAction>
    </StyledUserRow>
  );
};

export default UserRow;
