import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../ui';
import { ButtonSizes } from '../../../theme';
import { StyledButtonGroup } from './styled';
import { useScopedTranslation } from '../../../hooks';
import { ButtonGroupTypes } from './types';
import { Skeleton } from '@chakra-ui/react';
import { SkeletonRepeater } from '../SkeletonRepeater';

export type ButtonGroupValueType = string | number | null;

type IButtonGroup = {
  type?: ButtonGroupTypes;
  defaultValue?: ButtonGroupValueType | ButtonGroupValueType[];
  options: Array<{ value: string | number; label: string; isDisabled?: boolean }>;
  onChange: Function;
  size?: ButtonSizes;
  translationScope?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  skeletonNumber?: number;
  isMultiple?: boolean;
  isRequired?: boolean;
};

const ButtonGroup = ({
  type = ButtonGroupTypes.stack,
  options,
  onChange,
  defaultValue = null,
  size = ButtonSizes.sm,
  translationScope = '',
  isDisabled = false,
  isLoading = false,
  skeletonNumber = 6,
  isMultiple = false,
  isRequired = false,
  ...props
}: IButtonGroup) => {
  const { t } = useScopedTranslation(translationScope);
  const [selectedValue, setSelectedValue] = useState<ButtonGroupValueType[]>([]);

  const defaultConverted: ButtonGroupValueType[] = useMemo(() => {
    if (!defaultValue) return [];
    if (!isMultiple) return [defaultValue] as ButtonGroupValueType[];
    return defaultValue as ButtonGroupValueType[];
  }, [defaultValue]);

  const isSelected = (value: ButtonGroupValueType) => {
    return selectedValue.includes(value);
  };

  const selectValue = (value: ButtonGroupValueType) => () => {
    if (!isMultiple) {
      setSelectedValue([value]);
      onChange(value);
    } else {
      const updatedValues = (isSelected(value) &&
        selectedValue.filter((selected) => selected !== value)) || [...selectedValue, value];
      if ((isRequired && updatedValues.length > 0) || !isRequired) {
        setSelectedValue(updatedValues);
        onChange(updatedValues);
      }
    }
  };

  useEffect(() => {
    setSelectedValue(defaultConverted);
  }, [defaultConverted]);

  return (
    <StyledButtonGroup type={type} {...props}>
      {(isLoading && (
        <SkeletonRepeater number={skeletonNumber}>
          <Skeleton w={'5rem'} height={'3rem'} />
        </SkeletonRepeater>
      )) ||
        options.map(({ value, label, isDisabled: isOptionDisabled }) => (
          <Button
            isDisabled={isDisabled || isOptionDisabled}
            noShadow
            isLoading={isLoading}
            key={value}
            onClick={selectValue(value)}
            color={(isSelected(value) && 'primary') || 'secondary'}
            size={size}
          >
            {t(label)}
          </Button>
        ))}
    </StyledButtonGroup>
  );
};

export default ButtonGroup;
