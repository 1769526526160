import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Template, UpdateTemplateFormValues } from '../../../../types/production/template';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateTemplateScheme } from '../validationScheme';
import useGetSegments from '../../../../requests/production/useGetSegments';

const UseProcessUpdateForm = ({ template }: { template: Template }) => {
  const { segments, isLoading: isLoadingSegments } = useGetSegments();
  const segmentOptions = useMemo(() => {
    return segments.map((segment) => ({ value: segment.id, label: segment.name }));
  }, [segments]);
  const segmentsIds = useMemo(() => {
    return template.segments.map(({ id }) => id);
  }, [template]);

  const { register, reset, handleSubmit, getValues } = useForm<UpdateTemplateFormValues>({
    resolver: yupResolver(UpdateTemplateScheme),
  });

  const resetForm = (data: UpdateTemplateFormValues | null = null) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { name, intro_gain, intro_length, enabled, tag_padding, genres, tag_gain } = template;
    reset(
      data || {
        name,
        intro_gain,
        intro_length,
        tag_gain,
        enabled,
        tag_padding,
        segments_ids: segmentsIds,
        genres: genres?.join(', ') ?? '',
      },
    );
  };

  useEffect(() => {
    resetForm();
  }, [segmentsIds]);

  return {
    segmentOptions,
    segmentsIds,
    register,
    handleSubmit,
    getValues,
    resetForm,
    isLoadingSegments,
  };
};

export default UseProcessUpdateForm;
