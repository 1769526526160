import React, { useEffect } from 'react';
import { StyledHeader, StyledPanel, StyledSectionWrapper } from '../../styled';
import OrderInfoSelect from './OrderInfoSelect';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import useGetArtist from '../../../../../production/generator/requests/useGetArtist';
import useGetSegments from '../../../../requests/useGetSegments';
import useGetPersonalization from '../../../../requests/useGetPersonalization';
import { PersonalizationTypeEnum } from '../../../../../../shared/constants/production/personalization';
import { useOrderContext } from '../../hooks/useOrderContext';
import { Control } from 'react-hook-form';
import { OrderInfoForm } from '../../hooks/forms/useInfoForm';

const GenerationForm = ({
  watch,
  register,
  control,
  setValue,
}: {
  watch: Function;
  register: Function;
  setValue: Function;
  control: Control<OrderInfoForm, unknown> | undefined;
}) => {
  const { t } = useScopedTranslation('order.info');
  const { order } = useOrderContext();

  const artistId = watch('artist_id');
  const segmentId = watch('segment_id');
  const occasionId = watch('occasion_id');

  const { segmentOptions } = useGetSegments();
  const { personalizationOptions } = useGetPersonalization(PersonalizationTypeEnum.occasion);
  const { artists, getArtists, updateTemplatesAndIntro, intro, templates } = useGetArtist();

  useEffect(() => {
    if (segmentId && occasionId) getArtists(segmentId, occasionId);
  }, [segmentId, occasionId]);

  useEffect(() => {
    if (segmentId && occasionId && artistId)
      updateTemplatesAndIntro(segmentId, occasionId, artistId);
  }, [segmentId, occasionId, artistId]);

  return (
    <StyledPanel>
      <StyledHeader>{t('required_data')}</StyledHeader>
      <StyledSectionWrapper>
        <OrderInfoSelect
          options={segmentOptions}
          label={t('segment')}
          name={'segment_id'}
          defaultValue={order?.segment?.id}
          {...{ register, setValue }}
        />
        <OrderInfoSelect
          options={personalizationOptions}
          label={t('occasion')}
          name={'occasion_id'}
          defaultValue={order?.occasion?.id}
          {...{ register, setValue }}
        />
        <OrderInfoSelect
          options={artists}
          label={t('artist')}
          name={'artist_id'}
          defaultValue={order?.artist?.data?.id}
          {...{ register, setValue }}
        />
        <OrderInfoSelect
          options={templates}
          label={t('template')}
          name={'template_id'}
          defaultValue={order?.template_id}
          {...{ register, setValue }}
        />
        <OrderInfoSelect
          options={intro}
          label={t('intro_file')}
          name={'intro_file_id'}
          defaultValue={order?.intro_file_id}
          buttons
          {...{ register, setValue, control }}
        />
      </StyledSectionWrapper>
    </StyledPanel>
  );
};

export default GenerationForm;
