import React, { ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { css, Global, ThemeProvider } from '@emotion/react';
import { chakraTheme, designSystem } from '../config';

import '../fonts/fonts.css';
import { scrollbar } from '../styles';

export interface IGlobalThemeProvider {
  children: ReactNode;
}

/**
 * Important Details obout theme providers:
 * 1. GlobalStyle injects fonts from assets folder
 *    and apply general overrides to the application body tag
 *    (a replacement to index.css file).
 *    The important thing to remember is to place the GlobalStyle component as a
 *    sibling component to styled StyledAppWrapper.
 * 2. ChakraProvider - expect to receive the chakra theme.
 * 3. ThemeProvider - expect to receive our custom theme configs.
 */

const globalStyles = css`
  html,
  body {
    background: ${designSystem.palette.neutral5};
    font-family: 'GothamPro-Regular', sans-serif;
    font-size: ${designSystem.fontSize.md};
  }

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }

  * {
    ${scrollbar}
  }
,
`;

export const GlobalThemeProvider = ({ children }: IGlobalThemeProvider) => (
  <ThemeProvider theme={designSystem}>
    <ChakraProvider theme={chakraTheme}>
      {children}
      <Global styles={globalStyles} />
    </ChakraProvider>
  </ThemeProvider>
);
