import { css } from '@emotion/react';
import { designSystem } from '../config';

export const scrollbar = css`
  ::-webkit-scrollbar {
    background: transparent;
    width: 1rem;
    height: 1rem;

    :hover {
      background: transparent;
    }
  }

  ::-webkit-scrollbar-track-piece {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    opacity: 1;
    border-radius: 1.429rem;
    box-shadow: inset 0 0 0 1.429rem ${designSystem.palette.neutral50};
    border: 0.286rem solid transparent;
  }
`;

export const scrollOverlay = css`
  overflow: overlay;
  ${scrollbar};
`;
