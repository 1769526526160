import styled from '@emotion/styled';
import { Button } from '../Button';

export const VariantValueItem = styled(Button)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0.5rem 0.5rem;
  height: unset;

  > svg {
    width: 0.5rem;
  }
`;
