import React from 'react';
import useSegmentContext from '../hooks/useSegmentContext';
import {
  StyledInfoWrapper,
  StyledPersonalizationEditor,
  StyledSectionHeader,
  StyledSectionSubheader,
} from '../styled';
import useGetPersonalizationTypes from '../../../../shared/requests/production/useGetPersonalizationTypes';
import PersonalizationTypeRow from './PersonalizationTypeRow';
import { PersonalizationTypeEnum } from '../../../../shared/constants/production/personalization';
import PersonalizationTypeRowSkeleton from './PersonalizationTypeRowSkeleton';
import { SkeletonRepeater } from '../../../../shared/components/custom';
import { useScopedTranslation } from '../../../../shared/hooks';

const Personalizations = () => {
  const { t } = useScopedTranslation('production.segments');

  const { segment } = useSegmentContext();
  const { types, isLoading } = useGetPersonalizationTypes();

  return (
    segment && (
      <StyledInfoWrapper>
        <StyledSectionHeader>
          <p>{t('personalization_types')}</p>
        </StyledSectionHeader>
        <StyledSectionSubheader>{t('types_subheader')}</StyledSectionSubheader>

        <StyledPersonalizationEditor>
          {(!isLoading &&
            types.map(
              (type) =>
                (type.name !== PersonalizationTypeEnum.occasion && (
                  <PersonalizationTypeRow key={type.id} type={type} />
                )) || <></>,
            )) || (
            <SkeletonRepeater number={7}>
              <PersonalizationTypeRowSkeleton />
            </SkeletonRepeater>
          )}
        </StyledPersonalizationEditor>
      </StyledInfoWrapper>
    )
  );
};

export default Personalizations;
