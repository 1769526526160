import React from 'react';
import { Button, Input, Textarea } from '../../../ui';
import { ButtonSizes } from '../../../../theme';
import { StyledCustomRow } from '../styled';
import { PersonalizationLyrics } from '../../../../types/production/template';
import { Icon } from '../../../icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormType } from '../types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CustomLyricScheme } from '../validationScheme';
import { useScopedTranslation } from '../../../../hooks';
import { ConfirmDialog } from '../../ConfirmDialog';

type CustomStringRowType = {
  lyric: PersonalizationLyrics;
  onChange: Function;
  onRemove: Function;
};

const CustomStringRow = ({ lyric, onChange, onRemove }: CustomStringRowType) => {
  const { t } = useScopedTranslation('production.templates');

  const { register, handleSubmit } = useForm<FormType>({
    defaultValues: { ...lyric },
    mode: 'onChange',
    resolver: yupResolver(CustomLyricScheme),
  });

  const onSubmit: SubmitHandler<FormType> = (args) => {
    if (args.text && args.key) onChange({ ...lyric, ...args });
  };

  const removeRow = () => {
    onRemove(lyric);
  };

  return (
    <StyledCustomRow>
      <Input
        onBlur={handleSubmit(onSubmit)}
        {...{ register }}
        name={'key'}
        placeholder={t('enter_value')}
      />
      <Textarea
        onBlur={handleSubmit(onSubmit)}
        name={'text'}
        placeholder={t('enter_alias')}
        {...{ register }}
      />
      <ConfirmDialog
        title={t('delete_lyrics_row_confirm')}
        onConfirm={removeRow}
        activator={
          <Button title={t('remove')} fab noShadow size={ButtonSizes.sm}>
            <Icon type={'Close'} />
          </Button>
        }
      />
    </StyledCustomRow>
  );
};

export default CustomStringRow;
