import React, { useMemo, useState } from 'react';
import {
  StyledDivider,
  StyledErrorMessage,
  StyledErrorString,
  StyledQuickActions,
  StyledRemakeWrapper,
  StyledRemakingString,
  StyledStatusDate,
  StyledStatusHeader,
  StyledStatusString,
  StyledStatusWrapper,
} from '../styled';
import { useOrderContext } from '../hooks/useOrderContext';
import { useOrderFailureMessage, useScopedTranslation } from '../../../../../shared/hooks';
import { toPrettyDate } from '../../../../../shared/utils/utils';
import { Button, LinkButton } from '../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../shared/theme';
import { ConfirmDialog } from '../../../../../shared/components/custom';
import useOrderInWork from '../hooks/useOrderInWork';
import useRemake from '../hooks/useRemake';
import { InvoiceStatuses } from '../../../../../shared/types/invoice';
import { orderUrl } from '../../../../../shared/utils/navigation';

const OrderStatus = () => {
  const { order, isEdit } = useOrderContext();
  const { t, rootT } = useScopedTranslation('order');
  const isInWork = useOrderInWork(order);
  const [isRemakingQuery, setIsRemakingQuery] = useState(false);
  const { remake, isRemaking } = useRemake();

  const remakeOrder = async () => {
    setIsRemakingQuery(true);
    await remake();
    setIsRemakingQuery(false);
  };

  const payments = useMemo(
    () => order?.payments?.filter(({ status }) => status === InvoiceStatuses.paid) ?? [],
    [order],
  );
  const totalAmount = useMemo(
    () => payments.reduce((total, invoice) => total + invoice.final_amount, 0).toFixed(2),
    [payments],
  );

  const { errorMessage, hasError } = useOrderFailureMessage(order);

  return (
    order && (
      <StyledStatusWrapper>
        <div>
          <StyledStatusHeader>{t('current_state')}</StyledStatusHeader>
          {(hasError && (
            <>
              <StyledErrorString>{t(`error`)}</StyledErrorString>
              <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
            </>
          )) ||
            (isRemaking && <StyledRemakingString>{t(`is_remaking`)}</StyledRemakingString>) || (
              <StyledStatusString status={order.status}>
                {t(`statuses.${order.status}`)}
              </StyledStatusString>
            )}
          <StyledStatusDate>
            {t('created_at', { date: toPrettyDate(order.created_at, 'MMM d, yyyy HH:mm') })}
          </StyledStatusDate>
          {isInWork && (
            <StyledStatusDate>
              {t('ready_at', { date: toPrettyDate(order.deadline_at, 'MMM d, yyyy HH:mm') })}
            </StyledStatusDate>
          )}
        </div>
        {isEdit && (
          <StyledRemakeWrapper>
            <p>{t('remake_title')}</p>
            <div>
              <p>{t('remake_subtitle')}</p>
              <ConfirmDialog
                title={t('remake_confirm_title')}
                onConfirm={remakeOrder}
                isLoading={isRemakingQuery}
                activator={
                  <Button isLoading={isRemaking} color={'primary'} size={ButtonSizes.sm}>
                    {t('remake')}
                  </Button>
                }
              />
            </div>
          </StyledRemakeWrapper>
        )}
        <StyledDivider />
        <div>
          <StyledStatusHeader>{t('total_amount')}</StyledStatusHeader>
          {(!!payments.length && (
            <p>{rootT('order.payments_info', { count: payments.length, totalAmount })}</p>
          )) || <p>{rootT('order.invoices.no_payments')}</p>}
        </div>
        <StyledDivider />
        <div>
          <StyledStatusHeader>{t('quick_access')}</StyledStatusHeader>
          <StyledQuickActions>
            <LinkButton target={'_blank'} size={ButtonSizes.sm} noShadow to={orderUrl(order.uuid)}>
              {t('client_order_page')}
            </LinkButton>
          </StyledQuickActions>
        </div>
      </StyledStatusWrapper>
    )
  );
};

export default OrderStatus;
