export enum SocialNetworks {
  instagram = 'instagram',
  facebook = 'facebook',
}

export enum SocialNetworkUrls {
  instagram = 'https://instagram.com',
  twitter = 'https://twitter.com',
  facebook = 'https://facebook.com',
}
