import { useMemo, useState } from 'react';
import { differenceInSeconds, parseISO } from 'date-fns';

export const useCompletionPercentage = (startedAt: string, finishAt: string) => {
  const [defaultDate] = useState(null);

  const startedAtDate = useMemo(
    () => (!!startedAt && parseISO(startedAt)) || null,
    [startedAt, defaultDate],
  );
  const finishAtDate = useMemo(
    () => (!!finishAt && parseISO(finishAt)) || null,
    [finishAt, defaultDate],
  );

  const totalTimeUnix = useMemo(
    () => (finishAtDate && startedAtDate && differenceInSeconds(finishAtDate, startedAtDate)) || 0,
    [startedAtDate, finishAtDate],
  );

  return useMemo(() => {
    if (!totalTimeUnix) return 0;
    const timePassedUnix = (!!startedAtDate && differenceInSeconds(new Date(), startedAtDate)) || 0;
    const completion = (timePassedUnix / totalTimeUnix) * 100;
    return (completion > 100 && 101) || completion;
  }, [totalTimeUnix]);
};
