import { useNavigate } from 'react-router-dom';
import { useScopedTranslation } from '../../../hooks';
import { CommonPaths } from '../../../../router/routes';
import {
  StyledHeader,
  StyledText,
  StyledButton,
  StyledExceptionContainer,
  StyledHeadersContainer,
} from './styled';
import { MotionContainer } from '../MotionContainer';

const UncaughtException = ({ resetError }: { resetError: Function }) => {
  const { t, rootT } = useScopedTranslation('general.errors.exception');
  const navigate = useNavigate();

  const reset = () => {
    resetError();
    navigate(CommonPaths.dashboard);
  };

  return (
    <MotionContainer>
      <StyledExceptionContainer>
        <StyledHeadersContainer>
          <StyledHeader>
            {t('oh_no')}
            <br />
            {t('something_went_wrong')}
          </StyledHeader>
          <StyledText>{t('something_went_wrong_text')}</StyledText>
        </StyledHeadersContainer>
        <StyledButton onClick={reset} color={'primary'}>
          {rootT('general.errors.go_home')}
        </StyledButton>
      </StyledExceptionContainer>
    </MotionContainer>
  );
};

export default UncaughtException;
