/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgPostCard = (props) => (
  <svg
    {...props}
    width="69"
    height="71"
    viewBox="0 0 69 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.7213 35.2536L43.8945 35.3536L43.9945 35.1804L47.6769 28.8024L47.7769 28.6292L47.6037 28.5292L32.7428 19.9492L32.5696 19.8492L32.4696 20.0224L28.7872 26.4005L28.6872 26.5737L28.8604 26.6737L43.7213 35.2536ZM32.8388 15.3641L51.5265 26.1535C52.4876 26.7083 52.8169 27.9373 52.262 28.8984L46.3702 39.1033C45.8154 40.0643 44.5864 40.3936 43.6253 39.8388L24.9376 29.0494C23.9765 28.4945 23.6472 27.2655 24.2021 26.3045L30.0939 16.0996C30.6488 15.1385 31.8777 14.8092 32.8388 15.3641Z"
      fill="#BCBFC1"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M18.1083 40.877L29.5888 47.5053C30.5499 48.0602 30.8792 49.2892 30.3243 50.2502C29.7695 51.2113 28.5405 51.5406 27.5794 50.9857L16.0989 44.3575C15.1379 43.8026 14.8085 42.5736 15.3634 41.6125C15.9183 40.6515 17.1473 40.3222 18.1083 40.877Z"
      fill="#BCBFC1"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M21.7919 34.4984L40.671 45.3982C41.632 45.9531 41.9613 47.1821 41.4065 48.1431C40.8516 49.1042 39.6226 49.4335 38.6616 48.8786L19.7825 37.9788C18.8215 37.4239 18.4921 36.1949 19.047 35.2339C19.6019 34.2728 20.8309 33.9435 21.7919 34.4984Z"
      fill="#BCBFC1"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M32.8903 3.48734L62.2931 20.4631C65.3626 22.2353 66.4182 26.1747 64.646 29.2443L44.0247 64.9614C42.2525 68.0309 38.3131 69.0865 35.2435 67.3143L5.84069 50.3385C2.77118 48.5664 1.7156 44.6269 3.48778 41.5574L24.1091 5.84025C25.8813 2.77074 29.8207 1.71515 32.8903 3.48734ZM37.253 63.8339C38.4037 64.4982 39.8799 64.1027 40.5443 62.952L61.1656 27.2348C61.8299 26.0841 61.4344 24.6079 60.2837 23.9435L30.8808 6.96776C29.7301 6.3034 28.2539 6.69896 27.5895 7.84967L6.96821 43.5668C6.30384 44.7175 6.6994 46.1938 7.85012 46.8581L37.253 63.8339Z"
      fill="#BCBFC1"
      stroke="white"
      strokeWidth="0.4"
    />
  </svg>
);

export default SvgPostCard;
