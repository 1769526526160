import React from 'react';
import { MailingLetter } from '../../../../../shared/types/mailing';

export type IMailingContext = {
  letter: MailingLetter | null;
  variables: string[];
  register: Function;
  getValues: Function;
  reset: Function;
  watch: Function;
};

export const defaultState = {
  letter: null,
  variables: [],
  register: () => {},
  getValues: () => {},
  reset: () => {},
  watch: () => {},
};

export const MailingContext = React.createContext<IMailingContext>(defaultState);

export const MailingContextProvider = MailingContext.Provider;
