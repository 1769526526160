/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgCalendar = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.13078 35H30.8692C33.1481 35 35 33.2167 35 31.0222V11.2C35 9.00553 33.1481 7.22218 30.8692 7.22218H29.2308V6.11109C29.2308 5.49997 28.7115 5 28.0769 5C27.4422 5 26.923 5.49997 26.923 6.11109V7.22218H13.077V6.11109C13.077 5.49997 12.5577 5 11.923 5C11.2884 5 10.7692 5.49997 10.7692 6.11109V7.22218H9.13078C6.85194 7.22218 5 9.00553 5 11.2V31.0222C5 33.2167 6.85194 35 9.13078 35ZM7.30774 11.2C7.30774 10.2333 8.12696 9.44445 9.13078 9.44445H10.7692V10.5555C10.7692 11.1667 11.2884 11.6666 11.923 11.6666C12.5577 11.6666 13.0769 11.1667 13.0769 10.5555V9.44445H26.923V10.5555C26.923 11.1667 27.4422 11.6666 28.0769 11.6666C28.7115 11.6666 29.2307 11.1667 29.2307 10.5555V9.44445H30.8691C31.8729 9.44445 32.6922 10.2333 32.6922 11.2V14.4445H7.30774V11.2ZM7.30774 16.6666H32.6924V31.0222C32.6924 31.9888 31.8731 32.7777 30.8693 32.7777H9.13078C8.12696 32.7777 7.30774 31.9888 7.30774 31.0222V16.6666Z"
      {...props}
    />
  </svg>
);

export default SvgCalendar;
