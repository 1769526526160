import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOrderContext } from '../useOrderContext';

export type OrderInfoForm = {
  segment_id: number;
  occasion_id: number;
  artist_id: number;
  template_id: number;
  intro_file_id: number;
  sender_name: string;
  recipient_name: string;
};

const useInfoForm = () => {
  const { order, updateOrder } = useOrderContext();

  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { isDirty },
  } = useForm<OrderInfoForm>({
    defaultValues: {
      segment_id: order?.segment?.id,
      occasion_id: order?.occasion?.id,
      artist_id: order?.artist?.data?.id,
      template_id: order?.template_id,
      intro_file_id: order?.intro_file_id,
      sender_name: order?.sender_name,
      recipient_name: order?.recipient_name,
    },
  });

  useEffect(() => {
    reset({
      ...getValues(),
      recipient_name: order?.recipient_name,
      sender_name: order?.sender_name,
      template_id: order?.template_id,
      intro_file_id: order?.intro_file_id,
    });
  }, [order]);

  const onSubmit: SubmitHandler<OrderInfoForm> = async (args) => {
    await updateOrder({ ...args });
  };

  return { register, reset, setValue, onSave: handleSubmit(onSubmit), isDirty, watch, control };
};

export default useInfoForm;
