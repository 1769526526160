import React, { useEffect, useState } from 'react';
import { Button, Switch } from '../../../../shared/components/ui';
import {
  StyledPersonalizationForm,
  StyledPersonalizationRow,
  StyledPersonalizationInput,
  StyledPersonalizationIndex,
} from '../styled';
import { ButtonSizes } from '../../../../shared/theme';
import { Spacer } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddPersonalizationSchema } from '../validationScheme';
import { PersonalizationApi } from '../../../../api';
import { CreatePersonalizationRequest } from '../../../../shared/types/production/personalization';
import ImportModal from './ImportModal';
import { useScopedTranslation } from '../../../../shared/hooks';

const PersonalizationCreateForm = ({
  type,
  onSubmit,
  onImport,
}: {
  type: string;
  onSubmit: Function;
  onImport: Function;
}) => {
  const { t } = useScopedTranslation('production.personalization');

  const [isLoading, setIsLoading] = useState(false);
  const { register, reset, formState, handleSubmit } = useForm({
    resolver: yupResolver(AddPersonalizationSchema),
  });
  const { isValid } = formState;

  useEffect(() => {
    reset({ alias: '', visible: true });
  }, []);

  const onFormSubmit = (args: Object) => {
    const data = { ...args, type } as CreatePersonalizationRequest;
    setIsLoading(true);
    PersonalizationApi.create(data)
      .then((response) => {
        onSubmit(response.data);
        reset({ alias: '', visible: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <StyledPersonalizationForm onSubmit={handleSubmit(onFormSubmit)}>
      <StyledPersonalizationRow>
        <StyledPersonalizationIndex />
        <StyledPersonalizationInput
          name={'alias'}
          placeholder={t('enter_name')}
          {...{ register }}
        />
        <Switch name={'visible'} {...{ register }}>
          {t('visible')}
        </Switch>
        <Spacer />
        <ImportModal
          type={type}
          onImport={onImport}
          activator={
            <Button type={'button'} noShadow size={ButtonSizes.sm}>
              {t('import')}
            </Button>
          }
        />
        <Button
          isLoading={isLoading}
          type={'submit'}
          isDisabled={!isValid}
          color={'primary'}
          noShadow
          size={ButtonSizes.sm}
        >
          {t('add')}
        </Button>
      </StyledPersonalizationRow>
    </StyledPersonalizationForm>
  );
};

export default PersonalizationCreateForm;
