import { useOrderContext } from './useOrderContext';
import { useMemo } from 'react';

const useRemake = () => {
  const { remake, order } = useOrderContext();

  const isRemaking = useMemo(() => !!order?.remade_at, [order]);

  return { isRemaking, remake };
};

export default useRemake;
