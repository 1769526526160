import React from 'react';
import { StyledTemplatesWrapper, StyledCreateTemplateCard } from './styled';
import { SkeletonRepeater, TemplateCreateModal } from '../index';
import { Template } from '../../../types/production/template';
import TemplateCard from './components/TemplateCard';
import TemplateCardSkeleton from './components/TemplateCardSkeleton';
import { Artist } from '../../../types/production/artist';
import { Button } from '../../ui';
import { ButtonSizes } from '../../../theme';
import { Icon } from '../../icons';
import { useScopedTranslation } from '../../../hooks';

const SkeletonComponent = () => {
  return (
    <SkeletonRepeater number={4}>
      <TemplateCardSkeleton />
    </SkeletonRepeater>
  );
};

const TemplateSelector = ({
  templates,
  setTemplates,
  isLoading,
  selectedTemplate = null,
  setSelectedTemplate,
  artist,
}: {
  templates: Template[];
  isLoading: boolean;
  selectedTemplate: Template | null;
  setSelectedTemplate: Function;
  artist: Artist;
  setTemplates: Function;
}) => {
  const { t } = useScopedTranslation('production.templates');

  const isSelected = (template: Template) => {
    return template.id === selectedTemplate?.id;
  };

  const onCreated = (template: Template) => {
    setTemplates([...templates, template]);
  };

  return (
    <>
      <StyledTemplatesWrapper>
        {(isLoading && <SkeletonComponent />) || (
          <>
            {templates.map((template) => (
              <TemplateCard
                isSelected={isSelected(template)}
                onSelect={setSelectedTemplate}
                template={template}
                key={template.id}
              />
            ))}
            <TemplateCreateModal
              artistId={artist.id}
              activator={
                <StyledCreateTemplateCard>
                  <span>{t('create_template')}</span>
                  <Button noShadow size={ButtonSizes.sm} fab>
                    <Icon type={'Plus'} />
                  </Button>
                </StyledCreateTemplateCard>
              }
              onCreated={onCreated}
            />
          </>
        )}
      </StyledTemplatesWrapper>

      {/*{!!otherArtistTemplates.length && (*/}
      {/*  <>*/}
      {/*    <Divider my={'1rem'} />*/}

      {/*    <StyledArtistTemplatesTitle>*/}
      {/*      {t('other_templates', { artist: artist.name })}*/}
      {/*    </StyledArtistTemplatesTitle>*/}
      {/*    <StyledTemplatesWrapper>*/}
      {/*      {otherArtistTemplates.map((template) => (*/}
      {/*        <TemplateCard*/}
      {/*          isSelected={isSelected(template)}*/}
      {/*          onSelect={setSelectedTemplate}*/}
      {/*          template={template}*/}
      {/*          key={template.id}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </StyledTemplatesWrapper>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
};

export default TemplateSelector;
