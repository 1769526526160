import React from 'react';
import { Link } from 'react-router-dom';
import { Spacer } from '@chakra-ui/react';
import { Spinner } from '../../ui';
import { StyledBreadcrumbs } from './styled';
import { useScopedTranslation } from '../../../hooks';

type IPageBreadcrumbs = {
  rootPageName: string;
  rootPagePath: string;
  currentPageName?: string;
  isLoading?: boolean;
  isUpdating?: boolean;
};

const PageBreadcrumbs = ({
  isLoading = false,
  isUpdating = false,
  currentPageName = '',
  rootPageName,
  rootPagePath,
}: IPageBreadcrumbs) => {
  const { t } = useScopedTranslation('general');

  return (
    <StyledBreadcrumbs>
      <Link to={rootPagePath}>{rootPageName}</Link>
      <span>{'/'}</span>
      <span>{currentPageName || '...'}</span>
      <Spacer />
      {isLoading && (
        <>
          <span>{t((isUpdating && 'updating') || 'loading')}</span>
          <Spinner size={'xs'} />
        </>
      )}
    </StyledBreadcrumbs>
  );
};

export default PageBreadcrumbs;
