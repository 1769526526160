import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroller';
import { Input } from '../../../../shared/components/ui';

export const StyledListHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
`;

export const StyledListSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: 1rem;
`;

export const StyledSearchInput = styled(Input)`
  background: ${({ theme }) => theme.palette.primary8};
  height: 2.5rem;
`;

export const StyledLettersHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.75rem;
  align-items: center;
  padding: 1rem 1.25rem;
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-top: 0.5rem;

  > div:last-of-type {
    text-align: end;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledLettersWrapper = styled(InfiniteScroll)`
  display: grid;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1rem;
  }
`;

export const StyledLetterCard = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.75rem;
  align-items: center;

  box-shadow: ${({ theme }) => theme.shadow.card};
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 1rem 1.5rem;

  > div:first-of-type {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.md};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  > div:last-of-type {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  > div:last-of-type > a {
    margin-left: auto;
    height: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;
