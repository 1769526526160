import { useEffect, useState } from 'react';
import { MailingApi } from '../../../api';
import { MailingLetter, MailingLetterRequest } from '../../../shared/types/mailing';
import useRedirectOnError from '../../../shared/hooks/useRedirectOnError';
import { ControlPaths } from '../../../router/routes';

const useLoadLetters = (letterId: number | null) => {
  const [letter, setLetter] = useState<MailingLetter | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const redirect = useRedirectOnError(ControlPaths.mailing);

  const getLetter = async () => {
    if (!letterId) return;

    setIsLoading(true);
    await MailingApi.getLetter(letterId)
      .then(setLetter)
      .catch((error) => {
        redirect(error);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (letterId) getLetter();
  }, [letterId]);

  const updateLetter = async (data: MailingLetterRequest) => {
    if (!letterId) return;
    await MailingApi.updateLetter(letterId, data)
      .then(setLetter)
      .catch(() => null);
  };

  return { letter, isLoading, getLetter, updateLetter };
};

export default useLoadLetters;
