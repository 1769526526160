export type Paths = PublicPaths | CommonPaths | ProductionPaths | ControlPaths;

export enum PublicPaths {
  login = '/login',
}

export enum CommonPaths {
  dashboard = '/',
}

export enum ProductionPaths {
  segments = '/production/segments',
  segmentPage = '/production/segments/:segmentId',
  generator = '/production/generator',
  personalization = '/production/personalization',
  artists = '/production/artists',
  artistPage = '/production/artists/:artistId',
  intro = '/production/intro',
  templates = '/production/templates',
  settings = '/production/settings',
}

export enum ControlPaths {
  orders = '/orders',
  orderPage = '/orders/:orderId',
  invoices = '/invoices',
  users = '/users',
  userPage = '/users/:userId',
  mailing = '/mailing',
  letterPage = '/mailing/:letterId',
  roles = '/roles',
  prices = '/prices',
}
