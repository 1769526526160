import * as Yup from 'yup';

export const LetterScheme = Yup.object().shape({
  image_url: Yup.string().nullable().trim().label('Image url'),
  content: Yup.string().trim().label('Content').required(),
  action_label: Yup.string().trim().label('Action label').required(),
  action_url: Yup.string().trim().label('Action url').required(),
  subject: Yup.string().trim().label('Subject').required(),
  enabled: Yup.boolean().label('Enabled'),
});
