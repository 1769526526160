import React from 'react';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { StyledUserAction, StyledUserName, StyledUserRole, StyledUserRow } from '../styled';

const UserRowSkeleton = () => {
  return (
    <StyledUserRow>
      <StyledUserName>
        <SkeletonCircle w={'2rem'} h={'2rem'} />
        <div>
          <Skeleton w={'5rem'} h={'1rem'} mb={'0.5rem'} />
          <Skeleton w={'6rem'} h={'0.75rem'} />
        </div>
      </StyledUserName>
      <StyledUserRole>
        <Skeleton w={'5rem'} h={'1rem'} mb={'0.5rem'} />
        <Skeleton w={'6rem'} h={'0.75rem'} />
      </StyledUserRole>
      <StyledUserAction>
        <Skeleton w={'5rem'} h={'2.5rem'} />
      </StyledUserAction>
    </StyledUserRow>
  );
};

export default UserRowSkeleton;
