import React, { useEffect, useMemo, useState } from 'react';
import {
  StyledEmailForm,
  StyledFormActions,
  StyledLetterFormHeader,
  StyledLetterFormSubheader,
  StyledPageWrapper,
} from './styled';
import EmailPreview from './components/EmailPreview';
import { Button, Switch } from '../../../../shared/components/ui';
import { ButtonSizes } from '../../../../shared/theme';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LetterScheme } from '../../../roles/validation';
import { MailingLetterRequest } from '../../../../shared/types/mailing';
import { useScopedTranslation } from '../../../../shared/hooks';
import { useParams } from 'react-router-dom';
import useMailingApi from '../../requests/useMailingApi';
import { ControlPaths } from '../../../../router/routes';
import { PageBreadcrumbs } from '../../../../shared/components/custom';
import SubjectInput from './components/SubjectInput';
import { MailingContextProvider } from './context/MailingContext';
import ImageInput from './components/ImageInput';
import ContentForm from './components/ContentForm';
import ActionLabel from './components/ActionLabel';
import ActionUrl from './components/ActionUrl';

const LetterPage = () => {
  const { t, rootT } = useScopedTranslation('mailing.page');
  const [isUpdating, setIsUpdating] = useState(false);
  const { letterId } = useParams();

  const { letter, updateLetter } = useMailingApi(parseInt(letterId ?? '', 10));
  const variables = useMemo(() => letter?.variables ?? [], [letter]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
    reset,
    getValues,
  } = useForm<MailingLetterRequest>({
    mode: 'onBlur',
    resolver: yupResolver(LetterScheme),
  });

  useEffect(() => {
    reset((letter && { ...letter }) || {});
  }, [letter]);

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string>('');

  const imageUrl = watch('image_url');
  const watchLetter = watch();

  const onSubmit: SubmitHandler<MailingLetterRequest> = async (args) => {
    setIsUpdating(true);
    const data = { ...args, enabled: (args.enabled && 'true') || 'false' };
    if (!!imageFile) data.image = imageFile;
    await updateLetter(data);
    setIsUpdating(false);
  };

  return (
    <MailingContextProvider value={{ letter, register, reset, watch, getValues, variables }}>
      <PageBreadcrumbs
        rootPageName={rootT('mailing.list.settings')}
        rootPagePath={ControlPaths.mailing}
        currentPageName={(letter?.case && rootT(`mailing.cases.${letter?.case}`)) || '...'}
        isLoading={!letter}
      />
      {letter && (
        <StyledPageWrapper>
          <div>
            <StyledLetterFormHeader>{t(`edit_letter`)}</StyledLetterFormHeader>
            <StyledLetterFormSubheader>{t('use_form')}</StyledLetterFormSubheader>
            <StyledEmailForm onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Switch register={register} name={'enabled'}>
                  {t('enabled')}
                </Switch>
              </div>
              <SubjectInput />
              <ImageInput {...{ imageFile, setImageFile, setFilePreview }} />
              <ContentForm />
              <ActionLabel />
              <ActionUrl />

              <StyledFormActions>
                <Button
                  isDisabled={!isValid}
                  isLoading={isUpdating}
                  type={'submit'}
                  color={'primary'}
                  noShadow
                  size={ButtonSizes.sm}
                >
                  {t('save')}
                </Button>
              </StyledFormActions>
            </StyledEmailForm>
          </div>
          <EmailPreview
            imageUrl={filePreview || imageUrl || ''}
            actionUrl={watchLetter.action_url}
            actionLabel={watchLetter.action_label}
            content={watchLetter.content}
          />
        </StyledPageWrapper>
      )}
    </MailingContextProvider>
  );
};

export default LetterPage;
