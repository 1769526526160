import React from 'react';
import {
  Button,
  FileInput,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '../../../../shared/components/ui';
import {
  StyledButtonsWrapper,
  StyledModal,
  StyledFileWrapper,
  StyledFileWrapperHeader,
  StyledWarning,
} from '../../../../shared/components/custom/ConfirmDialog/styled';
import { useDisclosure } from '@chakra-ui/react';
import { useScopedTranslation } from '../../../../shared/hooks';
import useHandleImport from '../requests/useHandleImport';

type IImportModal = {
  type: string;
  activator: React.ReactNode;
  onImport: Function;
};

const ImportModal = ({ activator, type, onImport, ...props }: IImportModal) => {
  const { t, rootT } = useScopedTranslation('production.personalization');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onUpload = () => {
    onImport();
    onClose();
  };
  const { isUploading, uploadFile, parseFile, parsedAliases, selectedFile, isLoading } =
    useHandleImport({ type, isOpen, onUpload });

  return (
    <>
      <div className={'activator'} role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <StyledModal {...{ isOpen, onOpen, onClose, size: 'xs', ...props }}>
        <ModalHeader>{t('import_aliases')}</ModalHeader>
        <ModalBody>
          <StyledWarning>{t('warning')}</StyledWarning>

          <StyledFileWrapper>
            <StyledFileWrapperHeader>
              {(!selectedFile && t('file_is_not_selected')) ||
                (parsedAliases.length > 0 &&
                  rootT('production.found_aliases', { count: parsedAliases.length })) ||
                t('no_aliases_found')}
            </StyledFileWrapperHeader>
            <FileInput
              isLoading={isLoading}
              accept={'.txt'}
              color={'primary'}
              onFileChange={parseFile}
              label={(selectedFile && t('change_file')) || t('select_file')}
            />
          </StyledFileWrapper>
        </ModalBody>
        <ModalFooter>
          <StyledButtonsWrapper>
            <Button noShadow color={'secondary'} onClick={onClose}>
              {rootT('general.cancel')}
            </Button>
            <Button
              isDisabled={parsedAliases.length === 0}
              isLoading={isUploading}
              noShadow
              color={'primary'}
              onClick={uploadFile}
            >
              {t('import')}
            </Button>
          </StyledButtonsWrapper>
        </ModalFooter>
      </StyledModal>
    </>
  );
};

export default ImportModal;
