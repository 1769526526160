/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgTime = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7099 19.2903V19.0903V12.9325C21.7099 12.6117 21.5825 12.304 21.3555 12.0771L21.3555 12.0771C21.1288 11.8503 20.821 11.7228 20.5002 11.7228C20.1793 11.7228 19.8717 11.8502 19.6448 12.0771C19.6448 12.0771 19.6448 12.0771 19.6448 12.0771M21.7099 19.2903L19.5034 11.9357L19.6448 12.0771M21.7099 19.2903H21.9099H30.4619C30.7827 19.2903 31.0903 19.4177 31.3172 19.6446C31.5441 19.8715 31.6716 20.1792 31.6716 20.5C31.6716 20.8208 31.5442 21.1285 31.3172 21.3554C31.0903 21.5823 30.7827 21.7097 30.4619 21.7097H20.5002C20.1793 21.7097 19.8717 21.5823 19.6448 21.3554C19.4179 21.1285 19.2905 20.8208 19.2905 20.5V12.9325C19.2905 12.6117 19.4179 12.304 19.6448 12.0771M21.7099 19.2903L19.6448 12.0771M17.5151 5.49399L17.4761 5.29784L17.5152 5.49399C20.483 4.90364 23.5594 5.20663 26.3552 6.36465C29.1508 7.52267 31.5403 9.48371 33.2215 11.9998C34.9026 14.5158 35.8 17.4738 35.8 20.4998C35.7957 24.5563 34.1823 28.4455 31.3138 31.3138L31.4552 31.4552L31.3138 31.3138C28.4455 34.1823 24.5563 35.7956 20.4998 35.8C17.4738 35.8 14.5158 34.9026 11.9998 33.2215C9.48372 31.5403 7.52267 29.1508 6.36464 26.355C5.20663 23.5594 4.90364 20.483 5.49399 17.5151C6.08435 14.5472 7.54154 11.821 9.68127 9.68127C11.821 7.54152 14.5472 6.08435 17.5151 5.49399ZM20.5002 7.61941H20.5C17.9517 7.61941 15.4605 8.37512 13.3417 9.79099C11.2229 11.2069 9.57154 13.2192 8.59648 15.5737C7.62142 17.9281 7.36647 20.5188 7.86387 23.0181C8.36128 25.5175 9.5887 27.8132 11.3909 29.6149C13.1931 31.4166 15.4891 32.6434 17.9885 33.1402C20.488 33.6369 23.0786 33.3812 25.4329 32.4056C27.787 31.4299 29.799 29.778 31.2142 27.6588C32.6296 25.5396 33.3846 23.0483 33.384 20.5V20.4998C33.3803 17.0842 32.0218 13.8096 29.6061 11.3948C27.1907 8.97991 23.9157 7.6221 20.5002 7.61941Z"
      {...props}
      strokeWidth="0.4"
    />
  </svg>
);

export default SvgTime;
