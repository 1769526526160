import { format, parseISO } from 'date-fns';
import { showToast } from '../components/ui';
import i18n from '../i18n/i18n';

export const toPrettyDate = (date: string | null | undefined, fmt: string = 'MMMM dd, yyyy') => {
  if (!date) return date;
  try {
    return format(parseISO(date), fmt);
  } catch (e) {
    console.error(e);
    return date;
  }
};

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const range = (min: number, max: number, step: number = 1) =>
  Array.from({ length: max - min + step }, (_, i: number) => min + i);

export const currentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const copyToClipBoard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const shareUrl = (url: string, title: string) => {
  if (navigator.share) {
    navigator
      .share({ title, url })
      .then(() => {})
      .catch(console.error);
  } else {
    copyToClipBoard(url);
    showToast('success', i18n.t('general.copied'));
  }
};

export const withLeadingZero = (n: number) => {
  return ((n < 10 && '0') || '') + n;
};

export const downloadFromUrl = (url: string) => {
  window?.open(url, '_blank')?.focus();
};

export const objectToFormData = (object: Record<string, string | null | undefined | Blob>) => {
  const formData = new FormData();

  Object.keys(object).map((key) => {
    const value = object[key];
    if (value) formData.append(key, value);
    return key;
  });

  return formData;
};

export const insertValueIntoText = (
  input: HTMLInputElement | HTMLTextAreaElement,
  originalText: string,
  insertValue: string,
) => {
  const selectionStart = input?.selectionStart ?? 0;
  const selectionEnd = input?.selectionEnd ?? 0;

  return originalText.slice(0, selectionStart) + insertValue + originalText.slice(selectionEnd);
};

export const uuid = () => {
  let d = new Date().getTime(),
    d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
};

type ArrayElement = {
  [key: string]: number | string | boolean | null | undefined | Array<unknown>;
};
export const getRandomElement: (array: ArrayElement[]) => ArrayElement | undefined = (array) => {
  return array[(Math.random() * array.length) | 0];
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const generatePassword = (length: number = 10) =>
  new Array(length)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
    .map((x) =>
      (function (chars) {
        let umax = Math.pow(2, 32),
          r = new Uint32Array(1),
          max = umax - (umax % chars.length);
        do {
          crypto.getRandomValues(r);
        } while (r[0] > max);
        return chars[r[0] % chars.length];
      })(x),
    )
    .join('');

export const fancyTimeFormat = (seconds: number) => {
  // Hours, minutes and seconds
  const hrs = ~~(seconds / 3600);
  const mins = ~~((seconds % 3600) / 60);
  const secs = ~~seconds % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;

  return ret;
};
