import React, { ChangeEvent, useMemo } from 'react';
import {
  StyledChevronLeft,
  StyledChevronRight,
  StyledDatePickerHeader,
  StyledHeader,
  StyledMonthYear,
  StyledSelect,
  StyledSwitchMonthButton,
  StyledYearMonthSelect,
} from './styled';
import { useGetMonths, useGetYears } from '../../../hooks';
import { format } from 'date-fns';

type IDatepickerHeader = {
  date: Date;
  changeYear: Function;
  changeMonth: Function;
  decreaseMonth: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  increaseMonth: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

const DatepickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: IDatepickerHeader) => {
  const months = useGetMonths('LLLL', true);
  const years = useGetYears(1900, 2100);

  const currentMonth = useMemo(() => date.getMonth(), [date]);
  const currentYear = useMemo(() => date.getFullYear(), [date]);

  const yearChanged = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    changeYear(value);
  };

  const monthChanged = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    changeMonth(value);
  };

  const formattedDate = useMemo(() => {
    return format(date, 'LLLL yyyy');
  }, [date]);

  return (
    <StyledDatePickerHeader>
      <StyledHeader>
        <StyledSwitchMonthButton
          noShadow
          isDisabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
        >
          <StyledChevronLeft type={'ChevronLeft'} />
        </StyledSwitchMonthButton>
        <StyledMonthYear>{formattedDate}</StyledMonthYear>
        <StyledSwitchMonthButton
          noShadow
          isDisabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
        >
          <StyledChevronRight type={'ChevronLeft'} />
        </StyledSwitchMonthButton>
      </StyledHeader>
      <StyledYearMonthSelect>
        <StyledSelect
          options={months}
          value={currentMonth}
          onChange={monthChanged}
          name={'month'}
        />
        <StyledSelect options={years} value={currentYear} onChange={yearChanged} name={'year'} />
      </StyledYearMonthSelect>
    </StyledDatePickerHeader>
  );
};

export default DatepickerHeader;
