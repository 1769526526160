import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';

export const StyledBreadcrumbs = styled(Flex)`
  align-items: center;
  grid-gap: 0.5rem;
  margin-bottom: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.secondary};

  > a {
    color: ${({ theme }) => theme.palette.primary};
  }
`;
