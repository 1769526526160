import styled from '@emotion/styled';

export const StyledCarousel = styled.div`
  width: 100%;
  padding-bottom: 1rem;

  .slick-dots {
    li {
      margin-inline: 3px;
      box-shadow: none;
      background: ${({ theme }) => theme.palette.neutral10};
      opacity: 1;
      border-radius: ${({ theme }) => theme.radii.sm};
      width: 0.5rem;
      height: 0.5rem;
      transition: 0.3s ease-in-out;

      button:before {
        content: '';
      }
    }

    .slick-active {
      background: ${({ theme }) => theme.palette.primary};
    }
  }
`;
