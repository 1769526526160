import React from 'react';
import { Button } from '../../../../../shared/components/ui';
import { StyledVarsArray } from '../styled';
import { ButtonSizes } from '../../../../../shared/theme';

const VariablesButtons = ({ variables, onAdded }: { variables: string[]; onAdded: Function }) => {
  const addValue = (variable: string) => () => {
    onAdded(`{{${variable}}}`);
  };

  return (
    <StyledVarsArray>
      {variables.map((variable) => (
        <Button noShadow size={ButtonSizes.sm} key={variable} onClick={addValue(variable)}>
          {variable}
        </Button>
      ))}
    </StyledVarsArray>
  );
};

export default VariablesButtons;
