import React from 'react';
import { useOrderContext } from '../../hooks/useOrderContext';
import {
  StyledHeader,
  StyledPanel,
  StyledPostcardImage,
  StyledPostcardText,
  StyledPostcardWrapper,
} from '../../styled';
import { LinkBox, LinkOverlay } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const PostCard = () => {
  const { t } = useScopedTranslation('order');
  const { order } = useOrderContext();
  const postcard = order?.postcard;

  return (
    (postcard && (
      <LinkBox>
        <StyledPanel>
          <StyledHeader>{t('postcard')}</StyledHeader>
          <StyledPostcardWrapper>
            <StyledPostcardImage src={postcard.image_url} />
            <div>
              <LinkOverlay as={Link} target={'_blank'} to={postcard.share_url}>
                <h3>{postcard.title}</h3>
              </LinkOverlay>
              <StyledPostcardText noOfLines={4}>{postcard.text}</StyledPostcardText>
            </div>
          </StyledPostcardWrapper>
        </StyledPanel>
      </LinkBox>
    )) || <></>
  );
};

export default PostCard;
