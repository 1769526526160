/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgMic = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_761_23093)">
      <path
        d="M20.5767 5C20.9232 5.07326 21.2744 5.12974 21.6148 5.22285C24.2198 5.93259 26.0484 8.26331 26.0607 10.9306C26.0761 14.4534 26.0808 17.9762 26.0592 21.499C26.0399 24.5814 23.6062 27.1098 20.4462 27.3799C17.4182 27.6394 14.5909 25.4812 14.0383 22.4903C13.9688 22.1148 13.9317 21.7371 13.9325 21.3555C13.9333 17.9182 13.9287 14.4816 13.9341 11.0443C13.9387 7.95805 16.1609 5.46248 19.2576 5.04884C19.3131 5.04121 19.3664 5.01679 19.4212 5C19.8063 5 20.1915 5 20.5767 5ZM15.6661 16.172C15.6661 17.8754 15.6661 19.5788 15.6661 21.2822C15.6661 21.5295 15.6808 21.7752 15.7147 22.0217C16.0165 24.1922 18.0064 25.7926 20.2633 25.6743C22.4523 25.5598 24.3047 23.7221 24.3225 21.5593C24.3518 17.9815 24.3534 14.403 24.3217 10.8253C24.3024 8.61895 22.3689 6.75987 20.1398 6.71561C17.649 6.666 15.6746 8.57011 15.6676 11.0329C15.6622 12.7462 15.6661 14.4587 15.6661 16.172Z"
        {...props}
      />
      <path
        d="M19.984 34.2483C18.8386 34.2483 17.6931 34.2529 16.5477 34.246C16.0753 34.2429 15.7102 33.9049 15.6709 33.4622C15.6338 33.044 15.9209 32.6563 16.3486 32.5586C16.4497 32.5357 16.5585 32.5357 16.6635 32.5357C17.4045 32.5342 18.1462 32.5273 18.8872 32.5396C19.0948 32.5434 19.1458 32.4793 19.1388 32.2831C19.1242 31.8649 19.1249 31.4452 19.1388 31.027C19.145 30.8362 19.0894 30.7804 18.8911 30.7507C14.9978 30.1661 12.3496 28.0498 10.9733 24.4004C10.6214 23.4662 10.4801 22.4863 10.4701 21.4896C10.4647 20.9371 10.8205 20.5456 11.3253 20.5395C11.8309 20.5334 12.1898 20.915 12.2029 21.4698C12.2917 25.3368 15.0534 28.4505 18.9103 29.0328C23.0182 29.6532 27.0504 26.7372 27.6894 22.6855C27.7551 22.2711 27.796 21.8559 27.8029 21.4362C27.8122 20.9073 28.1881 20.5303 28.6828 20.5387C29.1768 20.5471 29.5326 20.9348 29.5342 21.4652C29.5442 26.032 25.989 30.0837 21.4227 30.711C20.8693 30.7873 20.8693 30.7873 20.8693 31.346C20.8693 31.6695 20.8801 31.9939 20.8655 32.3167C20.857 32.4961 20.9172 32.5411 21.0932 32.5388C21.8442 32.5296 22.5952 32.5342 23.3454 32.535C23.9359 32.5357 24.3296 32.8738 24.335 33.3821C24.3404 33.901 23.9429 34.2475 23.3354 34.2483C22.217 34.2491 21.1001 34.2483 19.984 34.2483Z"
        {...props}
      />
      <path
        d="M7.00047 14.4837C7.00047 13.371 6.99893 12.2591 7.00125 11.1464C7.00202 10.59 7.35476 10.2039 7.85878 10.1993C8.36357 10.1939 8.73252 10.5809 8.73329 11.1296C8.73561 13.3641 8.73638 15.5987 8.73329 17.8333C8.73252 18.5193 8.11889 18.9574 7.52688 18.6934C7.16719 18.5331 7.00047 18.24 7.00047 17.85C7.00125 16.7274 7.00047 15.6056 7.00047 14.4837Z"
        {...props}
      />
      <path
        d="M32.9989 14.4834C32.9989 15.5862 32.9997 16.689 32.9982 17.7925C32.9974 18.3741 32.6524 18.7625 32.1391 18.7671C31.6243 18.7717 31.2661 18.3802 31.2661 17.8063C31.2646 15.5908 31.2646 13.3753 31.2661 11.1598C31.2661 10.5859 31.6251 10.1952 32.1399 10.1997C32.6532 10.2043 32.9982 10.5928 32.9989 11.1743C32.9997 12.2771 32.9989 13.3799 32.9989 14.4834Z"
        {...props}
      />
      <path
        d="M10.4663 14.4748C10.4663 13.9238 10.4632 13.3728 10.4671 12.8217C10.4717 12.2944 10.8391 11.9128 11.3331 11.9136C11.8271 11.9143 12.1953 12.2952 12.1976 12.824C12.2014 13.9261 12.2014 15.0281 12.1976 16.1301C12.196 16.675 11.8209 17.0619 11.3169 17.0528C10.8237 17.0436 10.4701 16.662 10.4663 16.1278C10.464 15.5768 10.4663 15.0258 10.4663 14.4748Z"
        {...props}
      />
      <path
        d="M29.5313 14.4845C29.5313 15.0355 29.5351 15.5866 29.5305 16.1376C29.5266 16.668 29.1631 17.0488 28.6699 17.0518C28.1759 17.0549 27.8015 16.6733 27.8 16.1475C27.7961 15.0355 27.7954 13.9244 27.8 12.8124C27.8023 12.2889 28.1805 11.9073 28.6745 11.9127C29.1569 11.918 29.5243 12.2935 29.5305 12.8025C29.5367 13.3634 29.5313 13.9244 29.5313 14.4845Z"
        {...props}
      />
    </g>
    <defs>
      <clipPath id="clip0_761_23093">
        <rect width="26" height="29.25" fill="white" transform="translate(7 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMic;
