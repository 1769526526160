import { useEffect, useState } from 'react';
import useInfiniteScrollItems from '../../../shared/hooks/useInfiniteScrollItems';
import { MailingApi } from '../../../api';
import { MailingLetter } from '../../../shared/types/mailing';

const useLoadLetters = (limit: number = 15, search: string = '') => {
  const [isLoading, setIsLoading] = useState(false);
  const { items, hasMore, setHasMore, appendItems, resetItems } = useInfiniteScrollItems(limit);

  const getLetters = (reset: boolean = false) => {
    if (isLoading) return;

    setIsLoading(true);

    if (reset) resetItems();

    const offset = (!reset && items.length) || 0;

    MailingApi.getLetters({ limit, offset, search })
      .then((response) => {
        appendItems(response ?? [], reset);
        setIsLoading(false);
      })
      .catch(() => {
        setHasMore(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getLetters(true);
  }, [limit, search]);

  return { getLetters, isLoading, letters: items as MailingLetter[], hasMore };
};

export default useLoadLetters;
