import React from 'react';
import { User } from '../types/user';
import { Language } from '../types/language';

export type IAppContext = {
  fluid: boolean;
  setFluid: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarCollapsed: boolean;
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  accessToken: string;
  setAccessToken: React.Dispatch<React.SetStateAction<string>>;
  language: Language | null;
  setLanguage: React.Dispatch<React.SetStateAction<Language | null>>;
};

export const defaultState = {
  fluid: false,
  setFluid: () => {},
  sidebarCollapsed: false,
  setSidebarCollapsed: () => {},
  user: null,
  setUser: () => {},
  accessToken: '',
  setAccessToken: () => {},
  language: null,
  setLanguage: () => {},
};

export const AppContext = React.createContext<IAppContext>(defaultState);

export const AppContextProvider = AppContext.Provider;
