import * as Yup from 'yup';

export const CreateTemplateScheme = Yup.object().shape({
  name: Yup.string().trim().label('Name').required().min(1),
});

export const UpdateTemplateScheme = Yup.object().shape({
  name: Yup.string().trim().label('Name').required().min(1),
  intro_gain: Yup.number().label('Intro gain'),
  intro_length: Yup.number().label('Intro length'),
  tag_padding: Yup.number().label('Tag padding'),
  tag_gain: Yup.number().label('Tag gain'),
  enabled: Yup.boolean().label('Enabled').required(),
  segments_ids: Yup.array().label('Segments').required().min(1),
  genres: Yup.string().trim().label('Genres'),
});

export const PersonalizationTemplateScheme = Yup.object().shape({
  gain: Yup.number().label('Gain'),
  back: Yup.boolean(),
});
