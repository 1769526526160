import React from 'react';
import { Button } from '../../ui';
import { Icon } from '../../icons';
import { StyledSelectedSegmentCard } from './styled';
import { Segment } from '../../../types/production/segment';

const SelectedSegmentCard = ({
  segment,
  onRemove,
}: {
  segment: Segment;
  onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const { name } = segment;

  return (
    <StyledSelectedSegmentCard role={'button'}>
      <span>{name}</span>
      <Button onClick={onRemove} fab noShadow color={'primary'}>
        <Icon type={'Close'} size={'xs'} color={'white'} />
      </Button>
    </StyledSelectedSegmentCard>
  );
};

export default SelectedSegmentCard;
