import React, { useState } from 'react';
import {
  StyledArtistAvatar,
  StyledArtistCard,
  StyledArtistDescription,
  StyledArtistInfo,
  StyledArtistName,
  StyledHeaderWrapper,
} from './styled';
import { Artist } from '../../../types/production/artist';
import {
  Flex,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { DotsMenuButton, Switch } from '../../ui';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '../ConfirmDialog';
import { useNavigateWithParams, useScopedTranslation } from '../../../hooks';
import { ProductionPaths } from '../../../../router/routes';
import useHandleArtistApi from '../../../requests/production/useHandleArtistApi';

const CardArtist = ({
  artist,
  onDelete,
  isEdit = false,
  ...props
}: {
  artist: Artist;
  onDelete: Function;
  isEdit?: boolean;
}) => {
  const { t } = useScopedTranslation('production.artists');

  const { id, name, description, photo_url_thumb: photoUrl } = artist;
  const { urlWithParams } = useNavigateWithParams();
  const urlToPage = urlWithParams(ProductionPaths.artistPage, {
    artistId: id.toString(),
  });

  const { updateArtist, deleteArtist, isLoading } = useHandleArtistApi();

  const [enabled, setEnabled] = useState(artist.enabled);

  const toggleStatus = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEnabled(!enabled);
    updateArtist(artist, { enabled: !artist.enabled, gender: artist.gender });
  };

  const deleteConfirmed = () => {
    onDelete(artist);
    deleteArtist(artist);
  };

  return (
    <LinkBox>
      <StyledArtistCard {...{ ...props }}>
        <StyledHeaderWrapper>
          <StyledArtistAvatar enabled={enabled} src={photoUrl} name={name} />
          <StyledArtistInfo>
            <LinkOverlay as={Link} to={urlToPage}>
              <StyledArtistName>{name}</StyledArtistName>
            </LinkOverlay>
            <StyledArtistDescription noOfLines={2}>{description}</StyledArtistDescription>
          </StyledArtistInfo>
        </StyledHeaderWrapper>
        <Menu placement={'bottom-end'}>
          <MenuButton style={{ zIndex: 3 }}>
            <DotsMenuButton isLoading={isLoading} />
          </MenuButton>
          <MenuList zIndex={4}>
            <MenuItem as={Link} to={urlToPage}>
              {t('open')}
            </MenuItem>
            {isEdit && (
              <>
                <MenuItem onClick={toggleStatus}>
                  <Flex justifyContent={'space-between'} w={'100%'}>
                    <span>{t((enabled && 'enabled') || 'disabled')}</span>
                    <Switch isChecked={enabled} />
                  </Flex>
                </MenuItem>
                <MenuDivider />
                <ConfirmDialog
                  title={t('delete_confirm')}
                  onConfirm={deleteConfirmed}
                  confirmLabel={t('delete')}
                  activator={<MenuItem>{t('delete')}</MenuItem>}
                />
              </>
            )}
          </MenuList>
        </Menu>
      </StyledArtistCard>
    </LinkBox>
  );
};

export default CardArtist;
