import React from 'react';
import {
  StyledGeneralInfoWrapper,
  StyledInfoWrapper,
  StyledPageWrapper,
  StyledPersonalizationEditor,
} from './styled';
import { Divider, Flex, Skeleton } from '@chakra-ui/react';
import PersonalizationTypeRowSkeleton from './components/PersonalizationTypeRowSkeleton';
import { SkeletonRepeater } from '../../../shared/components/custom';

const SegmentPage = () => {
  return (
    <StyledPageWrapper>
      <StyledInfoWrapper>
        <Skeleton w={'10rem'} h={'1rem'} mb={'1rem'} />
        <StyledGeneralInfoWrapper>
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'10rem'} h={'3rem'} />
          <div />
          <Skeleton w={'6rem'} h={'3rem'} />
          <Skeleton w={'4rem'} h={'3rem'} />
        </StyledGeneralInfoWrapper>
      </StyledInfoWrapper>
      <Divider />
      <StyledInfoWrapper>
        <Skeleton w={'7rem'} h={'1rem'} mb={'1rem'} />
        <Flex gap={'0.5rem'}>
          <SkeletonRepeater number={3}>
            <Skeleton w={'4rem'} h={'3rem'} />
          </SkeletonRepeater>
        </Flex>
      </StyledInfoWrapper>
      <Divider />
      <StyledInfoWrapper>
        <Skeleton w={'12rem'} h={'1rem'} mb={'1rem'} />
        <Flex gap={'0.5rem'}>
          <SkeletonRepeater number={10}>
            <Skeleton w={'4rem'} h={'3rem'} />
          </SkeletonRepeater>
        </Flex>
      </StyledInfoWrapper>
      <Divider />
      <StyledInfoWrapper>
        <Skeleton w={'18rem'} h={'1rem'} mb={'1rem'} />
        <StyledPersonalizationEditor>
          <SkeletonRepeater number={7}>
            <PersonalizationTypeRowSkeleton />
          </SkeletonRepeater>
        </StyledPersonalizationEditor>
      </StyledInfoWrapper>
    </StyledPageWrapper>
  );
};

export default SegmentPage;
