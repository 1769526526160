import { useEffect, useState } from 'react';
import { RoleApi } from '../../../api';
import { Permission } from '../../types/role';

const useLoadPermissions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const getPermissions = async () => {
    setIsLoading(true);
    await RoleApi.getPermissions()
      .then(setPermissions)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return { getPermissions, setPermissions, isLoading, permissions };
};

export default useLoadPermissions;
