import ReactQuill from 'react-quill';
import styled from '@emotion/styled';

export const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar.ql-snow {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #edeef1;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ql-container.ql-snow {
    border: 1px solid #edeef1;
    border-radius: 8px;
  }

  .ql-editor {
    font-size: 0.875rem;
    color: #12203be5;
    font-family: 'GothamPro-Medium', sans-serif;
    font-weight: 500;
    min-height: 8rem;
  }
`;
