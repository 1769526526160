import React, { useState } from 'react';
import {
  StyledDangerHeader,
  StyledDangerZone,
  StyledInlineWrapper,
  StyledRandomButton,
} from '../styled';
import { Icon } from '../../../../../shared/components/icons';
import { Button, Input, showToast } from '../../../../../shared/components/ui';
import { copyToClipBoard, generatePassword } from '../../../../../shared/utils/utils';
import { ButtonSizes } from '../../../../../shared/theme';
import useUserContext from '../hooks/useUserContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { UpdatePasswordScheme } from '../../../validation';
import { ControlPaths } from '../../../../../router/routes';
import { ConfirmDialog } from '../../../../../shared/components/custom';
import { useNavigateWithParams, useScopedTranslation } from '../../../../../shared/hooks';

const DangerZone = () => {
  const { t } = useScopedTranslation('users.page');

  const { navigate } = useNavigateWithParams();
  const { user, deleteUser, updateUser } = useUserContext();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    register,
    reset,
    formState: { isValid },
    handleSubmit,
  } = useForm<{ password: string }>({
    resolver: yupResolver(UpdatePasswordScheme),
  });

  const createPassword = () => {
    const password = generatePassword(16);
    reset({ password });
  };

  const updatePassword: SubmitHandler<{ password: string }> = async (args) => {
    setIsUpdating(true);
    const updated = await updateUser({
      ...args,
      name: user?.name,
      email: user?.email,
      password_confirmation: args.password,
    });
    setIsUpdating(false);
    if (!!updated) {
      copyToClipBoard(args.password);
      showToast('success', t('password_copied'));
      reset({ password: '' });
    }
  };

  const onDeleteClick = async () => {
    setIsDeleting(true);
    await deleteUser();
    setIsDeleting(false);
    navigate(ControlPaths.users);
  };

  return (
    <StyledDangerZone>
      <StyledDangerHeader>{t('danger_zone')}</StyledDangerHeader>
      <form onSubmit={handleSubmit(updatePassword)}>
        <StyledInlineWrapper>
          <label>{t('reset_password')}</label>
          <Input
            name={'password'}
            register={register}
            placeholder={t('create_password')}
            inputRightElement={
              <StyledRandomButton
                fab
                noShadow
                title={t('random')}
                isLoading={isDeleting}
                onClick={createPassword}
              >
                <Icon type={'Dice'} />
              </StyledRandomButton>
            }
          />
          <Button
            isDisabled={!isValid}
            isLoading={isUpdating}
            color={'secondary'}
            size={ButtonSizes.sm}
            type={'submit'}
            noShadow
          >
            {t('reset')}
          </Button>
        </StyledInlineWrapper>
      </form>

      <StyledInlineWrapper>
        <label>{t('delete_user')}</label>
        <div>
          <ConfirmDialog
            title={t('delete_confirm')}
            onConfirm={onDeleteClick}
            activator={
              <Button color={'error'} size={ButtonSizes.sm} noShadow>
                {t('delete')}
              </Button>
            }
          />
        </div>
      </StyledInlineWrapper>
    </StyledDangerZone>
  );
};

export default DangerZone;
