import { httpDelete, httpGet, httpPost, httpPostFormData, httpPut } from '../../shared/utils/http';
import {
  Option,
  OptionNames,
  Order,
  OrderInfo,
  OrdersListRequest,
} from '../../shared/types/orders';
import { ORDERS_ROUTE } from '../constants';
import { objectToFormData } from '../../shared/utils/utils';
import { Personalization } from '../../shared/types/production/personalization';
import { Segment } from '../../shared/types/production/segment';
import { SlidesPhotosResponse } from '../../shared/types/slidesPhoto';

export type OrdersApiType = {
  get: (filters: OrdersListRequest) => Promise<{ data: Order[]; count: number }>;
  find: (orderId: string) => Promise<Order>;
  update: (orderId: string, data: Object) => Promise<Order>;
  speedUp: (orderId: string, value: number) => Promise<{ link: string }>;
  addOptions: (
    orderId: string,
    data: { success_url?: string; cancel_url?: string; options: OptionNames[] },
  ) => Promise<{ payment_url: string }>;
  addOptionsSilent: (orderId: string, options: OptionNames[]) => Promise<Option[]>;
  removeOption: (orderId: string, option: OptionNames) => Promise<string>;
  getPhotos: (orderId: string) => Promise<SlidesPhotosResponse[]>;
  uploadPhoto: (
    orderId: string,
    data: { photo: File; order: string },
  ) => Promise<SlidesPhotosResponse>;
  syncSlideshowPhotos: (
    orderId: string,
    data: { photos: { id: string | number; order: number }[] },
  ) => Promise<SlidesPhotosResponse[]>;
  deletePhoto: (orderId: string, photoId: string | number) => Promise<string>;
  personalizations: (data: Record<string, string>) => Promise<Personalization[]>;
  segments: (data: Record<string, string>) => Promise<Segment[]>;
  updateInfo: (orderId: string, data: Record<string, string>) => Promise<OrderInfo>;
  remake: (orderId: string) => Promise<Order>;
  remakeClientVoice: (orderId: string) => Promise<Option[]>;
  remakeSlideshow: (orderId: string) => Promise<Option>;
  uploadVoice: (orderId: string, file: File) => Promise<Order>;
  paymentSuccessful: (orderId: string, txnId: string) => Promise<never>;
};

export const OrdersApi: OrdersApiType = {
  get: (filters) => {
    return httpGet(`${ORDERS_ROUTE}`, filters);
  },
  find: (orderId) => {
    return httpGet(`${ORDERS_ROUTE}/${orderId}`);
  },
  update: (orderId, data) => {
    return httpPut(`${ORDERS_ROUTE}/${orderId}`, data);
  },
  speedUp: (orderId, value) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/payment/speed-up`, { speed_up: value });
  },
  addOptions: (orderId, data) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/payment/options`, data);
  },
  addOptionsSilent: (orderId, options) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/options/silent`, { options });
  },
  removeOption: (orderId, option) => {
    return httpDelete(`${ORDERS_ROUTE}/${orderId}/options`, { name: option });
  },
  getPhotos: (orderId) => {
    return httpGet(`${ORDERS_ROUTE}/${orderId}/photos`);
  },
  uploadPhoto: (orderId, data) => {
    return httpPostFormData(`${ORDERS_ROUTE}/${orderId}/photos`, objectToFormData(data));
  },
  syncSlideshowPhotos(orderId, data) {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/photos/sync`, data);
  },
  deletePhoto: (orderId, photoId) => {
    return httpDelete(`${ORDERS_ROUTE}/${orderId}/photos/${photoId}`);
  },
  personalizations: (data) => {
    return httpGet(`${ORDERS_ROUTE}/personalizations`, data);
  },
  segments: (params) => {
    return httpGet(`${ORDERS_ROUTE}/segments`, params);
  },
  updateInfo: (orderId, data) => {
    return httpPut(`${ORDERS_ROUTE}/${orderId}/info`, data);
  },
  remake: (orderId) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/remake`, {});
  },
  remakeClientVoice: (orderId) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/remake/voice`, {});
  },
  remakeSlideshow: (orderId) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/remake/video`, {});
  },
  uploadVoice: (orderId, file) => {
    return httpPostFormData(
      `${ORDERS_ROUTE}/${orderId}/client-voice`,
      objectToFormData({ voice: file }),
    );
  },
  paymentSuccessful: (orderId, txnId) => {
    return httpPost(`${ORDERS_ROUTE}/${orderId}/payment/success/${txnId}`, {});
  },
};
