import { CommonPaths, Paths } from '../../router/routes';
import { useNavigateWithParams } from './useNavigateWithParams';

const useRedirectOnError = (path: Paths = CommonPaths.dashboard) => {
  const { navigate } = useNavigateWithParams();

  return (error: { message: string; status: number }) => {
    if ([403, 404].includes(error.status)) navigate(path);
  };
};

export default useRedirectOnError;
