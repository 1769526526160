import { ButtonSizes, designSystem } from '../../../theme';
import { css } from '@emotion/react';

export const mapButtonSizes = (size: ButtonSizes) =>
  ({
    [ButtonSizes.sm]: css`
      height: 3rem;
      font-size: ${designSystem.fontSize.xs};
    `,
    [ButtonSizes.md]: css`
      height: 3.75rem;
    `,
  }[size] || css``);
