import styled from '@emotion/styled';

export const StyledSelectedWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  flex-wrap: wrap;

  > p:last-of-type {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;
