import React, { useState } from 'react';
import { ListNoItems, SkeletonRepeater } from '../../../../shared/components/custom';
import useLoadUsers from '../../requests/useLoadUsers';
import UserRow from './components/UserRow';
import { StyledUsersHeader, StyledUsersWrapper } from './styled';
import UserRowSkeleton from './components/UserRowSkeleton';
import UsersListFilters from './components/UsersListFilters';
import { User, UsersFilters } from '../../../../shared/types/user';
import { useScopedTranslation } from '../../../../shared/hooks';

const UsersList = () => {
  const { t } = useScopedTranslation('users.list');

  const [params, setParams] = useState<UsersFilters>({});
  const { users, getUsers, hasMore, isLoading, setUsers } = useLoadUsers({
    limit: 15,
    ...params,
  });

  const loadMore = () => {
    getUsers();
  };

  const onUserAdded = (user: User) => {
    setUsers([user, ...users]);
  };

  return (
    <>
      <UsersListFilters setParams={setParams} onUserAdded={onUserAdded} />
      <StyledUsersHeader>
        <p>{t('info')}</p>
        <p>{t('role')}</p>
        <p>{t('actions')}</p>
      </StyledUsersHeader>
      <StyledUsersWrapper loadMore={loadMore} initialLoad={false} hasMore={hasMore}>
        {(!!users.length && users.map((user) => <UserRow user={user} key={user.id} />)) ||
          (!isLoading && <ListNoItems title={t('no_users')} query={params.search} />)}
        {isLoading && (
          <SkeletonRepeater key={0} number={4}>
            <UserRowSkeleton />
          </SkeletonRepeater>
        )}
      </StyledUsersWrapper>
    </>
  );
};

export default UsersList;
