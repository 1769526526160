import React, { useEffect } from 'react';
import { Button, Switch } from '../../../../shared/components/ui';
import {
  StyledPersonalizationRow,
  StyledPersonalizationInput,
  StyledPersonalizationIndex,
  StyledDeleteButton,
  StyledSynonymsButton,
} from '../styled';
import { ButtonSizes } from '../../../../shared/theme';
import { Spacer, Icon as ChakraIcon } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddPersonalizationSchema } from '../validationScheme';
import { Personalization } from '../../../../shared/types/production/personalization';
import { ConfirmDialog } from '../../../../shared/components/custom';
import { Icon } from '../../../../shared/components/icons';
import useHandlePersonalizationUpdate from '../requests/useHandlePersonalizationUpdate';
import { useBreakpoints, useScopedTranslation } from '../../../../shared/hooks';
import SynonymsModal from './SynonymsModal';
import useSortableList from '../../../../shared/hooks/useSortableList';
import { MdDragIndicator } from 'react-icons/md';
import { StyledDragHandle } from '../../segments/styled';
import { BiDuplicate } from 'react-icons/bi';

type IPersonalizationRow = {
  onUpdate: Function;
  onDelete: Function;
  personalization: Personalization;
  index: number;
  isEdit?: boolean;
  isSortable?: boolean;
};

const PersonalizationRow = ({
  index,
  personalization,
  onUpdate,
  onDelete,
  isEdit = false,
  isSortable = true,
}: IPersonalizationRow) => {
  const { t } = useScopedTranslation('production.personalization');
  const synonyms = personalization.synonyms.length;
  const { attributes, listeners, setNodeRef, style } = useSortableList(personalization);
  const { isMd } = useBreakpoints();

  const { isLoading, deletePersonalization, updatePersonalization } =
    useHandlePersonalizationUpdate();

  const { register, reset, formState, handleSubmit } = useForm({
    resolver: yupResolver(AddPersonalizationSchema),
  });
  const { isValid, isDirty } = formState;

  useEffect(() => {
    const { name: alias, visible } = personalization;
    reset({ alias, visible });
  }, [personalization]);

  const onFormSubmit = async (args: Object) => {
    const updated = await updatePersonalization(personalization, args);
    onUpdate(updated);
  };

  const onRowDelete = async () => {
    await deletePersonalization(personalization);
    onDelete(personalization);
  };

  return (
    <div ref={setNodeRef} {...{ style }}>
      <StyledPersonalizationRow>
        {isSortable && <StyledDragHandle as={MdDragIndicator} {...attributes} {...listeners} />}
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <StyledPersonalizationIndex>{index}</StyledPersonalizationIndex>
          <StyledPersonalizationInput
            name={'alias'}
            isReadOnly={!isEdit}
            placeholder={t('enter_alias')}
            {...{ register }}
          />
          <Switch isReadOnly={!isEdit} name={'visible'} {...{ register }}>
            {t('visible')}
          </Switch>
          <Spacer />
          {isDirty && isEdit && (
            <Button
              isLoading={isLoading}
              type={'submit'}
              isDisabled={!isValid}
              color={'primary'}
              noShadow
              size={ButtonSizes.sm}
            >
              {t('save')}
            </Button>
          )}
        </form>
        <SynonymsModal
          personalization={personalization}
          onUpdate={onUpdate}
          isEdit={isEdit}
          activator={
            <StyledSynonymsButton
              isDisabled={!isEdit && !synonyms}
              type={'button'}
              noShadow
              size={ButtonSizes.sm}
            >
              {(!isMd && <ChakraIcon as={BiDuplicate} />) || t('synonyms')}
              {!!synonyms && <span>{synonyms}</span>}
            </StyledSynonymsButton>
          }
        />
        {isEdit && (
          <ConfirmDialog
            title={t('delete_confirm')}
            activator={
              <StyledDeleteButton
                isDisabled={isLoading}
                type={'button'}
                noShadow
                size={ButtonSizes.sm}
                fab
              >
                <Icon type={'Trashcan'} />
              </StyledDeleteButton>
            }
            onConfirm={onRowDelete}
          />
        )}
      </StyledPersonalizationRow>
    </div>
  );
};

export default PersonalizationRow;
