import { useAppContext } from './useAppContext';
import { appLocalStorage } from '../utils/appLocalStorage';
import { AUTH_TOKEN_KEY } from '../constants';

const useStoredAccessToken = () => {
  const { accessToken } = useAppContext();

  return appLocalStorage.getItem(AUTH_TOKEN_KEY) || accessToken;
};

export default useStoredAccessToken;
