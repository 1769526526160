import { useState } from 'react';
import { UserApi } from '../../../api';
import { CreateUserRequestData, User } from '../../types/user';
import useRedirectOnError from '../../hooks/useRedirectOnError';
import { ControlPaths } from '../../../router/routes';

const useUserApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User>();
  const redirect = useRedirectOnError(ControlPaths.users);

  const loadUser = async (userId: number) => {
    setIsLoading(true);
    const r = await UserApi.find(userId)
      .then((response) => {
        setUser(response);
        return response;
      })
      .catch((error) => {
        redirect(error);
        return null;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return r;
  };

  const createUser = async (data: CreateUserRequestData) => {
    setIsLoading(true);
    const r = await UserApi.create(data)
      .then((response) => response)
      .catch(() => null)
      .finally(() => {
        setIsLoading(false);
      });
    return r;
  };

  const updateUser = async (data: CreateUserRequestData) => {
    if (!user) return;
    setIsLoading(true);
    const r = await UserApi.update(user.id, data)
      .then((response) => {
        setUser(response);
        return response;
      })
      .catch(() => null)
      .finally(() => {
        setIsLoading(false);
      });
    return r;
  };

  const deleteUser = async () => {
    if (!user) return;
    setIsLoading(true);
    const r = await UserApi.delete(user.id)
      .then((response) => response)
      .catch(() => null)
      .finally(() => {
        setIsLoading(false);
      });
    return r;
  };

  return { loadUser, createUser, deleteUser, isLoading, user, setUser, updateUser };
};

export default useUserApi;
