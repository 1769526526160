import { Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const StyledSliderWrapper = styled.div``;

export const StyledSliderMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const StyledCurrentValue = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  padding: 0.45rem 0.75rem;
  border: 0.063rem solid ${({ theme }) => theme.palette.neutral60};
  border-radius: ${({ theme }) => theme.radii.sm};
  min-width: 3.75rem;
  text-align: center;
`;

export const StyledSlider = styled(Slider)`
  margin-top: 1.75rem;
`;

export const StyledSliderTrack = styled(SliderTrack)`
  height: 0.125rem;
  background: ${({ theme }) => theme.palette.neutral40};
`;

export const StyledSliderFilledTrack = styled(SliderFilledTrack)`
  background: ${({ theme }) => theme.palette.primary};
`;

export const StyledSliderThumb = styled(SliderThumb)`
  width: 1.5rem;
  height: 1.5rem;
  background: ${({ theme }) => theme.palette.white};
  border: 0.125rem solid ${({ theme }) => theme.palette.primary};
  border-radius: ${({ theme }) => theme.radii.md};
`;
