export enum Permissions {
  generationEdit = 'generation-edit',
  generationView = 'generation-view',
  invoiceView = 'invoice-view',
  mailingView = 'mailing-view',
  mailingEdit = 'mailing-edit',
  orderEdit = 'order-edit',
  orderView = 'order-view',
  paymentEdit = 'payment-edit',
  paymentView = 'payment-view',
  roleEdit = 'role-edit',
  roleView = 'role-view',
  userEdit = 'user-edit',
  userView = 'user-view',
  dashboardView = 'dashboard-view',
}
