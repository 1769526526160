import { useLocation } from 'react-router-dom';
import { useScrollTo } from '../../shared/hooks';
import { useCheckAuth } from './useCheckAuth';

export const useRouteGuard = () => {
  useCheckAuth();

  const { pathname } = useLocation();
  useScrollTo({ deps: [pathname] });
};
