import { Switch } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const StyledSwitch = styled(Switch)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .chakra-switch__track {
    width: 2.25rem;
    height: 1.25rem;

    &[data-checked] {
      background: ${({ theme }) => theme.palette.success};
    }

    .chakra-switch__thumb {
      width: 1rem;
      height: 1rem;
      margin-top: 0.15rem;
      margin-left: 0.2rem;
    }

    &:focus,
    &[data-focus] {
      box-shadow: none;
    }
  }

  .chakra-switch__label {
    margin-left: 0.75rem;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
  }
`;
