import { useEffect, useMemo, useRef, useState } from 'react';
import { isIOS } from '../../../../utils/utils';
import { Events } from '../../../../constants/Events';

const useAudioPlayer = (src: string, startOnInit: boolean = false) => {
  const [id] = useState(Math.random().toString(16).slice(2));
  const [audio] = useState<HTMLAudioElement>(new Audio());
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [iOS] = useState(isIOS());

  useEffect(() => {
    audio.src = src;
    audio.preload = 'metadata';
    audio.load();
    if (startOnInit) setTimeout(fireStartEvent, 400);
  }, [src]);

  const playingRef = useRef(playing);
  const setPlayingRef = (state: boolean) => {
    playingRef.current = state;
    setPlaying(state);
  };

  useEffect(() => {
    window.addEventListener(
      `audiostatechanged-${id}`,
      (e: CustomEventInit & Event) => {
        e.stopImmediatePropagation();
        const { state } = e.detail;
        setPlayingRef(state);
      },
      false,
    );

    return () => {
      window.removeEventListener(`${Events.audioStateChanged}-${id}`, () => {});
      window.dispatchEvent(new CustomEvent(Events.audioDestroyed, { detail: { id } }));
      audio.srcObject = null;
    };
  }, [id]);

  const fireStartEvent = () => {
    window.dispatchEvent(new CustomEvent(Events.audioStarted, { detail: { id } }));
  };

  const fireStopEvent = () => {
    window.dispatchEvent(new CustomEvent(Events.audioStopped, { detail: { id } }));
  };

  const fireStartStopEvent = () => {
    if (playing) fireStopEvent();
    else fireStartEvent();
  };

  const touchToggle = () => {
    if (iOS) {
      !playing ? audio.play() : audio.pause();
      setPlaying(!audio.paused);
      fireStartStopEvent();
    }
  };

  const toggle = () => {
    if (!iOS) fireStartStopEvent();
  };

  audio.onloadedmetadata = () => {
    setDuration(audio.duration);
  };

  audio.ontimeupdate = () => {
    setCurrentTime(audio.currentTime);
  };

  const currentPercent = useMemo(() => (currentTime * 100) / duration, [currentTime, duration]);

  const setTime = (time: number) => {
    audio.currentTime = time;
    if (!playing) toggle();
  };

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return { playing, toggle, touchToggle, duration, currentPercent, currentTime, setTime };
};

export default useAudioPlayer;
