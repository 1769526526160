import { useOrderContext } from '../useOrderContext';
import useCustomerApi from '../../../../../../shared/requests/customer/useCustomerApi';
import { SubmitHandler, useForm } from 'react-hook-form';

type OrderCustomerForm = {
  name: string;
  email: string;
};

const useCustomerForm = () => {
  const { order } = useOrderContext();
  const { updateCustomer, isLoading } = useCustomerApi();

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<OrderCustomerForm>({
    mode: 'onBlur',
    defaultValues: {
      name: order?.customer?.name,
      email: order?.customer?.email,
    },
  });

  const onSubmit: SubmitHandler<OrderCustomerForm> = async (args) => {
    if (order?.customer?.uuid) await updateCustomer(order?.customer?.uuid, args);
  };

  return { register, reset, onSave: handleSubmit(onSubmit), isLoading, isDirty };
};

export default useCustomerForm;
