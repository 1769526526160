import { toast, ToastOptions } from 'react-toastify';
import { designSystem } from '../../../theme';

export const defaultOptions: ToastOptions = {
  autoClose: 3000,
  draggable: true,
  closeButton: true,
  position:
    (window.innerWidth <= parseInt(designSystem.breakpoints.lg, 10) &&
      toast.POSITION.BOTTOM_CENTER) ||
    toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
};
