/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgInstagram = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10C6 7.79086 7.79086 6 10 6H30C32.2091 6 34 7.79086 34 10V30C34 32.2091 32.2091 34 30 34H10C7.79086 34 6 32.2091 6 30V10ZM13.728 19.9988C13.728 16.5327 16.5378 13.7229 20.0039 13.7229C23.47 13.7229 26.2798 16.5327 26.2798 19.9988C26.2798 23.4648 23.47 26.2746 20.0039 26.2746C16.5378 26.2746 13.728 23.4648 13.728 19.9988ZM26.7598 10.8279C25.9599 10.8279 25.3115 11.4763 25.3115 12.2762C25.3115 13.076 25.9599 13.7244 26.7598 13.7244C27.5597 13.7244 28.2081 13.076 28.2081 12.2762C28.2081 11.4763 27.5597 10.8279 26.7598 10.8279Z"
      {...props}
    />
  </svg>
);

export default SvgInstagram;
