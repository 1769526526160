import React from 'react';
import { Template } from '../../../../types/production/template';

export type ISegmentContext = {
  template: Template | null;
  setTemplate: React.Dispatch<React.SetStateAction<Template | null>>;
  updateTemplate: Function;
  isUpdating: boolean;
  addLyric: Function;
  updateLyric: Function;
  deleteLyric: Function;
};

export const defaultState = {
  template: null,
  setTemplate: () => {},
  updateTemplate: () => {},
  isUpdating: false,
  addLyric: () => {},
  updateLyric: () => {},
  deleteLyric: () => {},
};

export const LyricsContext = React.createContext<ISegmentContext>(defaultState);

export const LyricsContextProvider = LyricsContext.Provider;
