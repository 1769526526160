import React from 'react';
import { Artist } from '../../../types/production/artist';
import { Avatar, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { StyledArtistCard, StyledArtistsWrapper, StyledStatusIcon } from './styled';
import { SkeletonRepeater } from '../index';

const ArtistSelector = ({
  artists,
  isLoading,
  selectedArtist = null,
  setSelectedArtist,
}: {
  artists: Artist[];
  isLoading: boolean;
  selectedArtist: Artist | null;
  setSelectedArtist: Function;
}) => {
  const isSelected = (artist: Artist) => {
    return artist.id === selectedArtist?.id;
  };
  const selectArtist = (artist: Artist) => () => {
    setSelectedArtist((!isSelected(artist) && artist) || null);
  };

  return (
    <StyledArtistsWrapper>
      {(isLoading && (
        <SkeletonRepeater number={4}>
          <StyledArtistCard>
            <SkeletonCircle w={'1rem'} h={'1rem'} />
            <Skeleton w={'100%'} h={'1rem'} />
          </StyledArtistCard>
        </SkeletonRepeater>
      )) ||
        artists.map((artist) => (
          <StyledArtistCard
            role={'button'}
            selected={isSelected(artist)}
            onClick={selectArtist(artist)}
            key={artist.id}
          >
            <Avatar size={'sm'} name={artist.name} src={artist.photo_url_thumb} />
            <span>{artist.name}</span>
            <StyledStatusIcon enabled={artist.enabled} type={'Dot'}></StyledStatusIcon>
          </StyledArtistCard>
        ))}
    </StyledArtistsWrapper>
  );
};

export default ArtistSelector;
