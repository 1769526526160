import { ToastContainer } from 'react-toastify';
import styled from '@emotion/styled';
import { designSystem } from '../../../theme';

export const StyledToastContainer = styled(ToastContainer)`
  @media (max-width: 1024px) {
    padding: 1rem;
  }

  .Toastify__toast {
    border-radius: ${designSystem.radii.sm};
    margin-top: 0.5rem;
  }
  .Toastify__toast-body {
    font-family: ${designSystem.fontFamily.body};
    color: ${designSystem.colors.text.main};
    font-size: ${designSystem.fontSize.sm};
  }
  // .Toastify__progress-bar {
  //   background: ${designSystem.palette.primary};
  // }
`;
