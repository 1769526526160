import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Segment } from '../types/production/segment';
import { Personalization } from '../types/production/personalization';
import { designSystem } from '../theme';

export type SortableListItemType = Segment | Personalization;

const useSortableList = (item: SortableListItemType) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable(item);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    boxShadow: isDragging ? designSystem.shadow.card : 'inherit',
  };

  return { attributes, listeners, setNodeRef, style, isDragging };
};

export default useSortableList;
