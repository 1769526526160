export const API_URL = `${
  process.env.REACT_APP_BASE_API_URL || 'https://dev.api.eversong.com'
}/api`;

export const All = 'All';

export const ASC = 'ASC';
export const DESC = 'DESC';

export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_MASK = '99/99/9999';

export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';

export const AUTH_TOKEN_KEY = 'auth_token';
