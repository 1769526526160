import { useState } from 'react';
import { GeneratorApi } from '../../../../api';
import { Gender } from '../../../../shared/constants/Gender';

const useGetSegments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [segments, setSegments] = useState<{ value: number; label: string }[]>([]);

  const getSegments = async (gender: Gender) => {
    setIsLoading(true);
    GeneratorApi.getSegments(gender)
      .then((response) => {
        setSegments(response.map((segment) => ({ value: segment.id, label: segment.name })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    getSegments,
    segments,
  };
};

export default useGetSegments;
