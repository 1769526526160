import { PersonalizationApi } from '../../../../api';
import { Personalization } from '../../../../shared/types/production/personalization';
import { useState } from 'react';

const useHandlePersonalizationUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);

  const updatePersonalization = async (personalization: Personalization, args: Object) => {
    const data = { ...args } as { alias: string; visible: boolean };
    setIsLoading(true);
    const updated = await PersonalizationApi.update(personalization.id, data)
      .then((response) => {
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return updated;
  };

  const deletePersonalization = async (personalization: Personalization) => {
    await PersonalizationApi.delete(personalization.id);
  };

  return { isLoading, updatePersonalization, deletePersonalization };
};

export default useHandlePersonalizationUpdate;
