import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const StyledPageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding-top: 0.5rem;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledLetterFormHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl3};
  font-family: ${({ theme }) => theme.fontFamily.headingBold};
  line-height: 2rem;
  margin-bottom: 0.5rem;
`;

export const StyledLetterFormSubheader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: 1.5rem;
`;

export const StyledEmailForm = styled.form`
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
  display: grid;
  grid-gap: 1rem;
  padding: 1.5rem;

  > div > p {
    color: ${({ theme }) => theme.palette.neutral50};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    margin-bottom: 0.5rem;
  }

  textarea {
    background: transparent;
  }
`;

export const StyledImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.75rem;
  align-items: center;

  > div {
    > p {
      margin-bottom: 0;
    }

    > p:first-of-type {
      color: ${({ theme }) => theme.palette.primary};
      font-size: ${({ theme }) => theme.fontSize.sm};
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    }

    > p:last-of-type {
      color: ${({ theme }) => theme.colors.text.placeholder};
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledFormActions = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    justify-content: unset;
    button {
      width: 100%;
    }
  }
`;

export const StyledPreviewWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: ${({ theme }) => theme.shadow.card};
  background: ${({ theme }) => theme.palette.white};
  position: sticky;
  top: 1rem;
  overflow: hidden;

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-left {
    text-align: left;
  }
`;

export const StyledEmailHeader = styled.div`
  background: ${({ theme }) => theme.palette.white};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledEmailImage = styled.img``;

export const StyledEmailContentWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 2rem 1rem;
`;

export const StyledEmailContent = styled.div`
  // color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledEmailAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > a {
    border-radius: ${({ theme }) => theme.radii.sm};
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.white};
    padding: 0.75rem 2rem;
    width: 12rem;
    text-align: center;

    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }
`;

export const StyledEmailHelpWrapper = styled.div`
  background: ${({ theme }) => theme.palette.primary8};
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;

  > h2 {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.xl2};
    font-family: ${({ theme }) => theme.fontFamily.headingBold};
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
    text-transform: uppercase;
    margin-right: 0.35rem;
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    text-decoration: underline;
  }
`;

export const StyledEmailFooter = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.xs};
  line-height: 1.5rem;

  a {
    color: ${({ theme }) => theme.palette.error};
    text-decoration: underline;
  }
`;

export const StyledVarsArray = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  margin-top: 0.25rem;

  button {
    border-radius: ${({ theme }) => theme.radii.xs};
    font-size: ${({ theme }) => theme.fontSize.xs};
    height: 1.5rem;
  }
`;
