import styled from '@emotion/styled';
import { Text } from '@chakra-ui/react';

export const StyledSettingsWrapper = styled.div``;

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin-bottom: 1.5rem;
`;

export const StyledOptionCard = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: ${({ theme }) => theme.shadow.card};
  padding: 1rem 1.5rem;

  display: grid;
  grid-template-columns: 1fr 1fr auto auto;
  align-items: center;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledPlayerWrapper = styled.div`
  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 0.6rem 1rem;
  height: 3.75rem;

  > p:first-of-type {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  > p:last-of-type {
    color: ${({ theme }) => theme.colors.text.placeholder};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;
