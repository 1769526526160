import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Text } from '@chakra-ui/react';

export const StyledGeneratorWrapper = styled.div`
  display: grid;
  grid-template-columns: 25rem 1fr;
  grid-gap: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

export const StyledSectionWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;

  select {
    background: ${({ theme }) => theme.palette.neutral10};
  }
`;

export const StyledHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;

  > p {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledHeaderWithActionWrapper = styled(StyledHeaderWrapper)`
  grid-template-columns: auto 1fr auto;

  button {
    background: transparent;
    padding: 0;
    width: 2rem;
    height: 2rem;

    svg {
      width: 1.25rem;
      height: auto;
      fill: ${({ theme }) => theme.palette.neutral60};
    }
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.palette.neutral10};
`;

export const StyledTrackDivider = styled(StyledDivider)`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

export const StyledNoIntroHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: 0.5rem;
`;

export const PersonalizationRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 15rem;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;

  select {
    padding: 0.25rem 1rem;
    height: 2.25rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr 12rem;
  }
`;

export const StyledNamesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;

  > p {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  input {
    padding: 0.25rem 1rem;
    height: 2.25rem;
    background: ${({ theme }) => theme.palette.neutral10};

    + .chakra-input__right-element {
      top: -0.125rem;
    }
  }
`;

export const StyledActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 1rem;
  }
`;

export const StyledLoadingWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  padding: 1.2rem;
  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};

  > p {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;

export const StyledResultWrapper = styled.div`
  position: sticky;
  top: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
`;

export const StyledLyricsWrapper = styled.div`
  max-height: 40vh;
  overflow-y: auto;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export const StyledTrackWrapper = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: ${({ theme }) => theme.shadow.card};

  .chakra-tabs__tablist {
    margin-inline: 0;
  }
`;

export const StyledTrackDataWrapper = styled(StyledTrackWrapper)`
  padding: 0;

  .chakra-tabs__tab-panel {
    padding: 1.5rem !important;
  }
`;

export const StyledFileString = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.xs};
  line-height: 1;
  margin-bottom: 0.5rem;
  display: block;
`;

export const StyledDebugHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: 0.5rem;
`;

export const StyledDebugRow = styled.div`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledResultCardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;

  > p {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.md};
  }

  button {
    height: 2rem;
    padding: 0.25rem 1rem;
    background: transparent;
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr;

    button {
      background: ${({ theme }) => theme.palette.neutral10};
    }
  }
`;

export const StyledArtistWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.75rem;
  align-items: center;
  margin-bottom: 0.5rem;

  .chakra-avatar {
    border-radius: ${({ theme }) => theme.radii.md};
  }
`;

export const StyledArtistInfo = styled.div`
  h3 {
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.md};
  }

  p {
    color: ${({ theme }) => theme.colors.text.placeholder};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledResultActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  grid-gap: 1rem;
  align-items: center;

  > p {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  a {
    padding: 0.25rem 1rem;
    height: 2.25rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;

    a {
      height: 3rem;
    }
  }
`;

export const StyledVoiceWrapper = styled.div<{ isset: boolean }>`
  padding: 0.5rem 1rem;
  background: ${({ theme, isset }) => (isset && theme.palette.success) || theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;

  > p {
    color: ${({ theme, isset }) => (isset && theme.palette.white) || theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  button {
    padding: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    box-shadow: none;
    background: transparent;

    svg,
    path {
      ${({ isset }) =>
        isset &&
        css`
          fill: white;
        `}
    }
  }
`;

export const StyledFileInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;

  > div {
    color: ${({ theme }) => theme.colors.text.placeholder};

    > p:first-of-type {
      font-size: ${({ theme }) => theme.fontSize.sm};
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    }
    > p:last-of-type {
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;

export const StyledDividerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

  > span {
    color: ${({ theme }) => theme.palette.neutral60};
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const StyledVisualizationWrapper = styled.div`
  height: 10rem;
  margin-bottom: 1rem;
`;

export const StyledVoiceRecordWrapper = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.75rem;
`;
