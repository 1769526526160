import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useOrderApi } from '../../requests/useOrderApi';
import { OrderContextProvider } from './context/OrderContext';
import OrderSummary from './OrderSummary';
import { PageBreadcrumbs } from '../../../../shared/components/custom';
import { ControlPaths } from '../../../../router/routes';
import { useScopedTranslation } from '../../../../shared/hooks';
import useHasPermission from '../../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../../shared/constants/Permissions';

const OrderPage = () => {
  const { t } = useScopedTranslation('order');
  const { orderId } = useParams();
  const {
    order,
    setOrder,
    speedUpOrder,
    addOptions,
    updateInfo,
    updateOrder,
    remake,
    remakeClientVoice,
    remakeSlideshow,
    uploadVoice,
    removeOption,
  } = useOrderApi(orderId);

  const pageName = useMemo(() => {
    return (!!order && `#${order.id}`) || '';
  }, [order]);

  const isEdit = useHasPermission(Permissions.orderEdit);

  return (
    <OrderContextProvider
      value={{
        order,
        setOrder,
        speedUpOrder,
        addOptions,
        updateInfo,
        updateOrder,
        remake,
        remakeClientVoice,
        remakeSlideshow,
        uploadVoice,
        removeOption,
        isEdit,
      }}
    >
      <PageBreadcrumbs
        rootPageName={t('orders')}
        rootPagePath={ControlPaths.orders}
        currentPageName={pageName}
        isLoading={!order}
      />
      {(order && <OrderSummary order={order} />) || <div />}
    </OrderContextProvider>
  );
};

export default OrderPage;
