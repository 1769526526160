/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgPlus = (props) => (
  <svg {...props} width="14" height="15" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.949707C7.61042 0.949707 8.10526 1.44455 8.10526 2.05497V6.84444H12.8947C13.5052 6.84444 14 7.33929 14 7.94971C14 8.56013 13.5052 9.05497 12.8947 9.05497H8.10526V13.8444C8.10526 14.4549 7.61042 14.9497 7 14.9497C6.38958 14.9497 5.89474 14.4549 5.89474 13.8444V9.05497H1.10526C0.494843 9.05497 0 8.56013 0 7.94971C0 7.33929 0.494843 6.84444 1.10526 6.84444H5.89474V2.05497C5.89474 1.44455 6.38958 0.949707 7 0.949707Z"
    />
  </svg>
);

export default SvgPlus;
