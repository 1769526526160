import { useState } from 'react';
import { LoginCredentials } from '../../../shared/types/user';
import { UserApi } from '../../../api';
import { useAppContext } from '../../../shared/hooks';
import { useOnLoginRedirect } from '../hooks/useOnLoginRedirect';

const useLoginWithCredentials = () => {
  const { setAccessToken } = useAppContext();
  useOnLoginRedirect();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const login = (credentials: LoginCredentials) => {
    setIsLoading(true);
    setIsError(false);
    UserApi.login(credentials)
      .then((response) => {
        setAccessToken(response.access_token);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { login, isLoading, isError };
};

export default useLoginWithCredentials;
