export enum FileStructureTypes {
  folder = 'folder',
  file = 'file',
}

export type FileStructureUnit = {
  name: string;
  type: FileStructureTypes;
  children?: FileStructureUnit[];
  is_uploaded?: boolean;
  file_url?: string;
  data?: Object;
};

export type FileStructure = { root_url: string; scheme: FileStructureUnit[] };
