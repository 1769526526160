import React from 'react';
import useGeneratorContext from '../hooks/useGeneratorContext';
import { StyledResultWrapper } from '../styled';
import GenerationResultSkeleton from './GenerationResultSkeleton';
import QuickAccess from './result/QuickAccess';
import TrackWrapper from './result/TrackWrapper';
import ResultData from './result/ResultData';

const GenerationResult = () => {
  const { result, isProducing } = useGeneratorContext();

  return (
    (result && (
      <StyledResultWrapper>
        <TrackWrapper />
        <ResultData />
        <QuickAccess />
      </StyledResultWrapper>
    )) ||
    (isProducing && <GenerationResultSkeleton />) || <></>
  );
};

export default GenerationResult;
