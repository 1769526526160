import { httpGet, httpPost } from '../../shared/utils/http';
import { CUSTOMERS_ROUTE } from '../constants';
import { Customer, UpdateCustomerData } from '../../shared/types/customer';

export type CustomerApiType = {
  get: () => Promise<Customer[]>;
  find: (customerId: string) => Promise<Customer>;
  update: (customerId: string, data: UpdateCustomerData) => Promise<Customer>;
};

export const CustomerApi: CustomerApiType = {
  get: () => {
    return httpGet(`${CUSTOMERS_ROUTE}`);
  },
  find: (customerId) => {
    return httpGet(`${CUSTOMERS_ROUTE}/${customerId}`);
  },
  update: (customerId, data) => {
    return httpPost(`${CUSTOMERS_ROUTE}/${customerId}`, data);
  },
};
