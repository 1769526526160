/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgStar = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.3165 16.1109C36.2325 15.8409 36.078 15.6009 35.8705 15.418C35.6631 15.235 35.4109 15.1164 35.1424 15.0756L25.8485 13.6614L21.6894 4.84875C21.5694 4.594 21.3836 4.37949 21.1531 4.22949C20.9226 4.0795 20.6565 4 20.3851 4C20.1137 4 19.8476 4.0795 19.6171 4.22949C19.3866 4.37949 19.2008 4.594 19.0808 4.84875L14.9217 13.6614L5.63327 15.0756C5.36462 15.1164 5.11224 15.235 4.90469 15.4181C4.69714 15.6012 4.54269 15.8414 4.45883 16.1116C4.37497 16.3818 4.36504 16.6711 4.43017 16.9469C4.4953 17.2227 4.63288 17.4739 4.82736 17.6721L11.5521 24.5325L9.96486 34.2185C9.91833 34.4989 9.9479 34.7874 10.0502 35.0511C10.1526 35.3148 10.3235 35.5432 10.5437 35.7103C10.7639 35.8774 11.0244 35.9765 11.2958 35.9963C11.5671 36.0161 11.8383 35.9559 12.0785 35.8225L20.3878 31.2496L28.7006 35.8225C28.9407 35.9549 29.2115 36.0143 29.4822 35.994C29.753 35.9737 30.0129 35.8745 30.2325 35.7075C30.4521 35.5406 30.6227 35.3126 30.7249 35.0495C30.8271 34.7863 30.8569 34.4985 30.8108 34.2185L29.2235 24.5318L35.9483 17.6714C36.1427 17.4731 36.2802 17.2219 36.3453 16.9461C36.4104 16.6704 36.4004 16.381 36.3165 16.1109Z"
      {...props}
    />
  </svg>
);

export default SvgStar;
