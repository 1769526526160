import React, { useMemo } from 'react';
import useSegmentContext from '../hooks/useSegmentContext';
import { StyledInfoWrapper, StyledSectionHeader } from '../styled';
import { ButtonGroup } from '../../../../shared/components/custom';
import { ButtonGroupTypes } from '../../../../shared/components/custom/ButtonGroup/types';
import useGetOccasions from '../../../../shared/requests/production/useGetOccasions';
import { ButtonGroupValueType } from '../../../../shared/components/custom/ButtonGroup/ButtonGroup';
import { useScopedTranslation } from '../../../../shared/hooks';

const Occasions = () => {
  const { t } = useScopedTranslation('production.segments');

  const { segment, syncOccasions, isEdit } = useSegmentContext();
  const { occasions, isLoading } = useGetOccasions();

  const segmentOccasions = useMemo(() => {
    return segment?.occasions?.map(({ id }) => id) ?? [];
  }, [segment]);

  const mappedOccasions = useMemo(() => {
    return occasions.map((occasion) => ({ value: occasion.id, label: occasion.name }));
  }, [occasions]);

  const updateOccasions = (selected: ButtonGroupValueType[]) => {
    syncOccasions(segment, selected);
  };

  return (
    segment && (
      <StyledInfoWrapper>
        <StyledSectionHeader>
          <p>{t('occasions')}</p>
        </StyledSectionHeader>
        <ButtonGroup
          isDisabled={!isEdit}
          isLoading={isLoading}
          options={mappedOccasions}
          onChange={updateOccasions}
          defaultValue={segmentOccasions}
          isMultiple
          type={ButtonGroupTypes.stack}
        />
      </StyledInfoWrapper>
    )
  );
};

export default Occasions;
