import { useScopedTranslation } from '../../../hooks';
import { CommonPaths } from '../../../../router/routes';
import { StyledHeader, StyledText, StyledButton } from './styled';
import { MotionContainer } from '../MotionContainer';

const NotFound = () => {
  const { t, rootT } = useScopedTranslation('general.errors.not_found');

  return (
    <MotionContainer>
      <StyledHeader>{t('oops')}</StyledHeader>
      <StyledText>{t('not_found_text')}</StyledText>
      <StyledButton to={CommonPaths.dashboard} color={'primary'}>
        {rootT('general.errors.go_home')}
      </StyledButton>
    </MotionContainer>
  );
};

export default NotFound;
