import * as Yup from 'yup';

export const UpdateArtistSchema = Yup.object().shape({
  name: Yup.string().trim().label('Name').required().min(1),
  description: Yup.string().trim().label('Description'),
  enabled: Yup.boolean(),
});

export const UpdateSlotSchema = Yup.object().shape({
  type: Yup.string().label('Type'),
  value: Yup.string().nullable().label('Value'),
});
