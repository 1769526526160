import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ButtonSizes } from '../../../../theme';
import {
  PersonalizationLyrics,
  TemplatePersonalization,
} from '../../../../types/production/template';
import {
  StyledAddCustomRowButton,
  StyledFormHeader,
  StyledFormSubheader,
  StyledPersonalizationRow,
  StyledPersonalizationRowsWrapper,
} from '../styled';
import CustomStringRow from './CustomStringRow';
import { LyricsContext } from '../context/LyricsContext';
import CommonString from './CommonString';
import { useScopedTranslation } from '../../../../hooks';

type RowType = {
  personalization: TemplatePersonalization;
};

const PersonalizationValueRow = ({ personalization }: RowType) => {
  const { t } = useScopedTranslation('production.templates');

  const { template, addLyric, updateLyric, deleteLyric } = useContext(LyricsContext);
  const [customStrings, setCustomStrings] = useState<PersonalizationLyrics[]>([]);
  const { template_personalization_id: templPersId } = personalization;

  const commonLyric = useMemo(
    () => personalization.lyrics?.find((string) => !string.key),
    [personalization],
  );

  useEffect(() => {
    const lyrics = personalization.lyrics?.filter((string) => !!string.key);
    const updatedLyrics = customStrings.map((row) => {
      const updatedLyric = lyrics.find((lyric) => lyric.id === row.id || lyric.key === row.key);
      return updatedLyric || row;
    });
    setCustomStrings(
      (!!updatedLyrics.length && updatedLyrics) ||
        (!!lyrics.length && lyrics) || [{ key: 'Default', text: '' }],
    );
  }, [personalization]);

  const removeCustomString = (lyric: PersonalizationLyrics) => {
    setCustomStrings(customStrings.filter((obj) => obj !== lyric));
    if (lyric.id) deleteLyric(template, templPersId, lyric.id);
  };

  const addRow = () => {
    setCustomStrings([...customStrings, { key: '', text: '' }]);
  };

  const onLyricChange = (data: PersonalizationLyrics) => {
    if (data.id) updateLyric(template, templPersId, data.id, data);
    else addLyric(template, templPersId, data);
  };

  return (
    <StyledPersonalizationRow>
      <CommonString lyric={commonLyric} onChange={onLyricChange} />

      <StyledFormHeader>{t('custom_title')}</StyledFormHeader>
      <StyledFormSubheader>{t('custom_subtitle')}</StyledFormSubheader>

      <StyledPersonalizationRowsWrapper>
        {customStrings.map((string, index) => (
          <CustomStringRow
            key={`custom-${index}`}
            lyric={string}
            onRemove={removeCustomString}
            onChange={onLyricChange}
          />
        ))}
      </StyledPersonalizationRowsWrapper>

      <StyledAddCustomRowButton onClick={addRow} noShadow size={ButtonSizes.sm}>
        {t('add_more')}
      </StyledAddCustomRowButton>
    </StyledPersonalizationRow>
  );
};

export default PersonalizationValueRow;
