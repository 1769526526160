import React from 'react';
import { Segment } from '../../../../shared/types/production/segment';

export type ISegmentContext = {
  isLoading: boolean;
  segment: Segment | null;
  setSegment: React.Dispatch<React.SetStateAction<Segment | null>>;
  updateSegment: Function;
  deleteSegment: Function;
  updateSegmentSender: Function;
  syncOccasions: Function;
  addPersonalizationType: Function;
  deletePersonalizationType: Function;
  syncSegmentPersonalizationValues: Function;
  isEdit: boolean;
};

export const defaultState = {
  isLoading: false,
  segment: null,
  setSegment: () => {},
  updateSegment: () => {},
  deleteSegment: () => {},
  updateSegmentSender: () => {},
  syncOccasions: () => {},
  addPersonalizationType: () => {},
  deletePersonalizationType: () => {},
  syncSegmentPersonalizationValues: () => {},
  isEdit: false,
};

export const SegmentContext = React.createContext<ISegmentContext>(defaultState);

export const SegmentContextProvider = SegmentContext.Provider;
