import { useMemo } from 'react';
import { OptionNames, Order } from '../../../../../shared/types/orders';

const useOrderOptions = (order: Order | null = null) => {
  const options = useMemo(() => order?.options ?? [], [order]);

  const optionByName = (name: OptionNames) => options.find((option) => option.name === name);

  const clientVoice = useMemo(() => optionByName(OptionNames.clientVoice), [options]);
  const video = useMemo(() => optionByName(OptionNames.video), [options]);
  const duet = useMemo(() => optionByName(OptionNames.duet), [options]);

  return { options, clientVoice, video, duet };
};

export default useOrderOptions;
