import React from 'react';
import {
  StyledFooterWrapper,
  StyledLogo,
  StyledSocialButton,
  StyledSocialsWrapper,
} from './styled';
import Logo from '../../../theme/assets/img/logo.svg';
import { Link } from 'react-router-dom';
import { CommonPaths } from '../../../../router/routes';
import { FlexSpacer } from '../../ui';
import { Icon } from '../../icons';
import { SocialNetworkUrls } from '../../../constants/SocialNetworks';

const Footer = () => {
  return (
    <StyledFooterWrapper>
      <StyledLogo>
        <Link to={CommonPaths.dashboard}>
          <img src={Logo} alt={'logo'} />
        </Link>
      </StyledLogo>
      <FlexSpacer />
      <StyledSocialsWrapper>
        <StyledSocialButton href={SocialNetworkUrls.facebook} fab color={'secondary'} noShadow>
          <Icon type={'Facebook'} />
        </StyledSocialButton>
        <StyledSocialButton href={SocialNetworkUrls.twitter} fab color={'secondary'} noShadow>
          <Icon type={'Twitter'} />
        </StyledSocialButton>
        <StyledSocialButton href={SocialNetworkUrls.instagram} fab color={'secondary'} noShadow>
          <Icon type={'Instagram'} />
        </StyledSocialButton>
      </StyledSocialsWrapper>
    </StyledFooterWrapper>
  );
};

export default Footer;
