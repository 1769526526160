import styled from '@emotion/styled';
import { MenuButton, MenuItem } from '@chakra-ui/react';
import { Icon } from '../../icons';

export const StyledLanguageDropdown = styled(MenuButton)`
  background: ${({ theme }) => theme.palette.neutral10};
  padding: 0.25rem 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  height: 2.5rem;

  > span {
    display: flex;
    align-items: center;
    outline: none;

    img {
      margin-right: 0.7rem;
      width: 1.25rem !important;
      border-radius: 4px;
    }
  }
`;

export const StyledLanguageMenuItem = styled(MenuItem)`
  text-transform: capitalize;
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;

  img {
    border-radius: 2px;
    margin-top: 2px;
  }
`;

export const StyledArrowIcon = styled(Icon)<{ open: boolean }>`
  rotate: -90deg;
  transform: rotateY(${({ open }) => (!open && '0') || '180deg'});
  transition: 0.3s;

  width: 0.45rem;
  height: auto;
`;
