import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroller';

export const StyledUsersWrapper = styled(InfiniteScroll)`
  display: grid;
  grid-gap: 1rem;
`;

export const StyledUsersHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;

  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.sm};

  > p:last-of-type {
    text-align: end;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledUserRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;

  background: ${({ theme }) => theme.palette.white};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: ${({ theme }) => theme.shadow.card};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;

    a {
      width: 100%;
    }
  }
`;

export const StyledUserName = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  align-items: center;

  .chakra-avatar {
    border-radius: ${({ theme }) => theme.radii.md};
  }

  > div {
    > h3 {
      color: ${({ theme }) => theme.colors.text.main};
      font-size: ${({ theme }) => theme.fontSize.sm};
      font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    }

    > p {
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: ${({ theme }) => theme.fontSize.xs};
    }
  }
`;

export const StyledUserRole = styled.div`
  > h3 {
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    text-transform: capitalize;
  }

  > p {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

export const StyledUserAction = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;

  a,
  button {
    height: 2.5rem;
  }
`;

export const StyledFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr 1fr auto;
  grid-gap: 1rem;
  margin-bottom: 2rem;

  select,
  input {
    height: 2.5rem;
    padding: 0.5rem 1.5rem;
    background: ${({ theme }) => theme.palette.primary8};
  }

  button {
    height: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 0.5rem;
    margin-bottom: 1rem;

    > div:first-of-type {
      order: 2;
    }

    > div:nth-of-type(3) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > div:nth-of-type(2) {
      display: none;
    }

    > div:last-of-type {
      order: 3;
      button {
        width: 100%;
      }
    }
  }
`;
