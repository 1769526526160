import { useEffect, useState } from 'react';
import { ArtistsApi } from '../../../api';
import { Artist } from '../../types/production/artist';

const useGetArtists = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [artists, setArtists] = useState<Artist[]>([]);

  const getArtists = async () => {
    setIsLoading(true);
    await ArtistsApi.get()
      .then((response) => {
        setArtists(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getArtists();
  }, []);

  return { getArtists, setArtists, isLoading, artists };
};

export default useGetArtists;
