import React from 'react';
import { Order } from '../../../../../shared/types/orders';

export type IOrderContext = {
  order: Order | null;
  setOrder: Function;
  speedUpOrder: Function;
  addOptions: Function;
  updateInfo: Function;
  updateOrder: Function;
  remake: Function;
  remakeClientVoice: Function;
  remakeSlideshow: Function;
  uploadVoice: Function;
  removeOption: Function;
  isEdit: boolean;
};

export const defaultState = {
  order: null,
  setOrder: () => {},
  speedUpOrder: () => {},
  addOptions: () => {},
  updateInfo: () => {},
  updateOrder: () => {},
  remake: () => {},
  remakeClientVoice: () => {},
  remakeSlideshow: () => {},
  uploadVoice: () => {},
  removeOption: () => {},
  isEdit: false,
};

export const OrderContext = React.createContext<IOrderContext>(defaultState);

export const OrderContextProvider = OrderContext.Provider;
