import React, { useEffect, useMemo, useState } from 'react';
import useGetPersonalizationTypes from '../../../shared/requests/production/useGetPersonalizationTypes';
import useGetPersonalization from '../../../shared/requests/production/useGetPersonalization';
import { ButtonGroup, MotionContainer, SkeletonRepeater } from '../../../shared/components/custom';
import { StyledPersonalizationsWrapper } from './styled';
import { useScopedTranslation } from '../../../shared/hooks';
import PersonalizationCreateForm from './components/PersonalizationCreateForm';
import PersonalizationRow from './components/PersonalizationRow';
import { Divider } from '@chakra-ui/react';
import PersonalizationRowSkeleton from './components/PersonalizationRowSkeleton';
import useHandlePersonalizationsList from './hooks/useHandlePersonalizationsList';
import useHasPermission from '../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../shared/constants/Permissions';
import SortableList from '../../../shared/components/custom/SortableList/SortableList';
import { PersonalizationTypeEnum } from '../../../shared/constants/production/personalization';

const Personalization = () => {
  const { t } = useScopedTranslation('production.personalization');
  const [currentType, setCurrentType] = useState<PersonalizationTypeEnum | null>(null);
  const { types, isLoading: isLoadingTypes } = useGetPersonalizationTypes();
  const isEdit = useHasPermission(Permissions.generationEdit);
  const [sortableTypes] = useState<PersonalizationTypeEnum[]>([
    PersonalizationTypeEnum.eyes,
    PersonalizationTypeEnum.kids,
    PersonalizationTypeEnum.months,
    PersonalizationTypeEnum.occasion,
  ]);

  const {
    getPersonalizations,
    personalizations,
    setPersonalizations,
    isLoading: isLoadingPersonalizations,
    hasMore,
    syncOrder,
  } = useGetPersonalization();

  const { onDeleted, onUpdated, onCreated } = useHandlePersonalizationsList({
    personalizations,
    setPersonalizations,
  });

  useEffect(() => {
    setCurrentType(types[0]?.name);
  }, [types]);

  useEffect(() => {
    if (currentType) getPersonalizations(currentType, true);
  }, [currentType]);

  const onImport = () => {
    if (currentType) getPersonalizations(currentType, true);
  };

  const selectType = (type: PersonalizationTypeEnum) => {
    setCurrentType(type);
  };

  const typesTabs = useMemo(
    () => types.map((type) => ({ value: type.name, label: t(`types.${type.name}`) })),
    [types],
  );

  const loadMore = () => {
    if (currentType) getPersonalizations(currentType);
  };

  const isSortable = useMemo(
    () => (!!currentType && sortableTypes.includes(currentType)) || false,
    [currentType, sortableTypes],
  );

  return (
    <>
      <ButtonGroup
        isLoading={isLoadingTypes}
        options={typesTabs}
        onChange={selectType}
        defaultValue={currentType}
      />

      {currentType && (
        <MotionContainer>
          <StyledPersonalizationsWrapper loadMore={loadMore} initialLoad={false} hasMore={hasMore}>
            <SortableList
              items={personalizations}
              setItems={setPersonalizations}
              onDrag={syncOrder}
              isSortable={isSortable}
            >
              {personalizations.map((personalization, index) => (
                <PersonalizationRow
                  personalization={personalization}
                  index={index + 1}
                  key={personalization.id}
                  onUpdate={onUpdated}
                  onDelete={onDeleted}
                  isEdit={isEdit}
                  isSortable={isSortable}
                />
              ))}
            </SortableList>

            {!personalizations.length && !isLoadingPersonalizations && (
              <div>No personalizations added</div>
            )}

            {isLoadingPersonalizations && (
              <SkeletonRepeater number={5}>
                <PersonalizationRowSkeleton />
              </SkeletonRepeater>
            )}
            {isEdit && (
              <>
                <Divider />
                <PersonalizationCreateForm
                  type={currentType}
                  onSubmit={onCreated}
                  onImport={onImport}
                />
              </>
            )}
          </StyledPersonalizationsWrapper>
        </MotionContainer>
      )}
    </>
  );
};

export default Personalization;
