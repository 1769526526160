import { Badge, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Button } from '../../../shared/components/ui/Button';
import { ModalBody } from '../../../shared/components/ui';

export const StyledPageWrapper = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.radii.sm};
  box-shadow: ${({ theme }) => theme.shadow.card};
`;

export const StyledSectionHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

export const StyledSectionSubheader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  margin-bottom: 1rem;
  margin-top: -0.35rem;
`;

export const StyledInfoWrapper = styled.div`
  padding: 1rem;
`;

export const StyledGeneralInfoWrapper = styled.form`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr auto auto;
  align-items: center;

  input {
    height: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr auto;

    > div:nth-of-type(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > div:nth-of-type(2) {
      display: none;
    }
  }
`;

export const StyledVisibleWrapper = styled.div`
  padding: 0.75rem;
  background: ${({ theme }) => theme.palette.neutral10};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 9rem;

  > span {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const StyledPersonalizationEditor = styled.div`
  display: grid;
  grid-gap: 1rem;
`;

export const StyledPeronalizationTypeRow = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr;
  border-radius: ${({ theme }) => theme.radii.sm};
  border: 1px solid ${({ theme }) => theme.palette.neutral10};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledTypeCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-right: 1px solid ${({ theme }) => theme.palette.neutral10};

  > p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  > button {
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledVariantsCell = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  grid-gap: 0.5rem;
`;

export const StyledVariantItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
`;

export const StyledVariantItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.1rem 1rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.primary};
  transition: 0.3s ease-in-out;

  > p {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.palette.white};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  }

  > button {
    margin-left: 1rem;
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;

    svg,
    path {
      fill: ${({ theme }) => theme.palette.white};
    }
  }
`;

export const StyledInactiveLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

export const StyledVariantsModalBody = styled(ModalBody)`
  max-height: 40vh;
  overflow-y: auto;
`;

export const StyledBadge = styled(Badge)`
  color: ${({ theme }) => theme.palette.primary};
`;

export const VariantValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  color: ${({ theme }) => theme.colors.text.main};
`;

export const VariantValueItem = styled(Button)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: 0.5rem 0.5rem;
  height: unset;

  > svg {
    width: 0.5rem;
  }
`;
