import { DataSetType } from './types';
import { ScriptableContext } from 'chart.js';
import { designSystem } from '../../shared/theme';

export const buildChartData = (dataSet: DataSetType) => {
  return {
    labels: dataSet.map(({ label }) => label),
    datasets: [
      {
        label: 'Orders created',
        data: dataSet.map(({ value }) => value),
        fill: 'start',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 40);
          gradient.addColorStop(0, 'rgba(96,151,251,0.55)');
          gradient.addColorStop(1, 'rgba(96,151,251,0)');
          return gradient;
        },
        borderColor: designSystem.palette.primary,
      },
    ],
  };
};

export const chartOptions = (dataSet: DataSetType) => {
  const max = dataSet.reduce((result, { value }) => (value > result && value) || result, 0);
  const min = dataSet.reduce((result, { value }) => (value < result && value) || result, 0);

  return {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.35,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        min,
        max: max + 2,
      },
    },
  };
};
