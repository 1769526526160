import {
  Personalization,
  PersonalizationSynonym,
} from '../../../../shared/types/production/personalization';
import { PersonalizationApi } from '../../../../api';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const useSynonyms = (personalization: Personalization, onUpdate: Function) => {
  const [synonyms, setSynonyms] = useState(personalization.synonyms);
  const [isSaving, setIsSaving] = useState(false);

  const addSynonyms = async (names: string[]) => {
    const r = await PersonalizationApi.addSynonyms(personalization.id, names)
      .then((response) => response.data)
      .catch(() => null);
    return r;
  };

  const deleteSynonym = async (synonymId: number) => {
    const r = await PersonalizationApi.deleteSynonym(personalization.id, synonymId)
      .then((response) => response.data)
      .catch(() => null);
    return r;
  };

  useEffect(() => {
    setSynonyms(personalization.synonyms);
  }, [personalization]);

  const { register, handleSubmit, reset } = useForm<{ name: string }>({
    defaultValues: {
      name: '',
    },
  });

  const onAddSynonym: SubmitHandler<{ name: string }> = (args) => {
    const { name } = args;
    if (name) {
      const existingSynonym = synonyms.find((synonym) => synonym.name === name);
      if (!existingSynonym) setSynonyms([...synonyms, { name }]);
      reset({ name: '' });
    }
  };

  const removeValue = (synonym: PersonalizationSynonym) => async () => {
    setSynonyms(synonyms.filter(({ name }) => name !== synonym.name));
    if (!!synonym.id) {
      const updatedPersonalization = await deleteSynonym(synonym.id);
      onUpdate(updatedPersonalization);
    }
  };

  const add = async () => {
    setIsSaving(true);
    const synonymsToAdd = synonyms.filter((synonym) => !synonym.id).map((synonym) => synonym.name);
    if (synonymsToAdd.length) {
      const updatedPersonalization = await addSynonyms(synonymsToAdd);
      onUpdate(updatedPersonalization);
    }
    setIsSaving(false);
  };

  return { synonyms, register, handleSubmit, add, removeValue, onAddSynonym, isSaving };
};

export default useSynonyms;
