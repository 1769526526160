import React, { useMemo, useState } from 'react';
import { useOrderContext } from '../../hooks/useOrderContext';
import { StyledOrderInfoFormFooter, StyledPanelsWrapper } from '../../styled';
import OrderPersonalization from './OrderPersonalization';
import CustomerForm from './CustomerForm';
import { Button } from '../../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../../shared/theme';
import { ConfirmDialog } from '../../../../../../shared/components/custom';
import useInfoForm from '../../hooks/forms/useInfoForm';
import useCustomerForm from '../../hooks/forms/useCustomerForm';
import useRemake from '../../hooks/useRemake';
import usePersonalizationsForm from '../../hooks/forms/usePersonalizationsForm';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import GenerationForm from './GenerationForm';
import { CustomButtonProps } from '../../../../../../shared/components/ui/Button/Button';

const SaveButton = ({
  isLoading,
  isDisabled,
  ...props
}: {
  isLoading: boolean;
  isDisabled: boolean;
} & CustomButtonProps) => {
  const { t } = useScopedTranslation('order.info');

  return (
    <Button
      isLoading={isLoading}
      isDisabled={isDisabled}
      type={'button'}
      color={'primary'}
      size={ButtonSizes.sm}
      noShadow
      {...props}
    >
      {t('save')}
    </Button>
  );
};

const OrderInfo = () => {
  const { t } = useScopedTranslation('order.info');

  const [isLoading, setIsLoading] = useState(false);
  const { order, isEdit } = useOrderContext();
  const { remake, isRemaking } = useRemake();

  const { register, isDirty, setValue, onSave, watch, control } = useInfoForm();

  const {
    register: customerReg,
    isDirty: isCustomerDirty,
    onSave: onCustomerSave,
    isLoading: isLoadingCustomer,
    reset: resetCustomer,
  } = useCustomerForm();

  const {
    register: persRegister,
    isDirty: isPersDirty,
    onSave: onPersSave,
    reset: resetPers,
  } = usePersonalizationsForm();

  const isNeedsToRemake = useMemo(() => isDirty || isPersDirty, [isDirty, isPersDirty]);

  const submitAll = async () => {
    setIsLoading(true);
    if (isCustomerDirty) await onCustomerSave();
    if (isDirty) await onSave();
    if (isPersDirty) await onPersSave();
    if (isNeedsToRemake) remake();
    resetCustomer({}, { keepValues: true });
    setIsLoading(false);
  };

  return (
    <>
      {order && (
        <StyledPanelsWrapper>
          <CustomerForm {...{ register: customerReg, isLoading: isLoadingCustomer }} />

          <GenerationForm {...{ setValue, watch, register, control }} />

          <OrderPersonalization
            watchInfo={watch}
            registerRecipient={register}
            resetPersonalizations={resetPers}
            registerPersonalizations={persRegister}
          />

          {isEdit && (
            <StyledOrderInfoFormFooter>
              <div>
                <h3>{t('updating_info')}</h3>
                <p>{t('you_can_change')}</p>
              </div>
              {(isNeedsToRemake && (
                <ConfirmDialog
                  title={t('change_confirm_title')}
                  text={t('change_confirm_text')}
                  onConfirm={submitAll}
                  isLoading={isLoading}
                  activator={
                    <SaveButton
                      isLoading={isLoading}
                      isDisabled={isRemaking || (!isNeedsToRemake && !isCustomerDirty)}
                    />
                  }
                />
              )) || (
                <SaveButton
                  isLoading={isLoading}
                  isDisabled={isRemaking || (!isNeedsToRemake && !isCustomerDirty)}
                  onClick={submitAll}
                />
              )}
            </StyledOrderInfoFormFooter>
          )}
        </StyledPanelsWrapper>
      )}
    </>
  );
};

export default OrderInfo;
