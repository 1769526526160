import React, { useMemo, useState } from 'react';
import {
  StyledHeader,
  StyledPanel,
  StyledSpeedupActionsWrapper,
  StyledSpeedupHeader,
  StyledSpeedupWrapper,
} from '../../styled';
import { Order } from '../../../../../../shared/types/orders';
import { ButtonGroup } from '../../../../../../shared/components/custom';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { Button } from '../../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../../shared/theme';
import { useOrderContext } from '../../hooks/useOrderContext';
import OrderPaymentLink from '../OrderPaymentLink';

const OrderSpeedUp = ({ order }: { order: Order }) => {
  const { t, rootT } = useScopedTranslation('order.speed_up');
  const [speedUpValue, setSpeedUpValue] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<string>('');
  const { speedUpOrder } = useOrderContext();

  const options = useMemo(() => {
    const { speed_up: speedUp } = order;
    return (
      speedUp?.prices?.map((price) => ({
        label: `${rootT('order.speed_up.days', { count: price.value })} - $${price.price}`,
        value: price.value,
      })) || []
    );
  }, [order]);

  const currentValue = order?.speed_up?.current || null;
  const currentOption = useMemo(() => {
    return options.find((option) => option.value === currentValue) ?? null;
  }, [options]);

  const speedUp = async () => {
    setIsLoading(true);
    const link = await speedUpOrder(speedUpValue);
    setPaymentLink(link);
    setIsLoading(false);
  };

  return (
    <StyledPanel>
      <StyledHeader>{t('speeding_up')}</StyledHeader>
      <StyledSpeedupWrapper>
        {(!currentOption && (
          <>
            <div>
              <StyledSpeedupHeader>{t('order_ready_in')}</StyledSpeedupHeader>
              <ButtonGroup options={options} onChange={setSpeedUpValue} />
            </div>
            <StyledSpeedupActionsWrapper>
              <Button
                onClick={speedUp}
                isDisabled={!speedUpValue}
                color={'primary'}
                size={ButtonSizes.sm}
                noShadow
                isLoading={isLoading}
              >
                {t('create_payment')}
              </Button>
            </StyledSpeedupActionsWrapper>
          </>
        )) ||
          (currentOption && (
            <div>
              <StyledSpeedupHeader>{t('order_is_sped_up')}</StyledSpeedupHeader>
              <Button color={'primary'} noShadow size={ButtonSizes.sm}>
                {currentOption.label}
              </Button>
            </div>
          ))}
      </StyledSpeedupWrapper>

      <OrderPaymentLink link={paymentLink} />
    </StyledPanel>
  );
};

export default OrderSpeedUp;
