import React from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableListItemType } from '../../../hooks/useSortableList';

const SortableList = ({
  items,
  setItems,
  children,
  onDrag,
  isSortable = true,
}: {
  items: SortableListItemType[];
  setItems: Function;
  children: React.ReactNode[];
  onDrag: Function;
  isSortable?: boolean;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    if (!isSortable) return;

    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.map((item) => item.id).indexOf(active?.id as number);
      const newIndex = items.map((item) => item.id).indexOf(over?.id as number);
      const sortedItems = arrayMove(items, oldIndex, newIndex);
      setItems(sortedItems);
      onDrag(sortedItems);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
