import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useUserApi from '../../../../shared/requests/user/useUserApi';
import { ControlPaths } from '../../../../router/routes';
import { PageBreadcrumbs } from '../../../../shared/components/custom';
import {
  StyledSectionSubtitle,
  StyledSectionTitle,
  StyledUserPageWrapper,
  StyledUserPreview,
} from './styled';
import { UserContextProvider } from './context/OrderContext';
import InfoForm from './components/InfoForm';
import RolesForm from './components/RolesForm';
import DangerZone from './components/DangerZone';
import { toPrettyDate } from '../../../../shared/utils/utils';
import { Avatar } from '@chakra-ui/react';
import UserPageSkeleton from './UserPageSkeleton';
import { useScopedTranslation } from '../../../../shared/hooks';
import useHasPermission from '../../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../../shared/constants/Permissions';

const UserPage = () => {
  const { t } = useScopedTranslation('users.page');

  const { userId } = useParams();
  const { user, loadUser, updateUser, deleteUser } = useUserApi();

  useEffect(() => {
    if (userId) loadUser(parseInt(userId, 10));
  }, [userId]);

  const pageName = useMemo(() => {
    return (!!user && `${user.name} - ${user.email}`) || '';
  }, [user]);

  const isEdit = useHasPermission(Permissions.userEdit);

  return (
    <UserContextProvider value={{ user, updateUser, deleteUser, isEdit }}>
      <StyledUserPageWrapper>
        <PageBreadcrumbs
          rootPageName={t('users')}
          rootPagePath={ControlPaths.users}
          currentPageName={pageName}
          isLoading={!user}
        />

        {(user && (
          <>
            <StyledUserPreview>
              <Avatar name={user?.name} />
              <div>
                <StyledSectionTitle>{user?.name}</StyledSectionTitle>
                <StyledSectionSubtitle>
                  {user?.email}, {t('since')} {toPrettyDate(user?.created_at, 'MMM dd, yyyy')}
                </StyledSectionSubtitle>
              </div>
            </StyledUserPreview>

            <InfoForm />
            <RolesForm />
            {isEdit && <DangerZone />}
          </>
        )) || <UserPageSkeleton />}
      </StyledUserPageWrapper>
    </UserContextProvider>
  );
};

export default UserPage;
