import { useLocation, useNavigate } from 'react-router-dom';
import { PublicPaths } from '../routes';
import { useEffect, useMemo } from 'react';
import useStoredAccessToken from '../../shared/hooks/useStoredAccessToken';

export const useCheckAuth = () => {
  const storedToken = useStoredAccessToken();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const isPublicPath = useMemo(
    () => (Object.values(PublicPaths) as string[]).includes(pathname),
    [pathname],
  );

  const redirectIfNoPermission = () => {
    navigate(`${PublicPaths.login}?next=${pathname}${search}`, { replace: true });
  };

  useEffect(() => {
    if (!storedToken && !isPublicPath) redirectIfNoPermission();
  }, [pathname, storedToken, isPublicPath]);
};
