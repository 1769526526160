import React from 'react';
import { OptionStatus } from '../../../../../../shared/types/orders';
import { StyledOptionStatus } from '../../styled';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const OptionUpsaleStatus = ({
  status,
  isRemaking,
}: {
  status: OptionStatus;
  isRemaking: boolean;
}) => {
  const { t } = useScopedTranslation('order.options');

  return (
    <StyledOptionStatus status={status}>
      {t((isRemaking && 'is_remaking') || (!!status && `statuses.${status}`) || 'inactive')}
    </StyledOptionStatus>
  );
};

export default OptionUpsaleStatus;
