import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import { Language } from '../types/language';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { setDefaultOptions } from 'date-fns';
import { appLocalStorage } from '../utils/appLocalStorage';
import { LANGUAGE_KEY } from '../constants/mixed';

const resources: Record<Language, { translation: object; dateLocale: Locale }> = {
  en: {
    translation: translationEN,
    dateLocale: en,
  },
  ru: {
    translation: translationRU,
    dateLocale: ru,
  },
};

const defaultLocale = (appLocalStorage.getItem(LANGUAGE_KEY) ?? Language.ENGLISH) as Language;

export const setDatepickerLocale = (lng: Language | null = null) => {
  (Object.keys(resources) as Language[]).map((locale: Language) => {
    registerLocale(locale, resources[locale].dateLocale);
    return locale;
  });

  setDefaultLocale(lng || defaultLocale);
};

export const setDefaultDateLocale = (lng: Language | null = null) => {
  setDefaultOptions({
    locale: resources[lng || defaultLocale].dateLocale,
  });
  setDatepickerLocale(lng);
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: false,
    lng: defaultLocale,
    fallbackLng: Language.ENGLISH,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
