import { ButtonProps, Spinner } from '@chakra-ui/react';
import React from 'react';
import { StyledButton } from './styled';
import { ButtonSizes, ThemeColorButton } from '../../../theme';

export type CustomButtonProps = {
  type?: 'button' | 'submit' | 'reset' | undefined;
  isDisabled?: boolean | false;
  isLoading?: boolean | false;
  color?: ThemeColorButton;
  icon?: React.ReactElement | null;
  children?: React.ReactNode;
  size?: ButtonSizes;
  fab?: boolean;
  noShadow?: boolean;
  block?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button = ({
  type = 'button',
  color = 'secondary',
  size = ButtonSizes.md,
  fab = false,
  noShadow = false,
  block = false,
  onClick = () => {},
  isDisabled,
  isLoading,
  icon,
  children,
  ...props
}: CustomButtonProps & ButtonProps) => {
  return (
    <StyledButton
      {...{
        onClick,
        type,
        isDisabled,
        colortheme: color,
        size,
        isLoading,
        fab: (fab && 1) || 0,
        block: (block && 1) || 0,
        noshadow: (noShadow && 1) || 0,
        ...props,
      }}
    >
      {(isLoading && <Spinner color="currentColor" thickness="1px" speed="0.5s" size="sm" />) || (
        <>
          {icon}
          {children}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
