import { Personalization } from '../../../../shared/types/production/personalization';

const useHandlePersonalizationsList = ({
  personalizations,
  setPersonalizations,
}: {
  personalizations: Personalization[];
  setPersonalizations: Function;
}) => {
  const onCreated = (personalization: Personalization) => {
    setPersonalizations([...personalizations, personalization]);
  };

  const onUpdated = (personalization: Personalization) => {
    const updatedPersonalizations = personalizations.map((pers) => {
      if (pers.id === personalization.id) return personalization;
      return pers;
    });
    setPersonalizations(updatedPersonalizations);
  };

  const onDeleted = (personalization: Personalization) => {
    setPersonalizations(personalizations.filter((pers) => pers.id !== personalization.id));
  };

  return { onCreated, onUpdated, onDeleted };
};

export default useHandlePersonalizationsList;
