import {
  httpDelete,
  httpGet,
  httpPost,
  httpPostFormData,
  httpPut,
} from '../../../shared/utils/http';
import {
  CreatePersonalizationRequest,
  Personalization,
  PersonalizationType,
} from '../../../shared/types/production/personalization';
import { GENERATOR_ROUTE } from '../../constants';

export type PersonalizationApiType = {
  getOccasions: () => Promise<Personalization[]>;
  getTypes: () => Promise<PersonalizationType[]>;
  get: (data: Record<string, string | number>) => Promise<Personalization[]>;
  create: (data: CreatePersonalizationRequest) => Promise<{ data: Personalization }>;
  update: (
    personalizationId: number,
    data: { alias: string; visible: boolean },
  ) => Promise<{ data: Personalization }>;
  delete: (personalizationId: number) => Promise<string>;
  import: (data: FormData) => Promise<{ aliases: string[] }>;
  addSynonyms: (personalizationId: number, names: string[]) => Promise<{ data: Personalization }>;
  deleteSynonym: (
    personalizationId: number,
    synonymId: number,
  ) => Promise<{ data: Personalization }>;
  syncOrder: (ids: number[]) => Promise<string>;
};

export const PersonalizationApi: PersonalizationApiType = {
  getOccasions: () => {
    return httpGet(`${GENERATOR_ROUTE}/occasions`);
  },
  getTypes: () => {
    return httpGet(`${GENERATOR_ROUTE}/personalizations/types`);
  },
  get: (data) => {
    return httpGet(`${GENERATOR_ROUTE}/personalizations`, data);
  },
  create: (data) => {
    return httpPost(`${GENERATOR_ROUTE}/personalizations`, data);
  },
  update: (personalizationId, data) => {
    return httpPut(`${GENERATOR_ROUTE}/personalizations/${personalizationId}`, data);
  },
  delete: (personalizationId) => {
    return httpDelete(`${GENERATOR_ROUTE}/personalizations/${personalizationId}`);
  },
  import: (data) => {
    return httpPostFormData(`${GENERATOR_ROUTE}/personalizations/import`, data);
  },
  addSynonyms: (personalizationId, names) => {
    return httpPost(`${GENERATOR_ROUTE}/personalizations/${personalizationId}/synonyms`, { names });
  },
  deleteSynonym: (personalizationId, synonymId) => {
    return httpDelete(
      `${GENERATOR_ROUTE}/personalizations/${personalizationId}/synonyms/${synonymId}`,
    );
  },
  syncOrder: (ids) => {
    return httpPost(`${GENERATOR_ROUTE}/personalizations/sync`, { ids });
  },
};
