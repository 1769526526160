import { useState } from 'react';
import { CustomerApi } from '../../../api';
import { UpdateCustomerData } from '../../types/customer';

const useCustomerApi = () => {
  const [isLoading, setIsLoading] = useState(false);

  const updateCustomer = async (customerId: string, data: UpdateCustomerData) => {
    setIsLoading(true);
    await CustomerApi.update(customerId, data)
      .then((response) => response)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { updateCustomer, isLoading };
};

export default useCustomerApi;
