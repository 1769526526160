import React, { useEffect } from 'react';
import useGetSegments from '../../../shared/requests/production/useGetSegments';
import ArtistSelector from '../../../shared/components/custom/ArtistSelector/ArtistSelector';
import { Divider } from '@chakra-ui/react';
import { StyledSelectedWrapper } from './styled';
import SelectedArtistCard from '../../../shared/components/custom/ArtistSelector/SelectedArtistCard';
import useGetArtists from '../../../shared/requests/production/useGetArtists';
import SegmentSelector from '../../../shared/components/custom/SegmentSelector/SegmentSelector';
import SelectedSegmentCard from '../../../shared/components/custom/SegmentSelector/SelectedSegmentCard';
import useProcessQueryParams from './hooks/useProcessQueryParams';
import IntroUploader from './components/IntroUploader';

const IntroPage = () => {
  const { artists, isLoading: isLoadingArtists } = useGetArtists();
  const { segments, getSegments, isLoading: isLoadingSegments } = useGetSegments(false);

  const {
    selectedArtist,
    selectedSegment,
    setSelectedSegment,
    setSelectedArtist,
    clearArtist,
    clearSegment,
  } = useProcessQueryParams({ segments, artists });

  useEffect(() => {
    if (selectedArtist) getSegments(selectedArtist.id);
  }, [selectedArtist]);

  return (
    <div>
      <StyledSelectedWrapper>
        {selectedArtist && <SelectedArtistCard artist={selectedArtist} onRemove={clearArtist} />}
        {selectedSegment && (
          <SelectedSegmentCard segment={selectedSegment} onRemove={clearSegment} />
        )}
        {!selectedArtist && !selectedSegment && <p>Select artist and segment</p>}
      </StyledSelectedWrapper>
      <Divider my={'1rem'} />
      {!selectedArtist && (
        <ArtistSelector
          {...{ artists, isLoading: isLoadingArtists, selectedArtist, setSelectedArtist }}
        />
      )}
      {selectedArtist && !selectedSegment && (
        <SegmentSelector
          {...{ segments, selectedSegment, setSelectedSegment, isLoading: isLoadingSegments }}
        />
      )}
      {selectedArtist && selectedSegment && (
        <IntroUploader artist={selectedArtist} segment={selectedSegment} />
      )}
    </div>
  );
};

export default IntroPage;
