import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  StyledDropzoneContainer,
  StyledDropzoneHeader,
  StyledFilesGrid,
  StyledActions,
} from './styled';
import { FileStructure } from '../../../types/production/fileStructure';
import useProcessFilesToUpload from './hooks/useProcessFilesToUpload';
import DroppedFolderCard from './components/DroppedFolderCard';
import { Button } from '../../ui';
import { ButtonSizes } from '../../../theme';
import { useScopedTranslation, useTabFocus } from '../../../hooks';
import useHandleTabInfo from './hooks/useHandleTabInfo';

type IDropzone = {
  scheme: FileStructure;
  onUploadStart: Function;
  isUploading: boolean;
  uploadedNumber: number;
  isFinished?: boolean;
  setIsFinished?: Function;
};

const UploaderDropzone = ({
  scheme,
  onUploadStart,
  isUploading,
  uploadedNumber,
  isFinished = false,
  setIsFinished = () => {},
  ...props
}: IDropzone) => {
  const { t, rootT } = useScopedTranslation('production.intro');
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    accept: {
      'audio/*': ['*'],
    },
    disabled: isUploading,
  });

  const { processedFiles, preparedFiles, totalFiles, reset } = useProcessFilesToUpload({
    acceptedFiles,
    scheme,
  });

  const uploadFiles = () => {
    onUploadStart(preparedFiles);
  };

  useEffect(() => {
    setIsFinished(false);
  }, [preparedFiles]);

  useHandleTabInfo({ isFinished, isUploading, uploadedNumber, totalFiles });

  return (
    <>
      <StyledDropzoneContainer {...{ ...getRootProps(), isDragActive, ...props }}>
        <input {...getInputProps()} />

        <StyledActions>
          {(!!processedFiles.length && (
            <>
              {(isFinished && (
                <StyledDropzoneHeader>
                  {t('finished', { uploaded: uploadedNumber, total: totalFiles })}
                </StyledDropzoneHeader>
              )) || (
                <StyledDropzoneHeader>
                  {(isUploading &&
                    t('uploading', { current: uploadedNumber, total: totalFiles })) ||
                    rootT('production.intro_upload_ready', { count: totalFiles })}
                </StyledDropzoneHeader>
              )}

              {(isFinished && (
                <Button onClick={reset} color={'success'} noShadow size={ButtonSizes.sm}>
                  {t('continue')}
                </Button>
              )) || (
                <>
                  <Button isDisabled={isUploading} onClick={reset} noShadow size={ButtonSizes.sm}>
                    {t('reset')}
                  </Button>
                  <Button
                    isDisabled={isUploading || !totalFiles}
                    onClick={uploadFiles}
                    color={'primary'}
                    noShadow
                    size={ButtonSizes.sm}
                  >
                    {t('upload')}
                  </Button>
                </>
              )}
            </>
          )) || <StyledDropzoneHeader>{t('drop_here')}</StyledDropzoneHeader>}
        </StyledActions>

        {!isFinished && !!processedFiles?.length && (
          <StyledFilesGrid>
            {processedFiles.map((folder, index) => (
              <DroppedFolderCard key={`${folder.name}-${index}`} folder={folder} />
            ))}
          </StyledFilesGrid>
        )}
      </StyledDropzoneContainer>
    </>
  );
};

export default UploaderDropzone;
