import React, { useMemo } from 'react';
import { StyledGraph, StyledQuickStatCard, StyledVerticalDivider } from '../styled';
import { DashboardChart } from './DashboardChart';
import { numberWithCommas, toPrettyDate } from '../../../shared/utils/utils';
import { useScopedTranslation } from '../../../shared/hooks';
import { format } from 'date-fns';

const PaymentsCard = ({
  turnover,
  payments,
  isLoading = false,
}: {
  turnover: number;
  payments: { date: string; total: number }[];
  isLoading?: boolean;
}) => {
  const { t } = useScopedTranslation('dashboard.stats');

  const dataSet = useMemo(
    () =>
      payments.reverse().map((payment) => ({
        label: toPrettyDate(payment.date) ?? payment.date,
        value: payment.total,
      })),
    [payments],
  );

  const todayNumber = useMemo(() => {
    const now = format(new Date(), 'yyyy-MM-dd');
    return payments.find((payment) => payment.date === now)?.total ?? 0;
  }, [payments]);

  return (
    <StyledQuickStatCard>
      <div>
        <span>+${numberWithCommas(Math.round(turnover))}</span> {t('last_month')}
      </div>
      <StyledVerticalDivider />
      <div>
        <span>+${numberWithCommas(Math.round(todayNumber))}</span> {t('today')}
      </div>
      <StyledGraph>
        <DashboardChart dataSet={dataSet} />
      </StyledGraph>
    </StyledQuickStatCard>
  );
};

export default PaymentsCard;
