import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { StyledCustomer, StyledOptionsWrapper, StyledOrderCard } from '../styled';
import { SkeletonRepeater } from '../../../../../shared/components/custom';

const OrderCardSkeleton = () => {
  return (
    <StyledOrderCard>
      <div>
        <Skeleton w={'5rem'} h={'1rem'} mb={'0.5rem'} />
        <Skeleton w={'6rem'} h={'0.75rem'} />
      </div>
      <div>
        <StyledCustomer>
          <Skeleton w={'5rem'} h={'0.75rem'} mb={'0.5rem'} />
          <Skeleton w={'6rem'} h={'0.75rem'} />
        </StyledCustomer>
      </div>
      <div>
        <Skeleton w={'5rem'} h={'0.75rem'} mb={'0.5rem'} />
        <Skeleton w={'6rem'} h={'0.75rem'} mb={'0.5rem'} />
        <Skeleton w={'10rem'} h={'0.5rem'} />
      </div>
      <StyledOptionsWrapper>
        <SkeletonRepeater number={3}>
          <div>
            <Skeleton w={'100%'} h={'0.75rem'} mb={'0.5rem'} />
            <Skeleton w={'6rem'} h={'0.75rem'} ml={'auto'} mb={'0.5rem'} />
            <Skeleton w={'100%'} h={'0.5rem'} mb={'0.5rem'} />
          </div>
        </SkeletonRepeater>
      </StyledOptionsWrapper>
    </StyledOrderCard>
  );
};

export default OrderCardSkeleton;
