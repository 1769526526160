import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroller';

export const StyledOrdersWrapper = styled(InfiniteScroll)`
  display: grid;
  grid-gap: 1rem;
`;

export const StyledOrdersHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.7fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;

  color: ${({ theme }) => theme.colors.text.placeholder};
  font-size: ${({ theme }) => theme.fontSize.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  border-top: 1px solid ${({ theme }) => theme.palette.neutral10};
`;
