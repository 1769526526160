import { Gender } from '../../constants/Gender';

export type Artist = {
  id: number;
  name: string;
  description: string;
  photo_url: string;
  photo_url_thumb: string;
  example_url: string;
  enabled: boolean;
  gender: Gender;
};

export type CreateArtistRequest = {
  name: string;
  description?: string;
  gender: Gender;
  photo?: File;
  example?: File;
  enabled: 'true' | 'false';
};

export type UpdateArtistRequest = {
  name?: string;
  description?: string;
  enabled: boolean;
  gender: string;
};

export type ArtistSegmentScheme = {
  id: number;
  segment: {
    id: number;
    name: string;
  };
  slots: ArtistSegmentSchemeSlot[];
};

export type ArtistSegmentSchemeSlot = {
  id: number;
  type: SlotTypes;
  value: number | string | null;
};

export enum AvailableSlotTypes {
  nameTo = 'name_to',
  nameFrom = 'name_from',
  occasion = 'occasion',
}

export enum SlotTypes {
  nameTo = 'name_to',
  nameFrom = 'name_from',
  timing = 'timing',
  occasion = 'occasion',
}

export enum NameSlotValues {
  maleNames = 'male_names',
  femaleNames = 'female_names',
}
