import { useEffect, useState } from 'react';
import { Order } from '../../../shared/types/orders';
import { OrdersApi } from '../../../api';
import useInfiniteScrollItems from '../../../shared/hooks/useInfiniteScrollItems';

export type OrderFiltersType = {
  status?: string;
  search?: string;
  date_from?: string;
  date_to?: string;
};

type UseLoadOrdersType = {
  limit: number;
} & OrderFiltersType;

const useLoadOrders = ({ limit, status, search, date_to, date_from }: UseLoadOrdersType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState<{ count?: number }>();
  const { items, hasMore, setHasMore, appendItems, resetItems } = useInfiniteScrollItems(limit);

  const getOrders = (reset: boolean = false) => {
    if (isLoading) return;

    setIsLoading(true);

    if (reset) {
      resetItems();
      setMeta(undefined);
    }

    OrdersApi.get({
      limit,
      offset: (!reset && items.length) || 0,
      search,
      status,
      date_to,
      date_from,
    })
      .then((response) => {
        appendItems(response?.data ?? [], reset);
        setMeta({ count: response.count });
        setIsLoading(false);
      })
      .catch(() => {
        setHasMore(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOrders(true);
  }, [limit, status, search, date_to, date_from]);

  return { getOrders, isLoading, orders: items as Order[], hasMore, meta };
};

export default useLoadOrders;
