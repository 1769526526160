import styled from '@emotion/styled';
import { Flex, Grid } from '@chakra-ui/react';
import { ContentContainer } from '../../ui';

export const StyledMainLayout = styled(Grid)`
  grid-template-columns: auto 1fr;
  transition: background 0.3s ease-in;
  background: ${({ theme }) => theme.palette.neutral5};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledContentContainer = styled(ContentContainer)`
  min-height: calc(100vh - 8.75rem);
  padding-top: 1.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: calc(100vh - 9.55rem);
  }
`;

export const StyledPageWrapper = styled(Flex)`
  flex-direction: column;
`;
