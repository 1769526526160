import { useState } from 'react';
import {
  Template,
  UpdateTemplateRequest,
  CreateTemplateRequest,
  UpdateTemplatePersonalization,
  PersonalizationLyrics,
} from '../../types/production/template';
import { TemplatesApi } from '../../../api/modules/production/TemplatesApi';

const useHandleTemplateApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState<Template | null>(null);

  const getTemplate = async (templateId: number) => {
    setIsLoading(true);
    await TemplatesApi.getById(templateId)
      .then((response) => {
        setTemplate(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createTemplate = async (data: CreateTemplateRequest) => {
    setIsLoading(true);
    const createdTemplate = await TemplatesApi.create(data)
      .then((response) => {
        setTemplate(response.data);
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return createdTemplate;
  };

  const updateTemplate = async ({ id }: Template, data: UpdateTemplateRequest) => {
    setIsLoading(true);
    const updatedTemplate = await TemplatesApi.update(id, data)
      .then((response) => {
        setTemplate(response.data);
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return updatedTemplate;
  };

  const deleteTemplate = async ({ id }: Template) => {
    setIsLoading(true);
    await TemplatesApi.delete(id).finally(() => {
      setIsLoading(false);
    });
  };

  const updatePersonalization = async (
    { id }: Template,
    templatePersonalizationId: number,
    data: UpdateTemplatePersonalization,
  ) => {
    setIsLoading(true);
    const updatedTemplate = await TemplatesApi.updatePersonalization(
      id,
      templatePersonalizationId,
      data,
    )
      .then((response) => {
        setTemplate(response.data);
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return updatedTemplate;
  };

  const addLyric = async (
    { id }: Template,
    templatePersonalizationId: number,
    data: PersonalizationLyrics,
  ) => {
    setIsLoading(true);
    const addedLyric = await TemplatesApi.addPersonalizationLyrics(
      id,
      templatePersonalizationId,
      data,
    )
      .then((response) => {
        setTemplate(response.data);
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return addedLyric;
  };

  const updateLyric = async (
    { id }: Template,
    templatePersonalizationId: number,
    personalizationLyricId: number,
    data: PersonalizationLyrics,
  ) => {
    setIsLoading(true);
    const addedLyric = await TemplatesApi.updatePersonalizationLyrics(
      id,
      templatePersonalizationId,
      personalizationLyricId,
      data,
    )
      .then((response) => {
        setTemplate(response.data);
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return addedLyric;
  };

  const deleteLyric = async (
    { id }: Template,
    templatePersonalizationId: number,
    personalizationLyricId: number,
  ) => {
    setIsLoading(true);
    const addedLyric = await TemplatesApi.deletePersonalizationLyrics(
      id,
      templatePersonalizationId,
      personalizationLyricId,
    )
      .then((response) => {
        setTemplate(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return addedLyric;
  };

  return {
    getTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    setTemplate,
    isLoading,
    template,
    updatePersonalization,
    addLyric,
    updateLyric,
    deleteLyric,
  };
};

export default useHandleTemplateApi;
