import { useSearchParams } from 'react-router-dom';
import { FilterQueryParams } from '../constants/production/common';

export const useHandleQueryUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setInstanceByParameter = (
    parameter: FilterQueryParams,
    values: { id: number }[],
    setter: Function,
  ) => {
    const value = searchParams.get(parameter);
    if (value && !!values.length) {
      setter(values.find(({ id }) => id === parseInt(value)) || null);
    } else if (!value) {
      setter(null);
    }
  };

  const removeQueryParam = (param: FilterQueryParams) => {
    const searchParam = searchParams.get(param);
    if (searchParam) {
      searchParams.delete(param);
      setSearchParams(searchParams);
    }
  };

  const setQueryParam = (key: FilterQueryParams, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  return { setInstanceByParameter, setQueryParam, removeQueryParam };
};
