import React, { useState } from 'react';
import { AudioPlayer, Button, FileInput, Switch } from '../../../../shared/components/ui';
import { StyledVisibleWrapper } from '../../segmentPage/styled';
import { ButtonSizes } from '../../../../shared/theme';
import { Icon } from '../../../../shared/components/icons';
import { ConfirmDialog, GenderSelector } from '../../../../shared/components/custom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateArtistSchema } from '../validationScheme';
import useArtistContext from '../hooks/useArtistContext';
import {
  StyledInputsWrapper,
  StyledArtistInfoCard,
  StyledArtistPhoto,
  StyledArtistDescriptionInput,
  StyledArtistNameInput,
  StyledArtistNameWrapper,
} from '../styled';
import { StyledArtistExampleInputWrapper, StyledFileInput } from '../../artists/styled';
import { useNavigateWithParams, useScopedTranslation } from '../../../../shared/hooks';
import { ProductionPaths } from '../../../../router/routes';
import { Gender } from '../../../../shared/constants/Gender';

const ArtistInfo = () => {
  const { t } = useScopedTranslation('production.artists');

  const [isUploadingExample, setIsUploadingExample] = useState(false);
  const { artist, updateArtist, deleteArtist, updateAvatar, updateExample, isEdit } =
    useArtistContext();
  const { navigate } = useNavigateWithParams();
  const [gender, setGender] = useState(artist?.gender);

  const { register, handleSubmit, getValues } = useForm({
    resolver: yupResolver(UpdateArtistSchema),
    defaultValues: {
      name: artist?.name || '',
      description: artist?.description || '',
      enabled: artist?.enabled || false,
      gender: artist?.gender,
    },
  });

  const onSubmit = (args: Object) => {
    updateArtist(artist, { ...args });
  };

  const deleteConfirmed = () => {
    deleteArtist(artist);
    navigate(ProductionPaths.artists);
  };

  const onPhotoChange = (file: File) => {
    updateAvatar(artist, file);
  };

  const exampleUpdated = async (file: File) => {
    setIsUploadingExample(true);
    await updateExample(artist, file);
    setIsUploadingExample(false);
  };

  const updateGender = (value: Gender) => {
    setGender(value);
    updateArtist(artist, { ...getValues(), gender: value });
  };

  return (
    artist && (
      <>
        <StyledArtistInfoCard>
          <StyledArtistPhoto name={artist.name} src={artist.photo_url_thumb} />
          <StyledArtistNameWrapper>
            <StyledArtistNameInput
              name={'name'}
              isReadOnly={!isEdit}
              placeholder={t('enter_name')}
              onBlur={handleSubmit(onSubmit)}
              {...{ register }}
            />
            {isEdit && (
              <FileInput onFileChange={onPhotoChange}>
                <p>{t('change_photo')}</p>
              </FileInput>
            )}
          </StyledArtistNameWrapper>
          <div />
          <GenderSelector gender={gender} setGender={updateGender} />
          <StyledArtistExampleInputWrapper>
            <AudioPlayer src={artist.example_url} />
            {isEdit && (
              <StyledFileInput
                accept={'audio/*'}
                color={'secondary'}
                isLoading={isUploadingExample}
                onFileChange={exampleUpdated}
                label={t((artist.example_url && 'change_example') || 'select_example')}
              />
            )}
          </StyledArtistExampleInputWrapper>
          <StyledVisibleWrapper>
            <span>{t('enabled')}</span>
            <Switch
              isReadOnly={!isEdit}
              name={'enabled'}
              {...{ register }}
              onInput={handleSubmit(onSubmit)}
              defaultChecked={artist.enabled}
            />
          </StyledVisibleWrapper>
          {isEdit && (
            <ConfirmDialog
              title={t('delete_confirm')}
              onConfirm={deleteConfirmed}
              confirmLabel={'Delete'}
              activator={
                <Button noShadow size={ButtonSizes.sm} type={'button'}>
                  <Icon type={'Close'} color={'textMain'}></Icon>
                  {t('delete')}
                </Button>
              }
            />
          )}
        </StyledArtistInfoCard>
        <StyledInputsWrapper>
          <StyledArtistDescriptionInput
            name={'description'}
            isDisabled={!isEdit}
            placeholder={t('enter_description')}
            onBlur={handleSubmit(onSubmit)}
            {...{ register }}
          />
        </StyledInputsWrapper>
      </>
    )
  );
};

export default ArtistInfo;
