import React, { useState } from 'react';
import { StyledAlert, StyledLoginContainer, StyledShowPassButton } from './styled';
import { Button, Input } from '../../shared/components/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginCredentials } from '../../shared/types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Logo from '../../shared/theme/assets/img/logo.svg';
import useLoginWithCredentials from './requests/useLoginWithCredentials';
import { Icon } from '../../shared/components/icons';
import { AlertIcon, AlertTitle } from '@chakra-ui/react';
import { useScopedTranslation } from '../../shared/hooks';

const ValidationScheme = Yup.object().shape({
  email: Yup.string().email().trim().label('E-mail').required(),
  password: Yup.string().trim().label('Password').required(),
});

const LoginPage = () => {
  const { t } = useScopedTranslation('auth');
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const togglePassword = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>({
    mode: 'onChange',
    resolver: yupResolver(ValidationScheme),
  });
  const { login, isLoading, isError } = useLoginWithCredentials();

  const onSubmit: SubmitHandler<LoginCredentials> = (args) => {
    login(args);
  };

  return (
    <StyledLoginContainer>
      <img src={Logo} alt={'logo'} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <p>{t('please_sign_in')}</p>
        <Input
          autoComplete={'email'}
          name={'email'}
          register={register}
          errors={errors}
          placeholder={t('email')}
        />
        <Input
          name={'password'}
          type={(isShowingPassword && 'text') || 'password'}
          register={register}
          placeholder={t('password')}
          autoComplete={'current-password'}
          errors={errors}
          inputRightElement={
            <StyledShowPassButton onClick={togglePassword} active={isShowingPassword}>
              <Icon type={'Eye'} />
            </StyledShowPassButton>
          }
        />

        {isError && (
          <StyledAlert status="error">
            <AlertIcon />
            <AlertTitle>{t('wrong_pass')}</AlertTitle>
          </StyledAlert>
        )}

        <Button isLoading={isLoading} type={'submit'} color={'primary'}>
          {t('sign_in')}
        </Button>
      </form>
    </StyledLoginContainer>
  );
};

export default LoginPage;
