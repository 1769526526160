import { useState } from 'react';
import ReactQuill from 'react-quill';

export const useReactQuill = () => {
  const [quill, setQuill] = useState<ReactQuill | null>(null);

  const addValue = (variable: string) => {
    if (!quill) return;

    const editor = quill.getEditor();

    const range = editor.getSelection();
    const position = (!!range && range.index) || 0;
    editor.insertText(position, variable);
  };

  return { setEditor: setQuill, addValue };
};
