import styled from '@emotion/styled';
import { Box, Text } from '@chakra-ui/react';
import { InvoiceStatuses } from '../../../types/invoice';
import { invoiceStatusToColor } from '../../../utils/orderUtils';

export const StyledInvoiceCard = styled(Box)`
  border-radius: ${({ theme }) => theme.radii.sm};
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
`;

export const StyledInvoiceInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;

    > div:first-of-type {
      grid-column-start: 1;
      grid-column-end: 3;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > div:last-of-type {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

export const StyledInvoiceDate = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin-top: 0.25rem;
`;

export const StyledInvoiceStatus = styled(Text)<{ status: InvoiceStatuses }>`
  background: ${({ status }) => invoiceStatusToColor(status)};
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.white};
  padding: 0.15rem 0.75rem;
  border-radius: ${({ theme }) => theme.radii.xl};
  width: fit-content;
`;

export const StyledOrderId = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;

export const StyledInvoiceReason = styled(Text)`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export const StyledInvoicePromocode = styled(Text)`
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  text-transform: uppercase;
`;

export const StyledInvoiceAmount = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    text-align: end;
  }
`;

export const StyledInvoiceActions = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  justify-content: flex-end;

  button,
  a {
    height: 2.5rem;
    font-size: ${({ theme }) => theme.fontSize.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    button,
    a {
      width: 100%;
    }
  }
`;

export const StyledPositionsInfo = styled.div`
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.palette.neutral10};
`;

export const StyledInvoicePositionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding: 0.25rem 0.5rem;
  border-radius: ${({ theme }) => theme.radii.sm};

  &:nth-of-type(2n-1) {
    background: #e8eaed54;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr auto;

    > div:nth-of-type(2),
    > div:nth-of-type(4) {
      display: none;
    }
  }
`;

export const StyledInitialAmount = styled.span`
  color: ${({ theme }) => theme.palette.neutral60};
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-decoration: line-through;
  margin-left: 1rem;
`;
