import React, { useMemo } from 'react';
import { Menu, MenuList } from '@chakra-ui/react';
import ReactCountryFlag from 'react-country-flag';
import { StyledArrowIcon, StyledLanguageDropdown, StyledLanguageMenuItem } from './styled';
import { getLanguages } from './helpers';
import { useChangeLanguage, useScopedTranslation } from '../../../hooks';

const LanguageChanger = ({ ...props }) => {
  const { t } = useScopedTranslation('user');
  const { changeLanguage, currentLanguage } = useChangeLanguage();
  const languages = getLanguages();

  const currentCode = useMemo(() => {
    const currentLang = languages.find((lang) => lang.value === currentLanguage);
    return (currentLang && currentLang.code) || '';
  }, [currentLanguage]);

  const setLanguage = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const { lang } = evt?.currentTarget?.dataset || {};
    changeLanguage(lang ?? '');
  };

  return (
    (currentCode && (
      <Menu placement="bottom-end">
        {({ isOpen }) => (
          <>
            <StyledLanguageDropdown {...props}>
              <ReactCountryFlag countryCode={currentCode} svg />
              <StyledArrowIcon type={'ChevronLeft'} open={isOpen} />
            </StyledLanguageDropdown>
            <MenuList>
              {languages.map((lang) => (
                <StyledLanguageMenuItem
                  key={lang.value}
                  data-lang={lang.value}
                  onClick={setLanguage}
                >
                  <ReactCountryFlag countryCode={lang.code} svg />
                  {t(lang.label)}
                </StyledLanguageMenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    )) || <></>
  );
};

export default LanguageChanger;
