import { httpGet } from '../../shared/utils/http';
import { Order } from '../../shared/types/orders';
import { StatsResponse } from '../../shared/types/dashboard';
import { DASHBOARD_ROUTES } from '../constants';

export type DashboardApiType = {
  stats: () => Promise<StatsResponse>;
  attention: () => Promise<Order[]>;
};

export const DashboardApi: DashboardApiType = {
  stats: () => {
    return httpGet(`${DASHBOARD_ROUTES}/statistic`);
  },
  attention: () => {
    return httpGet(`${DASHBOARD_ROUTES}/attention`);
  },
};
