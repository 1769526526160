import { useEffect, useState } from 'react';

export const useImageThumbnail = (thumbnail: string, fallback: string) => {
  const [src, setSrc] = useState(thumbnail || fallback);

  const onError = () => {
    setSrc(fallback);
  };

  useEffect(() => {
    setSrc(thumbnail || fallback);
  }, [thumbnail]);

  return { src, onError };
};
