import { FileWithPath } from 'react-dropzone';
import {
  FileStructure,
  FileStructureTypes,
  FileStructureUnit,
} from '../../../types/production/fileStructure';

export type PreparedFile = {
  name: string;
  file_url: string;
  file: FileWithPath;
};

export type UploaderFolder = {
  name: string;
  error: FileWithPath[];
  success: PreparedFile[];
};

export type UploadFolderCreate = (name: string) => UploaderFolder;

export const createFolder: UploadFolderCreate = (name = '') => ({
  name,
  error: [],
  success: [],
});

const checkIfUnitHasFile = (
  unit: FileStructureUnit,
  filePath: string,
  rootPath: string,
): FileStructureUnit | null => {
  if (unit.type === FileStructureTypes.folder) {
    const children = unit.children ?? [];
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      const found = checkIfUnitHasFile(child, filePath, rootPath);
      if (found) return found;
    }
    return null;
  }
  const lowerUnitUrl = unit.file_url?.toLowerCase() ?? '';
  const lowerFilepath = `${rootPath}/${filePath}`.toLowerCase().replaceAll('//', '/');

  if (lowerUnitUrl === lowerFilepath) return unit;
  return null;
};

export const findInScheme = (
  scheme: FileStructure,
  file: FileWithPath,
): FileStructureUnit | null => {
  for (let i = 0; i < scheme.scheme.length; i += 1) {
    const unit = scheme.scheme[i];
    const found = checkIfUnitHasFile(unit, file.path ?? '', scheme.root_url);
    if (found) return found;
  }
  return null;
};
