import { httpGet } from '../../shared/utils/http';
import { Invoice, InvoicesListRequest } from '../../shared/types/invoice';
import { INVOICES_ROUTE } from '../constants';

export type InvoiceApiType = {
  get: (
    filters: InvoicesListRequest,
  ) => Promise<{ data: Invoice[]; count: number; total_amount: number }>;
  find: (invoiceId: string) => Promise<Invoice>;
};

export const InvoiceApi: InvoiceApiType = {
  get: (filters) => {
    return httpGet(`${INVOICES_ROUTE}`, filters);
  },
  find: (invoiceId) => {
    return httpGet(`${INVOICES_ROUTE}/${invoiceId}`);
  },
};
