import React, { useEffect, useState } from 'react';
import { AudioPlayer } from '../../../../shared/components/ui';
import { StyledArtistExampleInputWrapper, StyledFileInput } from '../styled';
import { readAsDataUrl } from '../../../../shared/utils/imageUtils';
import { useScopedTranslation } from '../../../../shared/hooks';

const ArtistExampleInput = ({
  example,
  setExample,
}: {
  example: File | null;
  setExample: Function;
}) => {
  const { t } = useScopedTranslation('production.artists');
  const [exampleTempSrc, setExampleTempSrc] = useState<string>('');

  useEffect(() => {
    if (example) readAsDataUrl(example).then(setExampleTempSrc);
    else setExampleTempSrc('');
  }, [example]);

  return (
    <StyledArtistExampleInputWrapper>
      <AudioPlayer src={exampleTempSrc} withProgress download />
      <StyledFileInput
        accept={'audio/*'}
        color={'secondary'}
        onFileChange={setExample}
        label={(example && t('change_example')) || t('select_example')}
      />
    </StyledArtistExampleInputWrapper>
  );
};

export default ArtistExampleInput;
