/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgCart = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    role="presentation"
    className="chakra-select__icon"
    focusable="false"
    aria-hidden="true"
  >
    <path {...props} d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
  </svg>
);

export default SvgCart;
