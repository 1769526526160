import React, { useRef } from 'react';
import { Button } from '../Button';
import { useScopedTranslation } from '../../../hooks';
import { CustomButtonProps } from '../Button/Button';

const FileInput = ({
  label = undefined,
  onFileChange,
  accept = '*',
  children = '',
  isLoading = false,
  ...props
}: {
  label?: string;
  onFileChange: Function;
  accept?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
} & CustomButtonProps) => {
  const { t } = useScopedTranslation('general');

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileUploaded = event.target.files[0];
      event.target.value = '';
      onFileChange(fileUploaded);
    }
  };

  return (
    <>
      {(children && <div onClick={handleClick}>{children}</div>) || (
        <Button onClick={handleClick} isLoading={isLoading} {...props}>
          {label || t('select_file')}
        </Button>
      )}
      <input
        accept={accept}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default FileInput;
