import { Language, LanguageFull } from '../../../types/language';

export const getLanguages = () => {
  return [
    {
      value: Language.ENGLISH,
      label: LanguageFull.ENGLISH.toLowerCase(),
      code: 'GB',
    },
    {
      value: Language.RUSSIAN,
      label: LanguageFull.RUSSIAN.toLowerCase(),
      code: 'RU',
    },
  ];
};
