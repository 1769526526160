import React, { useEffect } from 'react';
import { StyledFiltersWrapper } from '../styled';
import { Button, Input, Select } from '../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../shared/theme';
import { useForm } from 'react-hook-form';
import { useDebounce } from '../../../../../shared/hooks/useDebounce';
import { CreateUserModal } from '../../../../../shared/components/custom';
import { useScopedTranslation } from '../../../../../shared/hooks';
import useRolesApi from '../../../../../shared/requests/user/useRolesApi';

const UsersListFilters = ({
  setParams,
  onUserAdded,
}: {
  setParams: Function;
  onUserAdded: Function;
}) => {
  const { t } = useScopedTranslation('users.list.filters');

  const { isLoading: isLoadingRoles, rolesOptions } = useRolesApi();

  const { register, watch } = useForm();
  const role = watch('role');
  const search = watch('search');

  const debouncedSearch = useDebounce(search, 400);

  useEffect(() => {
    setParams({
      search: debouncedSearch,
      role,
    });
  }, [debouncedSearch, role]);

  return (
    <StyledFiltersWrapper>
      <Select
        placeholder={t('all_roles')}
        name={'role'}
        options={rolesOptions}
        isLoading={isLoadingRoles}
        register={register}
      />
      <div />
      <Input placeholder={t('search')} name={'search'} register={register} />
      <CreateUserModal
        onCreated={onUserAdded}
        activator={
          <Button size={ButtonSizes.sm} color={'primary'} noShadow>
            {t('add_user')}
          </Button>
        }
      />
    </StyledFiltersWrapper>
  );
};

export default UsersListFilters;
