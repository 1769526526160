import React from 'react';
import { StyledFormCard } from '../styled';
import { Skeleton } from '@chakra-ui/react';

const SegmentCardSkeleton = () => {
  return (
    <StyledFormCard>
      <div style={{ width: '100%' }}>
        <Skeleton w={'60%'} h={'1.5rem'} mb={'0.25rem'} />
        <Skeleton w={'40%'} h={'1rem'} />
      </div>
      <Skeleton w={'0.25rem'} h={'1rem'} />
    </StyledFormCard>
  );
};

export default SegmentCardSkeleton;
