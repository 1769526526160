import React from 'react';
import { StyledTemplateCard, StyledStatusIcon } from '../styled';
import { Template } from '../../../../types/production/template';

type TemplateCardType = {
  template: Template;
  onSelect: Function;
  isSelected: boolean;
};

const TemplateCard = ({ template, onSelect, isSelected }: TemplateCardType) => {
  const selectTemplate = () => {
    onSelect((!isSelected && template) || null);
  };

  return (
    <StyledTemplateCard role={'button'} selected={isSelected} onClick={selectTemplate}>
      <span>{template.name}</span>
      <StyledStatusIcon enabled={template.enabled} type={'Dot'}></StyledStatusIcon>
    </StyledTemplateCard>
  );
};

export default TemplateCard;
