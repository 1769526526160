import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';
import { Button } from '../../ui';

export const StyledHeaderWrapper = styled(Flex)`
  align-items: center;
  height: 4rem;
  padding: 0.75rem 1.25rem;
  grid-gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.card};
`;

export const StyledHeaderButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.neutral10};
  position: relative;
`;

export const StyledLogo = styled(Flex)`
  flex: 1 1;
  justify-content: center;
`;

export const StyledMenuButtonWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
`;
