import { useEffect, useState } from 'react';
import { GeneratorApi } from '../../../api';
import { SelectOption } from '../../types/common';
import { Personalization } from '../../types/production/personalization';

const useGeneratorPersonalizations = (segmentId: number | null = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [namesTo, setNamesTo] = useState<SelectOption[]>([]);
  const [namesFrom, setNamesFrom] = useState<SelectOption[]>([]);
  const [months, setMonths] = useState<SelectOption[]>([]);
  const [eyes, setEyes] = useState<SelectOption[]>([]);
  const [years, setYears] = useState<SelectOption[]>([]);
  const [spouses, setSpouses] = useState<SelectOption[]>([]);
  const [kids, setKids] = useState<SelectOption[]>([]);

  const personalizationsToListValue = (pers: Personalization[]) =>
    pers.map(({ name }) => ({ value: name, label: name }));

  const getSegmentPersonalizations = async (segment: number) => {
    setIsLoading(true);
    await GeneratorApi.getSegmentPersonalizations(segment)
      .then((response) => {
        setNamesTo(personalizationsToListValue(response.names_to));
        setNamesFrom(personalizationsToListValue(response.names_from));
        if (!months.length) setMonths(personalizationsToListValue(response.months));
        if (!eyes.length) setEyes(personalizationsToListValue(response.eyes));
        if (!years.length)
          setYears(
            personalizationsToListValue(
              response.start_year.sort((a, b) => parseInt(b.name) - parseInt(a.name)),
            ),
          );
        if (!kids.length) setKids(personalizationsToListValue(response.kids));
        setSpouses(personalizationsToListValue(response.spouse));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (segmentId) getSegmentPersonalizations(segmentId);
  }, [segmentId]);

  return {
    isLoading,
    months,
    eyes,
    years,
    kids,
    spouses,
    namesTo,
    namesFrom,
  };
};

export default useGeneratorPersonalizations;
