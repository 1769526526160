import { useEffect, useRef, useState } from 'react';
import { Events } from '../constants/Events';

type ITracks = {
  id: string;
  state: boolean;
}[];

export const useGlobalAudioHandler = () => {
  const [tracksArray, setTracksArray] = useState<ITracks>([]);

  const tracksRef = useRef(tracksArray);
  const setTracksRef = (data: ITracks) => {
    tracksRef.current = data;
    setTracksArray(data);
  };

  const changeState = (id: string, state: boolean) => {
    window.dispatchEvent(
      new CustomEvent(`${Events.audioStateChanged}-${id}`, { detail: { state } }),
    );
  };

  const removeTrack = (id: string) => {
    setTracksRef(tracksRef.current.filter((track) => track.id !== id));
  };

  const changeTrackState = (tracks: ITracks, id: string, state: boolean) =>
    tracks.map((track) => {
      const newState = (track.id === id && state) || false;
      changeState(track.id, newState);
      return { ...track, state: newState };
    });

  const addOrUpdateTrack = (id: string, state: boolean) => {
    const tracks = tracksRef.current;
    const existingTrack = tracks.find((track) => track.id === id);
    if (existingTrack) {
      const updatedTracks = changeTrackState(
        tracks.map((track) => (track.id === id && { ...track, state }) || track),
        id,
        state,
      );
      setTracksRef(updatedTracks);
    } else {
      const updatedTracks = changeTrackState([...tracks, { id, state }], id, state);
      setTracksRef(updatedTracks);
    }
  };

  useEffect(() => {
    window.addEventListener(
      Events.audioStarted,
      (e: CustomEventInit & Event) => {
        e.stopImmediatePropagation();
        const { id } = e.detail;
        addOrUpdateTrack(id, true);
      },
      false,
    );

    window.addEventListener(
      Events.audioStopped,
      (e: CustomEventInit & Event) => {
        e.stopImmediatePropagation();
        const { id } = e.detail;
        addOrUpdateTrack(id, false);
      },
      false,
    );

    window.addEventListener(
      Events.audioDestroyed,
      (e: CustomEventInit & Event) => {
        e.stopImmediatePropagation();
        const { id } = e.detail;
        removeTrack(id);
      },
      false,
    );

    return () => {
      window.removeEventListener(Events.audioStarted, () => {});
      window.removeEventListener(Events.audioStopped, () => {});
      window.removeEventListener(Events.audioDestroyed, () => {});
    };
  }, []);
};
