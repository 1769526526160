import { httpDelete, httpGet, httpPost, httpPut } from '../../../shared/utils/http';
import { CreateSegmentRequest, Segment } from '../../../shared/types/production/segment';
import { Gender } from '../../../shared/constants/Gender';
import { GENERATOR_ROUTE } from '../../constants';

export type SegmentsApiType = {
  get: (data: Record<string, string>) => Promise<Segment[]>;
  getById: (segmentId: number) => Promise<{ data: Segment }>;
  create: (data: CreateSegmentRequest) => Promise<{ data: Segment }>;
  update: (segmentId: number, data: CreateSegmentRequest) => Promise<{ data: Segment }>;
  delete: (segmentId: number) => Promise<string>;
  updateSender: (
    segmentId: number,
    senderId: number,
    data: { sender_gender: Gender },
  ) => Promise<{ data: Segment }>;
  syncOccasions: (segmentId: number, occasionsIds: number[]) => Promise<{ data: Segment }>;
  addPersonalizationType: (segmentId: number, typeId: number) => Promise<{ data: Segment }>;
  deletePersonalizationType: (segmentId: number, typeId: number) => Promise<{ data: Segment }>;
  syncSegmentPersonalizationValues: (
    segmentId: number,
    typeId: number,
    ids: number[],
  ) => Promise<{ data: Segment }>;
  syncOrder: (ids: number[]) => Promise<string>;
};

export const SegmentsApi: SegmentsApiType = {
  get: (params) => {
    return httpGet(`${GENERATOR_ROUTE}/segments`, params);
  },
  getById: (segmentId) => {
    return httpGet(`${GENERATOR_ROUTE}/segments/${segmentId}`);
  },
  create: (data) => {
    return httpPost(`${GENERATOR_ROUTE}/segments`, data);
  },
  update: (segmentId, data) => {
    return httpPut(`${GENERATOR_ROUTE}/segments/${segmentId}`, data);
  },
  delete: (segmentId) => {
    return httpDelete(`${GENERATOR_ROUTE}/segments/${segmentId}`);
  },
  updateSender: (segmentId, senderId, data) => {
    return httpPut(`${GENERATOR_ROUTE}/segments/${segmentId}/senders/${senderId}`, data);
  },
  syncOccasions: (segmentId, occasionsIds) => {
    return httpPost(`${GENERATOR_ROUTE}/segments/${segmentId}/occasions/sync`, {
      occasions_ids: occasionsIds,
    });
  },
  addPersonalizationType: (segmentId, typeId) => {
    return httpPost(`${GENERATOR_ROUTE}/segments/${segmentId}/personalization-types`, {
      personalization_type_id: typeId,
    });
  },
  deletePersonalizationType: (segmentId, typeId) => {
    return httpDelete(`${GENERATOR_ROUTE}/segments/${segmentId}/personalization-types/${typeId}`);
  },
  syncSegmentPersonalizationValues: (segmentId, typeId, ids) => {
    return httpPost(
      `${GENERATOR_ROUTE}/segments/${segmentId}/personalization-types/${typeId}/personalizations/sync`,
      {
        personalizations_ids: ids,
      },
    );
  },
  syncOrder: (ids) => {
    return httpPost(`${GENERATOR_ROUTE}/segments/sync`, {
      ids,
    });
  },
};
