import { useEffect, useState } from 'react';
import { PersonalizationType } from '../../types/production/personalization';
import { PersonalizationApi } from '../../../api';

const useGetPersonalizationType = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState<PersonalizationType[]>([]);

  const getTypes = () => {
    setIsLoading(true);
    PersonalizationApi.getTypes()
      .then((response) => {
        setTypes(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTypes();
  }, []);

  return { getTypes, types, isLoading };
};

export default useGetPersonalizationType;
