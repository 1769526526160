import React from 'react';
import { defaultData } from '../constants';
import { GeneratorDataType, GeneratorResponse } from '../types';

export type ISegmentContext = {
  data: GeneratorDataType;
  setData: React.Dispatch<React.SetStateAction<GeneratorDataType>>;
  result: GeneratorResponse | null;
  produceTrack: Function;
  isProducing: boolean;
};

export const defaultState = {
  data: defaultData,
  setData: () => {},
  result: null,
  produceTrack: () => {},
  isProducing: false,
};

export const GeneratorContext = React.createContext<ISegmentContext>(defaultState);

export const GeneratorContextProvider = GeneratorContext.Provider;
