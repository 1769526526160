import React from 'react';
import {
  StyledButtonsWrapper,
  StyledDivider,
  StyledSynonymForm,
  VariantValuesWrapper,
} from '../styled';
import {
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubheader,
} from '../../../../shared/components/ui';
import { Personalization } from '../../../../shared/types/production/personalization';
import { useScopedTranslation } from '../../../../shared/hooks';
import { useDisclosure } from '@chakra-ui/react';
import { ButtonSizes } from '../../../../shared/theme';
import useSynonyms from '../requests/useSynonyms';

type ISynonymsModal = {
  activator: React.ReactNode;
  personalization: Personalization;
  onUpdate: Function;
  isEdit?: boolean;
};

const SynonymsModal = ({
  activator,
  personalization,
  onUpdate,
  isEdit = false,
  ...props
}: ISynonymsModal) => {
  const { t } = useScopedTranslation('production.personalization');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { synonyms, register, handleSubmit, add, removeValue, onAddSynonym, isSaving } =
    useSynonyms(personalization, onUpdate);

  const onSave = async () => {
    await add();
    onClose();
  };

  const onModalClose = async () => {
    await onSave();
  };

  return (
    <>
      <div className={'activator'} role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal {...{ isOpen, onOpen, onClose: onModalClose, size: 'sm', persistent: true, ...props }}>
        <ModalHeader>{t('synonyms_header')}</ModalHeader>
        <ModalSubheader>{t('synonyms_subheader')}</ModalSubheader>
        <ModalBody>
          <VariantValuesWrapper>
            {(!!synonyms.length &&
              synonyms.map((synonym) => (
                <Chip
                  onClick={removeValue(synonym)}
                  key={synonym.id ?? synonym.name}
                  name={synonym.name}
                  isSelected
                  isEdit={isEdit}
                />
              ))) || <p>{t('no_synonyms')}</p>}
          </VariantValuesWrapper>
          {isEdit && (
            <>
              <StyledDivider />
              <StyledSynonymForm onSubmit={handleSubmit(onAddSynonym)}>
                <Input name={'name'} register={register} placeholder={t('synonym_value')} />
                <Button noShadow size={ButtonSizes.sm} type={'submit'}>
                  {t('add')}
                </Button>
              </StyledSynonymForm>
            </>
          )}
        </ModalBody>
        {isEdit && (
          <ModalFooter>
            <StyledButtonsWrapper>
              <Button
                isLoading={isSaving}
                noShadow
                color={'primary'}
                onClick={onSave}
                size={ButtonSizes.sm}
              >
                {t('save')}
              </Button>
            </StyledButtonsWrapper>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default SynonymsModal;
