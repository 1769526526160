import React, { useEffect, useMemo } from 'react';
import {
  StyledItemsWrapper,
  StyledSidebar,
  StyledSidebarBackdrop,
  StyledSidebarLogo,
} from './styled';
import SidebarItem from './components/SidebarItem';
import { CommonPaths, ControlPaths } from '../../../../router/routes';
import SidebarItemGroup from './components/SidebarItemGroup';
import { productionLinks, settingsLinks } from './constants';
import { Spacer } from '@chakra-ui/react';
import UserInfo from './components/UserInfo';
import SidebarGroupHeader from './components/SidebarGroupHeader';
import { RiFileList2Line } from 'react-icons/ri';
import { Icon } from '@chakra-ui/icons';
import { MdOutlineSpaceDashboard, MdAttachMoney } from 'react-icons/md';
import { TbUsers } from 'react-icons/tb';
import { FiMusic } from 'react-icons/fi';
import { IoSettingsOutline } from 'react-icons/io5';
import Logo from '../../../theme/assets/img/text-logo.svg';
import { useAppContext, useBreakpoints, useScopedTranslation } from '../../../hooks';
import { useLocation } from 'react-router-dom';
import useHasPermission, { checkPermission } from '../../../hooks/useHasPermission';
import { Permissions } from '../../../constants/Permissions';

const Sidebar = () => {
  const { pathname } = useLocation();
  const { isLg } = useBreakpoints();
  const { sidebarCollapsed, setSidebarCollapsed, user } = useAppContext();

  const { t } = useScopedTranslation('sidebar');

  const hideSidebar = () => {
    setSidebarCollapsed(true);
  };

  useEffect(() => {
    setSidebarCollapsed(!isLg);
  }, [pathname, isLg]);

  const canViewOrders = useHasPermission(Permissions.orderView);
  const canViewInvoices = useHasPermission(Permissions.invoiceView);
  const canViewUsers = useHasPermission(Permissions.userView);
  const canViewGenerator = useHasPermission(Permissions.generationView);
  const canChangePaymentSettings = useHasPermission(Permissions.paymentView);
  const canViewMailing = useHasPermission(Permissions.mailingView);
  const canViewRoles = useHasPermission(Permissions.roleView);

  const canAccessSettings = useMemo(
    () => canChangePaymentSettings || canViewMailing || canViewRoles,
    [canChangePaymentSettings, canViewMailing, canViewRoles],
  );

  const settingsItems = useMemo(() => {
    return settingsLinks.filter((item) => checkPermission(user, item.permission));
  }, [user, settingsLinks]);

  const productionItems = useMemo(() => {
    return productionLinks.filter((item) => checkPermission(user, item.permission));
  }, [user, productionLinks]);

  return (
    <>
      <StyledSidebar collapsed={sidebarCollapsed}>
        <StyledSidebarLogo src={Logo} alt={'logo'} />

        <StyledItemsWrapper>
          <SidebarItem to={CommonPaths.dashboard} icon={<Icon as={MdOutlineSpaceDashboard} />}>
            {t('dashboard')}
          </SidebarItem>

          {(canViewOrders || canViewInvoices) && (
            <SidebarGroupHeader>{t('orders')}</SidebarGroupHeader>
          )}
          {canViewOrders && (
            <SidebarItem to={ControlPaths.orders} icon={<Icon as={RiFileList2Line} />}>
              {t('orders')}
            </SidebarItem>
          )}
          {canViewInvoices && (
            <SidebarItem to={ControlPaths.invoices} icon={<Icon as={MdAttachMoney} />}>
              {t('payments')}
            </SidebarItem>
          )}

          {(canViewUsers || canAccessSettings || canViewGenerator) && (
            <SidebarGroupHeader>{t('system')}</SidebarGroupHeader>
          )}
          {canViewUsers && (
            <SidebarItem to={ControlPaths.users} icon={<Icon as={TbUsers} />}>
              {t('users')}
            </SidebarItem>
          )}
          {canAccessSettings && (
            <SidebarItemGroup
              label={t('settings')}
              items={settingsItems}
              icon={<Icon as={IoSettingsOutline} />}
            />
          )}
          {canViewGenerator && (
            <SidebarItemGroup
              label={t('generation')}
              items={productionItems}
              icon={<Icon as={FiMusic} />}
            />
          )}
        </StyledItemsWrapper>

        <Spacer />

        <UserInfo />
      </StyledSidebar>

      <StyledSidebarBackdrop show={!sidebarCollapsed} onClick={hideSidebar} />
    </>
  );
};

export default Sidebar;
