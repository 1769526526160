import React, { useEffect } from 'react';
import ArtistSelector from '../../../shared/components/custom/ArtistSelector/ArtistSelector';
import { Divider } from '@chakra-ui/react';
import { StyledSelectedWrapper } from './styled';
import SelectedArtistCard from '../../../shared/components/custom/ArtistSelector/SelectedArtistCard';
import useGetArtists from '../../../shared/requests/production/useGetArtists';
import useProcessFilters from './hooks/useProcessFilters';
import TemplateUploader from './components/TemplateUploader';
import useGetTemplates from '../../../shared/requests/production/useGetTemplates';
import { Template } from '../../../shared/types/production/template';
import { SelectedTemplateCard, TemplateSelector } from '../../../shared/components/custom';

const TemplatePage = () => {
  const { artists, isLoading: isLoadingArtists } = useGetArtists();
  const {
    getTemplates,
    setTemplates,
    templates,
    isLoading: isLoadingTemplates,
  } = useGetTemplates();

  const {
    selectedArtist,
    selectedTemplate,
    setSelectedArtist,
    setSelectedTemplate,
    clearTemplate,
    clearArtist,
  } = useProcessFilters({
    artists,
    templates,
  });

  useEffect(() => {
    if (selectedArtist) {
      getTemplates({
        artistId: selectedArtist.id,
      });
    }
  }, [selectedArtist]);

  const onTemplateUpdated = (template: Template) => {
    setTemplates(templates.map((templ) => (templ.id === template.id && template) || templ));
    setSelectedTemplate(template);
  };

  const onTemplateDeleted = (template: Template) => {
    setTemplates(templates.filter((templ) => templ.id !== template.id));
    clearTemplate();
  };

  return (
    <div>
      <StyledSelectedWrapper>
        {selectedArtist && <SelectedArtistCard artist={selectedArtist} onRemove={clearArtist} />}
        {selectedTemplate && (
          <SelectedTemplateCard template={selectedTemplate} onRemove={clearTemplate} />
        )}
        {!selectedArtist && <p>Select artist and template</p>}
      </StyledSelectedWrapper>
      <Divider my={'1rem'} />
      {!selectedArtist && (
        <ArtistSelector
          {...{ artists, isLoading: isLoadingArtists, selectedArtist, setSelectedArtist }}
        />
      )}
      {selectedArtist && !selectedTemplate && (
        <TemplateSelector
          {...{
            templates,
            setTemplates,
            selectedTemplate,
            setSelectedTemplate,
            isLoading: isLoadingTemplates,
            artist: selectedArtist,
          }}
        />
      )}
      {selectedTemplate && (
        <TemplateUploader
          template={selectedTemplate}
          onTemplateUpdate={onTemplateUpdated}
          onTemplateDeleted={onTemplateDeleted}
        />
      )}
    </div>
  );
};

export default TemplatePage;
