import React, { useEffect, useState } from 'react';
import {
  StyledFormActionsWrapper,
  StyledFormCard,
  StyledFormInput,
  StyledVisibleWrapper,
} from '../styled';
import { Gender } from '../../../../shared/constants/Gender';
import { useScopedTranslation } from '../../../../shared/hooks';
import { ButtonGroup } from '../../../../shared/components/custom';
import { ButtonGroupTypes } from '../../../../shared/components/custom/ButtonGroup/types';
import { Spacer, useDisclosure } from '@chakra-ui/react';
import { Button } from '../../../../shared/components/ui/Button';
import { CreateSegmentRequest } from '../../../../shared/types/production/segment';
import { ButtonSizes } from '../../../../shared/theme';
import { SegmentsApi } from '../../../../api';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
} from '../../../../shared/components/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddSegmentSchema } from '../validationScheme';
import { Icon } from '../../../../shared/components/icons';

const SegmentFormModal = ({ onCreate }: { onCreate: Function }) => {
  const { t } = useScopedTranslation('production');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreating, setIsCreating] = useState(false);

  const [gender, setGender] = useState<Gender>(Gender.male);
  const receiverGenders = Object.values(Gender).map((value) => ({
    value,
    label: t(`genders.${value}`),
  }));

  const { register, reset, formState, handleSubmit } = useForm({
    resolver: yupResolver(AddSegmentSchema),
  });
  const { isValid } = formState;

  useEffect(() => {
    reset({ name: '', enabled: false });
  }, [isOpen]);

  const onSubmit = (args: Object) => {
    const data = { ...args, receiver_gender: gender } as CreateSegmentRequest;
    setIsCreating(true);
    SegmentsApi.create(data)
      .then((response) => {
        onCreate(response.data);
        onClose();
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  return (
    <>
      <StyledFormCard>
        <span>Add segment</span>
        <Spacer />
        <Button noShadow onClick={onOpen} size={ButtonSizes.sm} fab>
          <Icon type={'Plus'} />
        </Button>
      </StyledFormCard>

      <Modal {...{ isOpen, onClose, onOpen }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Create segment</ModalHeader>
          <ModalBody>
            <StyledFormInput {...{ register }} name={'name'} placeholder={'Enter segment name'} />
            <ButtonGroup
              defaultValue={gender}
              type={ButtonGroupTypes.stack}
              options={receiverGenders}
              onChange={setGender}
            />
            <StyledVisibleWrapper>
              <span>Enabled</span>
              <Switch name={'enabled'} {...{ register }} />
            </StyledVisibleWrapper>
          </ModalBody>
          <ModalFooter>
            <StyledFormActionsWrapper>
              <Button noShadow size={ButtonSizes.sm} type={'button'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                noShadow
                size={ButtonSizes.sm}
                type={'submit'}
                color={'primary'}
                isDisabled={!isValid}
                isLoading={isCreating}
              >
                Add
              </Button>
            </StyledFormActionsWrapper>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default SegmentFormModal;
