import React, { useMemo } from 'react';
import { StyledGraph, StyledQuickStatCard, StyledVerticalDivider } from '../styled';
import { DashboardChart } from './DashboardChart';
import { numberWithCommas, toPrettyDate } from '../../../shared/utils/utils';
import { format } from 'date-fns';
import { useScopedTranslation } from '../../../shared/hooks';
import { StatsResponseOrders } from '../../../shared/types/dashboard';

const OrdersCard = ({
  orders = {
    full: [],
    demo: {
      total: 0,
      received: 0,
    },
  },
  isLoading,
}: {
  orders?: StatsResponseOrders;
  isLoading?: boolean;
}) => {
  const { t, rootT } = useScopedTranslation('dashboard.stats');
  const { full, demo } = orders;

  const dataSet = useMemo(
    () =>
      full.reverse().map((order) => ({
        label: toPrettyDate(order.date) ?? order.date,
        value: order.count,
      })),
    [full],
  );

  const total = useMemo(() => full.reduce((sum, order) => order.count + sum, 0), [full]);

  const todayNumber = useMemo(() => {
    const now = format(new Date(), 'yyyy-MM-dd');
    return full.find((order) => order.date === now)?.count ?? 0;
  }, [full]);

  return (
    <StyledQuickStatCard>
      <div>
        <span>{numberWithCommas(total)}</span>{' '}
        {rootT('dashboard.stats.orders_total', { count: total })}
      </div>
      <StyledVerticalDivider />
      <div>
        <span>{numberWithCommas(todayNumber)}</span> {t('today')}
      </div>
      <StyledGraph>
        <DashboardChart dataSet={dataSet} />
      </StyledGraph>
    </StyledQuickStatCard>
  );
};

export default OrdersCard;
