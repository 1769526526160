/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgCart = (props) => (
  <svg
    {...props}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9054 15.1118C14.5567 15.1105 13.4623 16.2028 13.4609 17.5515C13.4596 18.9003 14.5519 19.9947 15.9007 19.996C17.2494 19.9973 18.3438 18.905 18.3451 17.5563C18.3451 17.5555 18.3451 17.5547 18.3451 17.5539C18.3438 16.2066 17.2526 15.1144 15.9054 15.1118Z"
      fill="#7F8596"
    />
    <path
      d="M20.2368 3.41772C20.1784 3.40641 20.119 3.40066 20.0595 3.40057H5.19033L4.95484 1.82509C4.80811 0.778792 3.91314 0.000321967 2.85658 0H0.941983C0.42173 0 0 0.42173 0 0.941983C0 1.46224 0.42173 1.88397 0.941983 1.88397H2.85893C2.9787 1.88309 3.08007 1.97228 3.09442 2.09122L4.54507 12.0338C4.74395 13.2972 5.83068 14.2293 7.10962 14.2334H16.9086C18.1399 14.235 19.2022 13.3696 19.4496 12.1634L20.9827 4.52152C21.0815 4.01074 20.7476 3.51657 20.2368 3.41772Z"
      fill="#7F8596"
    />
    <path
      d="M9.9226 17.4495C9.86525 16.1403 8.78482 15.1097 7.47437 15.1143C6.12675 15.1687 5.07843 16.3054 5.13289 17.653C5.18514 18.946 6.237 19.9738 7.5309 19.9961H7.58977C8.9372 19.9371 9.98162 18.7969 9.9226 17.4495Z"
      fill="#7F8596"
    />
  </svg>
);

export default SvgCart;
