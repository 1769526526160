import styled from '@emotion/styled';
import { Button } from '../Button';
import { css } from '@emotion/react';

export const StyledVideoContainer = styled.div<{
  width?: string;
  height?: string;
  actionhidden?: boolean;
}>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  position: relative;
  min-height: 5rem;

  video {
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.radii.sm};
  }

  ${({ actionhidden }) =>
    actionhidden &&
    css`
      button {
        opacity: 0;
      }
      &:hover button {
        opacity: 1;
      }
    `}
`;

export const StyledPlayButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background: ${({ theme }) => theme.palette.white};
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in;

  &:hover {
    background: ${({ theme }) => theme.palette.neutral10};
  }

  > svg {
    margin-left: 2px;
    fill: ${({ theme }) => theme.palette.neutral100};
  }
`;
