import React, { useEffect } from 'react';
import { StyledImageWrapper } from '../styled';
import { Button, FileInput, Input } from '../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../shared/theme';
import { useScopedTranslation } from '../../../../../shared/hooks';
import { readAsDataUrl } from '../../../../../shared/utils/imageUtils';
import { useMailingContext } from '../hooks/useMailingContext';

const ImageInput = ({
  imageFile,
  setImageFile,
  setFilePreview,
}: {
  imageFile: File | null;
  setImageFile: Function;
  setFilePreview: (value: string) => void;
}) => {
  const { t } = useScopedTranslation('mailing.page');

  const { register } = useMailingContext();

  const resetImageFile = () => {
    setImageFile(null);
  };

  useEffect(() => {
    if (imageFile) readAsDataUrl(imageFile).then(setFilePreview);
    else setFilePreview('');
  }, [imageFile]);

  return (
    <div>
      <p>{t('image')}</p>
      <StyledImageWrapper>
        {(imageFile && (
          <StyledImageWrapper>
            <div>
              <p>{imageFile.name}</p>
              <p>
                {imageFile.size / 1024000} {t('mb')}
              </p>
            </div>
            <Button onClick={resetImageFile} type={'button'} size={ButtonSizes.sm} noShadow>
              {t('reset')}
            </Button>
          </StyledImageWrapper>
        )) || (
          <StyledImageWrapper>
            <Input name={'image_url'} register={register} placeholder={t('enter_image')} />
            <FileInput
              noShadow
              size={ButtonSizes.sm}
              label={t((imageFile && 'change_file') || 'select_file')}
              onFileChange={setImageFile}
              accept={'image/*'}
            />
          </StyledImageWrapper>
        )}
      </StyledImageWrapper>
    </div>
  );
};

export default ImageInput;
