import React, { useEffect } from 'react';
import useGeneratorContext from '../hooks/useGeneratorContext';
import { Select } from '../../../../shared/components/ui';
import useGetOccasions from '../requests/useGetOccasions';
import { useForm } from 'react-hook-form';
import { useScopedTranslation } from '../../../../shared/hooks';

const OccasionSelector = () => {
  const { t } = useScopedTranslation('production.generator');

  const { isLoading, occasions, getOccasions } = useGetOccasions();
  const { data, setData } = useGeneratorContext();

  useEffect(() => {
    if (data.segment_id) getOccasions(data.segment_id);
  }, [data.segment_id]);

  const { register, reset, watch } = useForm();
  const segmentId = watch('occasion_id');

  useEffect(() => {
    reset({ occasion_id: occasions[0]?.value ?? null });
  }, [occasions]);

  useEffect(() => {
    setData({ ...data, occasion_id: parseInt(segmentId) });
  }, [segmentId]);

  return (
    <Select
      placeholder={t('select_occasion')}
      name={'occasion_id'}
      options={occasions}
      isDisabled={isLoading}
      {...{ register }}
    />
  );
};

export default OccasionSelector;
