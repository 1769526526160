export type InvoicesListRequest = {
  limit: number;
  offset: number;
  status?: string;
  search?: string;
  date_from?: string;
  date_to?: string;
};

export enum PaymentReason {
  order = 'order',
  options = 'options',
  speed_up = 'speed_up',
}

export type PaymentPosition = {
  id: number;
  name: string;
  final_amount: number;
  discount_amount: number;
  amount: number;
  created_at: string;
};

export enum InvoiceStatuses {
  waiting = 'waiting',
  paid = 'paid',
  expired = 'expired',
}

export type Invoice = {
  id: number;
  reason: PaymentReason;
  transaction: string;
  amount: number;
  final_amount: number;
  discount_amount: number;
  receipt: string;
  payment_url: string;
  order_id: number;
  order_uuid: string;
  positions: PaymentPosition[];
  status: InvoiceStatuses;
  created_at: string;
  promocode: string;
};

export type InvoicesMeta = {
  count: number;
  total_amount: number;
};
