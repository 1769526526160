import React from 'react';
import { StyledContentContainer } from './styled';

const ContentContainer = ({
  children,
  fluid = false,
  ...props
}: {
  children: React.ReactNode;
  fluid?: boolean;
}) => {
  return (
    <StyledContentContainer {...props} fluid={fluid}>
      {children}
    </StyledContentContainer>
  );
};

export default ContentContainer;
