import styled from '@emotion/styled';
import { Flex, Grid, Text, Icon as ChakraIcon } from '@chakra-ui/react';
import { Card, Input } from '../../../shared/components/ui';
import { Icon } from '../../../shared/components/icons';

export const StyledSegmentsWrapper = styled(Grid)`
  grid-gap: 1rem;
`;

export const StyledFormCard = styled(Card)`
  padding: 1.25rem;
  grid-gap: 1rem;
  display: flex;
  align-items: center;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1.25rem;
  }

  > button {
    width: 3rem;
  }
`;

export const StyledDragHandle = styled(ChakraIcon)`
  z-index: 33;
  width: 1.2rem;
  height: auto;
  opacity: 0.5;
  cursor: grab;

  &:focus {
    cursor: grabbing;
    outline: none;
  }
`;

export const StyledSegmentName = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
`;

export const StyledSegmentFolderName = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledStatusIcon = styled(Icon)<{ enabled: boolean }>`
  display: inline-block;
  margin-right: 0.5rem;

  &,
  path {
    fill: ${({ theme, enabled }) => (enabled && theme.palette.success) || theme.palette.error};
  }
`;

export const StyledFormInput = styled(Input)`
  margin-bottom: 1rem;
`;

export const StyledVisibleWrapper = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.palette.neutral5};
  border-radius: ${({ theme }) => theme.radii.sm};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  > span {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    color: ${({ theme }) => theme.colors.text.main};
  }
`;

export const StyledFormActionsWrapper = styled(Flex)`
  justify-content: flex-end;
  grid-gap: 0.75rem;
`;
