import { useState } from 'react';
import { GeneratorApi } from '../../../../api';
import { GeneratorDataType, GeneratorResponse } from '../types';

const UseGeneratorApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<GeneratorResponse | null>(null);

  const produceTrack = async (data: GeneratorDataType) => {
    setResult(null);
    setIsLoading(true);
    await GeneratorApi.produceTrack(data)
      .then((response) => {
        setResult(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadVoice = async (file: File | Blob) => {
    setIsLoading(true);
    const res = await GeneratorApi.uploadVoice(file)
      .then((response) => {
        return response;
      })
      .finally(() => {
        setIsLoading(false);
      });
    return res;
  };

  return {
    isLoading,
    produceTrack,
    uploadVoice,
    result,
  };
};

export default UseGeneratorApi;
