import { useEffect, useMemo, useState } from 'react';
import {
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { DropzoneFile } from '../types/dropzoneFile';
import { SlidesPhoto } from '../types/slidesPhoto';
import { MAX_SLIDES_PHOTOS } from '../constants/mixed';

export const useSortableDropzone = ({
  files,
  setFiles,
  maxFiles = MAX_SLIDES_PHOTOS,
  onSort,
  isSortable = true,
}: {
  files: DropzoneFile[];
  setFiles: Function;
  maxFiles?: number;
  isSortable?: boolean;
  onSort: (sortedItems: SlidesPhoto[]) => void;
}) => {
  const [dropzoneFiles, setDropzoneFiles] = useState<DropzoneFile[]>(files);
  const [activeFile, setActiveFile] = useState<DropzoneFile | undefined>(undefined);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  useEffect(() => {
    setDropzoneFiles(files);
  }, [files]);

  const filesLeft = useMemo(() => maxFiles - dropzoneFiles?.length ?? 0, [maxFiles, dropzoneFiles]);

  const handleDragStart = (event: DragStartEvent) => {
    if (isSortable) setActiveFile(dropzoneFiles.find((fl) => fl.name === event.active.id));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!isSortable) return;

    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = dropzoneFiles.map((fl) => fl.name).indexOf(active?.id as string);
      const newIndex = dropzoneFiles.map((fl) => fl.name).indexOf(over?.id as string);

      const sortedItems = arrayMove(dropzoneFiles, oldIndex, newIndex);
      setDropzoneFiles(sortedItems);
      setFiles(sortedItems);
      onSort(sortedItems);
    }

    setActiveFile(undefined);
  };

  const handleDragCancel = () => {
    setActiveFile(undefined);
  };

  return {
    sensors,
    dropzoneFiles,
    activeFile,
    filesLeft,
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
  };
};
