import React, { useMemo } from 'react';
import { useOrderContext } from '../../hooks/useOrderContext';
import { StyledAudioCard, StyledPanelsWrapper, StyledTracksPanel } from '../../styled';
import { AudioPlayer, Tabs } from '../../../../../../shared/components/ui';
import useOrderOptions from '../../hooks/useOrderOptions';
import SlideshowSection from './SlideshowSection';
import ClientVoice from './ClientVoice';
import PostCard from './PostCard';
import { useScopedTranslation } from '../../../../../../shared/hooks';

const OrderMaterials = () => {
  const { t } = useScopedTranslation('order.materials');
  const { order } = useOrderContext();
  const { clientVoice, video } = useOrderOptions(order);
  const result = order?.result;
  const voiceUrl = order?.client_voice_url ?? '';

  const tabs = useMemo(() => {
    const arr = [t('artists_intro'), t('demo'), t('no_intro_tab')];
    if (!!clientVoice) return [t('client_voice'), ...arr];
    return arr;
  }, [order]);

  const trackItems = useMemo(() => {
    const items = [result?.track?.url ?? '', result?.demo?.url ?? '', result?.no_intro?.url ?? ''];
    if (!!clientVoice) return [result?.client_voice?.url ?? '', ...items];
    return items;
  }, [result]);

  return (
    (!!order && (
      <StyledPanelsWrapper>
        <StyledTracksPanel>
          <Tabs tabs={tabs}>
            {trackItems.map((track, index) => (
              <>
                {(track && (
                  <StyledAudioCard key={`track-${index}`}>
                    <AudioPlayer src={track} withProgress withDuration download />
                  </StyledAudioCard>
                )) || (
                  <StyledAudioCard>
                    <p>{t('not_ready')}</p>
                  </StyledAudioCard>
                )}
              </>
            ))}
          </Tabs>
        </StyledTracksPanel>

        <PostCard />

        {clientVoice && <ClientVoice voiceUrl={voiceUrl} />}

        {video && <SlideshowSection slideshow={video} />}
      </StyledPanelsWrapper>
    )) || <></>
  );
};

export default OrderMaterials;
