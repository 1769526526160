import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  ProgressHandler,
  uploadWithProgress,
} from '../../../shared/utils/http';
import { objectToFormData } from '../../../shared/utils/utils';
import { FileStructure } from '../../../shared/types/production/fileStructure';
import { PreparedFile } from '../../../shared/components/custom/UploaderDropzone/helpers';
import {
  CreateTemplateRequest,
  PersonalizationLyrics,
  Template,
  UpdateTemplatePersonalization,
  UpdateTemplateRequest,
} from '../../../shared/types/production/template';
import { GENERATOR_ROUTE } from '../../constants';

export type TemplatesApiType = {
  getById: (templateId: number) => Promise<{ data: Template }>;
  get: (data?: Record<string, string>) => Promise<Template[]>;
  create: (data: CreateTemplateRequest) => Promise<{ data: Template }>;
  update: (templateId: number, data: UpdateTemplateRequest) => Promise<{ data: Template }>;
  delete: (templateId: number) => Promise<string>;
  getScheme: (templateId: number) => Promise<FileStructure>;
  uploadTemplateFile: (
    templateId: number,
    file: PreparedFile,
    onProgressChange: ProgressHandler,
  ) => Promise<boolean>;
  updatePersonalization: (
    templateId: number,
    templatePersonalizationId: number,
    data: UpdateTemplatePersonalization,
  ) => Promise<{ data: Template }>;
  addPersonalizationLyrics: (
    templateId: number,
    templatePersonalizationId: number,
    data: PersonalizationLyrics,
  ) => Promise<{ data: Template }>;
  updatePersonalizationLyrics: (
    templateId: number,
    templatePersonalizationId: number,
    personalizationLyricId: number,
    data: PersonalizationLyrics,
  ) => Promise<{ data: Template }>;
  deletePersonalizationLyrics: (
    templateId: number,
    templatePersonalizationId: number,
    personalizationLyricId: number,
  ) => Promise<{ data: Template }>;
};

export const TemplatesApi: TemplatesApiType = {
  getById: (templateId) => {
    return httpGet(`${GENERATOR_ROUTE}/templates/${templateId}`);
  },
  get: (data) => {
    return httpGet(`${GENERATOR_ROUTE}/templates`, data);
  },
  create: (data) => {
    return httpPost(`${GENERATOR_ROUTE}/templates`, data);
  },
  update: (templateId, data) => {
    return httpPut(`${GENERATOR_ROUTE}/templates/${templateId}`, data);
  },
  delete: (templateId) => {
    return httpDelete(`${GENERATOR_ROUTE}/templates/${templateId}`);
  },
  getScheme: (templateId) => {
    return httpGet(`${GENERATOR_ROUTE}/templates/${templateId}/files`);
  },
  uploadTemplateFile: (templateId, file, onProgressChange) => {
    return uploadWithProgress(
      `${GENERATOR_ROUTE}/templates/${templateId}/files`,
      objectToFormData(file),
      onProgressChange,
    );
  },
  updatePersonalization: (templateId, templatePersonalizationId, data) => {
    return httpPut(
      `${GENERATOR_ROUTE}/templates/${templateId}/personalizations/${templatePersonalizationId}`,
      data,
    );
  },
  addPersonalizationLyrics: (templateId, templatePersonalizationId, data) => {
    return httpPost(
      `${GENERATOR_ROUTE}/templates/${templateId}/personalizations/${templatePersonalizationId}/lyrics`,
      data,
    );
  },
  updatePersonalizationLyrics: (
    templateId,
    templatePersonalizationId,
    personalizationLyricId,
    data,
  ) => {
    return httpPut(
      `${GENERATOR_ROUTE}/templates/${templateId}/personalizations/${templatePersonalizationId}/lyrics/${personalizationLyricId}`,
      data,
    );
  },
  deletePersonalizationLyrics: (templateId, templatePersonalizationId, personalizationLyricId) => {
    return httpDelete(
      `${GENERATOR_ROUTE}/templates/${templateId}/personalizations/${templatePersonalizationId}/lyrics/${personalizationLyricId}`,
    );
  },
};
