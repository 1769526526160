import React from 'react';
import {
  StyledGeneralInfoWrapper,
  StyledInfoWrapper,
  StyledSectionHeader,
  StyledVisibleWrapper,
} from '../styled';
import useSegmentContext from '../hooks/useSegmentContext';
import { Button, Input, Switch } from '../../../../shared/components/ui';
import { ButtonGroup, ConfirmDialog } from '../../../../shared/components/custom';
import { Gender } from '../../../../shared/constants/Gender';
import { useNavigateWithParams, useScopedTranslation } from '../../../../shared/hooks';
import { ProductionPaths } from '../../../../router/routes';
import { ButtonSizes } from '../../../../shared/theme';
import { Icon } from '../../../../shared/components/icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddSegmentSchema } from '../../segments/validationScheme';
import { Divider } from '@chakra-ui/react';

const GeneralInfo = () => {
  const { t, rootT } = useScopedTranslation('production.segments');
  const { navigate } = useNavigateWithParams();
  const { segment, updateSegment, deleteSegment, isEdit } = useSegmentContext();

  const genders = Object.values(Gender).map((value) => ({
    value,
    label: rootT(`production.genders.${value}`),
  }));

  const deleteConfirmed = async () => {
    await deleteSegment(segment);
    navigate(ProductionPaths.segments);
  };

  const saveGender = (receiverGender: Gender) => {
    if (segment?.receiver_gender !== receiverGender)
      updateSegment(segment, { receiver_gender: receiverGender });
  };

  const onSubmit = (args: Object) => {
    updateSegment(segment, { ...args });
  };

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(AddSegmentSchema),
    defaultValues: {
      name: segment?.name || '',
      enabled: segment?.enabled || false,
    },
  });

  return (
    segment && (
      <>
        <StyledInfoWrapper>
          <StyledSectionHeader>
            <p>{t('name_and_state')}</p>
          </StyledSectionHeader>
          <StyledGeneralInfoWrapper onSubmit={handleSubmit(onSubmit)}>
            <Input
              isReadOnly={!isEdit}
              name={'name'}
              placeholder={t('enter_name')}
              onBlur={handleSubmit(onSubmit)}
              {...{ register }}
            />
            <div />
            <StyledVisibleWrapper>
              <span>{t('enabled')}</span>
              <Switch
                isReadOnly={!isEdit}
                name={'enabled'}
                {...{ register }}
                onChange={handleSubmit(onSubmit)}
                defaultChecked={segment.enabled}
              />
            </StyledVisibleWrapper>
            {isEdit && (
              <ConfirmDialog
                title={t('delete_confirm')}
                onConfirm={deleteConfirmed}
                confirmLabel={t('delete')}
                activator={
                  <Button noShadow size={ButtonSizes.sm} type={'button'}>
                    <Icon type={'Close'} color={'textMain'}></Icon>
                    {t('delete')}
                  </Button>
                }
              />
            )}
          </StyledGeneralInfoWrapper>
        </StyledInfoWrapper>
        <Divider />
        <StyledInfoWrapper>
          <StyledSectionHeader>
            <p>{t('recipient')}</p>
          </StyledSectionHeader>
          <ButtonGroup
            isDisabled={!isEdit}
            options={genders}
            defaultValue={segment.receiver_gender}
            onChange={saveGender}
          />
        </StyledInfoWrapper>
      </>
    )
  );
};

export default GeneralInfo;
