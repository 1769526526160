import React, { useContext, useRef } from 'react';
import { LyricsContext } from '../context/LyricsContext';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  StyledMainTextWrapper,
  StyledPersonalizationButtons,
  StyledFormHeader,
  StyledFormSubheader,
  StyledStickySection,
} from '../styled';
import { Button, Textarea } from '../../../ui';
import { ButtonSizes } from '../../../../theme';
import { insertValueIntoText } from '../../../../utils/utils';
import { makeInsertValue } from '../helpers';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { MainLyricScheme } from '../validationScheme';
import { useScopedTranslation } from '../../../../hooks';

type FormType = {
  lyrics: string;
};

const MainTextEditor = () => {
  const { t } = useScopedTranslation('production.templates');

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const { template, updateTemplate } = useContext(LyricsContext);

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormType>({
    defaultValues: { lyrics: template?.lyrics },
    resolver: yupResolver(MainLyricScheme),
  });
  const { ref, ...rest } = register('lyrics');
  const lyrics = watch('lyrics');

  const onSubmit: SubmitHandler<FormType> = async (args) => {
    if (isValid && args.lyrics !== template?.lyrics) await updateTemplate(template, args);
  };

  const addValue = (value: string) => () => {
    const { current: input } = inputRef;
    if (!input) return;

    const result = insertValueIntoText(input, lyrics, makeInsertValue(value));

    const args = { lyrics: result };
    reset(args);
    updateTemplate(template, args);
  };

  return (
    <StyledMainTextWrapper>
      <Textarea
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
        {...{ ...rest, onBlur: handleSubmit(onSubmit) }}
        placeholder={t('enter_template_lyrics')}
      />
      <StyledStickySection>
        <StyledFormHeader>{t('slots')}</StyledFormHeader>
        <StyledFormSubheader>{t('slots_subtitle')}</StyledFormSubheader>
        <StyledPersonalizationButtons>
          {template?.personalizations.map((pers) => (
            <Button
              onClick={addValue(pers.name)}
              noShadow
              size={ButtonSizes.sm}
              key={pers.segment_personalization_id}
            >
              {pers.name}
            </Button>
          ))}
        </StyledPersonalizationButtons>
      </StyledStickySection>
    </StyledMainTextWrapper>
  );
};

export default MainTextEditor;
