import { objectToFormData } from '../../../shared/utils/utils';
import { GENERATOR_ROUTE } from '../../constants';
import { Settings } from '../../../shared/types/production/settings';
import { httpGet, httpPostFormData } from '../../../shared/utils/http';

export type SettingsApiType = {
  getSettings: () => Promise<Settings>;
  updateTag: (file: File) => Promise<string>;
};

export const SettingsApi: SettingsApiType = {
  getSettings: () => {
    return httpGet(`${GENERATOR_ROUTE}/settings`);
  },
  updateTag: (file) => {
    return httpPostFormData(`${GENERATOR_ROUTE}/settings/tag-file`, objectToFormData({ file }));
  },
};
