import styled from '@emotion/styled';
import { Button } from '../../ui';
import { Text } from '@chakra-ui/react';
import { Countdown } from '../Countdown';

export const StyledHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.text.main};
  line-height: 2rem;
  width: 100%;
  margin-bottom: 12.3rem;
`;

export const StyledRecorderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledCounter = styled(Countdown)`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  line-height: 1.5rem;
`;

export const StyledLabel = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-top: 0.75rem;
`;

export const StyledRecorderButton = styled(Button)<{ recording: boolean }>`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 100%;
  border: 3px solid ${({ theme }) => theme.palette.primary};
  background: transparent;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.palette.primary};
    width: ${({ recording }) => (recording && '1.5rem') || '3.1rem'};
    height: ${({ recording }) => (recording && '1.5rem') || '3.1rem'};
    border-radius: ${({ recording }) => (recording && '0.25rem') || '100%'};
    transition: 0.2s ease-in-out;
  }

  &:hover {
    background: transparent;

    &::after {
      width: ${({ recording }) => (recording && '1.7rem') || '2.9rem'};
      height: ${({ recording }) => (recording && '1.7rem') || '2.9rem'};
    }
  }
`;
