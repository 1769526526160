import React, { useEffect, useMemo, useState } from 'react';
import { SegmentPersonalizationType } from '../../../../shared/types/production/segment';
import useSegmentContext from '../hooks/useSegmentContext';
import { Divider, Skeleton, useDisclosure } from '@chakra-ui/react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSubheader,
} from '../../../../shared/components/ui';
import { ButtonSizes } from '../../../../shared/theme';
import {
  Personalization,
  PersonalizationType,
} from '../../../../shared/types/production/personalization';
import useGetPersonalization from '../../../../shared/requests/production/useGetPersonalization';
import {
  VariantValuesWrapper,
  VariantValueItem,
  StyledVariantsModalBody,
  StyledBadge,
} from '../styled';
import { Icon } from '../../../../shared/components/icons';
import { SkeletonRepeater } from '../../../../shared/components/custom';
import { useScopedTranslation } from '../../../../shared/hooks';

type IVariantsModal = {
  type: PersonalizationType;
  variant: SegmentPersonalizationType;
};

type IVariantItem = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  name: string;
  isSelected?: boolean;
  isEdit?: boolean;
};

const Item = ({ onClick, name, isSelected = false, isEdit = false }: IVariantItem) => {
  return (
    <VariantValueItem onClick={onClick} noShadow color={(isSelected && 'primary') || 'secondary'}>
      <span>{name}</span>
      {isEdit &&
        ((isSelected && <Icon type={'Close'} color={'white'} />) || <Icon type={'Plus'} />)}
    </VariantValueItem>
  );
};

const PersonalizationTypeVariantsModal = ({ type, variant }: IVariantsModal) => {
  const { t } = useScopedTranslation('production.segments');

  const {
    segment,
    isLoading: isUpdatingSegment,
    syncSegmentPersonalizationValues,
    isEdit,
  } = useSegmentContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getPersonalizations, personalizations, isLoading } = useGetPersonalization();
  const [selectedValues, setSelectedValues] = useState(variant.values);

  useEffect(() => {
    if (isOpen) getPersonalizations(type.name);
  }, [isOpen, type]);

  const selectValue = (value: Personalization) => () => {
    setSelectedValues([...selectedValues, value]);
  };

  const removeValue = (value: Personalization) => () => {
    setSelectedValues(selectedValues.filter((selVal) => selVal.id !== value.id));
  };

  const syncValues = async () => {
    const ids = selectedValues.map(({ id }) => id);
    await syncSegmentPersonalizationValues(segment, variant.id, ids);
    onClose();
  };

  const otherValues = useMemo(() => {
    return personalizations.filter((pers) => !selectedValues?.includes(pers));
  }, [personalizations, selectedValues]);

  return (
    <>
      <Button style={{ height: '2rem' }} noShadow onClick={onOpen} size={ButtonSizes.sm}>
        {t('customize')}
        {!!selectedValues.length && <StyledBadge>{selectedValues.length}</StyledBadge>}
      </Button>

      <Modal {...{ isOpen, onClose, onOpen }}>
        <ModalHeader>{t('customize_title')}</ModalHeader>
        <ModalSubheader>{t('customize_subtitle')}</ModalSubheader>
        <StyledVariantsModalBody>
          <VariantValuesWrapper>
            {(!!selectedValues.length &&
              selectedValues.map((pers) => (
                <Item
                  onClick={removeValue(pers)}
                  key={pers.id}
                  name={pers.name}
                  isSelected
                  isEdit={isEdit}
                />
              ))) || <p>{t('all_available')}</p>}
          </VariantValuesWrapper>
          <Divider my={'1rem'} />
          <VariantValuesWrapper>
            {(!isLoading &&
              otherValues.map((pers) => (
                <Item onClick={selectValue(pers)} key={pers.id} name={pers.name} isEdit={isEdit} />
              ))) || (
              <SkeletonRepeater number={12}>
                <Skeleton w={`${Math.floor(Math.random() * 7) + 4}rem`} h={'1.75rem'} />
              </SkeletonRepeater>
            )}
          </VariantValuesWrapper>
        </StyledVariantsModalBody>
        <ModalFooter>
          <Button size={ButtonSizes.sm} noShadow onClick={onClose}>
            {t('cancel')}
          </Button>
          {isEdit && (
            <Button
              isLoading={isUpdatingSegment}
              onClick={syncValues}
              size={ButtonSizes.sm}
              noShadow
              color={'primary'}
            >
              {t('save')}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PersonalizationTypeVariantsModal;
