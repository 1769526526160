/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgEdit = (props) => (
  <svg
    {...props}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8341 0C14.464 0 13.1501 0.544263 12.1813 1.51306L6.08874 7.60559C3.29056 10.4038 1.30546 13.9098 0.345697 17.7489L0.051308 18.9264C-0.25402 20.1477 0.852245 21.254 2.07356 20.9487L3.25112 20.6543C7.09018 19.6945 10.5962 17.7094 13.3944 14.9113L19.4869 8.81873C20.4557 7.84994 21 6.53597 21 5.16589C21 2.31285 18.6872 0 15.8341 0ZM14.0832 6.91679C15.0158 7.8494 16.097 8.24666 16.8989 8.10709L17.8371 7.1689C18.3683 6.63767 18.6668 5.91717 18.6668 5.16589C18.6668 3.60145 17.3986 2.33322 15.8341 2.33322C15.0828 2.33322 14.3623 2.63166 13.8311 3.16289L12.8929 4.10108C12.7533 4.90303 13.1506 5.98419 14.0832 6.91679Z"
      fill="white"
    />
  </svg>
);

export default SvgEdit;
