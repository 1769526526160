/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgCopy = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M9.96308 19.0711C7.77797 19.0711 5.99946 17.2925 5.99946 15.1074V5.54834H4.36745C2.95267 5.54834 1.80273 6.6981 1.80273 8.11289V20.7031C1.80273 22.1178 2.95267 23.2678 4.36745 23.2678H16.0249C17.4397 23.2678 18.5896 22.1178 18.5896 20.7031V19.0711H9.96308Z"
        fill="white"
      />
      <path
        d="M22.3201 3.44997C22.3201 2.03331 21.172 0.885254 19.7555 0.885254H9.96316C8.54649 0.885254 7.39844 2.03331 7.39844 3.44997V15.1074C7.39844 16.5241 8.54649 17.6721 9.96316 17.6721H19.7555C21.172 17.6721 22.3201 16.5241 22.3201 15.1074V3.44997Z"
        fill="white"
      />
    </g>
  </svg>
);

export default SvgCopy;
