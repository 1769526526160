import styled from '@emotion/styled';
import { Flex, Grid } from '@chakra-ui/react';
import { Card, FileInput, Input, Textarea } from '../../../shared/components/ui';
import { GenderSelector } from '../../../shared/components/custom';

export const StyledArtistsWrapper = styled(Grid)`
  grid-gap: 1rem;
`;

export const StyledCreateModalActivator = styled(Card)`
  padding: 1.25rem;
  grid-gap: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1.25rem;
  }

  > button {
    width: 3rem;
  }
`;

export const StyledFormInput = styled(Input)`
  margin-bottom: 1rem;
`;

export const StyledTextarea = styled(Textarea)`
  margin-bottom: 0.75rem;
`;

export const StyledFormActionsWrapper = styled(Flex)`
  justify-content: flex-end;
  grid-gap: 0.75rem;
`;

export const StyledArtistAvatarInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > div {
    margin-left: 0.75rem;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};

    &:hover {
      color: ${({ theme }) => theme.colors.text.darker};
    }
  }
`;

export const StyledArtistExampleInputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
`;

export const StyledFileInput = styled(FileInput)`
  height: 3rem;
  box-shadow: none;
`;

export const StyledGenderSelector = styled(GenderSelector)`
  margin-bottom: 1rem;
`;
