import React from 'react';
import { StyledSegmentsWrapper } from './styled';
import useGetSegments from '../../../shared/requests/production/useGetSegments';
import SegmentFormModal from './components/SegmentFormModal';
import { Segment } from '../../../shared/types/production/segment';
import SegmentCard from './components/SegmentCard';
import SegmentCardSkeleton from './components/SegmentCardSkeleton';
import { SkeletonRepeater } from '../../../shared/components/custom';
import useHasPermission from '../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../shared/constants/Permissions';
import SortableList from '../../../shared/components/custom/SortableList/SortableList';
import useHandleSegmentApi from '../../../shared/requests/production/useHandleSegmentApi';

const Segments = () => {
  const { setSegments, segments, isLoading } = useGetSegments();
  const { syncOrder } = useHandleSegmentApi();
  const isEdit = useHasPermission(Permissions.generationEdit);

  const addSegment = (segment: Segment) => {
    setSegments([...segments, segment]);
  };

  const deleteSegment = (segment: Segment) => {
    setSegments(segments.filter((seg) => seg.id !== segment.id));
  };

  return (
    <StyledSegmentsWrapper>
      {(!isLoading && (
        <>
          <SortableList items={segments} setItems={setSegments} onDrag={syncOrder}>
            {segments.map((segment) => (
              <SegmentCard
                segment={segment}
                key={segment.id}
                onDelete={deleteSegment}
                isEdit={isEdit}
              />
            ))}
          </SortableList>

          {isEdit && <SegmentFormModal onCreate={addSegment} />}
        </>
      )) ||
        (isLoading && (
          <SkeletonRepeater number={8}>
            <SegmentCardSkeleton />
          </SkeletonRepeater>
        ))}
    </StyledSegmentsWrapper>
  );
};

export default Segments;
