import React, { useEffect, useMemo, useState } from 'react';
import {
  StyledDropzoneContainer,
  StyledHeader,
  StyledPanel,
  StyledSlideshowActions,
  StyledSlideshowProgressWrapper,
} from '../../styled';
import { Button, ImagesDropzone, VideoPlayer } from '../../../../../../shared/components/ui';
import { useOrderContext } from '../../hooks/useOrderContext';
import { ButtonSizes } from '../../../../../../shared/theme';
import { shareUrl } from '../../../../../../shared/utils/utils';
import { Option, OptionStatus } from '../../../../../../shared/types/orders';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import useHandleSlideshow from '../../../../../../shared/components/ui/ImagesDropzone/hooks/useHandleSlideshow';
import OptionUpsaleProgress from '../addons/OptionUpsaleProgress';
import OptionUpsaleStatus from '../addons/OptionUpsaleStatus';

const SlideshowSection = ({ slideshow }: { slideshow: Option }) => {
  const { t } = useScopedTranslation('order.slideshow');
  const { order, remakeSlideshow, isEdit } = useOrderContext();
  const [isLoading, setIsLoading] = useState(false);
  const slideshowUrl = order?.slideshow?.url ?? '';
  const isRemaking = !!slideshow.remade_at;

  const isFinished = useMemo(() => slideshow.status === OptionStatus.finished, [slideshow]);
  const isPending = useMemo(() => slideshow.status === OptionStatus.pending, [slideshow]);

  const {
    photos,
    setPhotos,
    getPhotos,
    syncPhotos,
    removePhoto,
    isLoading: isLoadingPhoto,
    retryPhoto,
  } = useHandleSlideshow(order?.uuid ?? '');

  useEffect(() => {
    getPhotos();
  }, []);

  const copyLink = () => {
    shareUrl(slideshowUrl, t('slideshow'));
  };

  const remake = async () => {
    setIsLoading(true);
    await remakeSlideshow();
    setIsLoading(false);
  };

  return (
    <StyledPanel>
      <StyledHeader>{t('slideshow')}</StyledHeader>
      <div>
        {isRemaking && (
          <StyledSlideshowProgressWrapper>
            <OptionUpsaleStatus
              status={(isRemaking && OptionStatus.processing) || slideshow?.status}
              isRemaking={isRemaking}
            />
            <OptionUpsaleProgress option={slideshow} isRemaking={isRemaking} />
          </StyledSlideshowProgressWrapper>
        )}

        {slideshowUrl && <VideoPlayer src={slideshowUrl} />}

        <StyledDropzoneContainer>
          <ImagesDropzone
            files={photos}
            setFiles={setPhotos}
            isLoading={isLoadingPhoto}
            onRemove={removePhoto}
            onSort={syncPhotos}
            onDrop={syncPhotos}
            onRetry={retryPhoto}
            isEdit={isEdit}
          />
        </StyledDropzoneContainer>

        {(isPending || isFinished) && (
          <StyledSlideshowActions>
            {slideshowUrl && (
              <Button onClick={copyLink} size={ButtonSizes.sm} noShadow>
                {t('share_link')}
              </Button>
            )}
            {isEdit && (
              <Button
                isLoading={isLoading}
                isDisabled={isRemaking}
                color={'primary'}
                size={ButtonSizes.sm}
                noShadow
                onClick={remake}
              >
                {t('remake')}
              </Button>
            )}
          </StyledSlideshowActions>
        )}
      </div>
    </StyledPanel>
  );
};

export default SlideshowSection;
