import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';
import { Button, ModalBody } from '../../ui';

export const StyledModalBody = styled(ModalBody)`
  display: grid;
  grid-gap: 1rem;
`;

export const StyledFormActionsWrapper = styled(Flex)`
  justify-content: flex-end;
  grid-gap: 0.75rem;
`;

export const StyledRandomButton = styled(Button)`
  background: transparent;
  padding: 0;
  width: 2rem;
  height: 2rem;

  svg {
    width: 1.25rem;
    height: auto;
    fill: ${({ theme }) => theme.palette.neutral60};
  }
`;
