import { useState } from 'react';
import { CreateSegmentRequest, Segment, Sender } from '../../types/production/segment';
import { SegmentsApi } from '../../../api';
import { Gender } from '../../constants/Gender';

const useHandleSegmentApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [segment, setSegment] = useState<Segment | null>(null);

  const getSegment = async (segmentId: number) => {
    setIsLoading(true);
    await SegmentsApi.getById(segmentId)
      .then((response) => {
        setSegment(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateSegment = async (segmentToUpdate: Segment, data: CreateSegmentRequest) => {
    setIsLoading(true);
    await SegmentsApi.update(segmentToUpdate.id, data)
      .then((response) => {
        setSegment(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const syncOccasions = async (segmentToUpdate: Segment, occasionsIds: number[]) => {
    setIsLoading(true);
    await SegmentsApi.syncOccasions(segmentToUpdate.id, occasionsIds)
      .then((response) => {
        setSegment(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateSegmentSender = async (
    segmentToUpdate: Segment,
    sender: Sender,
    data: { sender_gender: Gender },
  ) => {
    setIsLoading(true);
    await SegmentsApi.updateSender(segmentToUpdate.id, sender.id, data)
      .then((response) => {
        setSegment(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteSegment = async (segmentToDelete: Segment) => {
    setIsLoading(true);
    await SegmentsApi.delete(segmentToDelete.id).finally(() => {
      setIsLoading(false);
    });
  };

  const addPersonalizationType = async (segmentToUpdate: Segment, typeId: number) => {
    setIsLoading(true);
    await SegmentsApi.addPersonalizationType(segmentToUpdate.id, typeId)
      .then((response) => {
        setSegment(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deletePersonalizationType = async (segmentToUpdate: Segment, typeId: number) => {
    setIsLoading(true);
    await SegmentsApi.deletePersonalizationType(segmentToUpdate.id, typeId)
      .then((response) => {
        setSegment(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const syncSegmentPersonalizationValues = async (
    segmentToUpdate: Segment,
    typeId: number,
    ids: number[],
  ) => {
    setIsLoading(true);
    await SegmentsApi.syncSegmentPersonalizationValues(segmentToUpdate.id, typeId, ids)
      .then((response) => {
        setSegment(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const syncOrder = async (segments: Segment[]) => {
    const ids = segments.map(({ id }) => id);
    await SegmentsApi.syncOrder(ids)
      .then(() => {})
      .finally(() => {});
  };

  return {
    getSegment,
    updateSegment,
    updateSegmentSender,
    syncOccasions,
    deleteSegment,
    setSegment,
    isLoading,
    segment,
    addPersonalizationType,
    deletePersonalizationType,
    syncSegmentPersonalizationValues,
    syncOrder,
  };
};

export default useHandleSegmentApi;
