import React, { useState } from 'react';
import { Button } from '../../../../shared/components/ui';
import { ButtonSizes } from '../../../../shared/theme';
import { Icon } from '../../../../shared/components/icons';
import { StyledVariantItem, StyledVariantItemWrapper } from '../styled';
import { SegmentPersonalizationType } from '../../../../shared/types/production/segment';
import useSegmentContext from '../hooks/useSegmentContext';
import PersonalizationTypeVariantsModal from './PersonalizationTypeVariantsModal';
import { PersonalizationType } from '../../../../shared/types/production/personalization';

const PersonalizationTypeVariantRow = ({
  type,
  variant,
}: {
  type: PersonalizationType;
  variant: SegmentPersonalizationType;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { segment, deletePersonalizationType, isEdit } = useSegmentContext();

  const deleteType = async () => {
    setIsDeleting(true);
    await deletePersonalizationType(segment, variant.id);
    setIsDeleting(false);
  };

  return (
    <StyledVariantItemWrapper>
      <StyledVariantItem>
        <p>{variant.name}</p>
        {isEdit && (
          <Button onClick={deleteType} isLoading={isDeleting} noShadow fab size={ButtonSizes.sm}>
            <Icon type={'Close'} />
          </Button>
        )}
      </StyledVariantItem>
      <PersonalizationTypeVariantsModal type={type} variant={variant} />
    </StyledVariantItemWrapper>
  );
};

export default PersonalizationTypeVariantRow;
