import React from 'react';
import { StyledSwitch } from './styled';
import { SwitchProps } from '@chakra-ui/react';

type CustomSwitchProps = {
  register?: Function;
  name?: string;
};

const Switch = ({
  children,
  register = () => {},
  name,
  ...props
}: SwitchProps & CustomSwitchProps) => {
  return <StyledSwitch {...{ ...register(name), name: name, ...props }}>{children}</StyledSwitch>;
};

export default Switch;
