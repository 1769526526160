import { useState } from 'react';
import { SlidesPhoto, SlidesPhotosResponse } from '../../../../types/slidesPhoto';
import { httpErrorHandler } from '../../../../utils/httpErrorHandler';
import { OrdersApi } from '../../../../../api';

const useHandleSlideshow = (orderId: string) => {
  const [photos, setPhotos] = useState<SlidesPhoto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const responseToPhoto = (photo: SlidesPhotosResponse) => ({
    id: photo.id,
    name: photo.id.toString(),
    src: photo.url,
    thumb_src: photo.thumb_url,
    file: null,
    error: false,
  });

  const getPhotos = async () => {
    setIsLoading(true);
    await OrdersApi.getPhotos(orderId)
      .then((response) => {
        const updatedPhotos = response.map((photo) => responseToPhoto(photo));
        setPhotos(updatedPhotos);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadPhoto = async (photoToUpload: SlidesPhoto, index: number) => {
    if (!photoToUpload.file) return null;

    const uploaded = await OrdersApi.uploadPhoto(orderId, {
      photo: photoToUpload.file,
      order: index.toString(),
    })
      .then((response) => {
        return responseToPhoto(response);
      })
      .catch(() => {
        setIsLoading(false);
        return null;
      });

    return uploaded;
  };

  const retryPhoto = async (photoToRetry: SlidesPhoto) => {
    setIsLoading(true);

    const statePhotos = photos;
    const index = statePhotos.findIndex((photo) => photo.id === photoToRetry.id);
    statePhotos[index].error = false;
    setPhotos(statePhotos);

    const uploadedPhoto = await uploadPhoto(statePhotos[index], index);
    if (uploadedPhoto) statePhotos[index] = uploadedPhoto;
    else statePhotos[index].error = true;
    setPhotos(statePhotos);

    setIsLoading(false);
  };

  const uploadPhotos = async (photosToUpload: SlidesPhoto[]) => {
    setIsLoading(true);

    const photosToUploadWithFiles = photosToUpload.filter(({ file }) => !!file);
    if (!photosToUploadWithFiles.length) return photosToUpload;

    const startIndex = photos.length;
    let dropzonePhotos = [...photos, ...photosToUploadWithFiles];
    setPhotos(dropzonePhotos);

    for (let i = 0; i < photosToUploadWithFiles.length; i += 1) {
      const photo = photosToUploadWithFiles[i];
      const uploaded = await uploadPhoto(photo, startIndex + i);
      dropzonePhotos = dropzonePhotos.map((loadingPhoto) => {
        if (loadingPhoto.id === photo.id) {
          if (uploaded) return uploaded;
          else return { ...loadingPhoto, error: true };
        }
        return loadingPhoto;
      });
      setPhotos(dropzonePhotos);
    }

    setIsLoading(false);
    return dropzonePhotos;
  };

  const syncPhotos = async (photosToSync: SlidesPhoto[] = []) => {
    const photosToProcess = (photosToSync?.length && photosToSync) || photos;
    if (!photosToProcess.length) return;

    setIsLoading(true);
    try {
      const syncedPhotos: SlidesPhoto[] = await uploadPhotos(photosToProcess);
      const data = await Promise.all(
        syncedPhotos
          .filter(({ file }) => !file)
          .map(async (photo, index) => ({
            id: photo.id,
            order: index,
          })),
      );
      await OrdersApi.syncSlideshowPhotos(orderId, { photos: data }).finally(() => {
        setIsLoading(false);
      });
    } catch (e) {
      httpErrorHandler(e);
    }
  };

  const removePhoto = async (photoToRemove: SlidesPhoto) => {
    setPhotos([...photos.filter((photo) => photo.id !== photoToRemove.id)]);

    if (photoToRemove.file) return;

    setIsLoading(true);
    await OrdersApi.deletePhoto(orderId, photoToRemove.id).finally(() => {
      setIsLoading(false);
    });
  };

  return {
    photos,
    setPhotos,
    getPhotos,
    syncPhotos,
    removePhoto,
    uploadPhotos,
    isLoading,
    retryPhoto,
  };
};

export default useHandleSlideshow;
