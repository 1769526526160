import React, { useEffect } from 'react';
import { StyledDivider, StyledDividerWrapper, StyledFileInputWrapper } from '../../styled';
import { FileInput, Input } from '../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../shared/theme';
import { useForm } from 'react-hook-form';
import { useScopedTranslation } from '../../../../../shared/hooks';

const FileSelectorComponent = ({
  file,
  setFile,
  setFileUrl,
}: {
  file: File | null;
  setFile: Function;
  setFileUrl: Function;
}) => {
  const { t } = useScopedTranslation('production.generator.client_voice');

  const { register, watch } = useForm({ defaultValues: { file_url: '' } });
  const fileUrl = watch('file_url');

  useEffect(() => {
    setFileUrl(fileUrl);
  }, [fileUrl]);

  return (
    <>
      <StyledFileInputWrapper>
        <div>
          <p>{(!!file && file.name) || t('select_file_for_upload')}</p>
          {!!file && (
            <p>
              {(file.size / 1000000).toFixed(2)} {t('mb')}
            </p>
          )}
        </div>
        <FileInput
          accept={'audio/*'}
          onFileChange={setFile}
          size={ButtonSizes.sm}
          label={t((!!file && 'change_file') || 'select_file')}
          noShadow
        />
      </StyledFileInputWrapper>
      <StyledDividerWrapper>
        <StyledDivider />
        <span>OR</span>
        <StyledDivider />
      </StyledDividerWrapper>
      <Input name={'file_url'} {...{ register }} placeholder={t('enter_link')} />
    </>
  );
};

export default FileSelectorComponent;
