import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Spacer } from '@chakra-ui/react';
import { addDays, format } from 'date-fns';
import { useScopedTranslation } from '../../../shared/hooks';
import { InvoicesMeta, InvoiceStatuses } from '../../../shared/types/invoice';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { StyledCountPanel, StyledFiltersWrapper, StyledPeriodWrapper } from '../styled';
import { Datepicker, Input, Select, Spinner } from '../../../shared/components/ui';
import { numberWithCommas } from '../../../shared/utils/utils';

const InvoicesFilter = ({ setParams, meta }: { setParams: Function; meta?: InvoicesMeta }) => {
  const { t, rootT } = useScopedTranslation('invoices.filters');
  const statuses = Object.values(InvoiceStatuses).map((status) => ({
    value: status,
    label: rootT(`order.invoices.statuses.${status}`),
  }));

  const { register, watch, control } = useForm();
  const search = watch('search');
  const status = watch('status');
  const dateFrom = watch('date_from');
  const dateTo = watch('date_to');

  const debouncedSearch = useDebounce(search, 400);

  const hasFilter = useMemo(() => {
    return !!debouncedSearch || !!status || !!dateFrom || !!dateTo;
  }, [debouncedSearch, status, dateFrom, dateTo]);

  useEffect(() => {
    setParams({
      search: debouncedSearch,
      status,
      date_from: !!dateFrom && format(dateFrom, 'yyyy-MM-dd'),
      date_to: !!dateTo && format(addDays(dateTo, 1), 'yyyy-MM-dd'),
    });
  }, [debouncedSearch, status, dateFrom, dateTo]);

  return (
    <>
      <StyledFiltersWrapper>
        <Select
          placeholder={t('all_statuses')}
          name={'status'}
          options={statuses}
          register={register}
        />
        <div />
        <Input placeholder={t('search')} name={'search'} register={register} />
      </StyledFiltersWrapper>
      <StyledPeriodWrapper>
        <Datepicker isClearable placeholder={t('date_from')} name={'date_from'} control={control} />
        <Datepicker isClearable placeholder={t('date_to')} name={'date_to'} control={control} />
        <Spacer />
        <StyledCountPanel>
          <h3>{t((hasFilter && 'filtered_num') || 'total_orders')}</h3>
          {(!!meta?.count && <p>{numberWithCommas(meta.count)}</p>) || <Spinner size={'sm'} />}
          <span>|</span>
          <h3>{t((hasFilter && 'filtered_amount') || 'total_amount')}</h3>
          {(!!meta?.total_amount && <p>${numberWithCommas(Math.round(meta.total_amount))}</p>) || (
            <Spinner size={'sm'} />
          )}
        </StyledCountPanel>
      </StyledPeriodWrapper>
    </>
  );
};

export default InvoicesFilter;
