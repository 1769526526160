import React, { useMemo } from 'react';
import { AccordionButton, AccordionIcon, Accordion, AccordionPanel, Box } from '@chakra-ui/react';
import { StyledAccordionItem, StyledItemsContainer } from '../styled';
import { useLocation } from 'react-router-dom';
import SidebarItem from './SidebarItem';

const SidebarItemGroup = ({
  items,
  label,
  icon = '',
}: {
  items: { to: string; caption: string }[];
  label: string;
  icon?: React.ReactNode;
}) => {
  const { pathname } = useLocation();
  const isOpen = useMemo(() => {
    for (const item of items) {
      if (item.to === pathname) return 0;
    }
    return undefined;
  }, [items, pathname]);

  return (
    <Accordion allowToggle defaultIndex={isOpen}>
      <StyledAccordionItem open={isOpen === 0}>
        <AccordionButton>
          {icon}
          <Box as={'span'} flex={1} textAlign="left">
            {label}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <StyledItemsContainer>
            {items.map((item) => (
              <SidebarItem groupItem to={item.to} key={item.to}>
                {item.caption}
              </SidebarItem>
            ))}
          </StyledItemsContainer>
        </AccordionPanel>
      </StyledAccordionItem>
    </Accordion>
  );
};

export default SidebarItemGroup;
