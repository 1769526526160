import React from 'react';
import { Order } from '../../../../shared/types/orders';
import { StyledOrderNumber, StyledOrderPageWrapper } from './styled';
import { Tabs } from '../../../../shared/components/ui';
import OrderInfo from './components/info/OrderInfo';
import OrderPayments from './components/payments/OrderPayments';
import OrderMaterials from './components/materials/OrderMaterials';
import OrderStatus from './components/OrderStatus';
import OrderAddons from './components/addons/OrderAddons';
import { useScopedTranslation } from '../../../../shared/hooks';

const OrderSummary = ({ order }: { order: Order }) => {
  const { t } = useScopedTranslation('order.summary');
  return (
    <div>
      <StyledOrderNumber>
        {t('order')} <span>#{order.id}</span>
      </StyledOrderNumber>
      <StyledOrderPageWrapper>
        <Tabs tabs={[t('info'), t('addons'), t('payments'), t('materials')]}>
          <OrderInfo />
          <OrderAddons />
          <OrderPayments />
          <OrderMaterials />
        </Tabs>
        <OrderStatus />
      </StyledOrderPageWrapper>
    </div>
  );
};

export default OrderSummary;
