import React, { useEffect } from 'react';
import useGeneratorContext from '../hooks/useGeneratorContext';
import useGetSegments from '../requests/useGetSegments';
import { Select } from '../../../../shared/components/ui';
import { useForm } from 'react-hook-form';
import { useScopedTranslation } from '../../../../shared/hooks';

const SegmentSelector = () => {
  const { t } = useScopedTranslation('production.generator');

  const { isLoading, segments, getSegments } = useGetSegments();
  const { data, setData } = useGeneratorContext();

  const { register, reset, watch } = useForm();
  const segmentId = watch('segment_id');

  useEffect(() => {
    if (data.gender) getSegments(data.gender);
  }, [data.gender]);

  useEffect(() => {
    reset({ segment_id: segments[0]?.value ?? null });
  }, [segments]);

  useEffect(() => {
    setData({ ...data, segment_id: parseInt(segmentId) });
  }, [segmentId]);

  return (
    <Select
      placeholder={t('select_segments')}
      {...{ register }}
      name={'segment_id'}
      options={segments}
      isDisabled={isLoading}
    />
  );
};

export default SegmentSelector;
