import React, { useEffect, useState } from 'react';
import { StyledVisualizationWrapper, StyledVoiceRecordWrapper } from '../../styled';
import { AudioPlayer, Button } from '../../../../../shared/components/ui';
import { ButtonSizes } from '../../../../../shared/theme';
import { VoiceRecorder, VoiceVisualization } from '../../../../../shared/components/custom';
import { RecordingResultType } from '../../../../../shared/utils/audioRecorder';
import { useScopedTranslation } from '../../../../../shared/hooks';

const VoiceRecorderComponent = ({
  recording,
  setRecording,
  onStatusChange,
}: {
  recording: Blob | null;
  setRecording: Function;
  onStatusChange: Function;
}) => {
  const { t } = useScopedTranslation('production.generator.client_voice');

  const [isRecording, setIsRecording] = useState(false);
  const [recordExampleUrl, setRecordExampleUrl] = useState('');

  const resetVoice = () => {
    setRecording(null);
  };

  const onRecordingStart = () => {
    resetVoice();
    setIsRecording(true);
  };

  const onRecordingEnd = (result: RecordingResultType) => {
    setRecording(result.audioBlob);
    setRecordExampleUrl(result.audioUrl);
    setIsRecording(false);
  };

  useEffect(() => {
    if (!recording) setRecordExampleUrl('');
  }, [recording]);

  useEffect(() => {
    onStatusChange(isRecording);
  }, [isRecording]);

  return (
    (recordExampleUrl && (
      <StyledVoiceRecordWrapper>
        <AudioPlayer src={recordExampleUrl} withProgress />
        <Button onClick={resetVoice} noShadow size={ButtonSizes.sm}>
          {t('try_again')}
        </Button>
      </StyledVoiceRecordWrapper>
    )) || (
      <>
        {isRecording && (
          <StyledVisualizationWrapper>
            <VoiceVisualization isWorking={isRecording} />
          </StyledVisualizationWrapper>
        )}
        <VoiceRecorder onStart={onRecordingStart} maxDuration={20000} onStop={onRecordingEnd} />
      </>
    )
  );
};

export default VoiceRecorderComponent;
