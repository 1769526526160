import React from 'react';
import { Input } from '../../../../../shared/components/ui';
import { useInsertValue, useScopedTranslation } from '../../../../../shared/hooks';
import { useMailingContext } from '../hooks/useMailingContext';
import VariablesButtons from './VariablesButtons';

const ActionUrl = () => {
  const { t } = useScopedTranslation('mailing.page');

  const { reset, register, variables, watch, getValues } = useMailingContext();
  const { ref, rest, addValue } = useInsertValue({ register, name: 'action_url' });
  const url = watch('action_url');

  const onAdded = (value: string) => {
    reset({ ...getValues(), action_url: addValue(url, value) });
  };
  return (
    <div>
      <p>{t('action_url')}</p>
      <Input ref={ref} {...rest} placeholder={t('action_url_placeholder')} />
      <VariablesButtons variables={variables} onAdded={onAdded} />
    </div>
  );
};

export default ActionUrl;
