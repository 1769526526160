import React, { useMemo, useState } from 'react';
import {
  StyledInactiveLabel,
  StyledPeronalizationTypeRow,
  StyledTypeCell,
  StyledVariantsCell,
} from '../styled';
import { Button } from '../../../../shared/components/ui';
import { ButtonSizes } from '../../../../shared/theme';
import { Icon } from '../../../../shared/components/icons';
import { PersonalizationType } from '../../../../shared/types/production/personalization';
import { useScopedTranslation } from '../../../../shared/hooks';
import useSegmentContext from '../hooks/useSegmentContext';
import PersonalizationTypeVariantRow from './PersonalizationTypeVariantRow';

const PersonalizationTypeRow = ({ type }: { type: PersonalizationType }) => {
  const { t } = useScopedTranslation('production.personalization.types');
  const [isAdding, setIsAdding] = useState(false);
  const { segment, addPersonalizationType, isEdit } = useSegmentContext();

  const addType = async () => {
    setIsAdding(true);
    await addPersonalizationType(segment, type.id);
    setIsAdding(false);
  };

  const variants = useMemo(() => {
    return (
      segment?.personalization_types?.filter((addedType) => addedType.type_id === type.id) ?? []
    );
  }, [segment]);

  return (
    <StyledPeronalizationTypeRow>
      <StyledTypeCell>
        <p>{t(type.name)}</p>
        {isEdit && (
          <Button onClick={addType} isLoading={isAdding} noShadow fab size={ButtonSizes.sm}>
            <Icon type={'Plus'} />
          </Button>
        )}
      </StyledTypeCell>
      <StyledVariantsCell>
        {(!!variants.length &&
          variants.map((variant) => (
            <PersonalizationTypeVariantRow key={variant.id} type={type} variant={variant} />
          ))) || <StyledInactiveLabel>{t('inactive')}</StyledInactiveLabel>}
      </StyledVariantsCell>
    </StyledPeronalizationTypeRow>
  );
};

export default PersonalizationTypeRow;
