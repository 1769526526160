import React, { ChangeEvent, useState } from 'react';
import {
  StyledLetterCard,
  StyledLettersHeader,
  StyledLettersWrapper,
  StyledListHeader,
  StyledListSubheader,
  StyledSearchInput,
} from './styled';
import { LinkButton } from '../../../../shared/components/ui';
import { ButtonSizes } from '../../../../shared/theme';
import { useNavigateWithParams, useScopedTranslation } from '../../../../shared/hooks';
import { ControlPaths } from '../../../../router/routes';
import useLoadLetters from '../../requests/useLoadLetters';
import { SkeletonRepeater } from '../../../../shared/components/custom';
import { Skeleton } from '@chakra-ui/react';
import { useDebounce } from '../../../../shared/hooks/useDebounce';
import useHasPermission from '../../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../../shared/constants/Permissions';

const LettersList = () => {
  const { t, rootT } = useScopedTranslation('mailing.list');
  const { urlWithParams } = useNavigateWithParams();
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 400);
  const isEdit = useHasPermission(Permissions.mailingEdit);

  const { letters, isLoading, hasMore, getLetters } = useLoadLetters(15, debouncedSearch as string);

  const loadMore = () => {
    getLetters();
  };

  const updateSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  return (
    <div>
      <StyledListHeader>{t('settings')}</StyledListHeader>
      <StyledListSubheader>{t('view_and_edit')}</StyledListSubheader>

      <StyledSearchInput name={'search'} onChange={updateSearch} placeholder={t('search')} />

      <StyledLettersHeader>
        <div>{t('table.description')}</div>
        <div>{t('table.actions')}</div>
      </StyledLettersHeader>

      <StyledLettersWrapper loadMore={loadMore} initialLoad={false} hasMore={hasMore}>
        {letters.map((letter) => (
          <StyledLetterCard key={letter.id}>
            <div>{rootT(`mailing.cases.${letter.case}`)}</div>
            <div>
              {isEdit && (
                <LinkButton
                  noShadow
                  to={urlWithParams(ControlPaths.letterPage, { letterId: letter.id.toString() })}
                  size={ButtonSizes.sm}
                >
                  {t('edit')}
                </LinkButton>
              )}
            </div>
          </StyledLetterCard>
        ))}
        {isLoading && (
          <SkeletonRepeater number={6}>
            <StyledLetterCard>
              <Skeleton w={'1rem'} h={'1rem'} />
              <Skeleton w={'9rem'} h={'1rem'} />
              <Skeleton w={'5rem'} h={'2rem'} />
            </StyledLetterCard>
          </SkeletonRepeater>
        )}
      </StyledLettersWrapper>
    </div>
  );
};

export default LettersList;
