import React from 'react';
import { Icon } from '../../icons';

const InstagramContent = () => {
  return (
    <>
      <Icon type={'Instagram2'} />
      <span>Instagram</span>
    </>
  );
};

export default InstagramContent;
