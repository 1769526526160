import React, { useMemo, useState } from 'react';
import { defaultData } from './constants';
import { GeneratorDataType } from './types';
import { GeneratorContextProvider } from './context/GeneratorContext';
import GenderSelector from './components/GenderSelector';
import SegmentSelector from './components/SegmentSelector';
import {
  StyledDivider,
  StyledGeneratorWrapper,
  StyledHeaderWrapper,
  StyledSectionWrapper,
} from './styled';
import OccasionSelector from './components/OccasionSelector';
import PersonalizationSelector from './components/PersonalizationSelector';
import ArtistSelector from './components/ArtistSelector';
import GeneratorActions from './components/GeneratorActions';
import useGeneratorApi from './requests/useGeneratorApi';
import GenerationResult from './components/GenerationResult';
import { useScopedTranslation } from '../../../shared/hooks';

const Header = ({ text }: { text: string }) => (
  <StyledHeaderWrapper>
    <p>{text}</p>
    <StyledDivider />
  </StyledHeaderWrapper>
);

const Generator = () => {
  const { t } = useScopedTranslation('production.generator');
  const { isLoading, produceTrack, result } = useGeneratorApi();
  const [data, setData] = useState<GeneratorDataType>(defaultData);
  const [artists, setArtists] = useState([]);

  const hasSegment = useMemo(() => {
    return !!data.segment_id;
  }, [data.segment_id]);

  const hasOccasion = useMemo(() => {
    return !!data.occasion_id;
  }, [data.occasion_id]);

  return (
    <GeneratorContextProvider
      value={{ data, setData, result, produceTrack, isProducing: isLoading }}
    >
      <StyledGeneratorWrapper>
        <div>
          <StyledSectionWrapper>
            <Header text={t('select_gender')} />
            <GenderSelector />
            <SegmentSelector />
            {hasSegment && <OccasionSelector />}
          </StyledSectionWrapper>
          {hasSegment && hasOccasion && (
            <>
              <StyledSectionWrapper>
                <PersonalizationSelector />
              </StyledSectionWrapper>
              <StyledSectionWrapper>
                <Header text={t('select_artist')} />
                <ArtistSelector onArtistsUpdate={setArtists} />
              </StyledSectionWrapper>
              {!!artists.length && (
                <StyledSectionWrapper>
                  <Header text={t('generation_options')} />
                  <GeneratorActions />
                </StyledSectionWrapper>
              )}
            </>
          )}
        </div>
        <div>
          <GenerationResult />
        </div>
      </StyledGeneratorWrapper>
    </GeneratorContextProvider>
  );
};

export default Generator;
