import { useAppContext } from '../hooks';
import { appLocalStorage } from '../utils/appLocalStorage';
import { AUTH_TOKEN_KEY } from '../constants';
import { useNavigate } from 'react-router-dom';
import { PublicPaths } from '../../router/routes';

export const useLogout = () => {
  const { setAccessToken, setUser } = useAppContext();
  const navigate = useNavigate();

  return () => {
    setUser(null);
    setAccessToken('');
    appLocalStorage.removeItem(AUTH_TOKEN_KEY);

    navigate(PublicPaths.login);
  };
};
