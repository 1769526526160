import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AccordionItem, Avatar, Divider, Flex, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { Button } from '../../ui';

export const StyledSidebarBackdrop = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  z-index: 98;
  visibility: ${({ show }) => (show && 'visible') || 'hidden'};
  opacity: ${({ show }) => (show && '0.3') || '0'};
  transition: visibility 0s, opacity 0.2s linear;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`;

export const StyledSidebar = styled.div<{ collapsed: boolean }>`
  background: ${({ theme }) => theme.palette.white};
  border-right: 1px solid ${({ theme }) => theme.palette.neutral10};
  transition: 0.3s ease-in-out;
  width: 15rem;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;

  ${({ collapsed }) =>
    collapsed &&
    css`
      padding-inline: 0;
      width: 0;
    `};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    position: fixed;
    z-index: 99;

    ${({ collapsed }) =>
      collapsed &&
      css`
        padding-inline: 0;
        width: 15rem;
        transform: translateX(-15rem);
      `};
  }
`;

export const StyledSidebarLogo = styled.img`
  width: 7rem;
  margin: 1rem 1.6rem 0;
`;

export const StyledItemsWrapper = styled.div`
  padding: 1rem;
`;

export const StyledUserWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.75rem;
  align-items: center;
  padding: 1rem;
  transition: 0.2s;
  border-top: 1px solid ${({ theme }) => theme.palette.neutral10};

  > div:last-of-type {
    display: grid;
  }

  &:hover {
    background: #e8eaed7a;
  }

  > svg {
    opacity: 0.6;
    width: 0.85rem;
    height: auto;
  }
`;

export const StyledUserAvatar = styled(Avatar)`
  border-radius: ${({ theme }) => theme.radii.md};
`;

export const StyledUserName = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.text.main};
  display: block;

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const StyledUserEmail = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

export const StyledUserRole = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
  display: block;

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const StyledAccordionItem = styled(AccordionItem)<{ open?: boolean }>`
  border: none;
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  margin-bottom: 0.25rem;

  > button {
    border-radius: ${({ theme }) => theme.radii.sm};
    transition: 0.2s ease-in-out;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.text.main};
    background: ${({ open, theme }) => (open && theme.palette.neutral10) || 'transparent'};
    display: flex;
    grid-gap: 0.75rem;
    padding: 0.5rem;

    > svg:first-of-type {
      width: 1.1rem;
      height: 1.1rem;
      opacity: 0.8;
    }

    &:hover {
      background: ${({ theme }) => theme.palette.neutral5};
    }
  }

  .chakra-accordion__panel {
    padding: 0.25rem 0;
  }
`;

export const StyledItem = styled(NavLink)<{ groupItem?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  color: ${({ theme }) => theme.colors.text.main};
  border-radius: ${({ theme }) => theme.radii.sm};
  transition: 0.2s ease-in-out;
  padding: 0.5rem 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  margin-bottom: 0.25rem;
  position: relative;

  > svg {
    width: 1.1rem;
    height: 1.1rem;
    opacity: 0.8;
  }

  &.active {
    background: ${({ theme }) => theme.palette.primary12};
    color: ${({ theme }) => theme.palette.primary};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.neutral5};

    &.active {
      background: ${({ theme }) => theme.palette.primary16};
    }
  }
`;

export const StyledItemsContainer = styled(Flex)`
  flex-direction: column;
  padding-left: 0.75rem;
  margin-left: 0.95rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-left: 2px solid ${({ theme }) => theme.palette.neutral10};
`;

export const StyledUserInfoDivider = styled(Divider)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledUserInfoActions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
`;

export const StyledLogoutButton = styled(Button)`
  height: 2rem;
  background: transparent;
  font-family: ${({ theme }) => theme.fontFamily.body};
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

export const StyledUserInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
`;

export const StyledProfileButton = styled(Button)`
  height: 2rem;
  width: 100%;
`;

export const StyledGroupHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 0.5rem;
  grid-gap: 0.75rem;
  align-items: center;
  margin-top: 0.5rem;
  text-transform: uppercase;

  > p {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.palette.neutral40};
    font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  > div {
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.palette.neutral10};
  }
`;
