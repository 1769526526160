import React, { useEffect, useMemo, useState } from 'react';
import { StyledActions, StyledPermissionsWrapper, StyledRoleCard } from '../styled';
import { Button, Input } from '../../../shared/components/ui';
import { Checkbox, useCheckboxGroup } from '@chakra-ui/react';
import { ButtonSizes } from '../../../shared/theme';
import { Permission, Role } from '../../../shared/types/role';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ConfirmDialog } from '../../../shared/components/custom';
import { useScopedTranslation } from '../../../shared/hooks';
import useHasPermission from '../../../shared/hooks/useHasPermission';
import { Permissions } from '../../../shared/constants/Permissions';

type RoleFormType = {
  role: Role;
  permissions: Permission[];
  onSave: Function;
  onDelete: Function;
};

type RoleHookFormType = {
  name: string;
  permissions: (string | number)[];
};

const RoleForm = ({ role, permissions, onSave, onDelete }: RoleFormType) => {
  const { t, rootT } = useScopedTranslation('roles');
  const isEdit = useHasPermission(Permissions.roleEdit);

  const { register, reset, handleSubmit, getValues } = useForm<RoleHookFormType>();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const rolePermissions = useMemo(() => role.permissions.map(({ id }) => id), [role]);

  useEffect(() => {
    reset({ name: role.name, permissions: rolePermissions });
  }, [role, rolePermissions]);

  const onSubmit: SubmitHandler<RoleHookFormType> = async (args) => {
    setIsSaving(true);
    await onSave({ id: role.id, ...args });
    setIsSaving(false);
  };

  const deleteRole = async () => {
    setIsDeleting(true);
    await onDelete(role.id);
    setIsDeleting(false);
  };

  const { value, getCheckboxProps, setValue } = useCheckboxGroup();

  useEffect(() => {
    setValue(rolePermissions);
  }, [role]);

  useEffect(() => {
    reset({ ...getValues(), permissions: value.map((val) => parseInt(val.toString(), 10)) });
  }, [value]);

  return (
    <StyledRoleCard onSubmit={handleSubmit(onSubmit)}>
      <div>
        <p>{t('role_name')}</p>
        <Input
          isReadOnly={!isEdit}
          register={register}
          name={'name'}
          placeholder={t('enter_name')}
        />
      </div>

      <div>
        <p>{t('role_perm')}</p>
        <StyledPermissionsWrapper>
          {permissions.map((permission) => (
            <Checkbox
              isReadOnly={!isEdit}
              key={permission.id}
              {...getCheckboxProps({ value: permission.id })}
            >
              {rootT(`permissions.${permission.name}`)}
            </Checkbox>
          ))}
        </StyledPermissionsWrapper>
      </div>

      {isEdit && (
        <StyledActions>
          <Button
            isDisabled={isDeleting}
            isLoading={isSaving}
            color={'primary'}
            noShadow
            size={ButtonSizes.sm}
            type={'submit'}
          >
            {t('save')}
          </Button>
          <ConfirmDialog
            title={t('delete_confirm')}
            text={t('delete_confirm_text')}
            onConfirm={deleteRole}
            isLoading={isDeleting}
            activator={
              <Button isDisabled={isSaving} noShadow size={ButtonSizes.sm} type={'button'}>
                {t('delete_role')}
              </Button>
            }
          />
        </StyledActions>
      )}
    </StyledRoleCard>
  );
};

export default RoleForm;
