import React, { useMemo } from 'react';
import { useAppContext, useScopedTranslation } from '../../shared/hooks';
import {
  StyledDashboardWrapper,
  StyledHeader,
  StyledMainWrapper,
  StyledOrdersGrid,
  StyledQuickCard,
  StyledQuickStatsWrapper,
  StyledQuickWrapper,
  StyledSWelcomeMessage,
} from './styled';
import OrdersCard from './components/OrdersCard';
import PaymentsCard from './components/PaymentsCard';
import OrderCard from '../orders/views/OrdersList/components/OrderCard';
import { Link } from 'react-router-dom';
import { ControlPaths, ProductionPaths } from '../../router/routes';
import useDashboardApi from '../../shared/requests/useDashboardApi';
import { LinkOverlay } from '@chakra-ui/react';
import { FiMusic } from 'react-icons/fi';
import { Icon } from '@chakra-ui/icons';
import { MdAttachMoney } from 'react-icons/md';
import useHasPermission from '../../shared/hooks/useHasPermission';
import { Permissions } from '../../shared/constants/Permissions';

const Dashboard = () => {
  const { t } = useScopedTranslation('dashboard');
  const { user } = useAppContext();
  const { stats, orders } = useDashboardApi();

  const canViewInvoices = useHasPermission(Permissions.invoiceView);
  const canViewOrders = useHasPermission(Permissions.orderView);
  const canViewGenerator = useHasPermission(Permissions.generationView);

  const hasFaultyOrders = useMemo(
    () => !!orders?.find((order) => !!order.failure_message),
    [orders],
  );

  return (
    <StyledDashboardWrapper>
      <StyledSWelcomeMessage>{t('welcome', { name: user?.name })}</StyledSWelcomeMessage>

      {(canViewOrders || canViewInvoices) && (
        <StyledQuickStatsWrapper>
          {canViewOrders && <OrdersCard orders={stats?.orders} isLoading={!stats} />}
          {canViewInvoices && (
            <PaymentsCard
              turnover={stats?.turnover?.sum ?? 0}
              payments={stats?.payments ?? []}
              isLoading={!stats}
            />
          )}
        </StyledQuickStatsWrapper>
      )}

      <StyledMainWrapper>
        {canViewOrders && (
          <StyledOrdersGrid>
            <StyledHeader>
              <p>{t((hasFaultyOrders && 'requires_attention') || 'last_orders')}</p>
              <div />
              <Link to={ControlPaths.orders}>{t('all_orders')}</Link>
            </StyledHeader>
            {orders.map((order) => (
              <OrderCard order={order} key={order.id} showOptions={false} />
            ))}
          </StyledOrdersGrid>
        )}
        <StyledQuickWrapper>
          {canViewGenerator && (
            <StyledQuickCard>
              <div>
                <Icon as={FiMusic} />
              </div>
              <LinkOverlay as={Link} to={ProductionPaths.generator}>
                <p>{t('generate_track')}</p>
              </LinkOverlay>
            </StyledQuickCard>
          )}
          {canViewInvoices && (
            <StyledQuickCard>
              <div>
                <Icon as={MdAttachMoney} />
              </div>
              <LinkOverlay as={Link} to={ControlPaths.invoices}>
                <p>{t('payments')}</p>
              </LinkOverlay>
            </StyledQuickCard>
          )}
        </StyledQuickWrapper>
      </StyledMainWrapper>
    </StyledDashboardWrapper>
  );
};

export default Dashboard;
