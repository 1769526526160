/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgTrashcan = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.2501 7.74956H25.676L25.0666 5.92216C24.683 4.77221 23.6109 3.99951 22.3987 3.99951H17.6013C16.3891 3.99951 15.317 4.77221 14.9325 5.92216L14.3238 7.74956H8.74988C7.19899 7.74956 5.93735 9.0112 5.93735 10.5621V12.4371C5.93735 13.4814 6.87742 13.3746 7.88631 13.3746C16.546 13.3746 24.3974 13.3746 33.1251 13.3746C33.6433 13.3746 34.0627 12.9553 34.0627 12.4371V10.5621C34.0627 9.0112 32.801 7.74956 31.2501 7.74956ZM16.3008 7.74956L16.7114 6.51542C16.8396 6.13179 17.1975 5.87453 17.6013 5.87453H22.3987C22.8025 5.87453 23.1605 6.13179 23.2877 6.51542L23.6988 7.74956H16.3008Z"
      {...props}
    />
    <path
      d="M8.04156 15.3745L9.53639 33.4417C9.66915 34.9001 10.8731 35.9997 12.337 35.9997H27.6632C29.1271 35.9997 30.3311 34.9001 30.4647 33.4334L31.9587 15.3745H8.04156ZM15.3125 31.3122C15.3125 32.548 13.4375 32.553 13.4375 31.3122V18.187C13.4375 16.9512 15.3125 16.9462 15.3125 18.187V31.3122ZM20.9376 31.3122C20.9376 32.548 19.0626 32.553 19.0626 31.3122V18.187C19.0626 16.9512 20.9376 16.9462 20.9376 18.187V31.3122ZM26.5626 31.3122C26.5626 32.548 24.6876 32.553 24.6876 31.3122V18.187C24.6876 17.6688 25.1069 17.2495 25.6251 17.2495C26.1433 17.2495 26.5626 17.6688 26.5626 18.187V31.3122Z"
      {...props}
    />
  </svg>
);

export default SvgTrashcan;
