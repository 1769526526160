import styled from '@emotion/styled';
import { ButtonSizes, ThemeColorButton } from '../../../theme';
import { mapButtonSizes } from './helpers';
import { Link } from 'react-router-dom';

type StyledButtonProps = {
  colortheme: ThemeColorButton;
  size: ButtonSizes;
  block: number;
  noshadow: number;
  fab: number;
};

export const StyledLinkButton = styled(Link)<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background: ${({ colortheme, theme }) => theme.colors.buttons.bg[colortheme]};
  color: ${({ colortheme, theme }) => theme.colors.buttons.text[colortheme]};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
  font-size: ${({ theme }) => theme.fontSize.sm};
  box-shadow: ${({ theme, noshadow }) => (!noshadow && theme.shadow.button) || 'none'};
  border-radius: ${({ theme, fab }) => (fab && '100%') || theme.radii.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  ${({ block }) => block && 'width: 100%'};
  ${({ size }) => mapButtonSizes(size)}
  border: 1px solid transparent;
  text-decoration: none;
  grid-gap: 0.6rem;
  transition: 0.2s;

  &:hover,
  &:hover[disabled] {
    background: ${({ colortheme, theme }) => theme.colors.buttons.hover[colortheme]};
    text-decoration: none;
  }

  &:focus,
  &:focus-visible {
    border-color: ${({ colortheme, theme }) => theme.colors.buttons.border[colortheme]};
    box-shadow: ${({ theme, noshadow }) => (!noshadow && theme.shadow.button) || 'none'};
    outline: none;
  }

  // svg,
  // path,
  // rect {
  //   fill: ${({ colortheme, theme }) => theme.colors.buttons.text[colortheme]};
  // }
`;
