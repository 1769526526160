import styled from '@emotion/styled';
import { Flex, Grid, Link } from '@chakra-ui/react';
import { LinkButton } from '../../ui';

export const StyledFooterWrapper = styled(Flex)`
  align-items: center;
  min-height: 4.5rem;
  padding: 0.75rem 2rem;
  margin-top: 0.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.card};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2rem;
    min-height: 5.5rem;
    margin-top: 0;
  }
`;

export const StyledLogo = styled.div`
  margin-right: 1.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
`;

export const StyledCopyrights = styled.div`
  margin-right: 2rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.neutral70};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    grid-gap: 0.3rem;
    text-align: center;
    margin-right: 0;
  }
`;

export const StyledSocialsWrapper = styled(Grid)`
  align-items: center;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StyledSocialButton = styled(LinkButton)`
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  background: ${({ theme }) => theme.palette.neutral10};

  svg {
    height: 1rem;
    width: auto;
    fill: ${({ theme }) => theme.palette.neutral70};
  }
`;

export const StyledLinksWrapper = styled(Grid)`
  margin-right: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fontFamily.bodyMedium};
`;
