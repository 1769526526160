import React, { useEffect, useMemo } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalHeader, Spinner, Tabs } from '../../ui';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { Template } from '../../../types/production/template';
import useHandleTemplateApi from '../../../requests/production/useHandleTemplateApi';
import MainTextEditor from './components/MainTextEditor';
import { LyricsContextProvider } from './context/LyricsContext';
import PersonalizationValueRow from './components/PersonalizationValueRow';
import { useScopedTranslation } from '../../../hooks';

type TemplateLyricsModalType = {
  template: Template;
  activator: React.ReactNode;
};

const TemplateLyricsModal = ({ template: tmpl, activator }: TemplateLyricsModalType) => {
  const { t } = useScopedTranslation('production.templates');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    template,
    setTemplate,
    updateTemplate,
    isLoading: isUpdating,
    addLyric,
    updateLyric,
    deleteLyric,
  } = useHandleTemplateApi();

  useEffect(() => {
    setTemplate(tmpl);
  }, [tmpl]);

  const personalizations = useMemo(() => template?.personalizations ?? [], [template]);
  const tabs = useMemo(
    () => [t('main_lyrics'), ...personalizations.map(({ name }) => name)],
    [personalizations],
  );
  const rows = useMemo(() => {
    return personalizations.map((pers) => (
      <PersonalizationValueRow personalization={pers} key={pers.segment_personalization_id} />
    ));
  }, [personalizations]);

  return (
    <LyricsContextProvider
      value={{
        template,
        setTemplate,
        updateTemplate,
        isUpdating,
        addLyric,
        updateLyric,
        deleteLyric,
      }}
    >
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      {template && (
        <Modal {...{ isOpen, onOpen, onClose, size: 'full' }}>
          <ModalHeader>
            <Flex gap={'1rem'} alignItems={'center'}>
              <span>{t('lyrics_settings', { template: template.name })}</span>
              {isUpdating && <Spinner size={'sm'} />}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs tabs={tabs}>{[<MainTextEditor key={'main'} />, ...rows]}</Tabs>
          </ModalBody>
        </Modal>
      )}
    </LyricsContextProvider>
  );
};

export default TemplateLyricsModal;
