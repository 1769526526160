import React, { useEffect, useMemo, useState } from 'react';
import { Select } from '../../../../../../shared/components/ui';
import { StyledSectionRow } from '../../styled';
import { ButtonGroup } from '../../../../../../shared/components/custom';
import { Control, Controller } from 'react-hook-form';
import { OrderInfoForm } from '../../hooks/forms/useInfoForm';
import { useOrderContext } from '../../hooks/useOrderContext';

export type OccasionSelectType = {
  defaultValue?: number;
  options: { label: string; value: string | number }[];
  label: string;
  name: string;
  setValue: Function;
  register: Function;
  buttons?: boolean;
  control?: Control<OrderInfoForm, unknown> | undefined;
};

const OrderInfoSelect = ({
  defaultValue,
  name,
  label,
  options,
  register,
  setValue,
  buttons = false,
  control,
}: OccasionSelectType) => {
  const { isEdit } = useOrderContext();
  const [buttonValue, setButtonValue] = useState<number | string | null | undefined>(defaultValue);

  const currentExistsInOptions = useMemo(
    () => options.find(({ value }) => value === defaultValue),
    [defaultValue, options],
  );

  useEffect(() => {
    const newValue =
      (currentExistsInOptions && defaultValue) || (!!options.length && options[0].value) || null;
    setValue(name, newValue);
    setButtonValue(newValue);
  }, [defaultValue, options, currentExistsInOptions]);

  return (
    <StyledSectionRow>
      <label>{label}</label>
      {(buttons && (
        <Controller
          name={'intro_file_id'}
          control={control}
          render={({ field: { onChange } }) => (
            <ButtonGroup
              isDisabled={!isEdit}
              options={options}
              onChange={onChange}
              skeletonNumber={5}
              isLoading={!options.length}
              defaultValue={buttonValue}
            />
          )}
        />
      )) || (
        <Select
          isReadOnly={!isEdit}
          name={name}
          options={options}
          register={register}
          isLoading={!options.length}
        />
      )}
    </StyledSectionRow>
  );
};

export default OrderInfoSelect;
