import { useState } from 'react';

const useInfiniteScrollItems = (limit: number) => {
  const [items, setItems] = useState<unknown[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const resetItems = () => {
    setItems([]);
    setHasMore(true);
  };

  const appendItems = (itemsToAdd: unknown[], reset: boolean) => {
    const length = itemsToAdd.length;
    if (!length || length < limit) setHasMore(false);

    if (reset) setItems(itemsToAdd);
    else setItems([...items, ...itemsToAdd]);
  };

  return { items, hasMore, setHasMore, appendItems, resetItems, setItems };
};

export default useInfiniteScrollItems;
