import { OrderStatuses } from '../constants/OrderStatuses';
import { designSystem } from '../theme';
import { OptionStatus } from '../types/orders';
import { InvoiceStatuses } from '../types/invoice';

export const statusToColor = (status: OrderStatuses | undefined = undefined) =>
  (status &&
    {
      [OrderStatuses.inWork]: designSystem.palette.neutral40,
      [OrderStatuses.waitingForPayment]: designSystem.palette.primary,
      [OrderStatuses.paymentIsProcessing]: designSystem.palette.primary,
      [OrderStatuses.done]: designSystem.palette.success,
      [OrderStatuses.paid]: designSystem.palette.neutral40,
    }[status]) ||
  designSystem.palette.neutral40;

export const invoiceStatusToColor = (status: InvoiceStatuses | undefined = undefined) =>
  (status &&
    {
      [InvoiceStatuses.waiting]: designSystem.palette.primary,
      [InvoiceStatuses.paid]: designSystem.palette.success,
      [InvoiceStatuses.expired]: designSystem.palette.error,
    }[status]) ||
  designSystem.palette.neutral40;

export const optionStatusToColor = (status: OptionStatus | undefined = undefined) =>
  (status &&
    {
      [OptionStatus.pending]: designSystem.palette.neutral40,
      [OptionStatus.waitingForPayment]: designSystem.palette.primary,
      [OptionStatus.processing]: designSystem.palette.primary,
      [OptionStatus.generate]: designSystem.palette.primary,
      [OptionStatus.finished]: designSystem.palette.success,
    }[status]) ||
  designSystem.palette.neutral40;
