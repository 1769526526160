import { useEffect, useMemo, useState } from 'react';
import { OrdersApi } from '../../../api';
import { Personalization } from '../../../shared/types/production/personalization';
import { PersonalizationTypeEnum } from '../../../shared/constants/production/personalization';

const useGetPersonalization = (type: PersonalizationTypeEnum) => {
  const [isLoading, setIsLoading] = useState(false);
  const [personalizations, setPersonalizations] = useState<Personalization[]>([]);

  const getPersonalizations = (tp: PersonalizationTypeEnum) => {
    setIsLoading(true);
    OrdersApi.personalizations({ type: tp })
      .then((response) => {
        setPersonalizations(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const personalizationOptions = useMemo(() => {
    return personalizations
      .filter((personalization) => personalization.visible)
      .map((personalization) => ({
        value: personalization.id,
        label: personalization.name,
      }));
  }, [personalizations]);

  useEffect(() => {
    getPersonalizations(type);
  }, [type]);

  return {
    getPersonalizations,
    personalizationOptions,
    setPersonalizations,
    isLoading,
    personalizations,
  };
};

export default useGetPersonalization;
