import { httpDelete, httpGet, httpPost, httpPut } from '../../shared/utils/http';
import {
  CreateUserRequestData,
  LoginCredentials,
  LoginResponse,
  User,
  UsersRequestParams,
} from '../../shared/types/user';
import { USERS_ROUTE } from '../constants';

export type UserApiType = {
  myself: () => Promise<User>;
  login: (credentials: LoginCredentials) => Promise<LoginResponse>;
  logout: () => Promise<string>;
  get: (params: UsersRequestParams) => Promise<{ data: User[]; count: number }>;
  find: (userId: number) => Promise<User>;
  create: (data: CreateUserRequestData) => Promise<User>;
  update: (userId: number, data: CreateUserRequestData) => Promise<User>;
  delete: (userId: number) => Promise<string>;
};

export const UserApi: UserApiType = {
  myself: () => {
    return httpGet(`/me`);
  },
  login: (credentials) => {
    return httpPost(`/auth/login`, credentials);
  },
  logout: () => {
    return httpPost(`/auth/logout`, {});
  },
  get: (params) => {
    return httpGet(`${USERS_ROUTE}`, params);
  },
  find: (userId) => {
    return httpGet(`${USERS_ROUTE}/${userId}`);
  },
  create: (data) => {
    return httpPost(`${USERS_ROUTE}`, data);
  },
  update: (userId, data) => {
    return httpPut(`${USERS_ROUTE}/${userId}`, data);
  },
  delete: (userId) => {
    return httpDelete(`${USERS_ROUTE}/${userId}`);
  },
};
