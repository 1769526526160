import { useEffect, useState } from 'react';
import { InvoiceApi } from '../../../api';
import { Invoice, InvoicesMeta } from '../../../shared/types/invoice';
import useInfiniteScrollItems from '../../../shared/hooks/useInfiniteScrollItems';

export type InvoiceFiltersType = {
  status?: string;
  search?: string;
  date_from?: string;
  date_to?: string;
};

type UseLoadInvoicesType = {
  limit: number;
} & InvoiceFiltersType;

const useLoadInvoices = ({ limit, status, search, date_from, date_to }: UseLoadInvoicesType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState<InvoicesMeta | undefined>(undefined);
  const { items, hasMore, setHasMore, appendItems, resetItems, setItems } =
    useInfiniteScrollItems(limit);

  const getInvoices = (reset: boolean = false) => {
    if (isLoading) return;

    setIsLoading(true);

    if (reset) {
      resetItems();
      setMeta(undefined);
    }

    InvoiceApi.get({
      limit,
      offset: (!reset && items.length) || 0,
      search,
      status,
      date_to,
      date_from,
    })
      .then((response) => {
        appendItems(response?.data ?? [], reset);
        setMeta({ count: response.count, total_amount: response.total_amount });
        setIsLoading(false);
      })
      .catch(() => {
        setHasMore(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getInvoices(true);
  }, [limit, status, search, date_to, date_from]);

  return { getInvoices, isLoading, invoices: items as Invoice[], hasMore, meta, setItems };
};

export default useLoadInvoices;
