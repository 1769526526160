import React, { ForwardedRef, useMemo } from 'react';
import { StyledTextarea } from './styled';
import { InputProps } from '@chakra-ui/react';
import { FormErrorMessage } from '../FormErrorMessage';
import { FieldErrorsImpl } from 'react-hook-form';

type CustomInputProps = {
  name: string;
  register?: Function;
  errors?: FieldErrorsImpl;
  autosize?: boolean;
  isDisabled?: boolean;
  minRows?: number;
};

const Textarea = React.forwardRef(
  (
    {
      register = () => {},
      errors = {},
      name,
      autosize = false,
      isDisabled = false,
      minRows = 1,
      ...props
    }: InputProps & CustomInputProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const errorMessage = useMemo(() => errors[name]?.message as string, [errors]);

    return (
      <div>
        <StyledTextarea
          ref={ref}
          {...{ ...register(name), ...props, minRows, name, disabled: isDisabled }}
        />
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </div>
    );
  },
);

export default Textarea;
