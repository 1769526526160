import { useEffect, useState } from 'react';
import { useScopedTranslation } from '../../../../shared/hooks';
import { GeneratorApi } from '../../../../api';
import { Gender } from '../../../../shared/constants/Gender';

const useGetGenders = () => {
  const { t } = useScopedTranslation('production');
  const [isLoading, setIsLoading] = useState(false);
  const [genders, setGenders] = useState<{ value: Gender; label: string }[]>([]);

  const getGenders = async () => {
    setIsLoading(true);
    GeneratorApi.getGenders()
      .then((response) => {
        setGenders(response.map((gender) => ({ value: gender, label: t(`genders.${gender}`) })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getGenders();
  }, []);

  return {
    isLoading,
    getGenders,
    genders,
  };
};

export default useGetGenders;
