import React, { useMemo } from 'react';
import useSegmentContext from '../hooks/useSegmentContext';
import { StyledInfoWrapper, StyledSectionHeader } from '../styled';
import { Gender } from '../../../../shared/constants/Gender';
import { useScopedTranslation } from '../../../../shared/hooks';
import { ButtonGroup } from '../../../../shared/components/custom';

const Senders = () => {
  const { t, rootT } = useScopedTranslation('production.segments');
  const { segment, updateSegmentSender, isEdit } = useSegmentContext();

  const sender = useMemo(() => {
    return segment?.senders[0] ?? null;
  }, [segment]);

  const genders = Object.values(Gender).map((value) => ({
    value,
    label: rootT(`production.genders.${value}`),
  }));

  const saveSender = (gender: Gender) => {
    if (sender?.sender_gender !== gender)
      updateSegmentSender(segment, sender, { sender_gender: gender });
  };

  return (
    segment && (
      <StyledInfoWrapper>
        <StyledSectionHeader>
          <p>{t('sender')}</p>
        </StyledSectionHeader>
        {sender && (
          <ButtonGroup
            isDisabled={!isEdit}
            options={genders}
            defaultValue={sender?.sender_gender}
            onChange={saveSender}
          />
        )}
      </StyledInfoWrapper>
    )
  );
};

export default Senders;
