import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '../../../../shared/components/ui';
import { ButtonSizes } from '../../../../shared/theme';
import { Icon } from '../../../../shared/components/icons';
import { useDisclosure } from '@chakra-ui/react';
import {
  StyledCreateModalActivator,
  StyledFormActionsWrapper,
  StyledFormInput,
  StyledGenderSelector,
  StyledTextarea,
} from '../styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddArtistSchema } from '../validationScheme';
import { CreateArtistRequest } from '../../../../shared/types/production/artist';
import ArtistAvatarInput from './ArtistAvatarInput';
import ArtistExampleInput from './ArtistExampleInput';
import useHandleArtistApi from '../../../../shared/requests/production/useHandleArtistApi';
import { Gender } from '../../../../shared/constants/Gender';
import { useScopedTranslation } from '../../../../shared/hooks';

const CreateArtistModal = ({ onCreate }: { onCreate: Function }) => {
  const { t } = useScopedTranslation('production.artists');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [avatar, setAvatar] = useState<File | null>(null);
  const [example, setExample] = useState<File | null>(null);
  const { createArtist, isLoading: isCreating } = useHandleArtistApi();
  const [gender, setGender] = useState(Gender.female);

  const { register, reset, watch, formState, handleSubmit } = useForm<CreateArtistRequest>({
    resolver: yupResolver(AddArtistSchema),
  });
  const { isValid } = formState;
  const name = watch('name');

  useEffect(() => {
    reset({ name: '', description: '' });
    setAvatar(null);
    setExample(null);
  }, [isOpen]);

  const onSubmit: SubmitHandler<CreateArtistRequest> = async (args) => {
    const data = { ...args, gender };
    data.enabled = 'false';
    if (avatar) data.photo = avatar;
    if (example) data.example = example;

    const artist = await createArtist(data);
    onCreate(artist);
    onClose();
  };

  return (
    <>
      <StyledCreateModalActivator>
        <span>{t('add_artist')}</span>
        <Button noShadow onClick={onOpen} size={ButtonSizes.sm} fab>
          <Icon type={'Plus'} />
        </Button>
      </StyledCreateModalActivator>

      <Modal {...{ isOpen, onClose, onOpen }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{t('add_new_artist')}</ModalHeader>
          <ModalBody>
            <ArtistAvatarInput {...{ avatar, setAvatar, name }} />
            <StyledFormInput {...{ register }} name={'name'} placeholder={t('enter_name')} />
            <StyledTextarea
              {...{ register }}
              name={'description'}
              placeholder={t('enter_description')}
            />
            <StyledGenderSelector gender={gender} setGender={setGender} />
            <ArtistExampleInput {...{ example, setExample }} />
          </ModalBody>
          <ModalFooter>
            <StyledFormActionsWrapper>
              <Button noShadow size={ButtonSizes.sm} type={'button'} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                noShadow
                size={ButtonSizes.sm}
                type={'submit'}
                color={'primary'}
                isDisabled={!isValid}
                isLoading={isCreating}
              >
                {t('add')}
              </Button>
            </StyledFormActionsWrapper>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CreateArtistModal;
