const palette = {
  primary: '#6097FB',
  primaryBorder: '#3A6AC6',
  primaryHover: '#3A6AC6',
  primary60: '#6097FB99',
  primary16: '#6097FB29',
  primary12: '#6097FB1F',
  primary8: '#6097FB14',
  neutral100: '#12213B',
  neutral90: '#12203BE5',
  neutral80: '#12203BCC',
  neutral70: '#12203BB2',
  neutral60: '#12203B99',
  neutral50: '#12203B80',
  neutral40: '#12203B66',
  neutral30: '#12203B4D',
  neutral20: '#12203B33',
  neutral10: '#E8EAEDCC',
  neutral5: '#F8FBFF',
  overlay: '#7f85963d',
  secondaryHover: '#e5e4e4',
  white: '#ffffff',
  success: '#74BF9F',
  successHover: '#8ed7b8',
  error: '#f44336',
  errorHover: '#be352b',
};

export default palette;
