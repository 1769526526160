import React from 'react';
import { StyledHeader, StyledPanel, StyledSectionRow, StyledSectionWrapper } from '../../styled';
import { Input } from '../../../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../../../shared/hooks';
import { useOrderContext } from '../../hooks/useOrderContext';

const CustomerForm = ({ register, isLoading }: { register: Function; isLoading: boolean }) => {
  const { t } = useScopedTranslation('order.customer');
  const { isEdit } = useOrderContext();

  return (
    <StyledPanel>
      <StyledHeader>{t('customer')}</StyledHeader>
      <StyledSectionWrapper>
        <StyledSectionRow>
          <label>{t('name')}</label>
          <Input isReadOnly={!isEdit} name={'name'} register={register} isLoading={isLoading} />
        </StyledSectionRow>
        <StyledSectionRow>
          <label>{t('email')}</label>
          <Input isReadOnly={!isEdit} name={'email'} register={register} isLoading={isLoading} />
        </StyledSectionRow>
      </StyledSectionWrapper>
    </StyledPanel>
  );
};

export default CustomerForm;
