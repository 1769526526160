/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import * as React from 'react';

const SvgTwitter = (props) => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M34 10.8408C32.9694 11.3236 31.8624 11.6484 30.7004 11.795C31.8872 11.0453 32.7971 9.8583 33.2257 8.44299C32.1165 9.13702 30.8867 9.64135 29.5772 9.91396C28.5294 8.73605 27.0369 8 25.3849 8C22.2125 8 19.6409 10.7136 19.6409 14.0588C19.6409 14.5336 19.6926 14.997 19.7895 15.4389C15.0157 15.1867 10.7835 12.7741 7.94916 9.10748C7.45594 10.0026 7.17272 11.043 7.17272 12.155C7.17272 14.2564 8.18607 16.1113 9.72709 17.1972C8.78589 17.1654 7.89962 16.8928 7.12534 16.4396C7.12534 16.4657 7.12534 16.4896 7.12534 16.5157C7.12534 19.4519 9.10465 21.9009 11.7333 22.4563C11.252 22.5949 10.7437 22.6687 10.2192 22.6687C9.84985 22.6687 9.4891 22.6301 9.13911 22.5608C9.87031 24.9678 11.9918 26.7204 14.5052 26.7693C12.5399 28.3947 10.0631 29.3636 7.37087 29.3636C6.90781 29.3636 6.45014 29.3352 6 29.2784C8.54252 30.997 11.561 32 14.8057 32C25.372 32 31.1484 22.7676 31.1484 14.7608C31.1484 14.4984 31.143 14.2371 31.1323 13.977C32.2555 13.1217 33.23 12.0551 34 10.8408Z" />
  </svg>
);

export default SvgTwitter;
