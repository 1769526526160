import React, { useEffect, useMemo, useState } from 'react';
import { Icon } from '../../../../../shared/components/icons';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tabs,
} from '../../../../../shared/components/ui';
import { useDisclosure } from '@chakra-ui/react';
import { StyledVoiceWrapper } from '../../styled';
import useGeneratorContext from '../../hooks/useGeneratorContext';
import useGeneratorApi from '../../requests/useGeneratorApi';
import VoiceRecorderComponent from './VoiceRecorderComponent';
import FileSelectorComponent from './FileSelectorComponent';
import { useScopedTranslation } from '../../../../../shared/hooks';

const ClientVoice = ({ onAdd }: { onAdd: Function }) => {
  const { t } = useScopedTranslation('production.generator.client_voice');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, setData } = useGeneratorContext();
  const { uploadVoice, isLoading } = useGeneratorApi();

  // link input
  const [fileUrl, setFileUrl] = useState<string>('');

  // file input
  const [file, setFile] = useState<File | null>(null);

  // voice recording
  const [voiceRecording, setVoiceRecording] = useState<Blob | null>(null);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (fileUrl) {
      setFile(null);
      setVoiceRecording(null);
    }
  }, [fileUrl]);

  useEffect(() => {
    if (file) {
      setFileUrl('');
      setVoiceRecording(null);
    }
  }, [file]);

  useEffect(() => {
    if (voiceRecording) {
      setFile(null);
      setFileUrl('');
    }
  }, [voiceRecording]);

  useEffect(() => {
    setFile(null);
    setFileUrl('');
    setVoiceRecording(null);
  }, [isOpen]);

  const addVoice = async () => {
    if (fileUrl) {
      onAdd(fileUrl);
    } else {
      const fileToUpload = file || voiceRecording;
      if (fileToUpload) {
        const uploadedFileUrl = await uploadVoice(fileToUpload);
        onAdd(uploadedFileUrl);
      }
    }
    onCloseGuard();
  };

  const onCloseGuard = () => {
    if (isRecording) return;
    onClose();
  };

  const voiceIsSet = useMemo(() => !!data.client_voice_url, [data]);
  const addOrRemoveVoice = () => {
    if (voiceIsSet) setData({ ...data, client_voice_url: null });
    else onOpen();
  };

  return (
    <>
      <StyledVoiceWrapper isset={voiceIsSet}>
        <p>{t((voiceIsSet && 'voice_set') || 'no_voice')}</p>
        <Button onClick={addOrRemoveVoice}>
          <Icon type={(voiceIsSet && 'Close') || 'Plus'} />
        </Button>
      </StyledVoiceWrapper>

      <Modal {...{ isOpen, onOpen, onClose: onCloseGuard }}>
        <ModalHeader>{t('adding_intro')}</ModalHeader>
        <ModalBody>
          <Tabs tabs={[t('existing_file'), t('voice_recording')]}>
            <div>
              <FileSelectorComponent {...{ file, setFile, setFileUrl }} />
            </div>
            <div>
              <VoiceRecorderComponent
                recording={voiceRecording}
                setRecording={setVoiceRecording}
                onStatusChange={setIsRecording}
              />
            </div>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button noShadow onClick={onCloseGuard}>
            {t('cancel')}
          </Button>
          <Button
            isDisabled={!file && !fileUrl && !voiceRecording}
            noShadow
            onClick={addVoice}
            color={'primary'}
            isLoading={isLoading}
          >
            {t('add')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ClientVoice;
