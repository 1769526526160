import {
  httpDelete,
  httpGet,
  httpPostFormData,
  httpPut,
  ProgressHandler,
  uploadWithProgress,
} from '../../../shared/utils/http';
import {
  Artist,
  ArtistSegmentScheme,
  CreateArtistRequest,
  SlotTypes,
  UpdateArtistRequest,
} from '../../../shared/types/production/artist';
import { objectToFormData } from '../../../shared/utils/utils';
import { FileStructure } from '../../../shared/types/production/fileStructure';
import { PreparedFile } from '../../../shared/components/custom/UploaderDropzone/helpers';
import { GENERATOR_ROUTE } from '../../constants';

export type ArtistsApiType = {
  getById: (artistId: number) => Promise<{ data: Artist }>;
  get: (data?: Record<string, string>) => Promise<Artist[]>;
  create: (data: CreateArtistRequest) => Promise<{ data: Artist }>;
  update: (artistId: number, data: UpdateArtistRequest) => Promise<{ data: Artist }>;
  updatePhoto: (artistId: number, file: Blob) => Promise<{ data: Artist }>;
  updateExample: (artistId: number, file: Blob) => Promise<{ data: Artist }>;
  delete: (artistId: number) => Promise<string>;
  getSchemes: (artistId: number) => Promise<ArtistSegmentScheme[]>;
  updateSlot: (
    artistId: number,
    schemeId: number,
    slotId: number,
    data: { type: SlotTypes; value: string | number | null },
  ) => Promise<{ data: ArtistSegmentScheme }>;
  getIntroScheme: (artistId: number, segmentId: number) => Promise<FileStructure>;
  uploadIntro: (
    artistId: number,
    segmentId: number,
    file: PreparedFile,
    onProgressChange: ProgressHandler,
  ) => Promise<boolean>;
};

export const ArtistsApi: ArtistsApiType = {
  getById: (artistId) => {
    return httpGet(`${GENERATOR_ROUTE}/artists/${artistId}`);
  },
  get: (data) => {
    return httpGet(`${GENERATOR_ROUTE}/artists`, data);
  },
  create: (data) => {
    return httpPostFormData(`${GENERATOR_ROUTE}/artists`, objectToFormData(data));
  },
  update: (artistId, data) => {
    return httpPut(`${GENERATOR_ROUTE}/artists/${artistId}`, data);
  },
  updatePhoto: (artistId, photo) => {
    return httpPostFormData(
      `${GENERATOR_ROUTE}/artists/${artistId}/photo`,
      objectToFormData({ photo }),
    );
  },
  updateExample: (artistId, example) => {
    return httpPostFormData(
      `${GENERATOR_ROUTE}/artists/${artistId}/example`,
      objectToFormData({ example }),
    );
  },
  delete: (artistId) => {
    return httpDelete(`${GENERATOR_ROUTE}/artists/${artistId}`);
  },
  getSchemes: (artistId) => {
    return httpGet(`${GENERATOR_ROUTE}/artists/${artistId}/schemes`);
  },
  updateSlot: (artistId, schemeId, slotId, data) => {
    return httpPut(
      `${GENERATOR_ROUTE}/artists/${artistId}/schemes/${schemeId}/slots/${slotId}`,
      data,
    );
  },
  getIntroScheme: (artistId, segmentId) => {
    return httpGet(`${GENERATOR_ROUTE}/artists/${artistId}/segments/${segmentId}/intro`);
  },
  uploadIntro: (artistId, segmentId, file, onProgressChange) => {
    return uploadWithProgress(
      `${GENERATOR_ROUTE}/artists/${artistId}/segments/${segmentId}/intro`,
      objectToFormData(file),
      onProgressChange,
    );
  },
};
