import React, { useState } from 'react';
import useLoadOrders, { OrderFiltersType } from '../../requests/useLoadOrders';
import OrderCard from './components/OrderCard';
import { StyledOrdersWrapper, StyledOrdersHeader } from '../../styled';
import OrderCardSkeleton from './components/OrderCardSkeleton';
import { ListNoItems, SkeletonRepeater } from '../../../../shared/components/custom';
import OrdersFilter from './components/OrdersFilter';
import { useScopedTranslation } from '../../../../shared/hooks';

const OrdersList = () => {
  const { t } = useScopedTranslation('order.list');
  const [params, setParams] = useState<OrderFiltersType>({});
  const { orders, getOrders, hasMore, isLoading, meta } = useLoadOrders({ limit: 15, ...params });

  const loadMore = () => {
    getOrders();
  };

  return (
    <>
      <OrdersFilter setParams={setParams} count={meta?.count ?? 0} />
      <StyledOrdersHeader>
        <p>{t('info')}</p>
        <p>{t('customer')}</p>
        <p>{t('order_status')}</p>
        <p>{t('options_status')}</p>
      </StyledOrdersHeader>
      <StyledOrdersWrapper loadMore={loadMore} initialLoad={false} hasMore={hasMore}>
        {(!!orders.length && orders.map((order) => <OrderCard order={order} key={order.id} />)) ||
          (!isLoading && <ListNoItems title={t('no_orders')} query={params.search} />)}
        {isLoading && (
          <SkeletonRepeater key={0} number={10}>
            <OrderCardSkeleton />
          </SkeletonRepeater>
        )}
      </StyledOrdersWrapper>
    </>
  );
};

export default OrdersList;
