import React from 'react';
import {
  StyledDebugHeader,
  StyledDebugRow,
  StyledFileString,
  StyledLyricsWrapper,
  StyledTrackDataWrapper,
} from '../../styled';
import { AudioPlayer, Tabs } from '../../../../../shared/components/ui';
import { useScopedTranslation } from '../../../../../shared/hooks';
import useGeneratorContext from '../../hooks/useGeneratorContext';

const ResultData = () => {
  const { t } = useScopedTranslation('production.generator');
  const { result } = useGeneratorContext();

  return (
    (!!result?.files && (
      <StyledTrackDataWrapper>
        <Tabs tabs={[t('lyrics'), t('used_files'), t('debug_data')]}>
          <StyledLyricsWrapper>{result.track.lyrics}</StyledLyricsWrapper>
          <div>
            {result.files.map((file, index) => (
              <StyledFileString key={`${file}-${index}`}>{file}</StyledFileString>
            ))}
          </div>
          <div>
            {result.debug_data?.map((track) => (
              <StyledDebugRow key={track.label}>
                <StyledDebugHeader>{track.label}</StyledDebugHeader>
                <AudioPlayer src={track.url} withProgress withDuration download />
              </StyledDebugRow>
            ))}
          </div>
        </Tabs>
      </StyledTrackDataWrapper>
    )) || <></>
  );
};

export default ResultData;
